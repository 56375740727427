import { DateCalendar, DayCalendarSkeleton, PickersDay } from "@mui/x-date-pickers";
import { useCallback, useMemo } from "react";
import { useTheme } from "@mui/system";
import { defaultTheme } from "../../config/theme";
import { AvailableTimeslotsQuery } from "../../graphql/graphql";
import testId from "../../../../cypress/support/testId";
import { defaultTicketDataForDay, getTicketRatios } from "../../util/service.util";

type Props = {
  selectedDate: Date;
  onChange: (date: Date) => void;
  month: Date;
  setMonth: (date: Date) => void;
  availableTimeslots?: AvailableTimeslotsQuery["availableTimeslots"];
  disabled?: boolean;
};

export const DatePickerCalendar = ({
  selectedDate,
  onChange,
  month,
  setMonth,
  availableTimeslots,
  disabled,
}: Props) => {
  const theme = useTheme(defaultTheme);

  const ticketRatios = useMemo(
    () => getTicketRatios(availableTimeslots),
    [availableTimeslots]
  );

  const getDayColor = useCallback(
    (day: number) => {
      if (!ticketRatios[day]) {
        return "Mui-disabled";
      }

      const { booked, max, hasServiceWithoutMax } = ticketRatios[day];
      const ticketRatio = (max - booked) / max;

      if (!ticketRatio && ticketRatio !== 0 && !hasServiceWithoutMax) {
        return "Mui-disabled";
      }

      if (ticketRatio >= 0.2 || hasServiceWithoutMax) {
        return "green";
      } else if (ticketRatio > 0) {
        return "yellow";
      } else {
        return "grey Mui-disabled";
      }
    },
    [ticketRatios]
  );

  return (
    <DateCalendar
      value={selectedDate}
      onChange={(date, selectionState) => selectionState === "finish" && onChange(date)}
      defaultCalendarMonth={month}
      onMonthChange={setMonth}
      onYearChange={setMonth}
      renderLoading={() => <DayCalendarSkeleton />}
      disablePast
      disabled={disabled}
      slots={{
        day: (props) => (
          <PickersDay
            {...props}
            className={getDayColor(props.day.getDate())}
            data-cy={testId.calendar.day(props.day.getDate())}
          />
        ),
      }}
      sx={{
        maxHeight: "376px",
        height: "376px",
        "& .MuiPickersDay-root": {
          fontSize: "16px",
          margin: "4px",
        },
        "& .MuiDayCalendar-weekDayLabel": {
          fontSize: "16px",
          margin: "0 4px",
        },
        "& .MuiPickersDay-dayWithMargin": {
          borderRadius: 0,
        },
        "& .MuiPickersCalendarHeader-label": {
          fontWeight: 400,
        },
        "& .MuiPickersSlideTransition-root": {
          height: "280px",
        },
        ".green:not(.Mui-disabled)": {
          backgroundColor: theme.palette.primary.main,
          color: "#ffffff",
        },
        ".yellow:not(.Mui-disabled)": {
          backgroundColor: theme.palette.warning.main,
          color: "#ffffff",
        },
        ".grey": {
          backgroundColor: "#C6C6C6", // TODO: use theme
          color: "#ffffff !important",
        },
      }}
    />
  );
};
