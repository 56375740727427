import React from "react";
import { MainLayout } from "../../layouts/MainLayout/MainLayout";
import { useUserStore } from "../../store/user.store";
import { useTenantStore } from "../../store/tenant.store";
import { ServiceSelector } from "../../components/ServiceSelector/ServiceSelector";
import { useEffect, useMemo } from "react";
import { useStep } from "../../hooks/useStep";
import {
  Service,
  getTimeslotsWithTicketsForDateByService,
} from "../../util/service.util";
import { SelectedChoices } from "../../components/SelectedChoiceDisplay";
import { useLocation } from "wouter";
import {
  BookingFlowEntryType,
  BookingFlowType,
} from "../../../models/constants/BookingFlowType";
import { useTenant } from "../../hooks/useTenant";

export const ServicePage = () => {
  const [, setLocation] = useLocation();
  const tenant = useTenant();
  const {
    selectedDate,
    selectedEvent,
    selectedTickets,
    setSelectedEvent,
    setSelectedTimeslot,
    clearSelectedTickets,
    bookingFlowType,
    bookingFlowEntryType,
  } = useUserStore();
  const { availableTimeslots, services, fetchServices } = useTenantStore();
  const { goForward, goBack } = useStep();

  useEffect(() => {
    setSelectedEvent(null);
  }, []);

  useEffect(() => {
    if (bookingFlowEntryType === BookingFlowEntryType.VoucherRedeem) return;

    if (
      Object.keys({ ...selectedTickets.tickets, ...selectedTickets.extras }).length > 0
    ) {
      clearSelectedTickets();
    }
  }, [selectedTickets, clearSelectedTickets, bookingFlowEntryType]);

  const timeslots = useMemo(
    () => getTimeslotsWithTicketsForDateByService(availableTimeslots, selectedDate),
    [availableTimeslots, selectedDate, getTimeslotsWithTicketsForDateByService]
  );

  const handleServiceSelect = (service: Service) => {
    if (selectedEvent?.id !== service.id) {
      clearSelectedTickets();
      setSelectedTimeslot(null);
    }
    setSelectedEvent({ id: service.id, name: service.title });
    goForward();
  };

  const servicesWithTimeslots =
    bookingFlowType === BookingFlowType.VoucherPurchase
      ? services.filter((service) => service.vouchersEnabled)
      : services.filter((service) => (timeslots[service.id] || [])?.length > 0);

  return (
    <MainLayout onBackButtonClick={goBack}>
      <SelectedChoices />
      <ServiceSelector
        services={servicesWithTimeslots}
        onDetails={({ id }) => setLocation(`/service/${id}`)}
        onSelect={handleServiceSelect}
      />
    </MainLayout>
  );
};
