import { useCallback, useMemo } from "react";
import { useLocation, useRoute } from "wouter";
import { useSearch } from "wouter/use-location";
import { UserStore, userStore } from "../store/user.store";
import {
  BookingFlowEntryType,
  BookingFlowType,
} from "../../models/constants/BookingFlowType";
import { useTenant } from "./useTenant";

export enum Step {
  Date = "DATE",
  Service = "SERVICE",
  Timeslot = "TIMESLOT",
  Tickets = "TICKETS",
  Overview = "OVERVIEW",
}

type StepDefinition = {
  route: string;
  isSkippable: (UserStore) => boolean;
};

export const stepOrder: Step[] = [
  Step.Date,
  Step.Service,
  Step.Timeslot,
  Step.Tickets,
  Step.Overview,
];

const orderByStep = stepOrder.reduce((acc, s, i) => ({ [s]: i, ...acc }), {});

const stepDefinitions: Record<Step, StepDefinition> = {
  [Step.Date]: {
    route: "date",
    isSkippable: (userStore: UserStore) => false,
  },
  [Step.Service]: {
    route: "service",
    isSkippable: (userStore: UserStore) => {
      const hasSelectedEvent = Boolean(userStore?.selectedEvent);
      return (
        (userStore.bookingFlowEntryType === BookingFlowEntryType.ServicePreselect &&
          hasSelectedEvent) ||
        userStore.bookingFlowEntryType === BookingFlowEntryType.VoucherRedeem ||
        (userStore.bookingFlowEntryType !== BookingFlowEntryType.Default &&
          userStore.bookingFlowType === BookingFlowType.VoucherPurchase &&
          Boolean(userStore?.selectedEvent))
      );
    },
  },
  [Step.Timeslot]: {
    route: "timeslot",
    isSkippable: (userStore: UserStore) =>
      userStore.bookingFlowType === BookingFlowType.VoucherPurchase,
  },
  [Step.Tickets]: {
    route: "tickets",
    isSkippable: (userStore: UserStore) =>
      userStore.bookingFlowEntryType === BookingFlowEntryType.VoucherRedeem,
  },
  [Step.Overview]: {
    route: "overview",
    isSkippable: (userStore: UserStore) => false,
  },
};

export const useStep = () => {
  const [location, setLocation] = useLocation();
  const [, params] = useRoute<{ step?: string }>("/:step*");
  const tenant = useTenant();
  const searchParams = useSearch();

  const search = useMemo(() => new URLSearchParams(searchParams), [searchParams]);

  const deleteSearch = useCallback(
    (key: string) => {
      search.delete(key);
      const newSearch = search.toString();
      setLocation(newSearch.length > 0 ? `${location}?${newSearch}` : location, {
        replace: true,
      });
    },
    [search, location, setLocation]
  );

  const setSearch = useCallback(
    (key: string, value: string) => {
      if (value === null) {
        deleteSearch(key);
        return;
      }

      search.set(key, value);
      setLocation(`${location}?${search.toString()}`);
    },
    [search, location, setLocation]
  );

  const currentStep = useMemo(() => {
    if (!params?.step) {
      return Step.Date;
    }
    const routeMatch = Object.keys(stepDefinitions).filter(
      (sd) => stepDefinitions[sd].route === params.step
    );
    if (routeMatch.length > 0) {
      return routeMatch[0];
    }
    return null;
  }, [params]);

  const currentStepIndex = useMemo(() => {
    if (!currentStep) {
      return null;
    }
    return stepOrder.findIndex((s) => s === currentStep);
  }, [currentStep]);

  const getNonSkippableSteps = useCallback(() => {
    const userStoreState = userStore.getState();
    const nonSkippableSteps = stepOrder.filter(
      (s) => !stepDefinitions[s].isSkippable(userStoreState)
    );
    return nonSkippableSteps;
  }, [setLocation]);

  const goTo = useCallback((path: string) => setLocation(path), [setLocation]);

  const goToStep = useCallback(
    (step: Step) => {
      const steps = getNonSkippableSteps();
      const stepIndices = steps.map((s) => orderByStep[s]);
      const targetStepIndex = orderByStep[step];
      const nextStep =
        stepIndices[
          stepIndices.findIndex((s) => s >= targetStepIndex) ?? currentStepIndex
        ];
      goTo(`/${stepDefinitions[stepOrder[nextStep]].route}`);
    },
    [setLocation]
  );

  const goForward = useCallback(() => {
    const steps = getNonSkippableSteps();
    const stepIndices = steps.map((s) => orderByStep[s]);
    const nextStep =
      stepIndices[stepIndices.findIndex((s) => s > currentStepIndex) ?? currentStepIndex];
    goToStep(stepOrder[nextStep] as Step);
  }, [tenant, setLocation]);

  const goBack = useCallback(() => {
    if (search.size > 0) {
      const lastKey = Array.from(search.keys()).pop();
      deleteSearch(lastKey);
      return;
    }

    const steps = getNonSkippableSteps();
    const stepIndices = steps.map((s) => orderByStep[s]);
    const previousStep =
      stepIndices[
        stepIndices.findLastIndex((s) => s < currentStepIndex) ?? currentStepIndex
      ];

    goToStep(stepOrder[previousStep]);
  }, [currentStep, tenant, setLocation, search, setSearch]);

  const canStepBack = currentStepIndex !== 0;

  return {
    currentStep,
    currentStepIndex,
    getNonSkippableSteps,
    goTo,
    goToStep,
    goBack,
    goForward,
    canStepBack,
    search,
    setSearch,
  };
};
