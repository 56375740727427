import React from "react";
import { Box, Stack } from "@mui/system";
import { BookingFlowButton } from "./BookingFlowButton";
import CalendarIcon from "../../icons/CalendarIcon.svg";
import TicketIcon from "../../icons/TicketIcon.svg";
import EyeIcon from "../../icons/EyeIcon.svg";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { useStep } from "../../hooks/useStep";
import { useUserStore } from "../../store/user.store";
import { useTenantStore } from "../../store/tenant.store";
import { useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import {
  BookingFlowEntryType,
  BookingFlowType,
} from "../../../models/constants/BookingFlowType";

type Props = {};

export const BookingFlowSelector = ({}: Props) => {
  const { t } = useFeTranslation();
  const { goForward } = useStep();
  const {
    setBookingFlowType,
    bookingFlowType,
    bookingFlowEntryType,
    selectedEvent,
    setVoucherCode,
  } = useUserStore();
  const { services } = useTenantStore();

  const handleVoucherFlowButtonClick = () => {
    setBookingFlowType(BookingFlowType.VoucherPurchase);
    goForward();
  };

  const handleFullBookingFlowButtonClick = () => {
    setBookingFlowType(BookingFlowType.FullBooking);
  };

  if (bookingFlowEntryType === BookingFlowEntryType.VoucherRedeem) {
    return null;
  }

  return (
    <Stack
      direction="row"
      gap="14px"
      margin="20px 0"
      sx={{
        "> *": {
          width: "50%",
        },
      }}
    >
      <BookingFlowButton
        icon={CalendarIcon}
        onClick={handleFullBookingFlowButtonClick}
        selected={
          bookingFlowType === BookingFlowType.FullBooking ||
          bookingFlowType === BookingFlowType.ServicePreselect
        }
        infoText={t("pages.date.bookWithTimeInfo")}
      >
        {t("pages.date.bookWithTime")}
      </BookingFlowButton>
      <BookingFlowButton
        icon={TicketIcon}
        onClick={handleVoucherFlowButtonClick}
        selected={bookingFlowType === BookingFlowType.VoucherPurchase}
        infoText={t("pages.date.bookWithoutTimeInfo")}
      >
        {t("pages.date.bookWithoutTime")}
      </BookingFlowButton>
    </Stack>
  );
};
