import hu from "./hu/translation.json";
import payloadHu from "./hu/payloadTranslation.json";
import en from "./en/translation.json";
import payloadEn from "./en/payloadTranslation.json";
import ee from "./ee/translation.json";
import payloadEe from "./ee/payloadTranslation.json";
import it from "./it/translation.json";
import payloadIt from "./it/payloadTranslation.json";

export type Translation = typeof hu;

// this way we get a type error if keys don't match in the translation files
const locales: Record<
  string,
  { translation: Translation; general: Record<string, string> }
> = {
  hu: {
    translation: hu,
    ...payloadHu,
  },
  en: {
    translation: en,
    ...payloadEn,
  },
  ee: {
    translation: ee,
    ...payloadEe,
  },
  it: {
    translation: it,
    ...payloadIt,
  },
};

export default locales;
