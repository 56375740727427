import { GraphQLClient, ClientError } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
import useSWR, { SWRConfiguration as SWRConfigInterface, Key as SWRKeyInterface } from 'swr';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
};

export type Access = {
  __typename?: 'Access';
  bookings?: Maybe<BookingsAccess>;
  canAccessAdmin: Scalars['Boolean']['output'];
  checkouts?: Maybe<CheckoutsAccess>;
  companyDetails?: Maybe<CompanyDetailsAccess>;
  companyDetailsGlobals?: Maybe<CompanyDetailsGlobalsAccess>;
  contacts?: Maybe<ContactsAccess>;
  events?: Maybe<EventsAccess>;
  extras?: Maybe<ExtrasAccess>;
  integrations?: Maybe<IntegrationsAccess>;
  integrationsGlobals?: Maybe<IntegrationsGlobalsAccess>;
  media?: Maybe<MediaAccess>;
  messages?: Maybe<MessagesAccess>;
  outgoingMessages?: Maybe<OutgoingMessagesAccess>;
  payload_preferences?: Maybe<Payload_PreferencesAccess>;
  paymentDetails?: Maybe<PaymentDetailsAccess>;
  paymentDetailsGlobals?: Maybe<PaymentDetailsGlobalsAccess>;
  services?: Maybe<ServicesAccess>;
  tenants?: Maybe<TenantsAccess>;
  ticketTypes?: Maybe<TicketTypesAccess>;
  users?: Maybe<UsersAccess>;
  vouchers?: Maybe<VouchersAccess>;
};

export type AdminTenantsResponse = {
  __typename?: 'AdminTenantsResponse';
  id?: Maybe<Scalars['ID']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type AvailableTimeslotResponse = {
  __typename?: 'AvailableTimeslotResponse';
  bookedTickets?: Maybe<Array<Maybe<BookedTicketsResponse>>>;
  service?: Maybe<Service>;
};

export type BookedTicketsResponse = {
  __typename?: 'BookedTicketsResponse';
  extras?: Maybe<Array<Maybe<TimeslotExtrasResponse>>>;
  tickets?: Maybe<Array<Maybe<TimeslotTicketsResponse>>>;
  timeslot?: Maybe<TimeslotResponse>;
};

export type Booking = {
  __typename?: 'Booking';
  bookingNumber?: Maybe<Scalars['String']['output']>;
  bookingStatus?: Maybe<Booking_BookingStatus>;
  checkoutID?: Maybe<Scalars['String']['output']>;
  contact: Contact;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  event: Event;
  extras?: Maybe<Array<BookingExtras>>;
  id?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  paidTimestamp?: Maybe<Scalars['DateTime']['output']>;
  stripePaymentIntentID?: Maybe<Scalars['String']['output']>;
  tenant?: Maybe<Tenant>;
  tickets?: Maybe<Array<BookingTickets>>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  voucher?: Maybe<Voucher>;
};


export type BookingContactArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};


export type BookingEventArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};


export type BookingTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};


export type BookingVoucherArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export enum BookingUpdate_BookingStatus_MutationInput {
  Cancelled = 'CANCELLED',
  Paid = 'PAID'
}

export type Booking_BookingNumber_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum Booking_BookingStatus {
  Cancelled = 'CANCELLED',
  Paid = 'PAID'
}

export enum Booking_BookingStatus_Input {
  Cancelled = 'CANCELLED',
  Paid = 'PAID'
}

export type Booking_BookingStatus_Operator = {
  all?: InputMaybe<Array<InputMaybe<Booking_BookingStatus_Input>>>;
  equals?: InputMaybe<Booking_BookingStatus_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Booking_BookingStatus_Input>>>;
  not_equals?: InputMaybe<Booking_BookingStatus_Input>;
  not_in?: InputMaybe<Array<InputMaybe<Booking_BookingStatus_Input>>>;
};

export type Booking_CheckoutId_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Booking_Contact_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Booking_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Booking_Event_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Booking_Extras__Count_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Booking_Extras__Extra_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Booking_Extras__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Booking_Extras__Price_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Booking_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Booking_Locale_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Booking_PaidTimestamp_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Booking_StripePaymentIntentId_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Booking_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Booking_Tickets__Count_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Booking_Tickets__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Booking_Tickets__Price_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Booking_Tickets__Ticket_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Booking_TotalPrice_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Booking_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Booking_Voucher_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Booking_Where = {
  AND?: InputMaybe<Array<InputMaybe<Booking_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Booking_Where_Or>>>;
  bookingNumber?: InputMaybe<Booking_BookingNumber_Operator>;
  bookingStatus?: InputMaybe<Booking_BookingStatus_Operator>;
  checkoutID?: InputMaybe<Booking_CheckoutId_Operator>;
  contact?: InputMaybe<Booking_Contact_Operator>;
  createdAt?: InputMaybe<Booking_CreatedAt_Operator>;
  event?: InputMaybe<Booking_Event_Operator>;
  extras__count?: InputMaybe<Booking_Extras__Count_Operator>;
  extras__extra?: InputMaybe<Booking_Extras__Extra_Operator>;
  extras__id?: InputMaybe<Booking_Extras__Id_Operator>;
  extras__price?: InputMaybe<Booking_Extras__Price_Operator>;
  id?: InputMaybe<Booking_Id_Operator>;
  locale?: InputMaybe<Booking_Locale_Operator>;
  paidTimestamp?: InputMaybe<Booking_PaidTimestamp_Operator>;
  stripePaymentIntentID?: InputMaybe<Booking_StripePaymentIntentId_Operator>;
  tenant?: InputMaybe<Booking_Tenant_Operator>;
  tickets__count?: InputMaybe<Booking_Tickets__Count_Operator>;
  tickets__id?: InputMaybe<Booking_Tickets__Id_Operator>;
  tickets__price?: InputMaybe<Booking_Tickets__Price_Operator>;
  tickets__ticket?: InputMaybe<Booking_Tickets__Ticket_Operator>;
  totalPrice?: InputMaybe<Booking_TotalPrice_Operator>;
  updatedAt?: InputMaybe<Booking_UpdatedAt_Operator>;
  voucher?: InputMaybe<Booking_Voucher_Operator>;
};

export type Booking_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Booking_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Booking_Where_Or>>>;
  bookingNumber?: InputMaybe<Booking_BookingNumber_Operator>;
  bookingStatus?: InputMaybe<Booking_BookingStatus_Operator>;
  checkoutID?: InputMaybe<Booking_CheckoutId_Operator>;
  contact?: InputMaybe<Booking_Contact_Operator>;
  createdAt?: InputMaybe<Booking_CreatedAt_Operator>;
  event?: InputMaybe<Booking_Event_Operator>;
  extras__count?: InputMaybe<Booking_Extras__Count_Operator>;
  extras__extra?: InputMaybe<Booking_Extras__Extra_Operator>;
  extras__id?: InputMaybe<Booking_Extras__Id_Operator>;
  extras__price?: InputMaybe<Booking_Extras__Price_Operator>;
  id?: InputMaybe<Booking_Id_Operator>;
  locale?: InputMaybe<Booking_Locale_Operator>;
  paidTimestamp?: InputMaybe<Booking_PaidTimestamp_Operator>;
  stripePaymentIntentID?: InputMaybe<Booking_StripePaymentIntentId_Operator>;
  tenant?: InputMaybe<Booking_Tenant_Operator>;
  tickets__count?: InputMaybe<Booking_Tickets__Count_Operator>;
  tickets__id?: InputMaybe<Booking_Tickets__Id_Operator>;
  tickets__price?: InputMaybe<Booking_Tickets__Price_Operator>;
  tickets__ticket?: InputMaybe<Booking_Tickets__Ticket_Operator>;
  totalPrice?: InputMaybe<Booking_TotalPrice_Operator>;
  updatedAt?: InputMaybe<Booking_UpdatedAt_Operator>;
  voucher?: InputMaybe<Booking_Voucher_Operator>;
};

export type Booking_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Booking_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Booking_Where_Or>>>;
  bookingNumber?: InputMaybe<Booking_BookingNumber_Operator>;
  bookingStatus?: InputMaybe<Booking_BookingStatus_Operator>;
  checkoutID?: InputMaybe<Booking_CheckoutId_Operator>;
  contact?: InputMaybe<Booking_Contact_Operator>;
  createdAt?: InputMaybe<Booking_CreatedAt_Operator>;
  event?: InputMaybe<Booking_Event_Operator>;
  extras__count?: InputMaybe<Booking_Extras__Count_Operator>;
  extras__extra?: InputMaybe<Booking_Extras__Extra_Operator>;
  extras__id?: InputMaybe<Booking_Extras__Id_Operator>;
  extras__price?: InputMaybe<Booking_Extras__Price_Operator>;
  id?: InputMaybe<Booking_Id_Operator>;
  locale?: InputMaybe<Booking_Locale_Operator>;
  paidTimestamp?: InputMaybe<Booking_PaidTimestamp_Operator>;
  stripePaymentIntentID?: InputMaybe<Booking_StripePaymentIntentId_Operator>;
  tenant?: InputMaybe<Booking_Tenant_Operator>;
  tickets__count?: InputMaybe<Booking_Tickets__Count_Operator>;
  tickets__id?: InputMaybe<Booking_Tickets__Id_Operator>;
  tickets__price?: InputMaybe<Booking_Tickets__Price_Operator>;
  tickets__ticket?: InputMaybe<Booking_Tickets__Ticket_Operator>;
  totalPrice?: InputMaybe<Booking_TotalPrice_Operator>;
  updatedAt?: InputMaybe<Booking_UpdatedAt_Operator>;
  voucher?: InputMaybe<Booking_Voucher_Operator>;
};

export type Bookings = {
  __typename?: 'Bookings';
  docs?: Maybe<Array<Maybe<Booking>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type BookingsCreateAccess = {
  __typename?: 'BookingsCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type BookingsCreateDocAccess = {
  __typename?: 'BookingsCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type BookingsDeleteAccess = {
  __typename?: 'BookingsDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type BookingsDeleteDocAccess = {
  __typename?: 'BookingsDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type BookingsDocAccessFields = {
  __typename?: 'BookingsDocAccessFields';
  bookingNumber?: Maybe<BookingsDocAccessFields_BookingNumber>;
  bookingStatus?: Maybe<BookingsDocAccessFields_BookingStatus>;
  checkoutID?: Maybe<BookingsDocAccessFields_CheckoutId>;
  contact?: Maybe<BookingsDocAccessFields_Contact>;
  createdAt?: Maybe<BookingsDocAccessFields_CreatedAt>;
  event?: Maybe<BookingsDocAccessFields_Event>;
  extras?: Maybe<BookingsDocAccessFields_Extras>;
  locale?: Maybe<BookingsDocAccessFields_Locale>;
  paidTimestamp?: Maybe<BookingsDocAccessFields_PaidTimestamp>;
  stripePaymentIntentID?: Maybe<BookingsDocAccessFields_StripePaymentIntentId>;
  tenant?: Maybe<BookingsDocAccessFields_Tenant>;
  tickets?: Maybe<BookingsDocAccessFields_Tickets>;
  totalPrice?: Maybe<BookingsDocAccessFields_TotalPrice>;
  updatedAt?: Maybe<BookingsDocAccessFields_UpdatedAt>;
  voucher?: Maybe<BookingsDocAccessFields_Voucher>;
};

export type BookingsDocAccessFields_BookingNumber = {
  __typename?: 'BookingsDocAccessFields_bookingNumber';
  create?: Maybe<BookingsDocAccessFields_BookingNumber_Create>;
  delete?: Maybe<BookingsDocAccessFields_BookingNumber_Delete>;
  read?: Maybe<BookingsDocAccessFields_BookingNumber_Read>;
  update?: Maybe<BookingsDocAccessFields_BookingNumber_Update>;
};

export type BookingsDocAccessFields_BookingNumber_Create = {
  __typename?: 'BookingsDocAccessFields_bookingNumber_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_BookingNumber_Delete = {
  __typename?: 'BookingsDocAccessFields_bookingNumber_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_BookingNumber_Read = {
  __typename?: 'BookingsDocAccessFields_bookingNumber_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_BookingNumber_Update = {
  __typename?: 'BookingsDocAccessFields_bookingNumber_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_BookingStatus = {
  __typename?: 'BookingsDocAccessFields_bookingStatus';
  create?: Maybe<BookingsDocAccessFields_BookingStatus_Create>;
  delete?: Maybe<BookingsDocAccessFields_BookingStatus_Delete>;
  read?: Maybe<BookingsDocAccessFields_BookingStatus_Read>;
  update?: Maybe<BookingsDocAccessFields_BookingStatus_Update>;
};

export type BookingsDocAccessFields_BookingStatus_Create = {
  __typename?: 'BookingsDocAccessFields_bookingStatus_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_BookingStatus_Delete = {
  __typename?: 'BookingsDocAccessFields_bookingStatus_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_BookingStatus_Read = {
  __typename?: 'BookingsDocAccessFields_bookingStatus_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_BookingStatus_Update = {
  __typename?: 'BookingsDocAccessFields_bookingStatus_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_CheckoutId = {
  __typename?: 'BookingsDocAccessFields_checkoutID';
  create?: Maybe<BookingsDocAccessFields_CheckoutId_Create>;
  delete?: Maybe<BookingsDocAccessFields_CheckoutId_Delete>;
  read?: Maybe<BookingsDocAccessFields_CheckoutId_Read>;
  update?: Maybe<BookingsDocAccessFields_CheckoutId_Update>;
};

export type BookingsDocAccessFields_CheckoutId_Create = {
  __typename?: 'BookingsDocAccessFields_checkoutID_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_CheckoutId_Delete = {
  __typename?: 'BookingsDocAccessFields_checkoutID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_CheckoutId_Read = {
  __typename?: 'BookingsDocAccessFields_checkoutID_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_CheckoutId_Update = {
  __typename?: 'BookingsDocAccessFields_checkoutID_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Contact = {
  __typename?: 'BookingsDocAccessFields_contact';
  create?: Maybe<BookingsDocAccessFields_Contact_Create>;
  delete?: Maybe<BookingsDocAccessFields_Contact_Delete>;
  read?: Maybe<BookingsDocAccessFields_Contact_Read>;
  update?: Maybe<BookingsDocAccessFields_Contact_Update>;
};

export type BookingsDocAccessFields_Contact_Create = {
  __typename?: 'BookingsDocAccessFields_contact_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Contact_Delete = {
  __typename?: 'BookingsDocAccessFields_contact_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Contact_Read = {
  __typename?: 'BookingsDocAccessFields_contact_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Contact_Update = {
  __typename?: 'BookingsDocAccessFields_contact_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_CreatedAt = {
  __typename?: 'BookingsDocAccessFields_createdAt';
  create?: Maybe<BookingsDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<BookingsDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<BookingsDocAccessFields_CreatedAt_Read>;
  update?: Maybe<BookingsDocAccessFields_CreatedAt_Update>;
};

export type BookingsDocAccessFields_CreatedAt_Create = {
  __typename?: 'BookingsDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_CreatedAt_Delete = {
  __typename?: 'BookingsDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_CreatedAt_Read = {
  __typename?: 'BookingsDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_CreatedAt_Update = {
  __typename?: 'BookingsDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Event = {
  __typename?: 'BookingsDocAccessFields_event';
  create?: Maybe<BookingsDocAccessFields_Event_Create>;
  delete?: Maybe<BookingsDocAccessFields_Event_Delete>;
  read?: Maybe<BookingsDocAccessFields_Event_Read>;
  update?: Maybe<BookingsDocAccessFields_Event_Update>;
};

export type BookingsDocAccessFields_Event_Create = {
  __typename?: 'BookingsDocAccessFields_event_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Event_Delete = {
  __typename?: 'BookingsDocAccessFields_event_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Event_Read = {
  __typename?: 'BookingsDocAccessFields_event_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Event_Update = {
  __typename?: 'BookingsDocAccessFields_event_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras = {
  __typename?: 'BookingsDocAccessFields_extras';
  create?: Maybe<BookingsDocAccessFields_Extras_Create>;
  delete?: Maybe<BookingsDocAccessFields_Extras_Delete>;
  fields?: Maybe<BookingsDocAccessFields_Extras_Fields>;
  read?: Maybe<BookingsDocAccessFields_Extras_Read>;
  update?: Maybe<BookingsDocAccessFields_Extras_Update>;
};

export type BookingsDocAccessFields_Extras_Create = {
  __typename?: 'BookingsDocAccessFields_extras_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Delete = {
  __typename?: 'BookingsDocAccessFields_extras_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Fields = {
  __typename?: 'BookingsDocAccessFields_extras_Fields';
  count?: Maybe<BookingsDocAccessFields_Extras_Count>;
  extra?: Maybe<BookingsDocAccessFields_Extras_Extra>;
  id?: Maybe<BookingsDocAccessFields_Extras_Id>;
  price?: Maybe<BookingsDocAccessFields_Extras_Price>;
};

export type BookingsDocAccessFields_Extras_Read = {
  __typename?: 'BookingsDocAccessFields_extras_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Update = {
  __typename?: 'BookingsDocAccessFields_extras_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Count = {
  __typename?: 'BookingsDocAccessFields_extras_count';
  create?: Maybe<BookingsDocAccessFields_Extras_Count_Create>;
  delete?: Maybe<BookingsDocAccessFields_Extras_Count_Delete>;
  read?: Maybe<BookingsDocAccessFields_Extras_Count_Read>;
  update?: Maybe<BookingsDocAccessFields_Extras_Count_Update>;
};

export type BookingsDocAccessFields_Extras_Count_Create = {
  __typename?: 'BookingsDocAccessFields_extras_count_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Count_Delete = {
  __typename?: 'BookingsDocAccessFields_extras_count_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Count_Read = {
  __typename?: 'BookingsDocAccessFields_extras_count_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Count_Update = {
  __typename?: 'BookingsDocAccessFields_extras_count_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Extra = {
  __typename?: 'BookingsDocAccessFields_extras_extra';
  create?: Maybe<BookingsDocAccessFields_Extras_Extra_Create>;
  delete?: Maybe<BookingsDocAccessFields_Extras_Extra_Delete>;
  read?: Maybe<BookingsDocAccessFields_Extras_Extra_Read>;
  update?: Maybe<BookingsDocAccessFields_Extras_Extra_Update>;
};

export type BookingsDocAccessFields_Extras_Extra_Create = {
  __typename?: 'BookingsDocAccessFields_extras_extra_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Extra_Delete = {
  __typename?: 'BookingsDocAccessFields_extras_extra_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Extra_Read = {
  __typename?: 'BookingsDocAccessFields_extras_extra_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Extra_Update = {
  __typename?: 'BookingsDocAccessFields_extras_extra_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Id = {
  __typename?: 'BookingsDocAccessFields_extras_id';
  create?: Maybe<BookingsDocAccessFields_Extras_Id_Create>;
  delete?: Maybe<BookingsDocAccessFields_Extras_Id_Delete>;
  read?: Maybe<BookingsDocAccessFields_Extras_Id_Read>;
  update?: Maybe<BookingsDocAccessFields_Extras_Id_Update>;
};

export type BookingsDocAccessFields_Extras_Id_Create = {
  __typename?: 'BookingsDocAccessFields_extras_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Id_Delete = {
  __typename?: 'BookingsDocAccessFields_extras_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Id_Read = {
  __typename?: 'BookingsDocAccessFields_extras_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Id_Update = {
  __typename?: 'BookingsDocAccessFields_extras_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Price = {
  __typename?: 'BookingsDocAccessFields_extras_price';
  create?: Maybe<BookingsDocAccessFields_Extras_Price_Create>;
  delete?: Maybe<BookingsDocAccessFields_Extras_Price_Delete>;
  read?: Maybe<BookingsDocAccessFields_Extras_Price_Read>;
  update?: Maybe<BookingsDocAccessFields_Extras_Price_Update>;
};

export type BookingsDocAccessFields_Extras_Price_Create = {
  __typename?: 'BookingsDocAccessFields_extras_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Price_Delete = {
  __typename?: 'BookingsDocAccessFields_extras_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Price_Read = {
  __typename?: 'BookingsDocAccessFields_extras_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Extras_Price_Update = {
  __typename?: 'BookingsDocAccessFields_extras_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Locale = {
  __typename?: 'BookingsDocAccessFields_locale';
  create?: Maybe<BookingsDocAccessFields_Locale_Create>;
  delete?: Maybe<BookingsDocAccessFields_Locale_Delete>;
  read?: Maybe<BookingsDocAccessFields_Locale_Read>;
  update?: Maybe<BookingsDocAccessFields_Locale_Update>;
};

export type BookingsDocAccessFields_Locale_Create = {
  __typename?: 'BookingsDocAccessFields_locale_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Locale_Delete = {
  __typename?: 'BookingsDocAccessFields_locale_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Locale_Read = {
  __typename?: 'BookingsDocAccessFields_locale_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Locale_Update = {
  __typename?: 'BookingsDocAccessFields_locale_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_PaidTimestamp = {
  __typename?: 'BookingsDocAccessFields_paidTimestamp';
  create?: Maybe<BookingsDocAccessFields_PaidTimestamp_Create>;
  delete?: Maybe<BookingsDocAccessFields_PaidTimestamp_Delete>;
  read?: Maybe<BookingsDocAccessFields_PaidTimestamp_Read>;
  update?: Maybe<BookingsDocAccessFields_PaidTimestamp_Update>;
};

export type BookingsDocAccessFields_PaidTimestamp_Create = {
  __typename?: 'BookingsDocAccessFields_paidTimestamp_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_PaidTimestamp_Delete = {
  __typename?: 'BookingsDocAccessFields_paidTimestamp_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_PaidTimestamp_Read = {
  __typename?: 'BookingsDocAccessFields_paidTimestamp_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_PaidTimestamp_Update = {
  __typename?: 'BookingsDocAccessFields_paidTimestamp_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_StripePaymentIntentId = {
  __typename?: 'BookingsDocAccessFields_stripePaymentIntentID';
  create?: Maybe<BookingsDocAccessFields_StripePaymentIntentId_Create>;
  delete?: Maybe<BookingsDocAccessFields_StripePaymentIntentId_Delete>;
  read?: Maybe<BookingsDocAccessFields_StripePaymentIntentId_Read>;
  update?: Maybe<BookingsDocAccessFields_StripePaymentIntentId_Update>;
};

export type BookingsDocAccessFields_StripePaymentIntentId_Create = {
  __typename?: 'BookingsDocAccessFields_stripePaymentIntentID_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_StripePaymentIntentId_Delete = {
  __typename?: 'BookingsDocAccessFields_stripePaymentIntentID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_StripePaymentIntentId_Read = {
  __typename?: 'BookingsDocAccessFields_stripePaymentIntentID_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_StripePaymentIntentId_Update = {
  __typename?: 'BookingsDocAccessFields_stripePaymentIntentID_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tenant = {
  __typename?: 'BookingsDocAccessFields_tenant';
  create?: Maybe<BookingsDocAccessFields_Tenant_Create>;
  delete?: Maybe<BookingsDocAccessFields_Tenant_Delete>;
  read?: Maybe<BookingsDocAccessFields_Tenant_Read>;
  update?: Maybe<BookingsDocAccessFields_Tenant_Update>;
};

export type BookingsDocAccessFields_Tenant_Create = {
  __typename?: 'BookingsDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tenant_Delete = {
  __typename?: 'BookingsDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tenant_Read = {
  __typename?: 'BookingsDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tenant_Update = {
  __typename?: 'BookingsDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets = {
  __typename?: 'BookingsDocAccessFields_tickets';
  create?: Maybe<BookingsDocAccessFields_Tickets_Create>;
  delete?: Maybe<BookingsDocAccessFields_Tickets_Delete>;
  fields?: Maybe<BookingsDocAccessFields_Tickets_Fields>;
  read?: Maybe<BookingsDocAccessFields_Tickets_Read>;
  update?: Maybe<BookingsDocAccessFields_Tickets_Update>;
};

export type BookingsDocAccessFields_Tickets_Create = {
  __typename?: 'BookingsDocAccessFields_tickets_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Delete = {
  __typename?: 'BookingsDocAccessFields_tickets_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Fields = {
  __typename?: 'BookingsDocAccessFields_tickets_Fields';
  count?: Maybe<BookingsDocAccessFields_Tickets_Count>;
  id?: Maybe<BookingsDocAccessFields_Tickets_Id>;
  price?: Maybe<BookingsDocAccessFields_Tickets_Price>;
  ticket?: Maybe<BookingsDocAccessFields_Tickets_Ticket>;
};

export type BookingsDocAccessFields_Tickets_Read = {
  __typename?: 'BookingsDocAccessFields_tickets_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Update = {
  __typename?: 'BookingsDocAccessFields_tickets_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Count = {
  __typename?: 'BookingsDocAccessFields_tickets_count';
  create?: Maybe<BookingsDocAccessFields_Tickets_Count_Create>;
  delete?: Maybe<BookingsDocAccessFields_Tickets_Count_Delete>;
  read?: Maybe<BookingsDocAccessFields_Tickets_Count_Read>;
  update?: Maybe<BookingsDocAccessFields_Tickets_Count_Update>;
};

export type BookingsDocAccessFields_Tickets_Count_Create = {
  __typename?: 'BookingsDocAccessFields_tickets_count_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Count_Delete = {
  __typename?: 'BookingsDocAccessFields_tickets_count_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Count_Read = {
  __typename?: 'BookingsDocAccessFields_tickets_count_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Count_Update = {
  __typename?: 'BookingsDocAccessFields_tickets_count_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Id = {
  __typename?: 'BookingsDocAccessFields_tickets_id';
  create?: Maybe<BookingsDocAccessFields_Tickets_Id_Create>;
  delete?: Maybe<BookingsDocAccessFields_Tickets_Id_Delete>;
  read?: Maybe<BookingsDocAccessFields_Tickets_Id_Read>;
  update?: Maybe<BookingsDocAccessFields_Tickets_Id_Update>;
};

export type BookingsDocAccessFields_Tickets_Id_Create = {
  __typename?: 'BookingsDocAccessFields_tickets_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Id_Delete = {
  __typename?: 'BookingsDocAccessFields_tickets_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Id_Read = {
  __typename?: 'BookingsDocAccessFields_tickets_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Id_Update = {
  __typename?: 'BookingsDocAccessFields_tickets_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Price = {
  __typename?: 'BookingsDocAccessFields_tickets_price';
  create?: Maybe<BookingsDocAccessFields_Tickets_Price_Create>;
  delete?: Maybe<BookingsDocAccessFields_Tickets_Price_Delete>;
  read?: Maybe<BookingsDocAccessFields_Tickets_Price_Read>;
  update?: Maybe<BookingsDocAccessFields_Tickets_Price_Update>;
};

export type BookingsDocAccessFields_Tickets_Price_Create = {
  __typename?: 'BookingsDocAccessFields_tickets_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Price_Delete = {
  __typename?: 'BookingsDocAccessFields_tickets_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Price_Read = {
  __typename?: 'BookingsDocAccessFields_tickets_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Price_Update = {
  __typename?: 'BookingsDocAccessFields_tickets_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Ticket = {
  __typename?: 'BookingsDocAccessFields_tickets_ticket';
  create?: Maybe<BookingsDocAccessFields_Tickets_Ticket_Create>;
  delete?: Maybe<BookingsDocAccessFields_Tickets_Ticket_Delete>;
  read?: Maybe<BookingsDocAccessFields_Tickets_Ticket_Read>;
  update?: Maybe<BookingsDocAccessFields_Tickets_Ticket_Update>;
};

export type BookingsDocAccessFields_Tickets_Ticket_Create = {
  __typename?: 'BookingsDocAccessFields_tickets_ticket_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Ticket_Delete = {
  __typename?: 'BookingsDocAccessFields_tickets_ticket_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Ticket_Read = {
  __typename?: 'BookingsDocAccessFields_tickets_ticket_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Tickets_Ticket_Update = {
  __typename?: 'BookingsDocAccessFields_tickets_ticket_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_TotalPrice = {
  __typename?: 'BookingsDocAccessFields_totalPrice';
  create?: Maybe<BookingsDocAccessFields_TotalPrice_Create>;
  delete?: Maybe<BookingsDocAccessFields_TotalPrice_Delete>;
  read?: Maybe<BookingsDocAccessFields_TotalPrice_Read>;
  update?: Maybe<BookingsDocAccessFields_TotalPrice_Update>;
};

export type BookingsDocAccessFields_TotalPrice_Create = {
  __typename?: 'BookingsDocAccessFields_totalPrice_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_TotalPrice_Delete = {
  __typename?: 'BookingsDocAccessFields_totalPrice_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_TotalPrice_Read = {
  __typename?: 'BookingsDocAccessFields_totalPrice_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_TotalPrice_Update = {
  __typename?: 'BookingsDocAccessFields_totalPrice_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_UpdatedAt = {
  __typename?: 'BookingsDocAccessFields_updatedAt';
  create?: Maybe<BookingsDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<BookingsDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<BookingsDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<BookingsDocAccessFields_UpdatedAt_Update>;
};

export type BookingsDocAccessFields_UpdatedAt_Create = {
  __typename?: 'BookingsDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'BookingsDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_UpdatedAt_Read = {
  __typename?: 'BookingsDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_UpdatedAt_Update = {
  __typename?: 'BookingsDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Voucher = {
  __typename?: 'BookingsDocAccessFields_voucher';
  create?: Maybe<BookingsDocAccessFields_Voucher_Create>;
  delete?: Maybe<BookingsDocAccessFields_Voucher_Delete>;
  read?: Maybe<BookingsDocAccessFields_Voucher_Read>;
  update?: Maybe<BookingsDocAccessFields_Voucher_Update>;
};

export type BookingsDocAccessFields_Voucher_Create = {
  __typename?: 'BookingsDocAccessFields_voucher_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Voucher_Delete = {
  __typename?: 'BookingsDocAccessFields_voucher_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Voucher_Read = {
  __typename?: 'BookingsDocAccessFields_voucher_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsDocAccessFields_Voucher_Update = {
  __typename?: 'BookingsDocAccessFields_voucher_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields = {
  __typename?: 'BookingsFields';
  bookingNumber?: Maybe<BookingsFields_BookingNumber>;
  bookingStatus?: Maybe<BookingsFields_BookingStatus>;
  checkoutID?: Maybe<BookingsFields_CheckoutId>;
  contact?: Maybe<BookingsFields_Contact>;
  createdAt?: Maybe<BookingsFields_CreatedAt>;
  event?: Maybe<BookingsFields_Event>;
  extras?: Maybe<BookingsFields_Extras>;
  locale?: Maybe<BookingsFields_Locale>;
  paidTimestamp?: Maybe<BookingsFields_PaidTimestamp>;
  stripePaymentIntentID?: Maybe<BookingsFields_StripePaymentIntentId>;
  tenant?: Maybe<BookingsFields_Tenant>;
  tickets?: Maybe<BookingsFields_Tickets>;
  totalPrice?: Maybe<BookingsFields_TotalPrice>;
  updatedAt?: Maybe<BookingsFields_UpdatedAt>;
  voucher?: Maybe<BookingsFields_Voucher>;
};

export type BookingsFields_BookingNumber = {
  __typename?: 'BookingsFields_bookingNumber';
  create?: Maybe<BookingsFields_BookingNumber_Create>;
  delete?: Maybe<BookingsFields_BookingNumber_Delete>;
  read?: Maybe<BookingsFields_BookingNumber_Read>;
  update?: Maybe<BookingsFields_BookingNumber_Update>;
};

export type BookingsFields_BookingNumber_Create = {
  __typename?: 'BookingsFields_bookingNumber_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_BookingNumber_Delete = {
  __typename?: 'BookingsFields_bookingNumber_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_BookingNumber_Read = {
  __typename?: 'BookingsFields_bookingNumber_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_BookingNumber_Update = {
  __typename?: 'BookingsFields_bookingNumber_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_BookingStatus = {
  __typename?: 'BookingsFields_bookingStatus';
  create?: Maybe<BookingsFields_BookingStatus_Create>;
  delete?: Maybe<BookingsFields_BookingStatus_Delete>;
  read?: Maybe<BookingsFields_BookingStatus_Read>;
  update?: Maybe<BookingsFields_BookingStatus_Update>;
};

export type BookingsFields_BookingStatus_Create = {
  __typename?: 'BookingsFields_bookingStatus_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_BookingStatus_Delete = {
  __typename?: 'BookingsFields_bookingStatus_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_BookingStatus_Read = {
  __typename?: 'BookingsFields_bookingStatus_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_BookingStatus_Update = {
  __typename?: 'BookingsFields_bookingStatus_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_CheckoutId = {
  __typename?: 'BookingsFields_checkoutID';
  create?: Maybe<BookingsFields_CheckoutId_Create>;
  delete?: Maybe<BookingsFields_CheckoutId_Delete>;
  read?: Maybe<BookingsFields_CheckoutId_Read>;
  update?: Maybe<BookingsFields_CheckoutId_Update>;
};

export type BookingsFields_CheckoutId_Create = {
  __typename?: 'BookingsFields_checkoutID_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_CheckoutId_Delete = {
  __typename?: 'BookingsFields_checkoutID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_CheckoutId_Read = {
  __typename?: 'BookingsFields_checkoutID_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_CheckoutId_Update = {
  __typename?: 'BookingsFields_checkoutID_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Contact = {
  __typename?: 'BookingsFields_contact';
  create?: Maybe<BookingsFields_Contact_Create>;
  delete?: Maybe<BookingsFields_Contact_Delete>;
  read?: Maybe<BookingsFields_Contact_Read>;
  update?: Maybe<BookingsFields_Contact_Update>;
};

export type BookingsFields_Contact_Create = {
  __typename?: 'BookingsFields_contact_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Contact_Delete = {
  __typename?: 'BookingsFields_contact_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Contact_Read = {
  __typename?: 'BookingsFields_contact_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Contact_Update = {
  __typename?: 'BookingsFields_contact_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_CreatedAt = {
  __typename?: 'BookingsFields_createdAt';
  create?: Maybe<BookingsFields_CreatedAt_Create>;
  delete?: Maybe<BookingsFields_CreatedAt_Delete>;
  read?: Maybe<BookingsFields_CreatedAt_Read>;
  update?: Maybe<BookingsFields_CreatedAt_Update>;
};

export type BookingsFields_CreatedAt_Create = {
  __typename?: 'BookingsFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_CreatedAt_Delete = {
  __typename?: 'BookingsFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_CreatedAt_Read = {
  __typename?: 'BookingsFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_CreatedAt_Update = {
  __typename?: 'BookingsFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Event = {
  __typename?: 'BookingsFields_event';
  create?: Maybe<BookingsFields_Event_Create>;
  delete?: Maybe<BookingsFields_Event_Delete>;
  read?: Maybe<BookingsFields_Event_Read>;
  update?: Maybe<BookingsFields_Event_Update>;
};

export type BookingsFields_Event_Create = {
  __typename?: 'BookingsFields_event_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Event_Delete = {
  __typename?: 'BookingsFields_event_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Event_Read = {
  __typename?: 'BookingsFields_event_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Event_Update = {
  __typename?: 'BookingsFields_event_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras = {
  __typename?: 'BookingsFields_extras';
  create?: Maybe<BookingsFields_Extras_Create>;
  delete?: Maybe<BookingsFields_Extras_Delete>;
  fields?: Maybe<BookingsFields_Extras_Fields>;
  read?: Maybe<BookingsFields_Extras_Read>;
  update?: Maybe<BookingsFields_Extras_Update>;
};

export type BookingsFields_Extras_Create = {
  __typename?: 'BookingsFields_extras_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Delete = {
  __typename?: 'BookingsFields_extras_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Fields = {
  __typename?: 'BookingsFields_extras_Fields';
  count?: Maybe<BookingsFields_Extras_Count>;
  extra?: Maybe<BookingsFields_Extras_Extra>;
  id?: Maybe<BookingsFields_Extras_Id>;
  price?: Maybe<BookingsFields_Extras_Price>;
};

export type BookingsFields_Extras_Read = {
  __typename?: 'BookingsFields_extras_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Update = {
  __typename?: 'BookingsFields_extras_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Count = {
  __typename?: 'BookingsFields_extras_count';
  create?: Maybe<BookingsFields_Extras_Count_Create>;
  delete?: Maybe<BookingsFields_Extras_Count_Delete>;
  read?: Maybe<BookingsFields_Extras_Count_Read>;
  update?: Maybe<BookingsFields_Extras_Count_Update>;
};

export type BookingsFields_Extras_Count_Create = {
  __typename?: 'BookingsFields_extras_count_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Count_Delete = {
  __typename?: 'BookingsFields_extras_count_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Count_Read = {
  __typename?: 'BookingsFields_extras_count_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Count_Update = {
  __typename?: 'BookingsFields_extras_count_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Extra = {
  __typename?: 'BookingsFields_extras_extra';
  create?: Maybe<BookingsFields_Extras_Extra_Create>;
  delete?: Maybe<BookingsFields_Extras_Extra_Delete>;
  read?: Maybe<BookingsFields_Extras_Extra_Read>;
  update?: Maybe<BookingsFields_Extras_Extra_Update>;
};

export type BookingsFields_Extras_Extra_Create = {
  __typename?: 'BookingsFields_extras_extra_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Extra_Delete = {
  __typename?: 'BookingsFields_extras_extra_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Extra_Read = {
  __typename?: 'BookingsFields_extras_extra_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Extra_Update = {
  __typename?: 'BookingsFields_extras_extra_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Id = {
  __typename?: 'BookingsFields_extras_id';
  create?: Maybe<BookingsFields_Extras_Id_Create>;
  delete?: Maybe<BookingsFields_Extras_Id_Delete>;
  read?: Maybe<BookingsFields_Extras_Id_Read>;
  update?: Maybe<BookingsFields_Extras_Id_Update>;
};

export type BookingsFields_Extras_Id_Create = {
  __typename?: 'BookingsFields_extras_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Id_Delete = {
  __typename?: 'BookingsFields_extras_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Id_Read = {
  __typename?: 'BookingsFields_extras_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Id_Update = {
  __typename?: 'BookingsFields_extras_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Price = {
  __typename?: 'BookingsFields_extras_price';
  create?: Maybe<BookingsFields_Extras_Price_Create>;
  delete?: Maybe<BookingsFields_Extras_Price_Delete>;
  read?: Maybe<BookingsFields_Extras_Price_Read>;
  update?: Maybe<BookingsFields_Extras_Price_Update>;
};

export type BookingsFields_Extras_Price_Create = {
  __typename?: 'BookingsFields_extras_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Price_Delete = {
  __typename?: 'BookingsFields_extras_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Price_Read = {
  __typename?: 'BookingsFields_extras_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Extras_Price_Update = {
  __typename?: 'BookingsFields_extras_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Locale = {
  __typename?: 'BookingsFields_locale';
  create?: Maybe<BookingsFields_Locale_Create>;
  delete?: Maybe<BookingsFields_Locale_Delete>;
  read?: Maybe<BookingsFields_Locale_Read>;
  update?: Maybe<BookingsFields_Locale_Update>;
};

export type BookingsFields_Locale_Create = {
  __typename?: 'BookingsFields_locale_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Locale_Delete = {
  __typename?: 'BookingsFields_locale_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Locale_Read = {
  __typename?: 'BookingsFields_locale_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Locale_Update = {
  __typename?: 'BookingsFields_locale_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_PaidTimestamp = {
  __typename?: 'BookingsFields_paidTimestamp';
  create?: Maybe<BookingsFields_PaidTimestamp_Create>;
  delete?: Maybe<BookingsFields_PaidTimestamp_Delete>;
  read?: Maybe<BookingsFields_PaidTimestamp_Read>;
  update?: Maybe<BookingsFields_PaidTimestamp_Update>;
};

export type BookingsFields_PaidTimestamp_Create = {
  __typename?: 'BookingsFields_paidTimestamp_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_PaidTimestamp_Delete = {
  __typename?: 'BookingsFields_paidTimestamp_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_PaidTimestamp_Read = {
  __typename?: 'BookingsFields_paidTimestamp_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_PaidTimestamp_Update = {
  __typename?: 'BookingsFields_paidTimestamp_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_StripePaymentIntentId = {
  __typename?: 'BookingsFields_stripePaymentIntentID';
  create?: Maybe<BookingsFields_StripePaymentIntentId_Create>;
  delete?: Maybe<BookingsFields_StripePaymentIntentId_Delete>;
  read?: Maybe<BookingsFields_StripePaymentIntentId_Read>;
  update?: Maybe<BookingsFields_StripePaymentIntentId_Update>;
};

export type BookingsFields_StripePaymentIntentId_Create = {
  __typename?: 'BookingsFields_stripePaymentIntentID_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_StripePaymentIntentId_Delete = {
  __typename?: 'BookingsFields_stripePaymentIntentID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_StripePaymentIntentId_Read = {
  __typename?: 'BookingsFields_stripePaymentIntentID_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_StripePaymentIntentId_Update = {
  __typename?: 'BookingsFields_stripePaymentIntentID_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tenant = {
  __typename?: 'BookingsFields_tenant';
  create?: Maybe<BookingsFields_Tenant_Create>;
  delete?: Maybe<BookingsFields_Tenant_Delete>;
  read?: Maybe<BookingsFields_Tenant_Read>;
  update?: Maybe<BookingsFields_Tenant_Update>;
};

export type BookingsFields_Tenant_Create = {
  __typename?: 'BookingsFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tenant_Delete = {
  __typename?: 'BookingsFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tenant_Read = {
  __typename?: 'BookingsFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tenant_Update = {
  __typename?: 'BookingsFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets = {
  __typename?: 'BookingsFields_tickets';
  create?: Maybe<BookingsFields_Tickets_Create>;
  delete?: Maybe<BookingsFields_Tickets_Delete>;
  fields?: Maybe<BookingsFields_Tickets_Fields>;
  read?: Maybe<BookingsFields_Tickets_Read>;
  update?: Maybe<BookingsFields_Tickets_Update>;
};

export type BookingsFields_Tickets_Create = {
  __typename?: 'BookingsFields_tickets_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Delete = {
  __typename?: 'BookingsFields_tickets_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Fields = {
  __typename?: 'BookingsFields_tickets_Fields';
  count?: Maybe<BookingsFields_Tickets_Count>;
  id?: Maybe<BookingsFields_Tickets_Id>;
  price?: Maybe<BookingsFields_Tickets_Price>;
  ticket?: Maybe<BookingsFields_Tickets_Ticket>;
};

export type BookingsFields_Tickets_Read = {
  __typename?: 'BookingsFields_tickets_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Update = {
  __typename?: 'BookingsFields_tickets_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Count = {
  __typename?: 'BookingsFields_tickets_count';
  create?: Maybe<BookingsFields_Tickets_Count_Create>;
  delete?: Maybe<BookingsFields_Tickets_Count_Delete>;
  read?: Maybe<BookingsFields_Tickets_Count_Read>;
  update?: Maybe<BookingsFields_Tickets_Count_Update>;
};

export type BookingsFields_Tickets_Count_Create = {
  __typename?: 'BookingsFields_tickets_count_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Count_Delete = {
  __typename?: 'BookingsFields_tickets_count_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Count_Read = {
  __typename?: 'BookingsFields_tickets_count_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Count_Update = {
  __typename?: 'BookingsFields_tickets_count_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Id = {
  __typename?: 'BookingsFields_tickets_id';
  create?: Maybe<BookingsFields_Tickets_Id_Create>;
  delete?: Maybe<BookingsFields_Tickets_Id_Delete>;
  read?: Maybe<BookingsFields_Tickets_Id_Read>;
  update?: Maybe<BookingsFields_Tickets_Id_Update>;
};

export type BookingsFields_Tickets_Id_Create = {
  __typename?: 'BookingsFields_tickets_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Id_Delete = {
  __typename?: 'BookingsFields_tickets_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Id_Read = {
  __typename?: 'BookingsFields_tickets_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Id_Update = {
  __typename?: 'BookingsFields_tickets_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Price = {
  __typename?: 'BookingsFields_tickets_price';
  create?: Maybe<BookingsFields_Tickets_Price_Create>;
  delete?: Maybe<BookingsFields_Tickets_Price_Delete>;
  read?: Maybe<BookingsFields_Tickets_Price_Read>;
  update?: Maybe<BookingsFields_Tickets_Price_Update>;
};

export type BookingsFields_Tickets_Price_Create = {
  __typename?: 'BookingsFields_tickets_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Price_Delete = {
  __typename?: 'BookingsFields_tickets_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Price_Read = {
  __typename?: 'BookingsFields_tickets_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Price_Update = {
  __typename?: 'BookingsFields_tickets_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Ticket = {
  __typename?: 'BookingsFields_tickets_ticket';
  create?: Maybe<BookingsFields_Tickets_Ticket_Create>;
  delete?: Maybe<BookingsFields_Tickets_Ticket_Delete>;
  read?: Maybe<BookingsFields_Tickets_Ticket_Read>;
  update?: Maybe<BookingsFields_Tickets_Ticket_Update>;
};

export type BookingsFields_Tickets_Ticket_Create = {
  __typename?: 'BookingsFields_tickets_ticket_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Ticket_Delete = {
  __typename?: 'BookingsFields_tickets_ticket_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Ticket_Read = {
  __typename?: 'BookingsFields_tickets_ticket_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Tickets_Ticket_Update = {
  __typename?: 'BookingsFields_tickets_ticket_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_TotalPrice = {
  __typename?: 'BookingsFields_totalPrice';
  create?: Maybe<BookingsFields_TotalPrice_Create>;
  delete?: Maybe<BookingsFields_TotalPrice_Delete>;
  read?: Maybe<BookingsFields_TotalPrice_Read>;
  update?: Maybe<BookingsFields_TotalPrice_Update>;
};

export type BookingsFields_TotalPrice_Create = {
  __typename?: 'BookingsFields_totalPrice_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_TotalPrice_Delete = {
  __typename?: 'BookingsFields_totalPrice_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_TotalPrice_Read = {
  __typename?: 'BookingsFields_totalPrice_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_TotalPrice_Update = {
  __typename?: 'BookingsFields_totalPrice_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_UpdatedAt = {
  __typename?: 'BookingsFields_updatedAt';
  create?: Maybe<BookingsFields_UpdatedAt_Create>;
  delete?: Maybe<BookingsFields_UpdatedAt_Delete>;
  read?: Maybe<BookingsFields_UpdatedAt_Read>;
  update?: Maybe<BookingsFields_UpdatedAt_Update>;
};

export type BookingsFields_UpdatedAt_Create = {
  __typename?: 'BookingsFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_UpdatedAt_Delete = {
  __typename?: 'BookingsFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_UpdatedAt_Read = {
  __typename?: 'BookingsFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_UpdatedAt_Update = {
  __typename?: 'BookingsFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Voucher = {
  __typename?: 'BookingsFields_voucher';
  create?: Maybe<BookingsFields_Voucher_Create>;
  delete?: Maybe<BookingsFields_Voucher_Delete>;
  read?: Maybe<BookingsFields_Voucher_Read>;
  update?: Maybe<BookingsFields_Voucher_Update>;
};

export type BookingsFields_Voucher_Create = {
  __typename?: 'BookingsFields_voucher_Create';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Voucher_Delete = {
  __typename?: 'BookingsFields_voucher_Delete';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Voucher_Read = {
  __typename?: 'BookingsFields_voucher_Read';
  permission: Scalars['Boolean']['output'];
};

export type BookingsFields_Voucher_Update = {
  __typename?: 'BookingsFields_voucher_Update';
  permission: Scalars['Boolean']['output'];
};

export type BookingsReadAccess = {
  __typename?: 'BookingsReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type BookingsReadDocAccess = {
  __typename?: 'BookingsReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type BookingsUpdateAccess = {
  __typename?: 'BookingsUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type BookingsUpdateDocAccess = {
  __typename?: 'BookingsUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type Checkout = {
  __typename?: 'Checkout';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  extras?: Maybe<Array<BookingExtras>>;
  id?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  service: Service;
  start?: Maybe<Scalars['DateTime']['output']>;
  tenant?: Maybe<Tenant>;
  tickets?: Maybe<Array<BookingTickets>>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  type: Checkout_Type;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  voucher?: Maybe<Voucher>;
};


export type CheckoutServiceArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};


export type CheckoutTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};


export type CheckoutVoucherArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type CheckoutStatusResponse = {
  __typename?: 'CheckoutStatusResponse';
  bookingFlowType?: Maybe<Scalars['String']['output']>;
  bookingId?: Maybe<Scalars['ID']['output']>;
  bookingNumber?: Maybe<Scalars['String']['output']>;
  contactId?: Maybe<Scalars['ID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  voucherId?: Maybe<Scalars['ID']['output']>;
};

export enum CheckoutUpdate_Type_MutationInput {
  Booking = 'BOOKING',
  Voucher = 'VOUCHER'
}

export type Checkout_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Checkout_End_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Checkout_Extras__Count_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Checkout_Extras__Extra_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Checkout_Extras__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Checkout_Extras__Price_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Checkout_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Checkout_Locale_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Checkout_Service_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Checkout_Start_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Checkout_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Checkout_Tickets__Count_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Checkout_Tickets__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Checkout_Tickets__Price_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Checkout_Tickets__Ticket_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Checkout_TotalPrice_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export enum Checkout_Type {
  Booking = 'BOOKING',
  Voucher = 'VOUCHER'
}

export enum Checkout_Type_Input {
  Booking = 'BOOKING',
  Voucher = 'VOUCHER'
}

export enum Checkout_Type_MutationInput {
  Booking = 'BOOKING',
  Voucher = 'VOUCHER'
}

export type Checkout_Type_Operator = {
  all?: InputMaybe<Array<InputMaybe<Checkout_Type_Input>>>;
  equals?: InputMaybe<Checkout_Type_Input>;
  in?: InputMaybe<Array<InputMaybe<Checkout_Type_Input>>>;
  not_equals?: InputMaybe<Checkout_Type_Input>;
  not_in?: InputMaybe<Array<InputMaybe<Checkout_Type_Input>>>;
};

export type Checkout_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Checkout_Voucher_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Checkout_Where = {
  AND?: InputMaybe<Array<InputMaybe<Checkout_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Checkout_Where_Or>>>;
  createdAt?: InputMaybe<Checkout_CreatedAt_Operator>;
  end?: InputMaybe<Checkout_End_Operator>;
  extras__count?: InputMaybe<Checkout_Extras__Count_Operator>;
  extras__extra?: InputMaybe<Checkout_Extras__Extra_Operator>;
  extras__id?: InputMaybe<Checkout_Extras__Id_Operator>;
  extras__price?: InputMaybe<Checkout_Extras__Price_Operator>;
  id?: InputMaybe<Checkout_Id_Operator>;
  locale?: InputMaybe<Checkout_Locale_Operator>;
  service?: InputMaybe<Checkout_Service_Operator>;
  start?: InputMaybe<Checkout_Start_Operator>;
  tenant?: InputMaybe<Checkout_Tenant_Operator>;
  tickets__count?: InputMaybe<Checkout_Tickets__Count_Operator>;
  tickets__id?: InputMaybe<Checkout_Tickets__Id_Operator>;
  tickets__price?: InputMaybe<Checkout_Tickets__Price_Operator>;
  tickets__ticket?: InputMaybe<Checkout_Tickets__Ticket_Operator>;
  totalPrice?: InputMaybe<Checkout_TotalPrice_Operator>;
  type?: InputMaybe<Checkout_Type_Operator>;
  updatedAt?: InputMaybe<Checkout_UpdatedAt_Operator>;
  voucher?: InputMaybe<Checkout_Voucher_Operator>;
};

export type Checkout_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Checkout_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Checkout_Where_Or>>>;
  createdAt?: InputMaybe<Checkout_CreatedAt_Operator>;
  end?: InputMaybe<Checkout_End_Operator>;
  extras__count?: InputMaybe<Checkout_Extras__Count_Operator>;
  extras__extra?: InputMaybe<Checkout_Extras__Extra_Operator>;
  extras__id?: InputMaybe<Checkout_Extras__Id_Operator>;
  extras__price?: InputMaybe<Checkout_Extras__Price_Operator>;
  id?: InputMaybe<Checkout_Id_Operator>;
  locale?: InputMaybe<Checkout_Locale_Operator>;
  service?: InputMaybe<Checkout_Service_Operator>;
  start?: InputMaybe<Checkout_Start_Operator>;
  tenant?: InputMaybe<Checkout_Tenant_Operator>;
  tickets__count?: InputMaybe<Checkout_Tickets__Count_Operator>;
  tickets__id?: InputMaybe<Checkout_Tickets__Id_Operator>;
  tickets__price?: InputMaybe<Checkout_Tickets__Price_Operator>;
  tickets__ticket?: InputMaybe<Checkout_Tickets__Ticket_Operator>;
  totalPrice?: InputMaybe<Checkout_TotalPrice_Operator>;
  type?: InputMaybe<Checkout_Type_Operator>;
  updatedAt?: InputMaybe<Checkout_UpdatedAt_Operator>;
  voucher?: InputMaybe<Checkout_Voucher_Operator>;
};

export type Checkout_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Checkout_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Checkout_Where_Or>>>;
  createdAt?: InputMaybe<Checkout_CreatedAt_Operator>;
  end?: InputMaybe<Checkout_End_Operator>;
  extras__count?: InputMaybe<Checkout_Extras__Count_Operator>;
  extras__extra?: InputMaybe<Checkout_Extras__Extra_Operator>;
  extras__id?: InputMaybe<Checkout_Extras__Id_Operator>;
  extras__price?: InputMaybe<Checkout_Extras__Price_Operator>;
  id?: InputMaybe<Checkout_Id_Operator>;
  locale?: InputMaybe<Checkout_Locale_Operator>;
  service?: InputMaybe<Checkout_Service_Operator>;
  start?: InputMaybe<Checkout_Start_Operator>;
  tenant?: InputMaybe<Checkout_Tenant_Operator>;
  tickets__count?: InputMaybe<Checkout_Tickets__Count_Operator>;
  tickets__id?: InputMaybe<Checkout_Tickets__Id_Operator>;
  tickets__price?: InputMaybe<Checkout_Tickets__Price_Operator>;
  tickets__ticket?: InputMaybe<Checkout_Tickets__Ticket_Operator>;
  totalPrice?: InputMaybe<Checkout_TotalPrice_Operator>;
  type?: InputMaybe<Checkout_Type_Operator>;
  updatedAt?: InputMaybe<Checkout_UpdatedAt_Operator>;
  voucher?: InputMaybe<Checkout_Voucher_Operator>;
};

export type Checkouts = {
  __typename?: 'Checkouts';
  docs?: Maybe<Array<Maybe<Checkout>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type CheckoutsCreateAccess = {
  __typename?: 'CheckoutsCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CheckoutsCreateDocAccess = {
  __typename?: 'CheckoutsCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CheckoutsDeleteAccess = {
  __typename?: 'CheckoutsDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CheckoutsDeleteDocAccess = {
  __typename?: 'CheckoutsDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CheckoutsDocAccessFields = {
  __typename?: 'CheckoutsDocAccessFields';
  createdAt?: Maybe<CheckoutsDocAccessFields_CreatedAt>;
  end?: Maybe<CheckoutsDocAccessFields_End>;
  extras?: Maybe<CheckoutsDocAccessFields_Extras>;
  locale?: Maybe<CheckoutsDocAccessFields_Locale>;
  service?: Maybe<CheckoutsDocAccessFields_Service>;
  start?: Maybe<CheckoutsDocAccessFields_Start>;
  tenant?: Maybe<CheckoutsDocAccessFields_Tenant>;
  tickets?: Maybe<CheckoutsDocAccessFields_Tickets>;
  totalPrice?: Maybe<CheckoutsDocAccessFields_TotalPrice>;
  type?: Maybe<CheckoutsDocAccessFields_Type>;
  updatedAt?: Maybe<CheckoutsDocAccessFields_UpdatedAt>;
  voucher?: Maybe<CheckoutsDocAccessFields_Voucher>;
};

export type CheckoutsDocAccessFields_CreatedAt = {
  __typename?: 'CheckoutsDocAccessFields_createdAt';
  create?: Maybe<CheckoutsDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_CreatedAt_Read>;
  update?: Maybe<CheckoutsDocAccessFields_CreatedAt_Update>;
};

export type CheckoutsDocAccessFields_CreatedAt_Create = {
  __typename?: 'CheckoutsDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_CreatedAt_Delete = {
  __typename?: 'CheckoutsDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_CreatedAt_Read = {
  __typename?: 'CheckoutsDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_CreatedAt_Update = {
  __typename?: 'CheckoutsDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_End = {
  __typename?: 'CheckoutsDocAccessFields_end';
  create?: Maybe<CheckoutsDocAccessFields_End_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_End_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_End_Read>;
  update?: Maybe<CheckoutsDocAccessFields_End_Update>;
};

export type CheckoutsDocAccessFields_End_Create = {
  __typename?: 'CheckoutsDocAccessFields_end_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_End_Delete = {
  __typename?: 'CheckoutsDocAccessFields_end_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_End_Read = {
  __typename?: 'CheckoutsDocAccessFields_end_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_End_Update = {
  __typename?: 'CheckoutsDocAccessFields_end_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras = {
  __typename?: 'CheckoutsDocAccessFields_extras';
  create?: Maybe<CheckoutsDocAccessFields_Extras_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Extras_Delete>;
  fields?: Maybe<CheckoutsDocAccessFields_Extras_Fields>;
  read?: Maybe<CheckoutsDocAccessFields_Extras_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Extras_Update>;
};

export type CheckoutsDocAccessFields_Extras_Create = {
  __typename?: 'CheckoutsDocAccessFields_extras_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Delete = {
  __typename?: 'CheckoutsDocAccessFields_extras_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Fields = {
  __typename?: 'CheckoutsDocAccessFields_extras_Fields';
  count?: Maybe<CheckoutsDocAccessFields_Extras_Count>;
  extra?: Maybe<CheckoutsDocAccessFields_Extras_Extra>;
  id?: Maybe<CheckoutsDocAccessFields_Extras_Id>;
  price?: Maybe<CheckoutsDocAccessFields_Extras_Price>;
};

export type CheckoutsDocAccessFields_Extras_Read = {
  __typename?: 'CheckoutsDocAccessFields_extras_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Update = {
  __typename?: 'CheckoutsDocAccessFields_extras_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Count = {
  __typename?: 'CheckoutsDocAccessFields_extras_count';
  create?: Maybe<CheckoutsDocAccessFields_Extras_Count_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Extras_Count_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Extras_Count_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Extras_Count_Update>;
};

export type CheckoutsDocAccessFields_Extras_Count_Create = {
  __typename?: 'CheckoutsDocAccessFields_extras_count_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Count_Delete = {
  __typename?: 'CheckoutsDocAccessFields_extras_count_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Count_Read = {
  __typename?: 'CheckoutsDocAccessFields_extras_count_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Count_Update = {
  __typename?: 'CheckoutsDocAccessFields_extras_count_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Extra = {
  __typename?: 'CheckoutsDocAccessFields_extras_extra';
  create?: Maybe<CheckoutsDocAccessFields_Extras_Extra_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Extras_Extra_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Extras_Extra_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Extras_Extra_Update>;
};

export type CheckoutsDocAccessFields_Extras_Extra_Create = {
  __typename?: 'CheckoutsDocAccessFields_extras_extra_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Extra_Delete = {
  __typename?: 'CheckoutsDocAccessFields_extras_extra_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Extra_Read = {
  __typename?: 'CheckoutsDocAccessFields_extras_extra_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Extra_Update = {
  __typename?: 'CheckoutsDocAccessFields_extras_extra_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Id = {
  __typename?: 'CheckoutsDocAccessFields_extras_id';
  create?: Maybe<CheckoutsDocAccessFields_Extras_Id_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Extras_Id_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Extras_Id_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Extras_Id_Update>;
};

export type CheckoutsDocAccessFields_Extras_Id_Create = {
  __typename?: 'CheckoutsDocAccessFields_extras_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Id_Delete = {
  __typename?: 'CheckoutsDocAccessFields_extras_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Id_Read = {
  __typename?: 'CheckoutsDocAccessFields_extras_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Id_Update = {
  __typename?: 'CheckoutsDocAccessFields_extras_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Price = {
  __typename?: 'CheckoutsDocAccessFields_extras_price';
  create?: Maybe<CheckoutsDocAccessFields_Extras_Price_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Extras_Price_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Extras_Price_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Extras_Price_Update>;
};

export type CheckoutsDocAccessFields_Extras_Price_Create = {
  __typename?: 'CheckoutsDocAccessFields_extras_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Price_Delete = {
  __typename?: 'CheckoutsDocAccessFields_extras_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Price_Read = {
  __typename?: 'CheckoutsDocAccessFields_extras_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Extras_Price_Update = {
  __typename?: 'CheckoutsDocAccessFields_extras_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Locale = {
  __typename?: 'CheckoutsDocAccessFields_locale';
  create?: Maybe<CheckoutsDocAccessFields_Locale_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Locale_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Locale_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Locale_Update>;
};

export type CheckoutsDocAccessFields_Locale_Create = {
  __typename?: 'CheckoutsDocAccessFields_locale_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Locale_Delete = {
  __typename?: 'CheckoutsDocAccessFields_locale_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Locale_Read = {
  __typename?: 'CheckoutsDocAccessFields_locale_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Locale_Update = {
  __typename?: 'CheckoutsDocAccessFields_locale_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Service = {
  __typename?: 'CheckoutsDocAccessFields_service';
  create?: Maybe<CheckoutsDocAccessFields_Service_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Service_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Service_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Service_Update>;
};

export type CheckoutsDocAccessFields_Service_Create = {
  __typename?: 'CheckoutsDocAccessFields_service_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Service_Delete = {
  __typename?: 'CheckoutsDocAccessFields_service_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Service_Read = {
  __typename?: 'CheckoutsDocAccessFields_service_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Service_Update = {
  __typename?: 'CheckoutsDocAccessFields_service_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Start = {
  __typename?: 'CheckoutsDocAccessFields_start';
  create?: Maybe<CheckoutsDocAccessFields_Start_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Start_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Start_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Start_Update>;
};

export type CheckoutsDocAccessFields_Start_Create = {
  __typename?: 'CheckoutsDocAccessFields_start_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Start_Delete = {
  __typename?: 'CheckoutsDocAccessFields_start_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Start_Read = {
  __typename?: 'CheckoutsDocAccessFields_start_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Start_Update = {
  __typename?: 'CheckoutsDocAccessFields_start_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tenant = {
  __typename?: 'CheckoutsDocAccessFields_tenant';
  create?: Maybe<CheckoutsDocAccessFields_Tenant_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Tenant_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Tenant_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Tenant_Update>;
};

export type CheckoutsDocAccessFields_Tenant_Create = {
  __typename?: 'CheckoutsDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tenant_Delete = {
  __typename?: 'CheckoutsDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tenant_Read = {
  __typename?: 'CheckoutsDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tenant_Update = {
  __typename?: 'CheckoutsDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets = {
  __typename?: 'CheckoutsDocAccessFields_tickets';
  create?: Maybe<CheckoutsDocAccessFields_Tickets_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Tickets_Delete>;
  fields?: Maybe<CheckoutsDocAccessFields_Tickets_Fields>;
  read?: Maybe<CheckoutsDocAccessFields_Tickets_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Tickets_Update>;
};

export type CheckoutsDocAccessFields_Tickets_Create = {
  __typename?: 'CheckoutsDocAccessFields_tickets_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Delete = {
  __typename?: 'CheckoutsDocAccessFields_tickets_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Fields = {
  __typename?: 'CheckoutsDocAccessFields_tickets_Fields';
  count?: Maybe<CheckoutsDocAccessFields_Tickets_Count>;
  id?: Maybe<CheckoutsDocAccessFields_Tickets_Id>;
  price?: Maybe<CheckoutsDocAccessFields_Tickets_Price>;
  ticket?: Maybe<CheckoutsDocAccessFields_Tickets_Ticket>;
};

export type CheckoutsDocAccessFields_Tickets_Read = {
  __typename?: 'CheckoutsDocAccessFields_tickets_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Update = {
  __typename?: 'CheckoutsDocAccessFields_tickets_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Count = {
  __typename?: 'CheckoutsDocAccessFields_tickets_count';
  create?: Maybe<CheckoutsDocAccessFields_Tickets_Count_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Tickets_Count_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Tickets_Count_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Tickets_Count_Update>;
};

export type CheckoutsDocAccessFields_Tickets_Count_Create = {
  __typename?: 'CheckoutsDocAccessFields_tickets_count_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Count_Delete = {
  __typename?: 'CheckoutsDocAccessFields_tickets_count_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Count_Read = {
  __typename?: 'CheckoutsDocAccessFields_tickets_count_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Count_Update = {
  __typename?: 'CheckoutsDocAccessFields_tickets_count_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Id = {
  __typename?: 'CheckoutsDocAccessFields_tickets_id';
  create?: Maybe<CheckoutsDocAccessFields_Tickets_Id_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Tickets_Id_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Tickets_Id_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Tickets_Id_Update>;
};

export type CheckoutsDocAccessFields_Tickets_Id_Create = {
  __typename?: 'CheckoutsDocAccessFields_tickets_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Id_Delete = {
  __typename?: 'CheckoutsDocAccessFields_tickets_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Id_Read = {
  __typename?: 'CheckoutsDocAccessFields_tickets_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Id_Update = {
  __typename?: 'CheckoutsDocAccessFields_tickets_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Price = {
  __typename?: 'CheckoutsDocAccessFields_tickets_price';
  create?: Maybe<CheckoutsDocAccessFields_Tickets_Price_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Tickets_Price_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Tickets_Price_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Tickets_Price_Update>;
};

export type CheckoutsDocAccessFields_Tickets_Price_Create = {
  __typename?: 'CheckoutsDocAccessFields_tickets_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Price_Delete = {
  __typename?: 'CheckoutsDocAccessFields_tickets_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Price_Read = {
  __typename?: 'CheckoutsDocAccessFields_tickets_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Price_Update = {
  __typename?: 'CheckoutsDocAccessFields_tickets_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Ticket = {
  __typename?: 'CheckoutsDocAccessFields_tickets_ticket';
  create?: Maybe<CheckoutsDocAccessFields_Tickets_Ticket_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Tickets_Ticket_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Tickets_Ticket_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Tickets_Ticket_Update>;
};

export type CheckoutsDocAccessFields_Tickets_Ticket_Create = {
  __typename?: 'CheckoutsDocAccessFields_tickets_ticket_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Ticket_Delete = {
  __typename?: 'CheckoutsDocAccessFields_tickets_ticket_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Ticket_Read = {
  __typename?: 'CheckoutsDocAccessFields_tickets_ticket_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Tickets_Ticket_Update = {
  __typename?: 'CheckoutsDocAccessFields_tickets_ticket_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_TotalPrice = {
  __typename?: 'CheckoutsDocAccessFields_totalPrice';
  create?: Maybe<CheckoutsDocAccessFields_TotalPrice_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_TotalPrice_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_TotalPrice_Read>;
  update?: Maybe<CheckoutsDocAccessFields_TotalPrice_Update>;
};

export type CheckoutsDocAccessFields_TotalPrice_Create = {
  __typename?: 'CheckoutsDocAccessFields_totalPrice_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_TotalPrice_Delete = {
  __typename?: 'CheckoutsDocAccessFields_totalPrice_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_TotalPrice_Read = {
  __typename?: 'CheckoutsDocAccessFields_totalPrice_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_TotalPrice_Update = {
  __typename?: 'CheckoutsDocAccessFields_totalPrice_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Type = {
  __typename?: 'CheckoutsDocAccessFields_type';
  create?: Maybe<CheckoutsDocAccessFields_Type_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Type_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Type_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Type_Update>;
};

export type CheckoutsDocAccessFields_Type_Create = {
  __typename?: 'CheckoutsDocAccessFields_type_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Type_Delete = {
  __typename?: 'CheckoutsDocAccessFields_type_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Type_Read = {
  __typename?: 'CheckoutsDocAccessFields_type_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Type_Update = {
  __typename?: 'CheckoutsDocAccessFields_type_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_UpdatedAt = {
  __typename?: 'CheckoutsDocAccessFields_updatedAt';
  create?: Maybe<CheckoutsDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<CheckoutsDocAccessFields_UpdatedAt_Update>;
};

export type CheckoutsDocAccessFields_UpdatedAt_Create = {
  __typename?: 'CheckoutsDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'CheckoutsDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_UpdatedAt_Read = {
  __typename?: 'CheckoutsDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_UpdatedAt_Update = {
  __typename?: 'CheckoutsDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Voucher = {
  __typename?: 'CheckoutsDocAccessFields_voucher';
  create?: Maybe<CheckoutsDocAccessFields_Voucher_Create>;
  delete?: Maybe<CheckoutsDocAccessFields_Voucher_Delete>;
  read?: Maybe<CheckoutsDocAccessFields_Voucher_Read>;
  update?: Maybe<CheckoutsDocAccessFields_Voucher_Update>;
};

export type CheckoutsDocAccessFields_Voucher_Create = {
  __typename?: 'CheckoutsDocAccessFields_voucher_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Voucher_Delete = {
  __typename?: 'CheckoutsDocAccessFields_voucher_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Voucher_Read = {
  __typename?: 'CheckoutsDocAccessFields_voucher_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsDocAccessFields_Voucher_Update = {
  __typename?: 'CheckoutsDocAccessFields_voucher_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields = {
  __typename?: 'CheckoutsFields';
  createdAt?: Maybe<CheckoutsFields_CreatedAt>;
  end?: Maybe<CheckoutsFields_End>;
  extras?: Maybe<CheckoutsFields_Extras>;
  locale?: Maybe<CheckoutsFields_Locale>;
  service?: Maybe<CheckoutsFields_Service>;
  start?: Maybe<CheckoutsFields_Start>;
  tenant?: Maybe<CheckoutsFields_Tenant>;
  tickets?: Maybe<CheckoutsFields_Tickets>;
  totalPrice?: Maybe<CheckoutsFields_TotalPrice>;
  type?: Maybe<CheckoutsFields_Type>;
  updatedAt?: Maybe<CheckoutsFields_UpdatedAt>;
  voucher?: Maybe<CheckoutsFields_Voucher>;
};

export type CheckoutsFields_CreatedAt = {
  __typename?: 'CheckoutsFields_createdAt';
  create?: Maybe<CheckoutsFields_CreatedAt_Create>;
  delete?: Maybe<CheckoutsFields_CreatedAt_Delete>;
  read?: Maybe<CheckoutsFields_CreatedAt_Read>;
  update?: Maybe<CheckoutsFields_CreatedAt_Update>;
};

export type CheckoutsFields_CreatedAt_Create = {
  __typename?: 'CheckoutsFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_CreatedAt_Delete = {
  __typename?: 'CheckoutsFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_CreatedAt_Read = {
  __typename?: 'CheckoutsFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_CreatedAt_Update = {
  __typename?: 'CheckoutsFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_End = {
  __typename?: 'CheckoutsFields_end';
  create?: Maybe<CheckoutsFields_End_Create>;
  delete?: Maybe<CheckoutsFields_End_Delete>;
  read?: Maybe<CheckoutsFields_End_Read>;
  update?: Maybe<CheckoutsFields_End_Update>;
};

export type CheckoutsFields_End_Create = {
  __typename?: 'CheckoutsFields_end_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_End_Delete = {
  __typename?: 'CheckoutsFields_end_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_End_Read = {
  __typename?: 'CheckoutsFields_end_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_End_Update = {
  __typename?: 'CheckoutsFields_end_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras = {
  __typename?: 'CheckoutsFields_extras';
  create?: Maybe<CheckoutsFields_Extras_Create>;
  delete?: Maybe<CheckoutsFields_Extras_Delete>;
  fields?: Maybe<CheckoutsFields_Extras_Fields>;
  read?: Maybe<CheckoutsFields_Extras_Read>;
  update?: Maybe<CheckoutsFields_Extras_Update>;
};

export type CheckoutsFields_Extras_Create = {
  __typename?: 'CheckoutsFields_extras_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Delete = {
  __typename?: 'CheckoutsFields_extras_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Fields = {
  __typename?: 'CheckoutsFields_extras_Fields';
  count?: Maybe<CheckoutsFields_Extras_Count>;
  extra?: Maybe<CheckoutsFields_Extras_Extra>;
  id?: Maybe<CheckoutsFields_Extras_Id>;
  price?: Maybe<CheckoutsFields_Extras_Price>;
};

export type CheckoutsFields_Extras_Read = {
  __typename?: 'CheckoutsFields_extras_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Update = {
  __typename?: 'CheckoutsFields_extras_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Count = {
  __typename?: 'CheckoutsFields_extras_count';
  create?: Maybe<CheckoutsFields_Extras_Count_Create>;
  delete?: Maybe<CheckoutsFields_Extras_Count_Delete>;
  read?: Maybe<CheckoutsFields_Extras_Count_Read>;
  update?: Maybe<CheckoutsFields_Extras_Count_Update>;
};

export type CheckoutsFields_Extras_Count_Create = {
  __typename?: 'CheckoutsFields_extras_count_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Count_Delete = {
  __typename?: 'CheckoutsFields_extras_count_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Count_Read = {
  __typename?: 'CheckoutsFields_extras_count_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Count_Update = {
  __typename?: 'CheckoutsFields_extras_count_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Extra = {
  __typename?: 'CheckoutsFields_extras_extra';
  create?: Maybe<CheckoutsFields_Extras_Extra_Create>;
  delete?: Maybe<CheckoutsFields_Extras_Extra_Delete>;
  read?: Maybe<CheckoutsFields_Extras_Extra_Read>;
  update?: Maybe<CheckoutsFields_Extras_Extra_Update>;
};

export type CheckoutsFields_Extras_Extra_Create = {
  __typename?: 'CheckoutsFields_extras_extra_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Extra_Delete = {
  __typename?: 'CheckoutsFields_extras_extra_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Extra_Read = {
  __typename?: 'CheckoutsFields_extras_extra_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Extra_Update = {
  __typename?: 'CheckoutsFields_extras_extra_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Id = {
  __typename?: 'CheckoutsFields_extras_id';
  create?: Maybe<CheckoutsFields_Extras_Id_Create>;
  delete?: Maybe<CheckoutsFields_Extras_Id_Delete>;
  read?: Maybe<CheckoutsFields_Extras_Id_Read>;
  update?: Maybe<CheckoutsFields_Extras_Id_Update>;
};

export type CheckoutsFields_Extras_Id_Create = {
  __typename?: 'CheckoutsFields_extras_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Id_Delete = {
  __typename?: 'CheckoutsFields_extras_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Id_Read = {
  __typename?: 'CheckoutsFields_extras_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Id_Update = {
  __typename?: 'CheckoutsFields_extras_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Price = {
  __typename?: 'CheckoutsFields_extras_price';
  create?: Maybe<CheckoutsFields_Extras_Price_Create>;
  delete?: Maybe<CheckoutsFields_Extras_Price_Delete>;
  read?: Maybe<CheckoutsFields_Extras_Price_Read>;
  update?: Maybe<CheckoutsFields_Extras_Price_Update>;
};

export type CheckoutsFields_Extras_Price_Create = {
  __typename?: 'CheckoutsFields_extras_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Price_Delete = {
  __typename?: 'CheckoutsFields_extras_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Price_Read = {
  __typename?: 'CheckoutsFields_extras_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Extras_Price_Update = {
  __typename?: 'CheckoutsFields_extras_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Locale = {
  __typename?: 'CheckoutsFields_locale';
  create?: Maybe<CheckoutsFields_Locale_Create>;
  delete?: Maybe<CheckoutsFields_Locale_Delete>;
  read?: Maybe<CheckoutsFields_Locale_Read>;
  update?: Maybe<CheckoutsFields_Locale_Update>;
};

export type CheckoutsFields_Locale_Create = {
  __typename?: 'CheckoutsFields_locale_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Locale_Delete = {
  __typename?: 'CheckoutsFields_locale_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Locale_Read = {
  __typename?: 'CheckoutsFields_locale_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Locale_Update = {
  __typename?: 'CheckoutsFields_locale_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Service = {
  __typename?: 'CheckoutsFields_service';
  create?: Maybe<CheckoutsFields_Service_Create>;
  delete?: Maybe<CheckoutsFields_Service_Delete>;
  read?: Maybe<CheckoutsFields_Service_Read>;
  update?: Maybe<CheckoutsFields_Service_Update>;
};

export type CheckoutsFields_Service_Create = {
  __typename?: 'CheckoutsFields_service_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Service_Delete = {
  __typename?: 'CheckoutsFields_service_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Service_Read = {
  __typename?: 'CheckoutsFields_service_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Service_Update = {
  __typename?: 'CheckoutsFields_service_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Start = {
  __typename?: 'CheckoutsFields_start';
  create?: Maybe<CheckoutsFields_Start_Create>;
  delete?: Maybe<CheckoutsFields_Start_Delete>;
  read?: Maybe<CheckoutsFields_Start_Read>;
  update?: Maybe<CheckoutsFields_Start_Update>;
};

export type CheckoutsFields_Start_Create = {
  __typename?: 'CheckoutsFields_start_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Start_Delete = {
  __typename?: 'CheckoutsFields_start_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Start_Read = {
  __typename?: 'CheckoutsFields_start_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Start_Update = {
  __typename?: 'CheckoutsFields_start_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tenant = {
  __typename?: 'CheckoutsFields_tenant';
  create?: Maybe<CheckoutsFields_Tenant_Create>;
  delete?: Maybe<CheckoutsFields_Tenant_Delete>;
  read?: Maybe<CheckoutsFields_Tenant_Read>;
  update?: Maybe<CheckoutsFields_Tenant_Update>;
};

export type CheckoutsFields_Tenant_Create = {
  __typename?: 'CheckoutsFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tenant_Delete = {
  __typename?: 'CheckoutsFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tenant_Read = {
  __typename?: 'CheckoutsFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tenant_Update = {
  __typename?: 'CheckoutsFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets = {
  __typename?: 'CheckoutsFields_tickets';
  create?: Maybe<CheckoutsFields_Tickets_Create>;
  delete?: Maybe<CheckoutsFields_Tickets_Delete>;
  fields?: Maybe<CheckoutsFields_Tickets_Fields>;
  read?: Maybe<CheckoutsFields_Tickets_Read>;
  update?: Maybe<CheckoutsFields_Tickets_Update>;
};

export type CheckoutsFields_Tickets_Create = {
  __typename?: 'CheckoutsFields_tickets_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Delete = {
  __typename?: 'CheckoutsFields_tickets_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Fields = {
  __typename?: 'CheckoutsFields_tickets_Fields';
  count?: Maybe<CheckoutsFields_Tickets_Count>;
  id?: Maybe<CheckoutsFields_Tickets_Id>;
  price?: Maybe<CheckoutsFields_Tickets_Price>;
  ticket?: Maybe<CheckoutsFields_Tickets_Ticket>;
};

export type CheckoutsFields_Tickets_Read = {
  __typename?: 'CheckoutsFields_tickets_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Update = {
  __typename?: 'CheckoutsFields_tickets_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Count = {
  __typename?: 'CheckoutsFields_tickets_count';
  create?: Maybe<CheckoutsFields_Tickets_Count_Create>;
  delete?: Maybe<CheckoutsFields_Tickets_Count_Delete>;
  read?: Maybe<CheckoutsFields_Tickets_Count_Read>;
  update?: Maybe<CheckoutsFields_Tickets_Count_Update>;
};

export type CheckoutsFields_Tickets_Count_Create = {
  __typename?: 'CheckoutsFields_tickets_count_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Count_Delete = {
  __typename?: 'CheckoutsFields_tickets_count_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Count_Read = {
  __typename?: 'CheckoutsFields_tickets_count_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Count_Update = {
  __typename?: 'CheckoutsFields_tickets_count_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Id = {
  __typename?: 'CheckoutsFields_tickets_id';
  create?: Maybe<CheckoutsFields_Tickets_Id_Create>;
  delete?: Maybe<CheckoutsFields_Tickets_Id_Delete>;
  read?: Maybe<CheckoutsFields_Tickets_Id_Read>;
  update?: Maybe<CheckoutsFields_Tickets_Id_Update>;
};

export type CheckoutsFields_Tickets_Id_Create = {
  __typename?: 'CheckoutsFields_tickets_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Id_Delete = {
  __typename?: 'CheckoutsFields_tickets_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Id_Read = {
  __typename?: 'CheckoutsFields_tickets_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Id_Update = {
  __typename?: 'CheckoutsFields_tickets_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Price = {
  __typename?: 'CheckoutsFields_tickets_price';
  create?: Maybe<CheckoutsFields_Tickets_Price_Create>;
  delete?: Maybe<CheckoutsFields_Tickets_Price_Delete>;
  read?: Maybe<CheckoutsFields_Tickets_Price_Read>;
  update?: Maybe<CheckoutsFields_Tickets_Price_Update>;
};

export type CheckoutsFields_Tickets_Price_Create = {
  __typename?: 'CheckoutsFields_tickets_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Price_Delete = {
  __typename?: 'CheckoutsFields_tickets_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Price_Read = {
  __typename?: 'CheckoutsFields_tickets_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Price_Update = {
  __typename?: 'CheckoutsFields_tickets_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Ticket = {
  __typename?: 'CheckoutsFields_tickets_ticket';
  create?: Maybe<CheckoutsFields_Tickets_Ticket_Create>;
  delete?: Maybe<CheckoutsFields_Tickets_Ticket_Delete>;
  read?: Maybe<CheckoutsFields_Tickets_Ticket_Read>;
  update?: Maybe<CheckoutsFields_Tickets_Ticket_Update>;
};

export type CheckoutsFields_Tickets_Ticket_Create = {
  __typename?: 'CheckoutsFields_tickets_ticket_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Ticket_Delete = {
  __typename?: 'CheckoutsFields_tickets_ticket_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Ticket_Read = {
  __typename?: 'CheckoutsFields_tickets_ticket_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Tickets_Ticket_Update = {
  __typename?: 'CheckoutsFields_tickets_ticket_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_TotalPrice = {
  __typename?: 'CheckoutsFields_totalPrice';
  create?: Maybe<CheckoutsFields_TotalPrice_Create>;
  delete?: Maybe<CheckoutsFields_TotalPrice_Delete>;
  read?: Maybe<CheckoutsFields_TotalPrice_Read>;
  update?: Maybe<CheckoutsFields_TotalPrice_Update>;
};

export type CheckoutsFields_TotalPrice_Create = {
  __typename?: 'CheckoutsFields_totalPrice_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_TotalPrice_Delete = {
  __typename?: 'CheckoutsFields_totalPrice_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_TotalPrice_Read = {
  __typename?: 'CheckoutsFields_totalPrice_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_TotalPrice_Update = {
  __typename?: 'CheckoutsFields_totalPrice_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Type = {
  __typename?: 'CheckoutsFields_type';
  create?: Maybe<CheckoutsFields_Type_Create>;
  delete?: Maybe<CheckoutsFields_Type_Delete>;
  read?: Maybe<CheckoutsFields_Type_Read>;
  update?: Maybe<CheckoutsFields_Type_Update>;
};

export type CheckoutsFields_Type_Create = {
  __typename?: 'CheckoutsFields_type_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Type_Delete = {
  __typename?: 'CheckoutsFields_type_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Type_Read = {
  __typename?: 'CheckoutsFields_type_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Type_Update = {
  __typename?: 'CheckoutsFields_type_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_UpdatedAt = {
  __typename?: 'CheckoutsFields_updatedAt';
  create?: Maybe<CheckoutsFields_UpdatedAt_Create>;
  delete?: Maybe<CheckoutsFields_UpdatedAt_Delete>;
  read?: Maybe<CheckoutsFields_UpdatedAt_Read>;
  update?: Maybe<CheckoutsFields_UpdatedAt_Update>;
};

export type CheckoutsFields_UpdatedAt_Create = {
  __typename?: 'CheckoutsFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_UpdatedAt_Delete = {
  __typename?: 'CheckoutsFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_UpdatedAt_Read = {
  __typename?: 'CheckoutsFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_UpdatedAt_Update = {
  __typename?: 'CheckoutsFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Voucher = {
  __typename?: 'CheckoutsFields_voucher';
  create?: Maybe<CheckoutsFields_Voucher_Create>;
  delete?: Maybe<CheckoutsFields_Voucher_Delete>;
  read?: Maybe<CheckoutsFields_Voucher_Read>;
  update?: Maybe<CheckoutsFields_Voucher_Update>;
};

export type CheckoutsFields_Voucher_Create = {
  __typename?: 'CheckoutsFields_voucher_Create';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Voucher_Delete = {
  __typename?: 'CheckoutsFields_voucher_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Voucher_Read = {
  __typename?: 'CheckoutsFields_voucher_Read';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsFields_Voucher_Update = {
  __typename?: 'CheckoutsFields_voucher_Update';
  permission: Scalars['Boolean']['output'];
};

export type CheckoutsReadAccess = {
  __typename?: 'CheckoutsReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CheckoutsReadDocAccess = {
  __typename?: 'CheckoutsReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CheckoutsUpdateAccess = {
  __typename?: 'CheckoutsUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CheckoutsUpdateDocAccess = {
  __typename?: 'CheckoutsUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CompanyDetail = {
  __typename?: 'CompanyDetail';
  address?: Maybe<CompanyDetail_Address>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  logo?: Maybe<Media>;
  originalLanguage?: Maybe<CompanyDetail_OriginalLanguage>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  taxID?: Maybe<Scalars['String']['output']>;
  tenant?: Maybe<Tenant>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};


export type CompanyDetailLogoArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
  where?: InputMaybe<CompanyDetail_Logo_Where>;
};


export type CompanyDetailTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type CompanyDetail_Address = {
  __typename?: 'CompanyDetail_Address';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
};

export type CompanyDetail_Logo_Alt_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CompanyDetail_Logo_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetail_Logo_Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetail_Logo_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_Sizes__Card__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_Sizes__Card__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetail_Logo_Sizes__Card__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetail_Logo_Sizes__Card__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_Sizes__Card__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_Sizes__Card__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetail_Logo_Sizes__Tablet__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_Sizes__Tablet__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetail_Logo_Sizes__Tablet__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetail_Logo_Sizes__Tablet__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_Sizes__Tablet__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_Sizes__Tablet__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetail_Logo_Sizes__Thumbnail__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_Sizes__Thumbnail__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetail_Logo_Sizes__Thumbnail__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetail_Logo_Sizes__Thumbnail__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_Sizes__Thumbnail__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_Sizes__Thumbnail__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetail_Logo_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type CompanyDetail_Logo_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CompanyDetail_Logo_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetail_Logo_Where = {
  AND?: InputMaybe<Array<InputMaybe<CompanyDetail_Logo_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CompanyDetail_Logo_Where_Or>>>;
  alt?: InputMaybe<CompanyDetail_Logo_Alt_Operator>;
  createdAt?: InputMaybe<CompanyDetail_Logo_CreatedAt_Operator>;
  filename?: InputMaybe<CompanyDetail_Logo_Filename_Operator>;
  filesize?: InputMaybe<CompanyDetail_Logo_Filesize_Operator>;
  height?: InputMaybe<CompanyDetail_Logo_Height_Operator>;
  id?: InputMaybe<CompanyDetail_Logo_Id_Operator>;
  mimeType?: InputMaybe<CompanyDetail_Logo_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<CompanyDetail_Logo_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<CompanyDetail_Logo_Tenant_Operator>;
  updatedAt?: InputMaybe<CompanyDetail_Logo_UpdatedAt_Operator>;
  url?: InputMaybe<CompanyDetail_Logo_Url_Operator>;
  width?: InputMaybe<CompanyDetail_Logo_Width_Operator>;
};

export type CompanyDetail_Logo_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<CompanyDetail_Logo_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CompanyDetail_Logo_Where_Or>>>;
  alt?: InputMaybe<CompanyDetail_Logo_Alt_Operator>;
  createdAt?: InputMaybe<CompanyDetail_Logo_CreatedAt_Operator>;
  filename?: InputMaybe<CompanyDetail_Logo_Filename_Operator>;
  filesize?: InputMaybe<CompanyDetail_Logo_Filesize_Operator>;
  height?: InputMaybe<CompanyDetail_Logo_Height_Operator>;
  id?: InputMaybe<CompanyDetail_Logo_Id_Operator>;
  mimeType?: InputMaybe<CompanyDetail_Logo_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<CompanyDetail_Logo_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<CompanyDetail_Logo_Tenant_Operator>;
  updatedAt?: InputMaybe<CompanyDetail_Logo_UpdatedAt_Operator>;
  url?: InputMaybe<CompanyDetail_Logo_Url_Operator>;
  width?: InputMaybe<CompanyDetail_Logo_Width_Operator>;
};

export type CompanyDetail_Logo_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<CompanyDetail_Logo_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CompanyDetail_Logo_Where_Or>>>;
  alt?: InputMaybe<CompanyDetail_Logo_Alt_Operator>;
  createdAt?: InputMaybe<CompanyDetail_Logo_CreatedAt_Operator>;
  filename?: InputMaybe<CompanyDetail_Logo_Filename_Operator>;
  filesize?: InputMaybe<CompanyDetail_Logo_Filesize_Operator>;
  height?: InputMaybe<CompanyDetail_Logo_Height_Operator>;
  id?: InputMaybe<CompanyDetail_Logo_Id_Operator>;
  mimeType?: InputMaybe<CompanyDetail_Logo_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<CompanyDetail_Logo_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<CompanyDetail_Logo_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<CompanyDetail_Logo_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<CompanyDetail_Logo_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<CompanyDetail_Logo_Tenant_Operator>;
  updatedAt?: InputMaybe<CompanyDetail_Logo_UpdatedAt_Operator>;
  url?: InputMaybe<CompanyDetail_Logo_Url_Operator>;
  width?: InputMaybe<CompanyDetail_Logo_Width_Operator>;
};

export type CompanyDetail_Logo_Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export enum CompanyDetail_OriginalLanguage {
  Ee = 'ee',
  Hu = 'hu',
  It = 'it'
}

export enum CompanyDetail_OriginalLanguage_MutationInput {
  Ee = 'ee',
  Hu = 'hu',
  It = 'it'
}

export type CompanyDetailsDocAccessFields = {
  __typename?: 'CompanyDetailsDocAccessFields';
  address?: Maybe<CompanyDetailsDocAccessFields_Address>;
  companyName?: Maybe<CompanyDetailsDocAccessFields_CompanyName>;
  createdAt?: Maybe<CompanyDetailsDocAccessFields_CreatedAt>;
  displayName?: Maybe<CompanyDetailsDocAccessFields_DisplayName>;
  email?: Maybe<CompanyDetailsDocAccessFields_Email>;
  logo?: Maybe<CompanyDetailsDocAccessFields_Logo>;
  originalLanguage?: Maybe<CompanyDetailsDocAccessFields_OriginalLanguage>;
  shortDescription?: Maybe<CompanyDetailsDocAccessFields_ShortDescription>;
  taxID?: Maybe<CompanyDetailsDocAccessFields_TaxId>;
  tenant?: Maybe<CompanyDetailsDocAccessFields_Tenant>;
  timezone?: Maybe<CompanyDetailsDocAccessFields_Timezone>;
  updatedAt?: Maybe<CompanyDetailsDocAccessFields_UpdatedAt>;
  website?: Maybe<CompanyDetailsDocAccessFields_Website>;
};

export type CompanyDetailsDocAccessFields_Address = {
  __typename?: 'CompanyDetailsDocAccessFields_address';
  create?: Maybe<CompanyDetailsDocAccessFields_Address_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_Address_Delete>;
  fields?: Maybe<CompanyDetailsDocAccessFields_Address_Fields>;
  read?: Maybe<CompanyDetailsDocAccessFields_Address_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_Address_Update>;
};

export type CompanyDetailsDocAccessFields_Address_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_address_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_address_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_Fields = {
  __typename?: 'CompanyDetailsDocAccessFields_address_Fields';
  addressLine1?: Maybe<CompanyDetailsDocAccessFields_Address_AddressLine1>;
  addressLine2?: Maybe<CompanyDetailsDocAccessFields_Address_AddressLine2>;
  city?: Maybe<CompanyDetailsDocAccessFields_Address_City>;
  country?: Maybe<CompanyDetailsDocAccessFields_Address_Country>;
  county?: Maybe<CompanyDetailsDocAccessFields_Address_County>;
  postcode?: Maybe<CompanyDetailsDocAccessFields_Address_Postcode>;
};

export type CompanyDetailsDocAccessFields_Address_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_address_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_address_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_AddressLine1 = {
  __typename?: 'CompanyDetailsDocAccessFields_address_addressLine1';
  create?: Maybe<CompanyDetailsDocAccessFields_Address_AddressLine1_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_Address_AddressLine1_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_Address_AddressLine1_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_Address_AddressLine1_Update>;
};

export type CompanyDetailsDocAccessFields_Address_AddressLine1_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_address_addressLine1_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_AddressLine1_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_address_addressLine1_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_AddressLine1_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_address_addressLine1_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_AddressLine1_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_address_addressLine1_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_AddressLine2 = {
  __typename?: 'CompanyDetailsDocAccessFields_address_addressLine2';
  create?: Maybe<CompanyDetailsDocAccessFields_Address_AddressLine2_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_Address_AddressLine2_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_Address_AddressLine2_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_Address_AddressLine2_Update>;
};

export type CompanyDetailsDocAccessFields_Address_AddressLine2_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_address_addressLine2_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_AddressLine2_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_address_addressLine2_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_AddressLine2_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_address_addressLine2_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_AddressLine2_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_address_addressLine2_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_City = {
  __typename?: 'CompanyDetailsDocAccessFields_address_city';
  create?: Maybe<CompanyDetailsDocAccessFields_Address_City_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_Address_City_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_Address_City_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_Address_City_Update>;
};

export type CompanyDetailsDocAccessFields_Address_City_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_address_city_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_City_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_address_city_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_City_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_address_city_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_City_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_address_city_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_Country = {
  __typename?: 'CompanyDetailsDocAccessFields_address_country';
  create?: Maybe<CompanyDetailsDocAccessFields_Address_Country_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_Address_Country_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_Address_Country_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_Address_Country_Update>;
};

export type CompanyDetailsDocAccessFields_Address_Country_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_address_country_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_Country_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_address_country_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_Country_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_address_country_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_Country_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_address_country_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_County = {
  __typename?: 'CompanyDetailsDocAccessFields_address_county';
  create?: Maybe<CompanyDetailsDocAccessFields_Address_County_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_Address_County_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_Address_County_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_Address_County_Update>;
};

export type CompanyDetailsDocAccessFields_Address_County_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_address_county_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_County_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_address_county_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_County_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_address_county_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_County_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_address_county_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_Postcode = {
  __typename?: 'CompanyDetailsDocAccessFields_address_postcode';
  create?: Maybe<CompanyDetailsDocAccessFields_Address_Postcode_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_Address_Postcode_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_Address_Postcode_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_Address_Postcode_Update>;
};

export type CompanyDetailsDocAccessFields_Address_Postcode_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_address_postcode_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_Postcode_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_address_postcode_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_Postcode_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_address_postcode_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Address_Postcode_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_address_postcode_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_CompanyName = {
  __typename?: 'CompanyDetailsDocAccessFields_companyName';
  create?: Maybe<CompanyDetailsDocAccessFields_CompanyName_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_CompanyName_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_CompanyName_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_CompanyName_Update>;
};

export type CompanyDetailsDocAccessFields_CompanyName_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_companyName_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_CompanyName_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_companyName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_CompanyName_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_companyName_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_CompanyName_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_companyName_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_CreatedAt = {
  __typename?: 'CompanyDetailsDocAccessFields_createdAt';
  create?: Maybe<CompanyDetailsDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_CreatedAt_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_CreatedAt_Update>;
};

export type CompanyDetailsDocAccessFields_CreatedAt_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_CreatedAt_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_CreatedAt_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_CreatedAt_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_DisplayName = {
  __typename?: 'CompanyDetailsDocAccessFields_displayName';
  create?: Maybe<CompanyDetailsDocAccessFields_DisplayName_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_DisplayName_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_DisplayName_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_DisplayName_Update>;
};

export type CompanyDetailsDocAccessFields_DisplayName_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_displayName_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_DisplayName_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_displayName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_DisplayName_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_displayName_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_DisplayName_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_displayName_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Email = {
  __typename?: 'CompanyDetailsDocAccessFields_email';
  create?: Maybe<CompanyDetailsDocAccessFields_Email_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_Email_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_Email_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_Email_Update>;
};

export type CompanyDetailsDocAccessFields_Email_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_email_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Email_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_email_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Email_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_email_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Email_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_email_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Logo = {
  __typename?: 'CompanyDetailsDocAccessFields_logo';
  create?: Maybe<CompanyDetailsDocAccessFields_Logo_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_Logo_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_Logo_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_Logo_Update>;
};

export type CompanyDetailsDocAccessFields_Logo_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_logo_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Logo_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_logo_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Logo_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_logo_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Logo_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_logo_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_OriginalLanguage = {
  __typename?: 'CompanyDetailsDocAccessFields_originalLanguage';
  create?: Maybe<CompanyDetailsDocAccessFields_OriginalLanguage_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_OriginalLanguage_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_OriginalLanguage_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_OriginalLanguage_Update>;
};

export type CompanyDetailsDocAccessFields_OriginalLanguage_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_originalLanguage_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_OriginalLanguage_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_originalLanguage_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_OriginalLanguage_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_originalLanguage_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_OriginalLanguage_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_originalLanguage_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_ShortDescription = {
  __typename?: 'CompanyDetailsDocAccessFields_shortDescription';
  create?: Maybe<CompanyDetailsDocAccessFields_ShortDescription_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_ShortDescription_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_ShortDescription_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_ShortDescription_Update>;
};

export type CompanyDetailsDocAccessFields_ShortDescription_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_shortDescription_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_ShortDescription_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_shortDescription_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_ShortDescription_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_shortDescription_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_ShortDescription_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_shortDescription_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_TaxId = {
  __typename?: 'CompanyDetailsDocAccessFields_taxID';
  create?: Maybe<CompanyDetailsDocAccessFields_TaxId_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_TaxId_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_TaxId_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_TaxId_Update>;
};

export type CompanyDetailsDocAccessFields_TaxId_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_taxID_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_TaxId_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_taxID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_TaxId_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_taxID_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_TaxId_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_taxID_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Tenant = {
  __typename?: 'CompanyDetailsDocAccessFields_tenant';
  create?: Maybe<CompanyDetailsDocAccessFields_Tenant_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_Tenant_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_Tenant_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_Tenant_Update>;
};

export type CompanyDetailsDocAccessFields_Tenant_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Tenant_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Tenant_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Tenant_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Timezone = {
  __typename?: 'CompanyDetailsDocAccessFields_timezone';
  create?: Maybe<CompanyDetailsDocAccessFields_Timezone_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_Timezone_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_Timezone_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_Timezone_Update>;
};

export type CompanyDetailsDocAccessFields_Timezone_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_timezone_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Timezone_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_timezone_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Timezone_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_timezone_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Timezone_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_timezone_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_UpdatedAt = {
  __typename?: 'CompanyDetailsDocAccessFields_updatedAt';
  create?: Maybe<CompanyDetailsDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_UpdatedAt_Update>;
};

export type CompanyDetailsDocAccessFields_UpdatedAt_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_UpdatedAt_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_UpdatedAt_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Website = {
  __typename?: 'CompanyDetailsDocAccessFields_website';
  create?: Maybe<CompanyDetailsDocAccessFields_Website_Create>;
  delete?: Maybe<CompanyDetailsDocAccessFields_Website_Delete>;
  read?: Maybe<CompanyDetailsDocAccessFields_Website_Read>;
  update?: Maybe<CompanyDetailsDocAccessFields_Website_Update>;
};

export type CompanyDetailsDocAccessFields_Website_Create = {
  __typename?: 'CompanyDetailsDocAccessFields_website_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Website_Delete = {
  __typename?: 'CompanyDetailsDocAccessFields_website_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Website_Read = {
  __typename?: 'CompanyDetailsDocAccessFields_website_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsDocAccessFields_Website_Update = {
  __typename?: 'CompanyDetailsDocAccessFields_website_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields = {
  __typename?: 'CompanyDetailsFields';
  address?: Maybe<CompanyDetailsFields_Address>;
  companyName?: Maybe<CompanyDetailsFields_CompanyName>;
  createdAt?: Maybe<CompanyDetailsFields_CreatedAt>;
  displayName?: Maybe<CompanyDetailsFields_DisplayName>;
  email?: Maybe<CompanyDetailsFields_Email>;
  logo?: Maybe<CompanyDetailsFields_Logo>;
  originalLanguage?: Maybe<CompanyDetailsFields_OriginalLanguage>;
  shortDescription?: Maybe<CompanyDetailsFields_ShortDescription>;
  taxID?: Maybe<CompanyDetailsFields_TaxId>;
  tenant?: Maybe<CompanyDetailsFields_Tenant>;
  timezone?: Maybe<CompanyDetailsFields_Timezone>;
  updatedAt?: Maybe<CompanyDetailsFields_UpdatedAt>;
  website?: Maybe<CompanyDetailsFields_Website>;
};

export type CompanyDetailsFields_Address = {
  __typename?: 'CompanyDetailsFields_address';
  create?: Maybe<CompanyDetailsFields_Address_Create>;
  delete?: Maybe<CompanyDetailsFields_Address_Delete>;
  fields?: Maybe<CompanyDetailsFields_Address_Fields>;
  read?: Maybe<CompanyDetailsFields_Address_Read>;
  update?: Maybe<CompanyDetailsFields_Address_Update>;
};

export type CompanyDetailsFields_Address_Create = {
  __typename?: 'CompanyDetailsFields_address_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_Delete = {
  __typename?: 'CompanyDetailsFields_address_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_Fields = {
  __typename?: 'CompanyDetailsFields_address_Fields';
  addressLine1?: Maybe<CompanyDetailsFields_Address_AddressLine1>;
  addressLine2?: Maybe<CompanyDetailsFields_Address_AddressLine2>;
  city?: Maybe<CompanyDetailsFields_Address_City>;
  country?: Maybe<CompanyDetailsFields_Address_Country>;
  county?: Maybe<CompanyDetailsFields_Address_County>;
  postcode?: Maybe<CompanyDetailsFields_Address_Postcode>;
};

export type CompanyDetailsFields_Address_Read = {
  __typename?: 'CompanyDetailsFields_address_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_Update = {
  __typename?: 'CompanyDetailsFields_address_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_AddressLine1 = {
  __typename?: 'CompanyDetailsFields_address_addressLine1';
  create?: Maybe<CompanyDetailsFields_Address_AddressLine1_Create>;
  delete?: Maybe<CompanyDetailsFields_Address_AddressLine1_Delete>;
  read?: Maybe<CompanyDetailsFields_Address_AddressLine1_Read>;
  update?: Maybe<CompanyDetailsFields_Address_AddressLine1_Update>;
};

export type CompanyDetailsFields_Address_AddressLine1_Create = {
  __typename?: 'CompanyDetailsFields_address_addressLine1_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_AddressLine1_Delete = {
  __typename?: 'CompanyDetailsFields_address_addressLine1_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_AddressLine1_Read = {
  __typename?: 'CompanyDetailsFields_address_addressLine1_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_AddressLine1_Update = {
  __typename?: 'CompanyDetailsFields_address_addressLine1_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_AddressLine2 = {
  __typename?: 'CompanyDetailsFields_address_addressLine2';
  create?: Maybe<CompanyDetailsFields_Address_AddressLine2_Create>;
  delete?: Maybe<CompanyDetailsFields_Address_AddressLine2_Delete>;
  read?: Maybe<CompanyDetailsFields_Address_AddressLine2_Read>;
  update?: Maybe<CompanyDetailsFields_Address_AddressLine2_Update>;
};

export type CompanyDetailsFields_Address_AddressLine2_Create = {
  __typename?: 'CompanyDetailsFields_address_addressLine2_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_AddressLine2_Delete = {
  __typename?: 'CompanyDetailsFields_address_addressLine2_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_AddressLine2_Read = {
  __typename?: 'CompanyDetailsFields_address_addressLine2_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_AddressLine2_Update = {
  __typename?: 'CompanyDetailsFields_address_addressLine2_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_City = {
  __typename?: 'CompanyDetailsFields_address_city';
  create?: Maybe<CompanyDetailsFields_Address_City_Create>;
  delete?: Maybe<CompanyDetailsFields_Address_City_Delete>;
  read?: Maybe<CompanyDetailsFields_Address_City_Read>;
  update?: Maybe<CompanyDetailsFields_Address_City_Update>;
};

export type CompanyDetailsFields_Address_City_Create = {
  __typename?: 'CompanyDetailsFields_address_city_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_City_Delete = {
  __typename?: 'CompanyDetailsFields_address_city_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_City_Read = {
  __typename?: 'CompanyDetailsFields_address_city_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_City_Update = {
  __typename?: 'CompanyDetailsFields_address_city_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_Country = {
  __typename?: 'CompanyDetailsFields_address_country';
  create?: Maybe<CompanyDetailsFields_Address_Country_Create>;
  delete?: Maybe<CompanyDetailsFields_Address_Country_Delete>;
  read?: Maybe<CompanyDetailsFields_Address_Country_Read>;
  update?: Maybe<CompanyDetailsFields_Address_Country_Update>;
};

export type CompanyDetailsFields_Address_Country_Create = {
  __typename?: 'CompanyDetailsFields_address_country_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_Country_Delete = {
  __typename?: 'CompanyDetailsFields_address_country_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_Country_Read = {
  __typename?: 'CompanyDetailsFields_address_country_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_Country_Update = {
  __typename?: 'CompanyDetailsFields_address_country_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_County = {
  __typename?: 'CompanyDetailsFields_address_county';
  create?: Maybe<CompanyDetailsFields_Address_County_Create>;
  delete?: Maybe<CompanyDetailsFields_Address_County_Delete>;
  read?: Maybe<CompanyDetailsFields_Address_County_Read>;
  update?: Maybe<CompanyDetailsFields_Address_County_Update>;
};

export type CompanyDetailsFields_Address_County_Create = {
  __typename?: 'CompanyDetailsFields_address_county_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_County_Delete = {
  __typename?: 'CompanyDetailsFields_address_county_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_County_Read = {
  __typename?: 'CompanyDetailsFields_address_county_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_County_Update = {
  __typename?: 'CompanyDetailsFields_address_county_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_Postcode = {
  __typename?: 'CompanyDetailsFields_address_postcode';
  create?: Maybe<CompanyDetailsFields_Address_Postcode_Create>;
  delete?: Maybe<CompanyDetailsFields_Address_Postcode_Delete>;
  read?: Maybe<CompanyDetailsFields_Address_Postcode_Read>;
  update?: Maybe<CompanyDetailsFields_Address_Postcode_Update>;
};

export type CompanyDetailsFields_Address_Postcode_Create = {
  __typename?: 'CompanyDetailsFields_address_postcode_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_Postcode_Delete = {
  __typename?: 'CompanyDetailsFields_address_postcode_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_Postcode_Read = {
  __typename?: 'CompanyDetailsFields_address_postcode_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Address_Postcode_Update = {
  __typename?: 'CompanyDetailsFields_address_postcode_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_CompanyName = {
  __typename?: 'CompanyDetailsFields_companyName';
  create?: Maybe<CompanyDetailsFields_CompanyName_Create>;
  delete?: Maybe<CompanyDetailsFields_CompanyName_Delete>;
  read?: Maybe<CompanyDetailsFields_CompanyName_Read>;
  update?: Maybe<CompanyDetailsFields_CompanyName_Update>;
};

export type CompanyDetailsFields_CompanyName_Create = {
  __typename?: 'CompanyDetailsFields_companyName_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_CompanyName_Delete = {
  __typename?: 'CompanyDetailsFields_companyName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_CompanyName_Read = {
  __typename?: 'CompanyDetailsFields_companyName_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_CompanyName_Update = {
  __typename?: 'CompanyDetailsFields_companyName_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_CreatedAt = {
  __typename?: 'CompanyDetailsFields_createdAt';
  create?: Maybe<CompanyDetailsFields_CreatedAt_Create>;
  delete?: Maybe<CompanyDetailsFields_CreatedAt_Delete>;
  read?: Maybe<CompanyDetailsFields_CreatedAt_Read>;
  update?: Maybe<CompanyDetailsFields_CreatedAt_Update>;
};

export type CompanyDetailsFields_CreatedAt_Create = {
  __typename?: 'CompanyDetailsFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_CreatedAt_Delete = {
  __typename?: 'CompanyDetailsFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_CreatedAt_Read = {
  __typename?: 'CompanyDetailsFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_CreatedAt_Update = {
  __typename?: 'CompanyDetailsFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_DisplayName = {
  __typename?: 'CompanyDetailsFields_displayName';
  create?: Maybe<CompanyDetailsFields_DisplayName_Create>;
  delete?: Maybe<CompanyDetailsFields_DisplayName_Delete>;
  read?: Maybe<CompanyDetailsFields_DisplayName_Read>;
  update?: Maybe<CompanyDetailsFields_DisplayName_Update>;
};

export type CompanyDetailsFields_DisplayName_Create = {
  __typename?: 'CompanyDetailsFields_displayName_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_DisplayName_Delete = {
  __typename?: 'CompanyDetailsFields_displayName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_DisplayName_Read = {
  __typename?: 'CompanyDetailsFields_displayName_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_DisplayName_Update = {
  __typename?: 'CompanyDetailsFields_displayName_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Email = {
  __typename?: 'CompanyDetailsFields_email';
  create?: Maybe<CompanyDetailsFields_Email_Create>;
  delete?: Maybe<CompanyDetailsFields_Email_Delete>;
  read?: Maybe<CompanyDetailsFields_Email_Read>;
  update?: Maybe<CompanyDetailsFields_Email_Update>;
};

export type CompanyDetailsFields_Email_Create = {
  __typename?: 'CompanyDetailsFields_email_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Email_Delete = {
  __typename?: 'CompanyDetailsFields_email_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Email_Read = {
  __typename?: 'CompanyDetailsFields_email_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Email_Update = {
  __typename?: 'CompanyDetailsFields_email_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Logo = {
  __typename?: 'CompanyDetailsFields_logo';
  create?: Maybe<CompanyDetailsFields_Logo_Create>;
  delete?: Maybe<CompanyDetailsFields_Logo_Delete>;
  read?: Maybe<CompanyDetailsFields_Logo_Read>;
  update?: Maybe<CompanyDetailsFields_Logo_Update>;
};

export type CompanyDetailsFields_Logo_Create = {
  __typename?: 'CompanyDetailsFields_logo_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Logo_Delete = {
  __typename?: 'CompanyDetailsFields_logo_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Logo_Read = {
  __typename?: 'CompanyDetailsFields_logo_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Logo_Update = {
  __typename?: 'CompanyDetailsFields_logo_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_OriginalLanguage = {
  __typename?: 'CompanyDetailsFields_originalLanguage';
  create?: Maybe<CompanyDetailsFields_OriginalLanguage_Create>;
  delete?: Maybe<CompanyDetailsFields_OriginalLanguage_Delete>;
  read?: Maybe<CompanyDetailsFields_OriginalLanguage_Read>;
  update?: Maybe<CompanyDetailsFields_OriginalLanguage_Update>;
};

export type CompanyDetailsFields_OriginalLanguage_Create = {
  __typename?: 'CompanyDetailsFields_originalLanguage_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_OriginalLanguage_Delete = {
  __typename?: 'CompanyDetailsFields_originalLanguage_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_OriginalLanguage_Read = {
  __typename?: 'CompanyDetailsFields_originalLanguage_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_OriginalLanguage_Update = {
  __typename?: 'CompanyDetailsFields_originalLanguage_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_ShortDescription = {
  __typename?: 'CompanyDetailsFields_shortDescription';
  create?: Maybe<CompanyDetailsFields_ShortDescription_Create>;
  delete?: Maybe<CompanyDetailsFields_ShortDescription_Delete>;
  read?: Maybe<CompanyDetailsFields_ShortDescription_Read>;
  update?: Maybe<CompanyDetailsFields_ShortDescription_Update>;
};

export type CompanyDetailsFields_ShortDescription_Create = {
  __typename?: 'CompanyDetailsFields_shortDescription_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_ShortDescription_Delete = {
  __typename?: 'CompanyDetailsFields_shortDescription_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_ShortDescription_Read = {
  __typename?: 'CompanyDetailsFields_shortDescription_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_ShortDescription_Update = {
  __typename?: 'CompanyDetailsFields_shortDescription_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_TaxId = {
  __typename?: 'CompanyDetailsFields_taxID';
  create?: Maybe<CompanyDetailsFields_TaxId_Create>;
  delete?: Maybe<CompanyDetailsFields_TaxId_Delete>;
  read?: Maybe<CompanyDetailsFields_TaxId_Read>;
  update?: Maybe<CompanyDetailsFields_TaxId_Update>;
};

export type CompanyDetailsFields_TaxId_Create = {
  __typename?: 'CompanyDetailsFields_taxID_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_TaxId_Delete = {
  __typename?: 'CompanyDetailsFields_taxID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_TaxId_Read = {
  __typename?: 'CompanyDetailsFields_taxID_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_TaxId_Update = {
  __typename?: 'CompanyDetailsFields_taxID_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Tenant = {
  __typename?: 'CompanyDetailsFields_tenant';
  create?: Maybe<CompanyDetailsFields_Tenant_Create>;
  delete?: Maybe<CompanyDetailsFields_Tenant_Delete>;
  read?: Maybe<CompanyDetailsFields_Tenant_Read>;
  update?: Maybe<CompanyDetailsFields_Tenant_Update>;
};

export type CompanyDetailsFields_Tenant_Create = {
  __typename?: 'CompanyDetailsFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Tenant_Delete = {
  __typename?: 'CompanyDetailsFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Tenant_Read = {
  __typename?: 'CompanyDetailsFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Tenant_Update = {
  __typename?: 'CompanyDetailsFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Timezone = {
  __typename?: 'CompanyDetailsFields_timezone';
  create?: Maybe<CompanyDetailsFields_Timezone_Create>;
  delete?: Maybe<CompanyDetailsFields_Timezone_Delete>;
  read?: Maybe<CompanyDetailsFields_Timezone_Read>;
  update?: Maybe<CompanyDetailsFields_Timezone_Update>;
};

export type CompanyDetailsFields_Timezone_Create = {
  __typename?: 'CompanyDetailsFields_timezone_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Timezone_Delete = {
  __typename?: 'CompanyDetailsFields_timezone_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Timezone_Read = {
  __typename?: 'CompanyDetailsFields_timezone_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Timezone_Update = {
  __typename?: 'CompanyDetailsFields_timezone_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_UpdatedAt = {
  __typename?: 'CompanyDetailsFields_updatedAt';
  create?: Maybe<CompanyDetailsFields_UpdatedAt_Create>;
  delete?: Maybe<CompanyDetailsFields_UpdatedAt_Delete>;
  read?: Maybe<CompanyDetailsFields_UpdatedAt_Read>;
  update?: Maybe<CompanyDetailsFields_UpdatedAt_Update>;
};

export type CompanyDetailsFields_UpdatedAt_Create = {
  __typename?: 'CompanyDetailsFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_UpdatedAt_Delete = {
  __typename?: 'CompanyDetailsFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_UpdatedAt_Read = {
  __typename?: 'CompanyDetailsFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_UpdatedAt_Update = {
  __typename?: 'CompanyDetailsFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Website = {
  __typename?: 'CompanyDetailsFields_website';
  create?: Maybe<CompanyDetailsFields_Website_Create>;
  delete?: Maybe<CompanyDetailsFields_Website_Delete>;
  read?: Maybe<CompanyDetailsFields_Website_Read>;
  update?: Maybe<CompanyDetailsFields_Website_Update>;
};

export type CompanyDetailsFields_Website_Create = {
  __typename?: 'CompanyDetailsFields_website_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Website_Delete = {
  __typename?: 'CompanyDetailsFields_website_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Website_Read = {
  __typename?: 'CompanyDetailsFields_website_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsFields_Website_Update = {
  __typename?: 'CompanyDetailsFields_website_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobal = {
  __typename?: 'CompanyDetailsGlobal';
  address?: Maybe<CompanyDetailsGlobal_Address>;
  companyName: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
  id?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Media>;
  originalLanguage?: Maybe<CompanyDetailsGlobal_OriginalLanguage>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  taxID: Scalars['String']['output'];
  tenant?: Maybe<Tenant>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  website: Scalars['String']['output'];
};


export type CompanyDetailsGlobalLogoArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
  where?: InputMaybe<CompanyDetailsGlobal_Logo_Where>;
};


export type CompanyDetailsGlobalTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export enum CompanyDetailsGlobalUpdate_OriginalLanguage_MutationInput {
  Ee = 'ee',
  Hu = 'hu',
  It = 'it'
}

export type CompanyDetailsGlobal_Address = {
  __typename?: 'CompanyDetailsGlobal_Address';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
};

export type CompanyDetailsGlobal_Logo_Alt_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CompanyDetailsGlobal_Logo_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetailsGlobal_Logo_Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetailsGlobal_Logo_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Card__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Card__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Card__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Card__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Card__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Card__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Tablet__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Tablet__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Tablet__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Tablet__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Tablet__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Tablet__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Thumbnail__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetailsGlobal_Logo_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CompanyDetailsGlobal_Logo_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_Where = {
  AND?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_Logo_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_Logo_Where_Or>>>;
  alt?: InputMaybe<CompanyDetailsGlobal_Logo_Alt_Operator>;
  createdAt?: InputMaybe<CompanyDetailsGlobal_Logo_CreatedAt_Operator>;
  filename?: InputMaybe<CompanyDetailsGlobal_Logo_Filename_Operator>;
  filesize?: InputMaybe<CompanyDetailsGlobal_Logo_Filesize_Operator>;
  height?: InputMaybe<CompanyDetailsGlobal_Logo_Height_Operator>;
  id?: InputMaybe<CompanyDetailsGlobal_Logo_Id_Operator>;
  mimeType?: InputMaybe<CompanyDetailsGlobal_Logo_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<CompanyDetailsGlobal_Logo_Tenant_Operator>;
  updatedAt?: InputMaybe<CompanyDetailsGlobal_Logo_UpdatedAt_Operator>;
  url?: InputMaybe<CompanyDetailsGlobal_Logo_Url_Operator>;
  width?: InputMaybe<CompanyDetailsGlobal_Logo_Width_Operator>;
};

export type CompanyDetailsGlobal_Logo_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_Logo_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_Logo_Where_Or>>>;
  alt?: InputMaybe<CompanyDetailsGlobal_Logo_Alt_Operator>;
  createdAt?: InputMaybe<CompanyDetailsGlobal_Logo_CreatedAt_Operator>;
  filename?: InputMaybe<CompanyDetailsGlobal_Logo_Filename_Operator>;
  filesize?: InputMaybe<CompanyDetailsGlobal_Logo_Filesize_Operator>;
  height?: InputMaybe<CompanyDetailsGlobal_Logo_Height_Operator>;
  id?: InputMaybe<CompanyDetailsGlobal_Logo_Id_Operator>;
  mimeType?: InputMaybe<CompanyDetailsGlobal_Logo_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<CompanyDetailsGlobal_Logo_Tenant_Operator>;
  updatedAt?: InputMaybe<CompanyDetailsGlobal_Logo_UpdatedAt_Operator>;
  url?: InputMaybe<CompanyDetailsGlobal_Logo_Url_Operator>;
  width?: InputMaybe<CompanyDetailsGlobal_Logo_Width_Operator>;
};

export type CompanyDetailsGlobal_Logo_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_Logo_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_Logo_Where_Or>>>;
  alt?: InputMaybe<CompanyDetailsGlobal_Logo_Alt_Operator>;
  createdAt?: InputMaybe<CompanyDetailsGlobal_Logo_CreatedAt_Operator>;
  filename?: InputMaybe<CompanyDetailsGlobal_Logo_Filename_Operator>;
  filesize?: InputMaybe<CompanyDetailsGlobal_Logo_Filesize_Operator>;
  height?: InputMaybe<CompanyDetailsGlobal_Logo_Height_Operator>;
  id?: InputMaybe<CompanyDetailsGlobal_Logo_Id_Operator>;
  mimeType?: InputMaybe<CompanyDetailsGlobal_Logo_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<CompanyDetailsGlobal_Logo_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<CompanyDetailsGlobal_Logo_Tenant_Operator>;
  updatedAt?: InputMaybe<CompanyDetailsGlobal_Logo_UpdatedAt_Operator>;
  url?: InputMaybe<CompanyDetailsGlobal_Logo_Url_Operator>;
  width?: InputMaybe<CompanyDetailsGlobal_Logo_Width_Operator>;
};

export type CompanyDetailsGlobal_Logo_Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyDetailsGlobal_Address__AddressLine1_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Address__AddressLine2_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Address__City_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Address__Country_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Address__County_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Address__Postcode_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_CompanyName_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CompanyDetailsGlobal_DisplayName_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Email_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
  contains?: InputMaybe<Scalars['EmailAddress']['input']>;
  equals?: InputMaybe<Scalars['EmailAddress']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
  like?: InputMaybe<Scalars['EmailAddress']['input']>;
  not_equals?: InputMaybe<Scalars['EmailAddress']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
};

export type CompanyDetailsGlobal_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Logo_Operator = {
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export enum CompanyDetailsGlobal_OriginalLanguage {
  Ee = 'ee',
  Hu = 'hu',
  It = 'it'
}

export enum CompanyDetailsGlobal_OriginalLanguage_Input {
  Ee = 'ee',
  Hu = 'hu',
  It = 'it'
}

export enum CompanyDetailsGlobal_OriginalLanguage_MutationInput {
  Ee = 'ee',
  Hu = 'hu',
  It = 'it'
}

export type CompanyDetailsGlobal_OriginalLanguage_Operator = {
  all?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_OriginalLanguage_Input>>>;
  equals?: InputMaybe<CompanyDetailsGlobal_OriginalLanguage_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_OriginalLanguage_Input>>>;
  not_equals?: InputMaybe<CompanyDetailsGlobal_OriginalLanguage_Input>;
  not_in?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_OriginalLanguage_Input>>>;
};

export type CompanyDetailsGlobal_ShortDescription_Operator = {
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyDetailsGlobal_TaxId_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type CompanyDetailsGlobal_Timezone_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CompanyDetailsGlobal_Website_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyDetailsGlobal_Where = {
  AND?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_Where_Or>>>;
  address__addressLine1?: InputMaybe<CompanyDetailsGlobal_Address__AddressLine1_Operator>;
  address__addressLine2?: InputMaybe<CompanyDetailsGlobal_Address__AddressLine2_Operator>;
  address__city?: InputMaybe<CompanyDetailsGlobal_Address__City_Operator>;
  address__country?: InputMaybe<CompanyDetailsGlobal_Address__Country_Operator>;
  address__county?: InputMaybe<CompanyDetailsGlobal_Address__County_Operator>;
  address__postcode?: InputMaybe<CompanyDetailsGlobal_Address__Postcode_Operator>;
  companyName?: InputMaybe<CompanyDetailsGlobal_CompanyName_Operator>;
  createdAt?: InputMaybe<CompanyDetailsGlobal_CreatedAt_Operator>;
  displayName?: InputMaybe<CompanyDetailsGlobal_DisplayName_Operator>;
  email?: InputMaybe<CompanyDetailsGlobal_Email_Operator>;
  id?: InputMaybe<CompanyDetailsGlobal_Id_Operator>;
  logo?: InputMaybe<CompanyDetailsGlobal_Logo_Operator>;
  originalLanguage?: InputMaybe<CompanyDetailsGlobal_OriginalLanguage_Operator>;
  shortDescription?: InputMaybe<CompanyDetailsGlobal_ShortDescription_Operator>;
  taxID?: InputMaybe<CompanyDetailsGlobal_TaxId_Operator>;
  tenant?: InputMaybe<CompanyDetailsGlobal_Tenant_Operator>;
  timezone?: InputMaybe<CompanyDetailsGlobal_Timezone_Operator>;
  updatedAt?: InputMaybe<CompanyDetailsGlobal_UpdatedAt_Operator>;
  website?: InputMaybe<CompanyDetailsGlobal_Website_Operator>;
};

export type CompanyDetailsGlobal_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_Where_Or>>>;
  address__addressLine1?: InputMaybe<CompanyDetailsGlobal_Address__AddressLine1_Operator>;
  address__addressLine2?: InputMaybe<CompanyDetailsGlobal_Address__AddressLine2_Operator>;
  address__city?: InputMaybe<CompanyDetailsGlobal_Address__City_Operator>;
  address__country?: InputMaybe<CompanyDetailsGlobal_Address__Country_Operator>;
  address__county?: InputMaybe<CompanyDetailsGlobal_Address__County_Operator>;
  address__postcode?: InputMaybe<CompanyDetailsGlobal_Address__Postcode_Operator>;
  companyName?: InputMaybe<CompanyDetailsGlobal_CompanyName_Operator>;
  createdAt?: InputMaybe<CompanyDetailsGlobal_CreatedAt_Operator>;
  displayName?: InputMaybe<CompanyDetailsGlobal_DisplayName_Operator>;
  email?: InputMaybe<CompanyDetailsGlobal_Email_Operator>;
  id?: InputMaybe<CompanyDetailsGlobal_Id_Operator>;
  logo?: InputMaybe<CompanyDetailsGlobal_Logo_Operator>;
  originalLanguage?: InputMaybe<CompanyDetailsGlobal_OriginalLanguage_Operator>;
  shortDescription?: InputMaybe<CompanyDetailsGlobal_ShortDescription_Operator>;
  taxID?: InputMaybe<CompanyDetailsGlobal_TaxId_Operator>;
  tenant?: InputMaybe<CompanyDetailsGlobal_Tenant_Operator>;
  timezone?: InputMaybe<CompanyDetailsGlobal_Timezone_Operator>;
  updatedAt?: InputMaybe<CompanyDetailsGlobal_UpdatedAt_Operator>;
  website?: InputMaybe<CompanyDetailsGlobal_Website_Operator>;
};

export type CompanyDetailsGlobal_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CompanyDetailsGlobal_Where_Or>>>;
  address__addressLine1?: InputMaybe<CompanyDetailsGlobal_Address__AddressLine1_Operator>;
  address__addressLine2?: InputMaybe<CompanyDetailsGlobal_Address__AddressLine2_Operator>;
  address__city?: InputMaybe<CompanyDetailsGlobal_Address__City_Operator>;
  address__country?: InputMaybe<CompanyDetailsGlobal_Address__Country_Operator>;
  address__county?: InputMaybe<CompanyDetailsGlobal_Address__County_Operator>;
  address__postcode?: InputMaybe<CompanyDetailsGlobal_Address__Postcode_Operator>;
  companyName?: InputMaybe<CompanyDetailsGlobal_CompanyName_Operator>;
  createdAt?: InputMaybe<CompanyDetailsGlobal_CreatedAt_Operator>;
  displayName?: InputMaybe<CompanyDetailsGlobal_DisplayName_Operator>;
  email?: InputMaybe<CompanyDetailsGlobal_Email_Operator>;
  id?: InputMaybe<CompanyDetailsGlobal_Id_Operator>;
  logo?: InputMaybe<CompanyDetailsGlobal_Logo_Operator>;
  originalLanguage?: InputMaybe<CompanyDetailsGlobal_OriginalLanguage_Operator>;
  shortDescription?: InputMaybe<CompanyDetailsGlobal_ShortDescription_Operator>;
  taxID?: InputMaybe<CompanyDetailsGlobal_TaxId_Operator>;
  tenant?: InputMaybe<CompanyDetailsGlobal_Tenant_Operator>;
  timezone?: InputMaybe<CompanyDetailsGlobal_Timezone_Operator>;
  updatedAt?: InputMaybe<CompanyDetailsGlobal_UpdatedAt_Operator>;
  website?: InputMaybe<CompanyDetailsGlobal_Website_Operator>;
};

export type CompanyDetailsGlobals = {
  __typename?: 'CompanyDetailsGlobals';
  docs?: Maybe<Array<Maybe<CompanyDetailsGlobal>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type CompanyDetailsGlobalsCreateAccess = {
  __typename?: 'CompanyDetailsGlobalsCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CompanyDetailsGlobalsCreateDocAccess = {
  __typename?: 'CompanyDetailsGlobalsCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CompanyDetailsGlobalsDeleteAccess = {
  __typename?: 'CompanyDetailsGlobalsDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CompanyDetailsGlobalsDeleteDocAccess = {
  __typename?: 'CompanyDetailsGlobalsDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CompanyDetailsGlobalsDocAccessFields = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields';
  address?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address>;
  companyName?: Maybe<CompanyDetailsGlobalsDocAccessFields_CompanyName>;
  createdAt?: Maybe<CompanyDetailsGlobalsDocAccessFields_CreatedAt>;
  displayName?: Maybe<CompanyDetailsGlobalsDocAccessFields_DisplayName>;
  email?: Maybe<CompanyDetailsGlobalsDocAccessFields_Email>;
  logo?: Maybe<CompanyDetailsGlobalsDocAccessFields_Logo>;
  originalLanguage?: Maybe<CompanyDetailsGlobalsDocAccessFields_OriginalLanguage>;
  shortDescription?: Maybe<CompanyDetailsGlobalsDocAccessFields_ShortDescription>;
  taxID?: Maybe<CompanyDetailsGlobalsDocAccessFields_TaxId>;
  tenant?: Maybe<CompanyDetailsGlobalsDocAccessFields_Tenant>;
  timezone?: Maybe<CompanyDetailsGlobalsDocAccessFields_Timezone>;
  updatedAt?: Maybe<CompanyDetailsGlobalsDocAccessFields_UpdatedAt>;
  website?: Maybe<CompanyDetailsGlobalsDocAccessFields_Website>;
};

export type CompanyDetailsGlobalsDocAccessFields_Address = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Delete>;
  fields?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Fields>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Fields = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_Fields';
  addressLine1?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_AddressLine1>;
  addressLine2?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_AddressLine2>;
  city?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_City>;
  country?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Country>;
  county?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_County>;
  postcode?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Postcode>;
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_AddressLine1 = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_addressLine1';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_AddressLine1_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_AddressLine1_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_AddressLine1_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_AddressLine1_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_Address_AddressLine1_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_addressLine1_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_AddressLine1_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_addressLine1_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_AddressLine1_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_addressLine1_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_AddressLine1_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_addressLine1_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_AddressLine2 = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_addressLine2';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_AddressLine2_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_AddressLine2_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_AddressLine2_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_AddressLine2_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_Address_AddressLine2_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_addressLine2_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_AddressLine2_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_addressLine2_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_AddressLine2_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_addressLine2_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_AddressLine2_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_addressLine2_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_City = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_city';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_City_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_City_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_City_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_City_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_Address_City_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_city_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_City_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_city_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_City_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_city_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_City_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_city_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Country = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_country';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Country_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Country_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Country_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Country_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Country_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_country_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Country_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_country_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Country_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_country_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Country_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_country_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_County = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_county';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_County_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_County_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_County_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_County_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_Address_County_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_county_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_County_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_county_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_County_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_county_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_County_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_county_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Postcode = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_postcode';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Postcode_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Postcode_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Postcode_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_Address_Postcode_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Postcode_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_postcode_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Postcode_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_postcode_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Postcode_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_postcode_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Address_Postcode_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_address_postcode_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_CompanyName = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_companyName';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_CompanyName_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_CompanyName_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_CompanyName_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_CompanyName_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_CompanyName_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_companyName_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_CompanyName_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_companyName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_CompanyName_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_companyName_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_CompanyName_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_companyName_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_CreatedAt = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_createdAt';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_CreatedAt_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_CreatedAt_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_CreatedAt_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_CreatedAt_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_CreatedAt_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_CreatedAt_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_DisplayName = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_displayName';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_DisplayName_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_DisplayName_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_DisplayName_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_DisplayName_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_DisplayName_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_displayName_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_DisplayName_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_displayName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_DisplayName_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_displayName_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_DisplayName_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_displayName_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Email = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_email';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_Email_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_Email_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_Email_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_Email_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_Email_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_email_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Email_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_email_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Email_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_email_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Email_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_email_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Logo = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_logo';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_Logo_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_Logo_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_Logo_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_Logo_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_Logo_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_logo_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Logo_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_logo_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Logo_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_logo_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Logo_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_logo_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_OriginalLanguage = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_originalLanguage';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_OriginalLanguage_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_OriginalLanguage_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_OriginalLanguage_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_OriginalLanguage_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_OriginalLanguage_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_originalLanguage_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_OriginalLanguage_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_originalLanguage_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_OriginalLanguage_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_originalLanguage_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_OriginalLanguage_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_originalLanguage_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_ShortDescription = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_shortDescription';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_ShortDescription_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_ShortDescription_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_ShortDescription_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_ShortDescription_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_ShortDescription_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_shortDescription_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_ShortDescription_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_shortDescription_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_ShortDescription_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_shortDescription_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_ShortDescription_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_shortDescription_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_TaxId = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_taxID';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_TaxId_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_TaxId_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_TaxId_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_TaxId_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_TaxId_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_taxID_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_TaxId_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_taxID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_TaxId_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_taxID_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_TaxId_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_taxID_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Tenant = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_tenant';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_Tenant_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_Tenant_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_Tenant_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_Tenant_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_Tenant_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Tenant_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Tenant_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Tenant_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Timezone = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_timezone';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_Timezone_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_Timezone_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_Timezone_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_Timezone_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_Timezone_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_timezone_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Timezone_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_timezone_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Timezone_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_timezone_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Timezone_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_timezone_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_UpdatedAt = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_updatedAt';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_UpdatedAt_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_UpdatedAt_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_UpdatedAt_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_UpdatedAt_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Website = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_website';
  create?: Maybe<CompanyDetailsGlobalsDocAccessFields_Website_Create>;
  delete?: Maybe<CompanyDetailsGlobalsDocAccessFields_Website_Delete>;
  read?: Maybe<CompanyDetailsGlobalsDocAccessFields_Website_Read>;
  update?: Maybe<CompanyDetailsGlobalsDocAccessFields_Website_Update>;
};

export type CompanyDetailsGlobalsDocAccessFields_Website_Create = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_website_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Website_Delete = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_website_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Website_Read = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_website_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsDocAccessFields_Website_Update = {
  __typename?: 'CompanyDetailsGlobalsDocAccessFields_website_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields = {
  __typename?: 'CompanyDetailsGlobalsFields';
  address?: Maybe<CompanyDetailsGlobalsFields_Address>;
  companyName?: Maybe<CompanyDetailsGlobalsFields_CompanyName>;
  createdAt?: Maybe<CompanyDetailsGlobalsFields_CreatedAt>;
  displayName?: Maybe<CompanyDetailsGlobalsFields_DisplayName>;
  email?: Maybe<CompanyDetailsGlobalsFields_Email>;
  logo?: Maybe<CompanyDetailsGlobalsFields_Logo>;
  originalLanguage?: Maybe<CompanyDetailsGlobalsFields_OriginalLanguage>;
  shortDescription?: Maybe<CompanyDetailsGlobalsFields_ShortDescription>;
  taxID?: Maybe<CompanyDetailsGlobalsFields_TaxId>;
  tenant?: Maybe<CompanyDetailsGlobalsFields_Tenant>;
  timezone?: Maybe<CompanyDetailsGlobalsFields_Timezone>;
  updatedAt?: Maybe<CompanyDetailsGlobalsFields_UpdatedAt>;
  website?: Maybe<CompanyDetailsGlobalsFields_Website>;
};

export type CompanyDetailsGlobalsFields_Address = {
  __typename?: 'CompanyDetailsGlobalsFields_address';
  create?: Maybe<CompanyDetailsGlobalsFields_Address_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_Address_Delete>;
  fields?: Maybe<CompanyDetailsGlobalsFields_Address_Fields>;
  read?: Maybe<CompanyDetailsGlobalsFields_Address_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_Address_Update>;
};

export type CompanyDetailsGlobalsFields_Address_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_address_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_address_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_Fields = {
  __typename?: 'CompanyDetailsGlobalsFields_address_Fields';
  addressLine1?: Maybe<CompanyDetailsGlobalsFields_Address_AddressLine1>;
  addressLine2?: Maybe<CompanyDetailsGlobalsFields_Address_AddressLine2>;
  city?: Maybe<CompanyDetailsGlobalsFields_Address_City>;
  country?: Maybe<CompanyDetailsGlobalsFields_Address_Country>;
  county?: Maybe<CompanyDetailsGlobalsFields_Address_County>;
  postcode?: Maybe<CompanyDetailsGlobalsFields_Address_Postcode>;
};

export type CompanyDetailsGlobalsFields_Address_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_address_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_address_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_AddressLine1 = {
  __typename?: 'CompanyDetailsGlobalsFields_address_addressLine1';
  create?: Maybe<CompanyDetailsGlobalsFields_Address_AddressLine1_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_Address_AddressLine1_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_Address_AddressLine1_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_Address_AddressLine1_Update>;
};

export type CompanyDetailsGlobalsFields_Address_AddressLine1_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_address_addressLine1_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_AddressLine1_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_address_addressLine1_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_AddressLine1_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_address_addressLine1_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_AddressLine1_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_address_addressLine1_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_AddressLine2 = {
  __typename?: 'CompanyDetailsGlobalsFields_address_addressLine2';
  create?: Maybe<CompanyDetailsGlobalsFields_Address_AddressLine2_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_Address_AddressLine2_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_Address_AddressLine2_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_Address_AddressLine2_Update>;
};

export type CompanyDetailsGlobalsFields_Address_AddressLine2_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_address_addressLine2_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_AddressLine2_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_address_addressLine2_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_AddressLine2_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_address_addressLine2_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_AddressLine2_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_address_addressLine2_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_City = {
  __typename?: 'CompanyDetailsGlobalsFields_address_city';
  create?: Maybe<CompanyDetailsGlobalsFields_Address_City_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_Address_City_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_Address_City_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_Address_City_Update>;
};

export type CompanyDetailsGlobalsFields_Address_City_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_address_city_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_City_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_address_city_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_City_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_address_city_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_City_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_address_city_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_Country = {
  __typename?: 'CompanyDetailsGlobalsFields_address_country';
  create?: Maybe<CompanyDetailsGlobalsFields_Address_Country_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_Address_Country_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_Address_Country_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_Address_Country_Update>;
};

export type CompanyDetailsGlobalsFields_Address_Country_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_address_country_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_Country_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_address_country_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_Country_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_address_country_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_Country_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_address_country_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_County = {
  __typename?: 'CompanyDetailsGlobalsFields_address_county';
  create?: Maybe<CompanyDetailsGlobalsFields_Address_County_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_Address_County_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_Address_County_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_Address_County_Update>;
};

export type CompanyDetailsGlobalsFields_Address_County_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_address_county_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_County_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_address_county_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_County_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_address_county_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_County_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_address_county_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_Postcode = {
  __typename?: 'CompanyDetailsGlobalsFields_address_postcode';
  create?: Maybe<CompanyDetailsGlobalsFields_Address_Postcode_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_Address_Postcode_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_Address_Postcode_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_Address_Postcode_Update>;
};

export type CompanyDetailsGlobalsFields_Address_Postcode_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_address_postcode_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_Postcode_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_address_postcode_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_Postcode_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_address_postcode_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Address_Postcode_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_address_postcode_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_CompanyName = {
  __typename?: 'CompanyDetailsGlobalsFields_companyName';
  create?: Maybe<CompanyDetailsGlobalsFields_CompanyName_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_CompanyName_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_CompanyName_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_CompanyName_Update>;
};

export type CompanyDetailsGlobalsFields_CompanyName_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_companyName_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_CompanyName_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_companyName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_CompanyName_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_companyName_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_CompanyName_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_companyName_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_CreatedAt = {
  __typename?: 'CompanyDetailsGlobalsFields_createdAt';
  create?: Maybe<CompanyDetailsGlobalsFields_CreatedAt_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_CreatedAt_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_CreatedAt_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_CreatedAt_Update>;
};

export type CompanyDetailsGlobalsFields_CreatedAt_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_CreatedAt_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_CreatedAt_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_CreatedAt_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_DisplayName = {
  __typename?: 'CompanyDetailsGlobalsFields_displayName';
  create?: Maybe<CompanyDetailsGlobalsFields_DisplayName_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_DisplayName_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_DisplayName_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_DisplayName_Update>;
};

export type CompanyDetailsGlobalsFields_DisplayName_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_displayName_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_DisplayName_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_displayName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_DisplayName_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_displayName_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_DisplayName_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_displayName_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Email = {
  __typename?: 'CompanyDetailsGlobalsFields_email';
  create?: Maybe<CompanyDetailsGlobalsFields_Email_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_Email_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_Email_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_Email_Update>;
};

export type CompanyDetailsGlobalsFields_Email_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_email_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Email_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_email_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Email_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_email_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Email_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_email_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Logo = {
  __typename?: 'CompanyDetailsGlobalsFields_logo';
  create?: Maybe<CompanyDetailsGlobalsFields_Logo_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_Logo_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_Logo_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_Logo_Update>;
};

export type CompanyDetailsGlobalsFields_Logo_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_logo_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Logo_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_logo_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Logo_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_logo_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Logo_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_logo_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_OriginalLanguage = {
  __typename?: 'CompanyDetailsGlobalsFields_originalLanguage';
  create?: Maybe<CompanyDetailsGlobalsFields_OriginalLanguage_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_OriginalLanguage_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_OriginalLanguage_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_OriginalLanguage_Update>;
};

export type CompanyDetailsGlobalsFields_OriginalLanguage_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_originalLanguage_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_OriginalLanguage_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_originalLanguage_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_OriginalLanguage_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_originalLanguage_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_OriginalLanguage_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_originalLanguage_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_ShortDescription = {
  __typename?: 'CompanyDetailsGlobalsFields_shortDescription';
  create?: Maybe<CompanyDetailsGlobalsFields_ShortDescription_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_ShortDescription_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_ShortDescription_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_ShortDescription_Update>;
};

export type CompanyDetailsGlobalsFields_ShortDescription_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_shortDescription_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_ShortDescription_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_shortDescription_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_ShortDescription_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_shortDescription_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_ShortDescription_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_shortDescription_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_TaxId = {
  __typename?: 'CompanyDetailsGlobalsFields_taxID';
  create?: Maybe<CompanyDetailsGlobalsFields_TaxId_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_TaxId_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_TaxId_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_TaxId_Update>;
};

export type CompanyDetailsGlobalsFields_TaxId_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_taxID_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_TaxId_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_taxID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_TaxId_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_taxID_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_TaxId_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_taxID_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Tenant = {
  __typename?: 'CompanyDetailsGlobalsFields_tenant';
  create?: Maybe<CompanyDetailsGlobalsFields_Tenant_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_Tenant_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_Tenant_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_Tenant_Update>;
};

export type CompanyDetailsGlobalsFields_Tenant_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Tenant_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Tenant_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Tenant_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Timezone = {
  __typename?: 'CompanyDetailsGlobalsFields_timezone';
  create?: Maybe<CompanyDetailsGlobalsFields_Timezone_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_Timezone_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_Timezone_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_Timezone_Update>;
};

export type CompanyDetailsGlobalsFields_Timezone_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_timezone_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Timezone_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_timezone_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Timezone_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_timezone_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Timezone_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_timezone_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_UpdatedAt = {
  __typename?: 'CompanyDetailsGlobalsFields_updatedAt';
  create?: Maybe<CompanyDetailsGlobalsFields_UpdatedAt_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_UpdatedAt_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_UpdatedAt_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_UpdatedAt_Update>;
};

export type CompanyDetailsGlobalsFields_UpdatedAt_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_UpdatedAt_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_UpdatedAt_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_UpdatedAt_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Website = {
  __typename?: 'CompanyDetailsGlobalsFields_website';
  create?: Maybe<CompanyDetailsGlobalsFields_Website_Create>;
  delete?: Maybe<CompanyDetailsGlobalsFields_Website_Delete>;
  read?: Maybe<CompanyDetailsGlobalsFields_Website_Read>;
  update?: Maybe<CompanyDetailsGlobalsFields_Website_Update>;
};

export type CompanyDetailsGlobalsFields_Website_Create = {
  __typename?: 'CompanyDetailsGlobalsFields_website_Create';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Website_Delete = {
  __typename?: 'CompanyDetailsGlobalsFields_website_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Website_Read = {
  __typename?: 'CompanyDetailsGlobalsFields_website_Read';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsFields_Website_Update = {
  __typename?: 'CompanyDetailsGlobalsFields_website_Update';
  permission: Scalars['Boolean']['output'];
};

export type CompanyDetailsGlobalsReadAccess = {
  __typename?: 'CompanyDetailsGlobalsReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CompanyDetailsGlobalsReadDocAccess = {
  __typename?: 'CompanyDetailsGlobalsReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CompanyDetailsGlobalsUpdateAccess = {
  __typename?: 'CompanyDetailsGlobalsUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CompanyDetailsGlobalsUpdateDocAccess = {
  __typename?: 'CompanyDetailsGlobalsUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CompanyDetailsReadAccess = {
  __typename?: 'CompanyDetailsReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CompanyDetailsReadDocAccess = {
  __typename?: 'CompanyDetailsReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CompanyDetailsUpdateAccess = {
  __typename?: 'CompanyDetailsUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CompanyDetailsUpdateDocAccess = {
  __typename?: 'CompanyDetailsUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type Contact = {
  __typename?: 'Contact';
  billingAddress?: Maybe<Contact_BillingAddress>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['EmailAddress']['output'];
  fullName: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  stripeID?: Maybe<Scalars['String']['output']>;
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type ContactTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export enum ContactUpdate_BillingAddress_Country_MutationInput {
  Hu = 'HU'
}

export type Contact_BillingAddress = {
  __typename?: 'Contact_BillingAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Contact_BillingAddress_Country>;
  county?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
};

export enum Contact_BillingAddress_Country {
  Hu = 'HU'
}

export enum Contact_BillingAddress_Country_MutationInput {
  Hu = 'HU'
}

export type Contact_BillingAddress__AddressLine1_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Contact_BillingAddress__AddressLine2_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Contact_BillingAddress__City_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum Contact_BillingAddress__Country_Input {
  Hu = 'HU'
}

export type Contact_BillingAddress__Country_Operator = {
  all?: InputMaybe<Array<InputMaybe<Contact_BillingAddress__Country_Input>>>;
  equals?: InputMaybe<Contact_BillingAddress__Country_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Contact_BillingAddress__Country_Input>>>;
  not_equals?: InputMaybe<Contact_BillingAddress__Country_Input>;
  not_in?: InputMaybe<Array<InputMaybe<Contact_BillingAddress__Country_Input>>>;
};

export type Contact_BillingAddress__County_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Contact_BillingAddress__Postcode_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Contact_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Contact_Email_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
  contains?: InputMaybe<Scalars['EmailAddress']['input']>;
  equals?: InputMaybe<Scalars['EmailAddress']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
  like?: InputMaybe<Scalars['EmailAddress']['input']>;
  not_equals?: InputMaybe<Scalars['EmailAddress']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
};

export type Contact_FullName_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Contact_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Contact_Phone_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Contact_StripeId_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Contact_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Contact_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Contact_Where = {
  AND?: InputMaybe<Array<InputMaybe<Contact_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Contact_Where_Or>>>;
  billingAddress__addressLine1?: InputMaybe<Contact_BillingAddress__AddressLine1_Operator>;
  billingAddress__addressLine2?: InputMaybe<Contact_BillingAddress__AddressLine2_Operator>;
  billingAddress__city?: InputMaybe<Contact_BillingAddress__City_Operator>;
  billingAddress__country?: InputMaybe<Contact_BillingAddress__Country_Operator>;
  billingAddress__county?: InputMaybe<Contact_BillingAddress__County_Operator>;
  billingAddress__postcode?: InputMaybe<Contact_BillingAddress__Postcode_Operator>;
  createdAt?: InputMaybe<Contact_CreatedAt_Operator>;
  email?: InputMaybe<Contact_Email_Operator>;
  fullName?: InputMaybe<Contact_FullName_Operator>;
  id?: InputMaybe<Contact_Id_Operator>;
  phone?: InputMaybe<Contact_Phone_Operator>;
  stripeID?: InputMaybe<Contact_StripeId_Operator>;
  tenant?: InputMaybe<Contact_Tenant_Operator>;
  updatedAt?: InputMaybe<Contact_UpdatedAt_Operator>;
};

export type Contact_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Contact_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Contact_Where_Or>>>;
  billingAddress__addressLine1?: InputMaybe<Contact_BillingAddress__AddressLine1_Operator>;
  billingAddress__addressLine2?: InputMaybe<Contact_BillingAddress__AddressLine2_Operator>;
  billingAddress__city?: InputMaybe<Contact_BillingAddress__City_Operator>;
  billingAddress__country?: InputMaybe<Contact_BillingAddress__Country_Operator>;
  billingAddress__county?: InputMaybe<Contact_BillingAddress__County_Operator>;
  billingAddress__postcode?: InputMaybe<Contact_BillingAddress__Postcode_Operator>;
  createdAt?: InputMaybe<Contact_CreatedAt_Operator>;
  email?: InputMaybe<Contact_Email_Operator>;
  fullName?: InputMaybe<Contact_FullName_Operator>;
  id?: InputMaybe<Contact_Id_Operator>;
  phone?: InputMaybe<Contact_Phone_Operator>;
  stripeID?: InputMaybe<Contact_StripeId_Operator>;
  tenant?: InputMaybe<Contact_Tenant_Operator>;
  updatedAt?: InputMaybe<Contact_UpdatedAt_Operator>;
};

export type Contact_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Contact_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Contact_Where_Or>>>;
  billingAddress__addressLine1?: InputMaybe<Contact_BillingAddress__AddressLine1_Operator>;
  billingAddress__addressLine2?: InputMaybe<Contact_BillingAddress__AddressLine2_Operator>;
  billingAddress__city?: InputMaybe<Contact_BillingAddress__City_Operator>;
  billingAddress__country?: InputMaybe<Contact_BillingAddress__Country_Operator>;
  billingAddress__county?: InputMaybe<Contact_BillingAddress__County_Operator>;
  billingAddress__postcode?: InputMaybe<Contact_BillingAddress__Postcode_Operator>;
  createdAt?: InputMaybe<Contact_CreatedAt_Operator>;
  email?: InputMaybe<Contact_Email_Operator>;
  fullName?: InputMaybe<Contact_FullName_Operator>;
  id?: InputMaybe<Contact_Id_Operator>;
  phone?: InputMaybe<Contact_Phone_Operator>;
  stripeID?: InputMaybe<Contact_StripeId_Operator>;
  tenant?: InputMaybe<Contact_Tenant_Operator>;
  updatedAt?: InputMaybe<Contact_UpdatedAt_Operator>;
};

export type Contacts = {
  __typename?: 'Contacts';
  docs?: Maybe<Array<Maybe<Contact>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type ContactsCreateAccess = {
  __typename?: 'ContactsCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ContactsCreateDocAccess = {
  __typename?: 'ContactsCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ContactsDeleteAccess = {
  __typename?: 'ContactsDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ContactsDeleteDocAccess = {
  __typename?: 'ContactsDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ContactsDocAccessFields = {
  __typename?: 'ContactsDocAccessFields';
  billingAddress?: Maybe<ContactsDocAccessFields_BillingAddress>;
  createdAt?: Maybe<ContactsDocAccessFields_CreatedAt>;
  email?: Maybe<ContactsDocAccessFields_Email>;
  fullName?: Maybe<ContactsDocAccessFields_FullName>;
  phone?: Maybe<ContactsDocAccessFields_Phone>;
  stripeID?: Maybe<ContactsDocAccessFields_StripeId>;
  tenant?: Maybe<ContactsDocAccessFields_Tenant>;
  updatedAt?: Maybe<ContactsDocAccessFields_UpdatedAt>;
};

export type ContactsDocAccessFields_BillingAddress = {
  __typename?: 'ContactsDocAccessFields_billingAddress';
  create?: Maybe<ContactsDocAccessFields_BillingAddress_Create>;
  delete?: Maybe<ContactsDocAccessFields_BillingAddress_Delete>;
  fields?: Maybe<ContactsDocAccessFields_BillingAddress_Fields>;
  read?: Maybe<ContactsDocAccessFields_BillingAddress_Read>;
  update?: Maybe<ContactsDocAccessFields_BillingAddress_Update>;
};

export type ContactsDocAccessFields_BillingAddress_Create = {
  __typename?: 'ContactsDocAccessFields_billingAddress_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_Delete = {
  __typename?: 'ContactsDocAccessFields_billingAddress_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_Fields = {
  __typename?: 'ContactsDocAccessFields_billingAddress_Fields';
  addressLine1?: Maybe<ContactsDocAccessFields_BillingAddress_AddressLine1>;
  addressLine2?: Maybe<ContactsDocAccessFields_BillingAddress_AddressLine2>;
  city?: Maybe<ContactsDocAccessFields_BillingAddress_City>;
  country?: Maybe<ContactsDocAccessFields_BillingAddress_Country>;
  county?: Maybe<ContactsDocAccessFields_BillingAddress_County>;
  postcode?: Maybe<ContactsDocAccessFields_BillingAddress_Postcode>;
};

export type ContactsDocAccessFields_BillingAddress_Read = {
  __typename?: 'ContactsDocAccessFields_billingAddress_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_Update = {
  __typename?: 'ContactsDocAccessFields_billingAddress_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_AddressLine1 = {
  __typename?: 'ContactsDocAccessFields_billingAddress_addressLine1';
  create?: Maybe<ContactsDocAccessFields_BillingAddress_AddressLine1_Create>;
  delete?: Maybe<ContactsDocAccessFields_BillingAddress_AddressLine1_Delete>;
  read?: Maybe<ContactsDocAccessFields_BillingAddress_AddressLine1_Read>;
  update?: Maybe<ContactsDocAccessFields_BillingAddress_AddressLine1_Update>;
};

export type ContactsDocAccessFields_BillingAddress_AddressLine1_Create = {
  __typename?: 'ContactsDocAccessFields_billingAddress_addressLine1_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_AddressLine1_Delete = {
  __typename?: 'ContactsDocAccessFields_billingAddress_addressLine1_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_AddressLine1_Read = {
  __typename?: 'ContactsDocAccessFields_billingAddress_addressLine1_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_AddressLine1_Update = {
  __typename?: 'ContactsDocAccessFields_billingAddress_addressLine1_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_AddressLine2 = {
  __typename?: 'ContactsDocAccessFields_billingAddress_addressLine2';
  create?: Maybe<ContactsDocAccessFields_BillingAddress_AddressLine2_Create>;
  delete?: Maybe<ContactsDocAccessFields_BillingAddress_AddressLine2_Delete>;
  read?: Maybe<ContactsDocAccessFields_BillingAddress_AddressLine2_Read>;
  update?: Maybe<ContactsDocAccessFields_BillingAddress_AddressLine2_Update>;
};

export type ContactsDocAccessFields_BillingAddress_AddressLine2_Create = {
  __typename?: 'ContactsDocAccessFields_billingAddress_addressLine2_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_AddressLine2_Delete = {
  __typename?: 'ContactsDocAccessFields_billingAddress_addressLine2_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_AddressLine2_Read = {
  __typename?: 'ContactsDocAccessFields_billingAddress_addressLine2_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_AddressLine2_Update = {
  __typename?: 'ContactsDocAccessFields_billingAddress_addressLine2_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_City = {
  __typename?: 'ContactsDocAccessFields_billingAddress_city';
  create?: Maybe<ContactsDocAccessFields_BillingAddress_City_Create>;
  delete?: Maybe<ContactsDocAccessFields_BillingAddress_City_Delete>;
  read?: Maybe<ContactsDocAccessFields_BillingAddress_City_Read>;
  update?: Maybe<ContactsDocAccessFields_BillingAddress_City_Update>;
};

export type ContactsDocAccessFields_BillingAddress_City_Create = {
  __typename?: 'ContactsDocAccessFields_billingAddress_city_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_City_Delete = {
  __typename?: 'ContactsDocAccessFields_billingAddress_city_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_City_Read = {
  __typename?: 'ContactsDocAccessFields_billingAddress_city_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_City_Update = {
  __typename?: 'ContactsDocAccessFields_billingAddress_city_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_Country = {
  __typename?: 'ContactsDocAccessFields_billingAddress_country';
  create?: Maybe<ContactsDocAccessFields_BillingAddress_Country_Create>;
  delete?: Maybe<ContactsDocAccessFields_BillingAddress_Country_Delete>;
  read?: Maybe<ContactsDocAccessFields_BillingAddress_Country_Read>;
  update?: Maybe<ContactsDocAccessFields_BillingAddress_Country_Update>;
};

export type ContactsDocAccessFields_BillingAddress_Country_Create = {
  __typename?: 'ContactsDocAccessFields_billingAddress_country_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_Country_Delete = {
  __typename?: 'ContactsDocAccessFields_billingAddress_country_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_Country_Read = {
  __typename?: 'ContactsDocAccessFields_billingAddress_country_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_Country_Update = {
  __typename?: 'ContactsDocAccessFields_billingAddress_country_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_County = {
  __typename?: 'ContactsDocAccessFields_billingAddress_county';
  create?: Maybe<ContactsDocAccessFields_BillingAddress_County_Create>;
  delete?: Maybe<ContactsDocAccessFields_BillingAddress_County_Delete>;
  read?: Maybe<ContactsDocAccessFields_BillingAddress_County_Read>;
  update?: Maybe<ContactsDocAccessFields_BillingAddress_County_Update>;
};

export type ContactsDocAccessFields_BillingAddress_County_Create = {
  __typename?: 'ContactsDocAccessFields_billingAddress_county_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_County_Delete = {
  __typename?: 'ContactsDocAccessFields_billingAddress_county_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_County_Read = {
  __typename?: 'ContactsDocAccessFields_billingAddress_county_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_County_Update = {
  __typename?: 'ContactsDocAccessFields_billingAddress_county_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_Postcode = {
  __typename?: 'ContactsDocAccessFields_billingAddress_postcode';
  create?: Maybe<ContactsDocAccessFields_BillingAddress_Postcode_Create>;
  delete?: Maybe<ContactsDocAccessFields_BillingAddress_Postcode_Delete>;
  read?: Maybe<ContactsDocAccessFields_BillingAddress_Postcode_Read>;
  update?: Maybe<ContactsDocAccessFields_BillingAddress_Postcode_Update>;
};

export type ContactsDocAccessFields_BillingAddress_Postcode_Create = {
  __typename?: 'ContactsDocAccessFields_billingAddress_postcode_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_Postcode_Delete = {
  __typename?: 'ContactsDocAccessFields_billingAddress_postcode_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_Postcode_Read = {
  __typename?: 'ContactsDocAccessFields_billingAddress_postcode_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_BillingAddress_Postcode_Update = {
  __typename?: 'ContactsDocAccessFields_billingAddress_postcode_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_CreatedAt = {
  __typename?: 'ContactsDocAccessFields_createdAt';
  create?: Maybe<ContactsDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<ContactsDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<ContactsDocAccessFields_CreatedAt_Read>;
  update?: Maybe<ContactsDocAccessFields_CreatedAt_Update>;
};

export type ContactsDocAccessFields_CreatedAt_Create = {
  __typename?: 'ContactsDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_CreatedAt_Delete = {
  __typename?: 'ContactsDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_CreatedAt_Read = {
  __typename?: 'ContactsDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_CreatedAt_Update = {
  __typename?: 'ContactsDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_Email = {
  __typename?: 'ContactsDocAccessFields_email';
  create?: Maybe<ContactsDocAccessFields_Email_Create>;
  delete?: Maybe<ContactsDocAccessFields_Email_Delete>;
  read?: Maybe<ContactsDocAccessFields_Email_Read>;
  update?: Maybe<ContactsDocAccessFields_Email_Update>;
};

export type ContactsDocAccessFields_Email_Create = {
  __typename?: 'ContactsDocAccessFields_email_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_Email_Delete = {
  __typename?: 'ContactsDocAccessFields_email_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_Email_Read = {
  __typename?: 'ContactsDocAccessFields_email_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_Email_Update = {
  __typename?: 'ContactsDocAccessFields_email_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_FullName = {
  __typename?: 'ContactsDocAccessFields_fullName';
  create?: Maybe<ContactsDocAccessFields_FullName_Create>;
  delete?: Maybe<ContactsDocAccessFields_FullName_Delete>;
  read?: Maybe<ContactsDocAccessFields_FullName_Read>;
  update?: Maybe<ContactsDocAccessFields_FullName_Update>;
};

export type ContactsDocAccessFields_FullName_Create = {
  __typename?: 'ContactsDocAccessFields_fullName_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_FullName_Delete = {
  __typename?: 'ContactsDocAccessFields_fullName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_FullName_Read = {
  __typename?: 'ContactsDocAccessFields_fullName_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_FullName_Update = {
  __typename?: 'ContactsDocAccessFields_fullName_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_Phone = {
  __typename?: 'ContactsDocAccessFields_phone';
  create?: Maybe<ContactsDocAccessFields_Phone_Create>;
  delete?: Maybe<ContactsDocAccessFields_Phone_Delete>;
  read?: Maybe<ContactsDocAccessFields_Phone_Read>;
  update?: Maybe<ContactsDocAccessFields_Phone_Update>;
};

export type ContactsDocAccessFields_Phone_Create = {
  __typename?: 'ContactsDocAccessFields_phone_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_Phone_Delete = {
  __typename?: 'ContactsDocAccessFields_phone_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_Phone_Read = {
  __typename?: 'ContactsDocAccessFields_phone_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_Phone_Update = {
  __typename?: 'ContactsDocAccessFields_phone_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_StripeId = {
  __typename?: 'ContactsDocAccessFields_stripeID';
  create?: Maybe<ContactsDocAccessFields_StripeId_Create>;
  delete?: Maybe<ContactsDocAccessFields_StripeId_Delete>;
  read?: Maybe<ContactsDocAccessFields_StripeId_Read>;
  update?: Maybe<ContactsDocAccessFields_StripeId_Update>;
};

export type ContactsDocAccessFields_StripeId_Create = {
  __typename?: 'ContactsDocAccessFields_stripeID_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_StripeId_Delete = {
  __typename?: 'ContactsDocAccessFields_stripeID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_StripeId_Read = {
  __typename?: 'ContactsDocAccessFields_stripeID_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_StripeId_Update = {
  __typename?: 'ContactsDocAccessFields_stripeID_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_Tenant = {
  __typename?: 'ContactsDocAccessFields_tenant';
  create?: Maybe<ContactsDocAccessFields_Tenant_Create>;
  delete?: Maybe<ContactsDocAccessFields_Tenant_Delete>;
  read?: Maybe<ContactsDocAccessFields_Tenant_Read>;
  update?: Maybe<ContactsDocAccessFields_Tenant_Update>;
};

export type ContactsDocAccessFields_Tenant_Create = {
  __typename?: 'ContactsDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_Tenant_Delete = {
  __typename?: 'ContactsDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_Tenant_Read = {
  __typename?: 'ContactsDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_Tenant_Update = {
  __typename?: 'ContactsDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_UpdatedAt = {
  __typename?: 'ContactsDocAccessFields_updatedAt';
  create?: Maybe<ContactsDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<ContactsDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<ContactsDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<ContactsDocAccessFields_UpdatedAt_Update>;
};

export type ContactsDocAccessFields_UpdatedAt_Create = {
  __typename?: 'ContactsDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'ContactsDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_UpdatedAt_Read = {
  __typename?: 'ContactsDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsDocAccessFields_UpdatedAt_Update = {
  __typename?: 'ContactsDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields = {
  __typename?: 'ContactsFields';
  billingAddress?: Maybe<ContactsFields_BillingAddress>;
  createdAt?: Maybe<ContactsFields_CreatedAt>;
  email?: Maybe<ContactsFields_Email>;
  fullName?: Maybe<ContactsFields_FullName>;
  phone?: Maybe<ContactsFields_Phone>;
  stripeID?: Maybe<ContactsFields_StripeId>;
  tenant?: Maybe<ContactsFields_Tenant>;
  updatedAt?: Maybe<ContactsFields_UpdatedAt>;
};

export type ContactsFields_BillingAddress = {
  __typename?: 'ContactsFields_billingAddress';
  create?: Maybe<ContactsFields_BillingAddress_Create>;
  delete?: Maybe<ContactsFields_BillingAddress_Delete>;
  fields?: Maybe<ContactsFields_BillingAddress_Fields>;
  read?: Maybe<ContactsFields_BillingAddress_Read>;
  update?: Maybe<ContactsFields_BillingAddress_Update>;
};

export type ContactsFields_BillingAddress_Create = {
  __typename?: 'ContactsFields_billingAddress_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_Delete = {
  __typename?: 'ContactsFields_billingAddress_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_Fields = {
  __typename?: 'ContactsFields_billingAddress_Fields';
  addressLine1?: Maybe<ContactsFields_BillingAddress_AddressLine1>;
  addressLine2?: Maybe<ContactsFields_BillingAddress_AddressLine2>;
  city?: Maybe<ContactsFields_BillingAddress_City>;
  country?: Maybe<ContactsFields_BillingAddress_Country>;
  county?: Maybe<ContactsFields_BillingAddress_County>;
  postcode?: Maybe<ContactsFields_BillingAddress_Postcode>;
};

export type ContactsFields_BillingAddress_Read = {
  __typename?: 'ContactsFields_billingAddress_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_Update = {
  __typename?: 'ContactsFields_billingAddress_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_AddressLine1 = {
  __typename?: 'ContactsFields_billingAddress_addressLine1';
  create?: Maybe<ContactsFields_BillingAddress_AddressLine1_Create>;
  delete?: Maybe<ContactsFields_BillingAddress_AddressLine1_Delete>;
  read?: Maybe<ContactsFields_BillingAddress_AddressLine1_Read>;
  update?: Maybe<ContactsFields_BillingAddress_AddressLine1_Update>;
};

export type ContactsFields_BillingAddress_AddressLine1_Create = {
  __typename?: 'ContactsFields_billingAddress_addressLine1_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_AddressLine1_Delete = {
  __typename?: 'ContactsFields_billingAddress_addressLine1_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_AddressLine1_Read = {
  __typename?: 'ContactsFields_billingAddress_addressLine1_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_AddressLine1_Update = {
  __typename?: 'ContactsFields_billingAddress_addressLine1_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_AddressLine2 = {
  __typename?: 'ContactsFields_billingAddress_addressLine2';
  create?: Maybe<ContactsFields_BillingAddress_AddressLine2_Create>;
  delete?: Maybe<ContactsFields_BillingAddress_AddressLine2_Delete>;
  read?: Maybe<ContactsFields_BillingAddress_AddressLine2_Read>;
  update?: Maybe<ContactsFields_BillingAddress_AddressLine2_Update>;
};

export type ContactsFields_BillingAddress_AddressLine2_Create = {
  __typename?: 'ContactsFields_billingAddress_addressLine2_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_AddressLine2_Delete = {
  __typename?: 'ContactsFields_billingAddress_addressLine2_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_AddressLine2_Read = {
  __typename?: 'ContactsFields_billingAddress_addressLine2_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_AddressLine2_Update = {
  __typename?: 'ContactsFields_billingAddress_addressLine2_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_City = {
  __typename?: 'ContactsFields_billingAddress_city';
  create?: Maybe<ContactsFields_BillingAddress_City_Create>;
  delete?: Maybe<ContactsFields_BillingAddress_City_Delete>;
  read?: Maybe<ContactsFields_BillingAddress_City_Read>;
  update?: Maybe<ContactsFields_BillingAddress_City_Update>;
};

export type ContactsFields_BillingAddress_City_Create = {
  __typename?: 'ContactsFields_billingAddress_city_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_City_Delete = {
  __typename?: 'ContactsFields_billingAddress_city_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_City_Read = {
  __typename?: 'ContactsFields_billingAddress_city_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_City_Update = {
  __typename?: 'ContactsFields_billingAddress_city_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_Country = {
  __typename?: 'ContactsFields_billingAddress_country';
  create?: Maybe<ContactsFields_BillingAddress_Country_Create>;
  delete?: Maybe<ContactsFields_BillingAddress_Country_Delete>;
  read?: Maybe<ContactsFields_BillingAddress_Country_Read>;
  update?: Maybe<ContactsFields_BillingAddress_Country_Update>;
};

export type ContactsFields_BillingAddress_Country_Create = {
  __typename?: 'ContactsFields_billingAddress_country_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_Country_Delete = {
  __typename?: 'ContactsFields_billingAddress_country_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_Country_Read = {
  __typename?: 'ContactsFields_billingAddress_country_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_Country_Update = {
  __typename?: 'ContactsFields_billingAddress_country_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_County = {
  __typename?: 'ContactsFields_billingAddress_county';
  create?: Maybe<ContactsFields_BillingAddress_County_Create>;
  delete?: Maybe<ContactsFields_BillingAddress_County_Delete>;
  read?: Maybe<ContactsFields_BillingAddress_County_Read>;
  update?: Maybe<ContactsFields_BillingAddress_County_Update>;
};

export type ContactsFields_BillingAddress_County_Create = {
  __typename?: 'ContactsFields_billingAddress_county_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_County_Delete = {
  __typename?: 'ContactsFields_billingAddress_county_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_County_Read = {
  __typename?: 'ContactsFields_billingAddress_county_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_County_Update = {
  __typename?: 'ContactsFields_billingAddress_county_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_Postcode = {
  __typename?: 'ContactsFields_billingAddress_postcode';
  create?: Maybe<ContactsFields_BillingAddress_Postcode_Create>;
  delete?: Maybe<ContactsFields_BillingAddress_Postcode_Delete>;
  read?: Maybe<ContactsFields_BillingAddress_Postcode_Read>;
  update?: Maybe<ContactsFields_BillingAddress_Postcode_Update>;
};

export type ContactsFields_BillingAddress_Postcode_Create = {
  __typename?: 'ContactsFields_billingAddress_postcode_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_Postcode_Delete = {
  __typename?: 'ContactsFields_billingAddress_postcode_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_Postcode_Read = {
  __typename?: 'ContactsFields_billingAddress_postcode_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_BillingAddress_Postcode_Update = {
  __typename?: 'ContactsFields_billingAddress_postcode_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_CreatedAt = {
  __typename?: 'ContactsFields_createdAt';
  create?: Maybe<ContactsFields_CreatedAt_Create>;
  delete?: Maybe<ContactsFields_CreatedAt_Delete>;
  read?: Maybe<ContactsFields_CreatedAt_Read>;
  update?: Maybe<ContactsFields_CreatedAt_Update>;
};

export type ContactsFields_CreatedAt_Create = {
  __typename?: 'ContactsFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_CreatedAt_Delete = {
  __typename?: 'ContactsFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_CreatedAt_Read = {
  __typename?: 'ContactsFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_CreatedAt_Update = {
  __typename?: 'ContactsFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_Email = {
  __typename?: 'ContactsFields_email';
  create?: Maybe<ContactsFields_Email_Create>;
  delete?: Maybe<ContactsFields_Email_Delete>;
  read?: Maybe<ContactsFields_Email_Read>;
  update?: Maybe<ContactsFields_Email_Update>;
};

export type ContactsFields_Email_Create = {
  __typename?: 'ContactsFields_email_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_Email_Delete = {
  __typename?: 'ContactsFields_email_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_Email_Read = {
  __typename?: 'ContactsFields_email_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_Email_Update = {
  __typename?: 'ContactsFields_email_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_FullName = {
  __typename?: 'ContactsFields_fullName';
  create?: Maybe<ContactsFields_FullName_Create>;
  delete?: Maybe<ContactsFields_FullName_Delete>;
  read?: Maybe<ContactsFields_FullName_Read>;
  update?: Maybe<ContactsFields_FullName_Update>;
};

export type ContactsFields_FullName_Create = {
  __typename?: 'ContactsFields_fullName_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_FullName_Delete = {
  __typename?: 'ContactsFields_fullName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_FullName_Read = {
  __typename?: 'ContactsFields_fullName_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_FullName_Update = {
  __typename?: 'ContactsFields_fullName_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_Phone = {
  __typename?: 'ContactsFields_phone';
  create?: Maybe<ContactsFields_Phone_Create>;
  delete?: Maybe<ContactsFields_Phone_Delete>;
  read?: Maybe<ContactsFields_Phone_Read>;
  update?: Maybe<ContactsFields_Phone_Update>;
};

export type ContactsFields_Phone_Create = {
  __typename?: 'ContactsFields_phone_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_Phone_Delete = {
  __typename?: 'ContactsFields_phone_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_Phone_Read = {
  __typename?: 'ContactsFields_phone_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_Phone_Update = {
  __typename?: 'ContactsFields_phone_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_StripeId = {
  __typename?: 'ContactsFields_stripeID';
  create?: Maybe<ContactsFields_StripeId_Create>;
  delete?: Maybe<ContactsFields_StripeId_Delete>;
  read?: Maybe<ContactsFields_StripeId_Read>;
  update?: Maybe<ContactsFields_StripeId_Update>;
};

export type ContactsFields_StripeId_Create = {
  __typename?: 'ContactsFields_stripeID_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_StripeId_Delete = {
  __typename?: 'ContactsFields_stripeID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_StripeId_Read = {
  __typename?: 'ContactsFields_stripeID_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_StripeId_Update = {
  __typename?: 'ContactsFields_stripeID_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_Tenant = {
  __typename?: 'ContactsFields_tenant';
  create?: Maybe<ContactsFields_Tenant_Create>;
  delete?: Maybe<ContactsFields_Tenant_Delete>;
  read?: Maybe<ContactsFields_Tenant_Read>;
  update?: Maybe<ContactsFields_Tenant_Update>;
};

export type ContactsFields_Tenant_Create = {
  __typename?: 'ContactsFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_Tenant_Delete = {
  __typename?: 'ContactsFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_Tenant_Read = {
  __typename?: 'ContactsFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_Tenant_Update = {
  __typename?: 'ContactsFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_UpdatedAt = {
  __typename?: 'ContactsFields_updatedAt';
  create?: Maybe<ContactsFields_UpdatedAt_Create>;
  delete?: Maybe<ContactsFields_UpdatedAt_Delete>;
  read?: Maybe<ContactsFields_UpdatedAt_Read>;
  update?: Maybe<ContactsFields_UpdatedAt_Update>;
};

export type ContactsFields_UpdatedAt_Create = {
  __typename?: 'ContactsFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_UpdatedAt_Delete = {
  __typename?: 'ContactsFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_UpdatedAt_Read = {
  __typename?: 'ContactsFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ContactsFields_UpdatedAt_Update = {
  __typename?: 'ContactsFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ContactsReadAccess = {
  __typename?: 'ContactsReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ContactsReadDocAccess = {
  __typename?: 'ContactsReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ContactsUpdateAccess = {
  __typename?: 'ContactsUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ContactsUpdateDocAccess = {
  __typename?: 'ContactsUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CreateBookingExtraInput = {
  count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateBookingInput = {
  end: Scalars['String']['input'];
  extras?: InputMaybe<Array<CreateBookingExtraInput>>;
  locale?: InputMaybe<LocaleInputType>;
  serviceID: Scalars['ID']['input'];
  start: Scalars['String']['input'];
  tenant: Scalars['String']['input'];
  tickets?: InputMaybe<Array<CreateBookingTicketInput>>;
  voucherID?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBookingTicketInput = {
  count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateVoucherCheckoutInput = {
  extras?: InputMaybe<Array<InputMaybe<CreateBookingExtraInput>>>;
  locale?: InputMaybe<LocaleInputType>;
  serviceID: Scalars['ID']['input'];
  tenant: Scalars['String']['input'];
  tickets?: InputMaybe<Array<InputMaybe<CreateBookingTicketInput>>>;
};

export enum Currency {
  Eur = 'EUR',
  Huf = 'HUF'
}

export type Error = {
  __typename?: 'Error';
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<ErrorReason>;
};

export enum ErrorReason {
  ApplicationError = 'APPLICATION_ERROR',
  NotFound = 'NOT_FOUND',
  ValidationError = 'VALIDATION_ERROR'
}

export type Event = {
  __typename?: 'Event';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  end: Scalars['DateTime']['output'];
  eventStatus?: Maybe<Event_EventStatus>;
  id?: Maybe<Scalars['String']['output']>;
  location: Scalars['String']['output'];
  service?: Maybe<Service>;
  start: Scalars['DateTime']['output'];
  tenant?: Maybe<Tenant>;
  title?: Maybe<Scalars['String']['output']>;
  totalTickets?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type EventServiceArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};


export type EventTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export enum EventUpdate_EventStatus_MutationInput {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type Event_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Event_End_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum Event_EventStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export enum Event_EventStatus_Input {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export enum Event_EventStatus_MutationInput {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type Event_EventStatus_Operator = {
  all?: InputMaybe<Array<InputMaybe<Event_EventStatus_Input>>>;
  equals?: InputMaybe<Event_EventStatus_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Event_EventStatus_Input>>>;
  not_equals?: InputMaybe<Event_EventStatus_Input>;
  not_in?: InputMaybe<Array<InputMaybe<Event_EventStatus_Input>>>;
};

export type Event_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Event_Location_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Event_Service_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Event_Start_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Event_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Event_Title_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Event_TotalTickets_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Event_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Event_Where = {
  AND?: InputMaybe<Array<InputMaybe<Event_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Event_Where_Or>>>;
  createdAt?: InputMaybe<Event_CreatedAt_Operator>;
  end?: InputMaybe<Event_End_Operator>;
  eventStatus?: InputMaybe<Event_EventStatus_Operator>;
  id?: InputMaybe<Event_Id_Operator>;
  location?: InputMaybe<Event_Location_Operator>;
  service?: InputMaybe<Event_Service_Operator>;
  start?: InputMaybe<Event_Start_Operator>;
  tenant?: InputMaybe<Event_Tenant_Operator>;
  title?: InputMaybe<Event_Title_Operator>;
  totalTickets?: InputMaybe<Event_TotalTickets_Operator>;
  updatedAt?: InputMaybe<Event_UpdatedAt_Operator>;
};

export type Event_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Event_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Event_Where_Or>>>;
  createdAt?: InputMaybe<Event_CreatedAt_Operator>;
  end?: InputMaybe<Event_End_Operator>;
  eventStatus?: InputMaybe<Event_EventStatus_Operator>;
  id?: InputMaybe<Event_Id_Operator>;
  location?: InputMaybe<Event_Location_Operator>;
  service?: InputMaybe<Event_Service_Operator>;
  start?: InputMaybe<Event_Start_Operator>;
  tenant?: InputMaybe<Event_Tenant_Operator>;
  title?: InputMaybe<Event_Title_Operator>;
  totalTickets?: InputMaybe<Event_TotalTickets_Operator>;
  updatedAt?: InputMaybe<Event_UpdatedAt_Operator>;
};

export type Event_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Event_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Event_Where_Or>>>;
  createdAt?: InputMaybe<Event_CreatedAt_Operator>;
  end?: InputMaybe<Event_End_Operator>;
  eventStatus?: InputMaybe<Event_EventStatus_Operator>;
  id?: InputMaybe<Event_Id_Operator>;
  location?: InputMaybe<Event_Location_Operator>;
  service?: InputMaybe<Event_Service_Operator>;
  start?: InputMaybe<Event_Start_Operator>;
  tenant?: InputMaybe<Event_Tenant_Operator>;
  title?: InputMaybe<Event_Title_Operator>;
  totalTickets?: InputMaybe<Event_TotalTickets_Operator>;
  updatedAt?: InputMaybe<Event_UpdatedAt_Operator>;
};

export type Events = {
  __typename?: 'Events';
  docs?: Maybe<Array<Maybe<Event>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type EventsCreateAccess = {
  __typename?: 'EventsCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type EventsCreateDocAccess = {
  __typename?: 'EventsCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type EventsDeleteAccess = {
  __typename?: 'EventsDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type EventsDeleteDocAccess = {
  __typename?: 'EventsDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type EventsDocAccessFields = {
  __typename?: 'EventsDocAccessFields';
  createdAt?: Maybe<EventsDocAccessFields_CreatedAt>;
  end?: Maybe<EventsDocAccessFields_End>;
  eventStatus?: Maybe<EventsDocAccessFields_EventStatus>;
  location?: Maybe<EventsDocAccessFields_Location>;
  service?: Maybe<EventsDocAccessFields_Service>;
  start?: Maybe<EventsDocAccessFields_Start>;
  tenant?: Maybe<EventsDocAccessFields_Tenant>;
  title?: Maybe<EventsDocAccessFields_Title>;
  totalTickets?: Maybe<EventsDocAccessFields_TotalTickets>;
  updatedAt?: Maybe<EventsDocAccessFields_UpdatedAt>;
};

export type EventsDocAccessFields_CreatedAt = {
  __typename?: 'EventsDocAccessFields_createdAt';
  create?: Maybe<EventsDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<EventsDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<EventsDocAccessFields_CreatedAt_Read>;
  update?: Maybe<EventsDocAccessFields_CreatedAt_Update>;
};

export type EventsDocAccessFields_CreatedAt_Create = {
  __typename?: 'EventsDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_CreatedAt_Delete = {
  __typename?: 'EventsDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_CreatedAt_Read = {
  __typename?: 'EventsDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_CreatedAt_Update = {
  __typename?: 'EventsDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_End = {
  __typename?: 'EventsDocAccessFields_end';
  create?: Maybe<EventsDocAccessFields_End_Create>;
  delete?: Maybe<EventsDocAccessFields_End_Delete>;
  read?: Maybe<EventsDocAccessFields_End_Read>;
  update?: Maybe<EventsDocAccessFields_End_Update>;
};

export type EventsDocAccessFields_End_Create = {
  __typename?: 'EventsDocAccessFields_end_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_End_Delete = {
  __typename?: 'EventsDocAccessFields_end_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_End_Read = {
  __typename?: 'EventsDocAccessFields_end_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_End_Update = {
  __typename?: 'EventsDocAccessFields_end_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_EventStatus = {
  __typename?: 'EventsDocAccessFields_eventStatus';
  create?: Maybe<EventsDocAccessFields_EventStatus_Create>;
  delete?: Maybe<EventsDocAccessFields_EventStatus_Delete>;
  read?: Maybe<EventsDocAccessFields_EventStatus_Read>;
  update?: Maybe<EventsDocAccessFields_EventStatus_Update>;
};

export type EventsDocAccessFields_EventStatus_Create = {
  __typename?: 'EventsDocAccessFields_eventStatus_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_EventStatus_Delete = {
  __typename?: 'EventsDocAccessFields_eventStatus_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_EventStatus_Read = {
  __typename?: 'EventsDocAccessFields_eventStatus_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_EventStatus_Update = {
  __typename?: 'EventsDocAccessFields_eventStatus_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Location = {
  __typename?: 'EventsDocAccessFields_location';
  create?: Maybe<EventsDocAccessFields_Location_Create>;
  delete?: Maybe<EventsDocAccessFields_Location_Delete>;
  read?: Maybe<EventsDocAccessFields_Location_Read>;
  update?: Maybe<EventsDocAccessFields_Location_Update>;
};

export type EventsDocAccessFields_Location_Create = {
  __typename?: 'EventsDocAccessFields_location_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Location_Delete = {
  __typename?: 'EventsDocAccessFields_location_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Location_Read = {
  __typename?: 'EventsDocAccessFields_location_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Location_Update = {
  __typename?: 'EventsDocAccessFields_location_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Service = {
  __typename?: 'EventsDocAccessFields_service';
  create?: Maybe<EventsDocAccessFields_Service_Create>;
  delete?: Maybe<EventsDocAccessFields_Service_Delete>;
  read?: Maybe<EventsDocAccessFields_Service_Read>;
  update?: Maybe<EventsDocAccessFields_Service_Update>;
};

export type EventsDocAccessFields_Service_Create = {
  __typename?: 'EventsDocAccessFields_service_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Service_Delete = {
  __typename?: 'EventsDocAccessFields_service_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Service_Read = {
  __typename?: 'EventsDocAccessFields_service_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Service_Update = {
  __typename?: 'EventsDocAccessFields_service_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Start = {
  __typename?: 'EventsDocAccessFields_start';
  create?: Maybe<EventsDocAccessFields_Start_Create>;
  delete?: Maybe<EventsDocAccessFields_Start_Delete>;
  read?: Maybe<EventsDocAccessFields_Start_Read>;
  update?: Maybe<EventsDocAccessFields_Start_Update>;
};

export type EventsDocAccessFields_Start_Create = {
  __typename?: 'EventsDocAccessFields_start_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Start_Delete = {
  __typename?: 'EventsDocAccessFields_start_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Start_Read = {
  __typename?: 'EventsDocAccessFields_start_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Start_Update = {
  __typename?: 'EventsDocAccessFields_start_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Tenant = {
  __typename?: 'EventsDocAccessFields_tenant';
  create?: Maybe<EventsDocAccessFields_Tenant_Create>;
  delete?: Maybe<EventsDocAccessFields_Tenant_Delete>;
  read?: Maybe<EventsDocAccessFields_Tenant_Read>;
  update?: Maybe<EventsDocAccessFields_Tenant_Update>;
};

export type EventsDocAccessFields_Tenant_Create = {
  __typename?: 'EventsDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Tenant_Delete = {
  __typename?: 'EventsDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Tenant_Read = {
  __typename?: 'EventsDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Tenant_Update = {
  __typename?: 'EventsDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Title = {
  __typename?: 'EventsDocAccessFields_title';
  create?: Maybe<EventsDocAccessFields_Title_Create>;
  delete?: Maybe<EventsDocAccessFields_Title_Delete>;
  read?: Maybe<EventsDocAccessFields_Title_Read>;
  update?: Maybe<EventsDocAccessFields_Title_Update>;
};

export type EventsDocAccessFields_Title_Create = {
  __typename?: 'EventsDocAccessFields_title_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Title_Delete = {
  __typename?: 'EventsDocAccessFields_title_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Title_Read = {
  __typename?: 'EventsDocAccessFields_title_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_Title_Update = {
  __typename?: 'EventsDocAccessFields_title_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_TotalTickets = {
  __typename?: 'EventsDocAccessFields_totalTickets';
  create?: Maybe<EventsDocAccessFields_TotalTickets_Create>;
  delete?: Maybe<EventsDocAccessFields_TotalTickets_Delete>;
  read?: Maybe<EventsDocAccessFields_TotalTickets_Read>;
  update?: Maybe<EventsDocAccessFields_TotalTickets_Update>;
};

export type EventsDocAccessFields_TotalTickets_Create = {
  __typename?: 'EventsDocAccessFields_totalTickets_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_TotalTickets_Delete = {
  __typename?: 'EventsDocAccessFields_totalTickets_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_TotalTickets_Read = {
  __typename?: 'EventsDocAccessFields_totalTickets_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_TotalTickets_Update = {
  __typename?: 'EventsDocAccessFields_totalTickets_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_UpdatedAt = {
  __typename?: 'EventsDocAccessFields_updatedAt';
  create?: Maybe<EventsDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<EventsDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<EventsDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<EventsDocAccessFields_UpdatedAt_Update>;
};

export type EventsDocAccessFields_UpdatedAt_Create = {
  __typename?: 'EventsDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'EventsDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_UpdatedAt_Read = {
  __typename?: 'EventsDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsDocAccessFields_UpdatedAt_Update = {
  __typename?: 'EventsDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields = {
  __typename?: 'EventsFields';
  createdAt?: Maybe<EventsFields_CreatedAt>;
  end?: Maybe<EventsFields_End>;
  eventStatus?: Maybe<EventsFields_EventStatus>;
  location?: Maybe<EventsFields_Location>;
  service?: Maybe<EventsFields_Service>;
  start?: Maybe<EventsFields_Start>;
  tenant?: Maybe<EventsFields_Tenant>;
  title?: Maybe<EventsFields_Title>;
  totalTickets?: Maybe<EventsFields_TotalTickets>;
  updatedAt?: Maybe<EventsFields_UpdatedAt>;
};

export type EventsFields_CreatedAt = {
  __typename?: 'EventsFields_createdAt';
  create?: Maybe<EventsFields_CreatedAt_Create>;
  delete?: Maybe<EventsFields_CreatedAt_Delete>;
  read?: Maybe<EventsFields_CreatedAt_Read>;
  update?: Maybe<EventsFields_CreatedAt_Update>;
};

export type EventsFields_CreatedAt_Create = {
  __typename?: 'EventsFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_CreatedAt_Delete = {
  __typename?: 'EventsFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_CreatedAt_Read = {
  __typename?: 'EventsFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_CreatedAt_Update = {
  __typename?: 'EventsFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_End = {
  __typename?: 'EventsFields_end';
  create?: Maybe<EventsFields_End_Create>;
  delete?: Maybe<EventsFields_End_Delete>;
  read?: Maybe<EventsFields_End_Read>;
  update?: Maybe<EventsFields_End_Update>;
};

export type EventsFields_End_Create = {
  __typename?: 'EventsFields_end_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_End_Delete = {
  __typename?: 'EventsFields_end_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_End_Read = {
  __typename?: 'EventsFields_end_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_End_Update = {
  __typename?: 'EventsFields_end_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_EventStatus = {
  __typename?: 'EventsFields_eventStatus';
  create?: Maybe<EventsFields_EventStatus_Create>;
  delete?: Maybe<EventsFields_EventStatus_Delete>;
  read?: Maybe<EventsFields_EventStatus_Read>;
  update?: Maybe<EventsFields_EventStatus_Update>;
};

export type EventsFields_EventStatus_Create = {
  __typename?: 'EventsFields_eventStatus_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_EventStatus_Delete = {
  __typename?: 'EventsFields_eventStatus_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_EventStatus_Read = {
  __typename?: 'EventsFields_eventStatus_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_EventStatus_Update = {
  __typename?: 'EventsFields_eventStatus_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Location = {
  __typename?: 'EventsFields_location';
  create?: Maybe<EventsFields_Location_Create>;
  delete?: Maybe<EventsFields_Location_Delete>;
  read?: Maybe<EventsFields_Location_Read>;
  update?: Maybe<EventsFields_Location_Update>;
};

export type EventsFields_Location_Create = {
  __typename?: 'EventsFields_location_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Location_Delete = {
  __typename?: 'EventsFields_location_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Location_Read = {
  __typename?: 'EventsFields_location_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Location_Update = {
  __typename?: 'EventsFields_location_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Service = {
  __typename?: 'EventsFields_service';
  create?: Maybe<EventsFields_Service_Create>;
  delete?: Maybe<EventsFields_Service_Delete>;
  read?: Maybe<EventsFields_Service_Read>;
  update?: Maybe<EventsFields_Service_Update>;
};

export type EventsFields_Service_Create = {
  __typename?: 'EventsFields_service_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Service_Delete = {
  __typename?: 'EventsFields_service_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Service_Read = {
  __typename?: 'EventsFields_service_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Service_Update = {
  __typename?: 'EventsFields_service_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Start = {
  __typename?: 'EventsFields_start';
  create?: Maybe<EventsFields_Start_Create>;
  delete?: Maybe<EventsFields_Start_Delete>;
  read?: Maybe<EventsFields_Start_Read>;
  update?: Maybe<EventsFields_Start_Update>;
};

export type EventsFields_Start_Create = {
  __typename?: 'EventsFields_start_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Start_Delete = {
  __typename?: 'EventsFields_start_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Start_Read = {
  __typename?: 'EventsFields_start_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Start_Update = {
  __typename?: 'EventsFields_start_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Tenant = {
  __typename?: 'EventsFields_tenant';
  create?: Maybe<EventsFields_Tenant_Create>;
  delete?: Maybe<EventsFields_Tenant_Delete>;
  read?: Maybe<EventsFields_Tenant_Read>;
  update?: Maybe<EventsFields_Tenant_Update>;
};

export type EventsFields_Tenant_Create = {
  __typename?: 'EventsFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Tenant_Delete = {
  __typename?: 'EventsFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Tenant_Read = {
  __typename?: 'EventsFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Tenant_Update = {
  __typename?: 'EventsFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Title = {
  __typename?: 'EventsFields_title';
  create?: Maybe<EventsFields_Title_Create>;
  delete?: Maybe<EventsFields_Title_Delete>;
  read?: Maybe<EventsFields_Title_Read>;
  update?: Maybe<EventsFields_Title_Update>;
};

export type EventsFields_Title_Create = {
  __typename?: 'EventsFields_title_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Title_Delete = {
  __typename?: 'EventsFields_title_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Title_Read = {
  __typename?: 'EventsFields_title_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_Title_Update = {
  __typename?: 'EventsFields_title_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_TotalTickets = {
  __typename?: 'EventsFields_totalTickets';
  create?: Maybe<EventsFields_TotalTickets_Create>;
  delete?: Maybe<EventsFields_TotalTickets_Delete>;
  read?: Maybe<EventsFields_TotalTickets_Read>;
  update?: Maybe<EventsFields_TotalTickets_Update>;
};

export type EventsFields_TotalTickets_Create = {
  __typename?: 'EventsFields_totalTickets_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_TotalTickets_Delete = {
  __typename?: 'EventsFields_totalTickets_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_TotalTickets_Read = {
  __typename?: 'EventsFields_totalTickets_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_TotalTickets_Update = {
  __typename?: 'EventsFields_totalTickets_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_UpdatedAt = {
  __typename?: 'EventsFields_updatedAt';
  create?: Maybe<EventsFields_UpdatedAt_Create>;
  delete?: Maybe<EventsFields_UpdatedAt_Delete>;
  read?: Maybe<EventsFields_UpdatedAt_Read>;
  update?: Maybe<EventsFields_UpdatedAt_Update>;
};

export type EventsFields_UpdatedAt_Create = {
  __typename?: 'EventsFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_UpdatedAt_Delete = {
  __typename?: 'EventsFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_UpdatedAt_Read = {
  __typename?: 'EventsFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type EventsFields_UpdatedAt_Update = {
  __typename?: 'EventsFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type EventsReadAccess = {
  __typename?: 'EventsReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type EventsReadDocAccess = {
  __typename?: 'EventsReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type EventsUpdateAccess = {
  __typename?: 'EventsUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type EventsUpdateDocAccess = {
  __typename?: 'EventsUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type Extra = {
  __typename?: 'Extra';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  displayName_en?: Maybe<Scalars['String']['output']>;
  displayName_original?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Media>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  shortDescription?: Maybe<Scalars['String']['output']>;
  shortDescription_en?: Maybe<Scalars['String']['output']>;
  shortDescription_original?: Maybe<Scalars['String']['output']>;
  stripeID?: Maybe<Scalars['String']['output']>;
  stripePriceID?: Maybe<Scalars['String']['output']>;
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type ExtraImageArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
  where?: InputMaybe<Extra_Image_Where>;
};


export type ExtraTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type Extra_Image_Alt_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Extra_Image_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Extra_Image_Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Extra_Image_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_Sizes__Card__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_Sizes__Card__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Extra_Image_Sizes__Card__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Extra_Image_Sizes__Card__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_Sizes__Card__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_Sizes__Card__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Extra_Image_Sizes__Tablet__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_Sizes__Tablet__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Extra_Image_Sizes__Tablet__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Extra_Image_Sizes__Tablet__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_Sizes__Tablet__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_Sizes__Tablet__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Extra_Image_Sizes__Thumbnail__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_Sizes__Thumbnail__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Extra_Image_Sizes__Thumbnail__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Extra_Image_Sizes__Thumbnail__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_Sizes__Thumbnail__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_Sizes__Thumbnail__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Extra_Image_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Extra_Image_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Extra_Image_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_Where = {
  AND?: InputMaybe<Array<InputMaybe<Extra_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Extra_Image_Where_Or>>>;
  alt?: InputMaybe<Extra_Image_Alt_Operator>;
  createdAt?: InputMaybe<Extra_Image_CreatedAt_Operator>;
  filename?: InputMaybe<Extra_Image_Filename_Operator>;
  filesize?: InputMaybe<Extra_Image_Filesize_Operator>;
  height?: InputMaybe<Extra_Image_Height_Operator>;
  id?: InputMaybe<Extra_Image_Id_Operator>;
  mimeType?: InputMaybe<Extra_Image_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<Extra_Image_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<Extra_Image_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<Extra_Image_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<Extra_Image_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<Extra_Image_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<Extra_Image_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<Extra_Image_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<Extra_Image_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<Extra_Image_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<Extra_Image_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<Extra_Image_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<Extra_Image_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<Extra_Image_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<Extra_Image_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<Extra_Image_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<Extra_Image_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<Extra_Image_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<Extra_Image_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<Extra_Image_Tenant_Operator>;
  updatedAt?: InputMaybe<Extra_Image_UpdatedAt_Operator>;
  url?: InputMaybe<Extra_Image_Url_Operator>;
  width?: InputMaybe<Extra_Image_Width_Operator>;
};

export type Extra_Image_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Extra_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Extra_Image_Where_Or>>>;
  alt?: InputMaybe<Extra_Image_Alt_Operator>;
  createdAt?: InputMaybe<Extra_Image_CreatedAt_Operator>;
  filename?: InputMaybe<Extra_Image_Filename_Operator>;
  filesize?: InputMaybe<Extra_Image_Filesize_Operator>;
  height?: InputMaybe<Extra_Image_Height_Operator>;
  id?: InputMaybe<Extra_Image_Id_Operator>;
  mimeType?: InputMaybe<Extra_Image_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<Extra_Image_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<Extra_Image_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<Extra_Image_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<Extra_Image_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<Extra_Image_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<Extra_Image_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<Extra_Image_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<Extra_Image_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<Extra_Image_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<Extra_Image_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<Extra_Image_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<Extra_Image_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<Extra_Image_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<Extra_Image_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<Extra_Image_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<Extra_Image_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<Extra_Image_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<Extra_Image_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<Extra_Image_Tenant_Operator>;
  updatedAt?: InputMaybe<Extra_Image_UpdatedAt_Operator>;
  url?: InputMaybe<Extra_Image_Url_Operator>;
  width?: InputMaybe<Extra_Image_Width_Operator>;
};

export type Extra_Image_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Extra_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Extra_Image_Where_Or>>>;
  alt?: InputMaybe<Extra_Image_Alt_Operator>;
  createdAt?: InputMaybe<Extra_Image_CreatedAt_Operator>;
  filename?: InputMaybe<Extra_Image_Filename_Operator>;
  filesize?: InputMaybe<Extra_Image_Filesize_Operator>;
  height?: InputMaybe<Extra_Image_Height_Operator>;
  id?: InputMaybe<Extra_Image_Id_Operator>;
  mimeType?: InputMaybe<Extra_Image_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<Extra_Image_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<Extra_Image_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<Extra_Image_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<Extra_Image_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<Extra_Image_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<Extra_Image_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<Extra_Image_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<Extra_Image_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<Extra_Image_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<Extra_Image_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<Extra_Image_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<Extra_Image_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<Extra_Image_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<Extra_Image_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<Extra_Image_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<Extra_Image_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<Extra_Image_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<Extra_Image_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<Extra_Image_Tenant_Operator>;
  updatedAt?: InputMaybe<Extra_Image_UpdatedAt_Operator>;
  url?: InputMaybe<Extra_Image_Url_Operator>;
  width?: InputMaybe<Extra_Image_Width_Operator>;
};

export type Extra_Image_Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Extra_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Extra_DisplayName_En_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_DisplayName_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_DisplayName_Original_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Image_Operator = {
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type Extra_Name_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Price_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Extra_ShortDescription_En_Operator = {
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type Extra_ShortDescription_Operator = {
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type Extra_ShortDescription_Original_Operator = {
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type Extra_StripeId_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_StripePriceId_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Extra_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Extra_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Extra_Where = {
  AND?: InputMaybe<Array<InputMaybe<Extra_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Extra_Where_Or>>>;
  createdAt?: InputMaybe<Extra_CreatedAt_Operator>;
  displayName?: InputMaybe<Extra_DisplayName_Operator>;
  displayName_en?: InputMaybe<Extra_DisplayName_En_Operator>;
  displayName_original?: InputMaybe<Extra_DisplayName_Original_Operator>;
  id?: InputMaybe<Extra_Id_Operator>;
  image?: InputMaybe<Extra_Image_Operator>;
  name?: InputMaybe<Extra_Name_Operator>;
  price?: InputMaybe<Extra_Price_Operator>;
  shortDescription?: InputMaybe<Extra_ShortDescription_Operator>;
  shortDescription_en?: InputMaybe<Extra_ShortDescription_En_Operator>;
  shortDescription_original?: InputMaybe<Extra_ShortDescription_Original_Operator>;
  stripeID?: InputMaybe<Extra_StripeId_Operator>;
  stripePriceID?: InputMaybe<Extra_StripePriceId_Operator>;
  tenant?: InputMaybe<Extra_Tenant_Operator>;
  updatedAt?: InputMaybe<Extra_UpdatedAt_Operator>;
};

export type Extra_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Extra_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Extra_Where_Or>>>;
  createdAt?: InputMaybe<Extra_CreatedAt_Operator>;
  displayName?: InputMaybe<Extra_DisplayName_Operator>;
  displayName_en?: InputMaybe<Extra_DisplayName_En_Operator>;
  displayName_original?: InputMaybe<Extra_DisplayName_Original_Operator>;
  id?: InputMaybe<Extra_Id_Operator>;
  image?: InputMaybe<Extra_Image_Operator>;
  name?: InputMaybe<Extra_Name_Operator>;
  price?: InputMaybe<Extra_Price_Operator>;
  shortDescription?: InputMaybe<Extra_ShortDescription_Operator>;
  shortDescription_en?: InputMaybe<Extra_ShortDescription_En_Operator>;
  shortDescription_original?: InputMaybe<Extra_ShortDescription_Original_Operator>;
  stripeID?: InputMaybe<Extra_StripeId_Operator>;
  stripePriceID?: InputMaybe<Extra_StripePriceId_Operator>;
  tenant?: InputMaybe<Extra_Tenant_Operator>;
  updatedAt?: InputMaybe<Extra_UpdatedAt_Operator>;
};

export type Extra_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Extra_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Extra_Where_Or>>>;
  createdAt?: InputMaybe<Extra_CreatedAt_Operator>;
  displayName?: InputMaybe<Extra_DisplayName_Operator>;
  displayName_en?: InputMaybe<Extra_DisplayName_En_Operator>;
  displayName_original?: InputMaybe<Extra_DisplayName_Original_Operator>;
  id?: InputMaybe<Extra_Id_Operator>;
  image?: InputMaybe<Extra_Image_Operator>;
  name?: InputMaybe<Extra_Name_Operator>;
  price?: InputMaybe<Extra_Price_Operator>;
  shortDescription?: InputMaybe<Extra_ShortDescription_Operator>;
  shortDescription_en?: InputMaybe<Extra_ShortDescription_En_Operator>;
  shortDescription_original?: InputMaybe<Extra_ShortDescription_Original_Operator>;
  stripeID?: InputMaybe<Extra_StripeId_Operator>;
  stripePriceID?: InputMaybe<Extra_StripePriceId_Operator>;
  tenant?: InputMaybe<Extra_Tenant_Operator>;
  updatedAt?: InputMaybe<Extra_UpdatedAt_Operator>;
};

export type Extras = {
  __typename?: 'Extras';
  docs?: Maybe<Array<Maybe<Extra>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type ExtrasCreateAccess = {
  __typename?: 'ExtrasCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ExtrasCreateDocAccess = {
  __typename?: 'ExtrasCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ExtrasDeleteAccess = {
  __typename?: 'ExtrasDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ExtrasDeleteDocAccess = {
  __typename?: 'ExtrasDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ExtrasDocAccessFields = {
  __typename?: 'ExtrasDocAccessFields';
  createdAt?: Maybe<ExtrasDocAccessFields_CreatedAt>;
  displayName?: Maybe<ExtrasDocAccessFields_DisplayName>;
  displayName_en?: Maybe<ExtrasDocAccessFields_DisplayName_En>;
  displayName_original?: Maybe<ExtrasDocAccessFields_DisplayName_Original>;
  image?: Maybe<ExtrasDocAccessFields_Image>;
  name?: Maybe<ExtrasDocAccessFields_Name>;
  price?: Maybe<ExtrasDocAccessFields_Price>;
  shortDescription?: Maybe<ExtrasDocAccessFields_ShortDescription>;
  shortDescription_en?: Maybe<ExtrasDocAccessFields_ShortDescription_En>;
  shortDescription_original?: Maybe<ExtrasDocAccessFields_ShortDescription_Original>;
  stripeID?: Maybe<ExtrasDocAccessFields_StripeId>;
  stripePriceID?: Maybe<ExtrasDocAccessFields_StripePriceId>;
  tenant?: Maybe<ExtrasDocAccessFields_Tenant>;
  updatedAt?: Maybe<ExtrasDocAccessFields_UpdatedAt>;
};

export type ExtrasDocAccessFields_CreatedAt = {
  __typename?: 'ExtrasDocAccessFields_createdAt';
  create?: Maybe<ExtrasDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<ExtrasDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<ExtrasDocAccessFields_CreatedAt_Read>;
  update?: Maybe<ExtrasDocAccessFields_CreatedAt_Update>;
};

export type ExtrasDocAccessFields_CreatedAt_Create = {
  __typename?: 'ExtrasDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_CreatedAt_Delete = {
  __typename?: 'ExtrasDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_CreatedAt_Read = {
  __typename?: 'ExtrasDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_CreatedAt_Update = {
  __typename?: 'ExtrasDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_DisplayName = {
  __typename?: 'ExtrasDocAccessFields_displayName';
  create?: Maybe<ExtrasDocAccessFields_DisplayName_Create>;
  delete?: Maybe<ExtrasDocAccessFields_DisplayName_Delete>;
  read?: Maybe<ExtrasDocAccessFields_DisplayName_Read>;
  update?: Maybe<ExtrasDocAccessFields_DisplayName_Update>;
};

export type ExtrasDocAccessFields_DisplayName_Create = {
  __typename?: 'ExtrasDocAccessFields_displayName_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_DisplayName_Delete = {
  __typename?: 'ExtrasDocAccessFields_displayName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_DisplayName_Read = {
  __typename?: 'ExtrasDocAccessFields_displayName_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_DisplayName_Update = {
  __typename?: 'ExtrasDocAccessFields_displayName_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_DisplayName_En = {
  __typename?: 'ExtrasDocAccessFields_displayName_en';
  create?: Maybe<ExtrasDocAccessFields_DisplayName_En_Create>;
  delete?: Maybe<ExtrasDocAccessFields_DisplayName_En_Delete>;
  read?: Maybe<ExtrasDocAccessFields_DisplayName_En_Read>;
  update?: Maybe<ExtrasDocAccessFields_DisplayName_En_Update>;
};

export type ExtrasDocAccessFields_DisplayName_En_Create = {
  __typename?: 'ExtrasDocAccessFields_displayName_en_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_DisplayName_En_Delete = {
  __typename?: 'ExtrasDocAccessFields_displayName_en_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_DisplayName_En_Read = {
  __typename?: 'ExtrasDocAccessFields_displayName_en_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_DisplayName_En_Update = {
  __typename?: 'ExtrasDocAccessFields_displayName_en_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_DisplayName_Original = {
  __typename?: 'ExtrasDocAccessFields_displayName_original';
  create?: Maybe<ExtrasDocAccessFields_DisplayName_Original_Create>;
  delete?: Maybe<ExtrasDocAccessFields_DisplayName_Original_Delete>;
  read?: Maybe<ExtrasDocAccessFields_DisplayName_Original_Read>;
  update?: Maybe<ExtrasDocAccessFields_DisplayName_Original_Update>;
};

export type ExtrasDocAccessFields_DisplayName_Original_Create = {
  __typename?: 'ExtrasDocAccessFields_displayName_original_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_DisplayName_Original_Delete = {
  __typename?: 'ExtrasDocAccessFields_displayName_original_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_DisplayName_Original_Read = {
  __typename?: 'ExtrasDocAccessFields_displayName_original_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_DisplayName_Original_Update = {
  __typename?: 'ExtrasDocAccessFields_displayName_original_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Image = {
  __typename?: 'ExtrasDocAccessFields_image';
  create?: Maybe<ExtrasDocAccessFields_Image_Create>;
  delete?: Maybe<ExtrasDocAccessFields_Image_Delete>;
  read?: Maybe<ExtrasDocAccessFields_Image_Read>;
  update?: Maybe<ExtrasDocAccessFields_Image_Update>;
};

export type ExtrasDocAccessFields_Image_Create = {
  __typename?: 'ExtrasDocAccessFields_image_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Image_Delete = {
  __typename?: 'ExtrasDocAccessFields_image_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Image_Read = {
  __typename?: 'ExtrasDocAccessFields_image_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Image_Update = {
  __typename?: 'ExtrasDocAccessFields_image_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Name = {
  __typename?: 'ExtrasDocAccessFields_name';
  create?: Maybe<ExtrasDocAccessFields_Name_Create>;
  delete?: Maybe<ExtrasDocAccessFields_Name_Delete>;
  read?: Maybe<ExtrasDocAccessFields_Name_Read>;
  update?: Maybe<ExtrasDocAccessFields_Name_Update>;
};

export type ExtrasDocAccessFields_Name_Create = {
  __typename?: 'ExtrasDocAccessFields_name_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Name_Delete = {
  __typename?: 'ExtrasDocAccessFields_name_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Name_Read = {
  __typename?: 'ExtrasDocAccessFields_name_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Name_Update = {
  __typename?: 'ExtrasDocAccessFields_name_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Price = {
  __typename?: 'ExtrasDocAccessFields_price';
  create?: Maybe<ExtrasDocAccessFields_Price_Create>;
  delete?: Maybe<ExtrasDocAccessFields_Price_Delete>;
  read?: Maybe<ExtrasDocAccessFields_Price_Read>;
  update?: Maybe<ExtrasDocAccessFields_Price_Update>;
};

export type ExtrasDocAccessFields_Price_Create = {
  __typename?: 'ExtrasDocAccessFields_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Price_Delete = {
  __typename?: 'ExtrasDocAccessFields_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Price_Read = {
  __typename?: 'ExtrasDocAccessFields_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Price_Update = {
  __typename?: 'ExtrasDocAccessFields_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_ShortDescription = {
  __typename?: 'ExtrasDocAccessFields_shortDescription';
  create?: Maybe<ExtrasDocAccessFields_ShortDescription_Create>;
  delete?: Maybe<ExtrasDocAccessFields_ShortDescription_Delete>;
  read?: Maybe<ExtrasDocAccessFields_ShortDescription_Read>;
  update?: Maybe<ExtrasDocAccessFields_ShortDescription_Update>;
};

export type ExtrasDocAccessFields_ShortDescription_Create = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_ShortDescription_Delete = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_ShortDescription_Read = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_ShortDescription_Update = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_ShortDescription_En = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_en';
  create?: Maybe<ExtrasDocAccessFields_ShortDescription_En_Create>;
  delete?: Maybe<ExtrasDocAccessFields_ShortDescription_En_Delete>;
  read?: Maybe<ExtrasDocAccessFields_ShortDescription_En_Read>;
  update?: Maybe<ExtrasDocAccessFields_ShortDescription_En_Update>;
};

export type ExtrasDocAccessFields_ShortDescription_En_Create = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_en_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_ShortDescription_En_Delete = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_en_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_ShortDescription_En_Read = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_en_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_ShortDescription_En_Update = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_en_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_ShortDescription_Original = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_original';
  create?: Maybe<ExtrasDocAccessFields_ShortDescription_Original_Create>;
  delete?: Maybe<ExtrasDocAccessFields_ShortDescription_Original_Delete>;
  read?: Maybe<ExtrasDocAccessFields_ShortDescription_Original_Read>;
  update?: Maybe<ExtrasDocAccessFields_ShortDescription_Original_Update>;
};

export type ExtrasDocAccessFields_ShortDescription_Original_Create = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_original_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_ShortDescription_Original_Delete = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_original_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_ShortDescription_Original_Read = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_original_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_ShortDescription_Original_Update = {
  __typename?: 'ExtrasDocAccessFields_shortDescription_original_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_StripeId = {
  __typename?: 'ExtrasDocAccessFields_stripeID';
  create?: Maybe<ExtrasDocAccessFields_StripeId_Create>;
  delete?: Maybe<ExtrasDocAccessFields_StripeId_Delete>;
  read?: Maybe<ExtrasDocAccessFields_StripeId_Read>;
  update?: Maybe<ExtrasDocAccessFields_StripeId_Update>;
};

export type ExtrasDocAccessFields_StripeId_Create = {
  __typename?: 'ExtrasDocAccessFields_stripeID_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_StripeId_Delete = {
  __typename?: 'ExtrasDocAccessFields_stripeID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_StripeId_Read = {
  __typename?: 'ExtrasDocAccessFields_stripeID_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_StripeId_Update = {
  __typename?: 'ExtrasDocAccessFields_stripeID_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_StripePriceId = {
  __typename?: 'ExtrasDocAccessFields_stripePriceID';
  create?: Maybe<ExtrasDocAccessFields_StripePriceId_Create>;
  delete?: Maybe<ExtrasDocAccessFields_StripePriceId_Delete>;
  read?: Maybe<ExtrasDocAccessFields_StripePriceId_Read>;
  update?: Maybe<ExtrasDocAccessFields_StripePriceId_Update>;
};

export type ExtrasDocAccessFields_StripePriceId_Create = {
  __typename?: 'ExtrasDocAccessFields_stripePriceID_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_StripePriceId_Delete = {
  __typename?: 'ExtrasDocAccessFields_stripePriceID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_StripePriceId_Read = {
  __typename?: 'ExtrasDocAccessFields_stripePriceID_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_StripePriceId_Update = {
  __typename?: 'ExtrasDocAccessFields_stripePriceID_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Tenant = {
  __typename?: 'ExtrasDocAccessFields_tenant';
  create?: Maybe<ExtrasDocAccessFields_Tenant_Create>;
  delete?: Maybe<ExtrasDocAccessFields_Tenant_Delete>;
  read?: Maybe<ExtrasDocAccessFields_Tenant_Read>;
  update?: Maybe<ExtrasDocAccessFields_Tenant_Update>;
};

export type ExtrasDocAccessFields_Tenant_Create = {
  __typename?: 'ExtrasDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Tenant_Delete = {
  __typename?: 'ExtrasDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Tenant_Read = {
  __typename?: 'ExtrasDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_Tenant_Update = {
  __typename?: 'ExtrasDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_UpdatedAt = {
  __typename?: 'ExtrasDocAccessFields_updatedAt';
  create?: Maybe<ExtrasDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<ExtrasDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<ExtrasDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<ExtrasDocAccessFields_UpdatedAt_Update>;
};

export type ExtrasDocAccessFields_UpdatedAt_Create = {
  __typename?: 'ExtrasDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'ExtrasDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_UpdatedAt_Read = {
  __typename?: 'ExtrasDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasDocAccessFields_UpdatedAt_Update = {
  __typename?: 'ExtrasDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields = {
  __typename?: 'ExtrasFields';
  createdAt?: Maybe<ExtrasFields_CreatedAt>;
  displayName?: Maybe<ExtrasFields_DisplayName>;
  displayName_en?: Maybe<ExtrasFields_DisplayName_En>;
  displayName_original?: Maybe<ExtrasFields_DisplayName_Original>;
  image?: Maybe<ExtrasFields_Image>;
  name?: Maybe<ExtrasFields_Name>;
  price?: Maybe<ExtrasFields_Price>;
  shortDescription?: Maybe<ExtrasFields_ShortDescription>;
  shortDescription_en?: Maybe<ExtrasFields_ShortDescription_En>;
  shortDescription_original?: Maybe<ExtrasFields_ShortDescription_Original>;
  stripeID?: Maybe<ExtrasFields_StripeId>;
  stripePriceID?: Maybe<ExtrasFields_StripePriceId>;
  tenant?: Maybe<ExtrasFields_Tenant>;
  updatedAt?: Maybe<ExtrasFields_UpdatedAt>;
};

export type ExtrasFields_CreatedAt = {
  __typename?: 'ExtrasFields_createdAt';
  create?: Maybe<ExtrasFields_CreatedAt_Create>;
  delete?: Maybe<ExtrasFields_CreatedAt_Delete>;
  read?: Maybe<ExtrasFields_CreatedAt_Read>;
  update?: Maybe<ExtrasFields_CreatedAt_Update>;
};

export type ExtrasFields_CreatedAt_Create = {
  __typename?: 'ExtrasFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_CreatedAt_Delete = {
  __typename?: 'ExtrasFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_CreatedAt_Read = {
  __typename?: 'ExtrasFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_CreatedAt_Update = {
  __typename?: 'ExtrasFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_DisplayName = {
  __typename?: 'ExtrasFields_displayName';
  create?: Maybe<ExtrasFields_DisplayName_Create>;
  delete?: Maybe<ExtrasFields_DisplayName_Delete>;
  read?: Maybe<ExtrasFields_DisplayName_Read>;
  update?: Maybe<ExtrasFields_DisplayName_Update>;
};

export type ExtrasFields_DisplayName_Create = {
  __typename?: 'ExtrasFields_displayName_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_DisplayName_Delete = {
  __typename?: 'ExtrasFields_displayName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_DisplayName_Read = {
  __typename?: 'ExtrasFields_displayName_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_DisplayName_Update = {
  __typename?: 'ExtrasFields_displayName_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_DisplayName_En = {
  __typename?: 'ExtrasFields_displayName_en';
  create?: Maybe<ExtrasFields_DisplayName_En_Create>;
  delete?: Maybe<ExtrasFields_DisplayName_En_Delete>;
  read?: Maybe<ExtrasFields_DisplayName_En_Read>;
  update?: Maybe<ExtrasFields_DisplayName_En_Update>;
};

export type ExtrasFields_DisplayName_En_Create = {
  __typename?: 'ExtrasFields_displayName_en_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_DisplayName_En_Delete = {
  __typename?: 'ExtrasFields_displayName_en_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_DisplayName_En_Read = {
  __typename?: 'ExtrasFields_displayName_en_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_DisplayName_En_Update = {
  __typename?: 'ExtrasFields_displayName_en_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_DisplayName_Original = {
  __typename?: 'ExtrasFields_displayName_original';
  create?: Maybe<ExtrasFields_DisplayName_Original_Create>;
  delete?: Maybe<ExtrasFields_DisplayName_Original_Delete>;
  read?: Maybe<ExtrasFields_DisplayName_Original_Read>;
  update?: Maybe<ExtrasFields_DisplayName_Original_Update>;
};

export type ExtrasFields_DisplayName_Original_Create = {
  __typename?: 'ExtrasFields_displayName_original_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_DisplayName_Original_Delete = {
  __typename?: 'ExtrasFields_displayName_original_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_DisplayName_Original_Read = {
  __typename?: 'ExtrasFields_displayName_original_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_DisplayName_Original_Update = {
  __typename?: 'ExtrasFields_displayName_original_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Image = {
  __typename?: 'ExtrasFields_image';
  create?: Maybe<ExtrasFields_Image_Create>;
  delete?: Maybe<ExtrasFields_Image_Delete>;
  read?: Maybe<ExtrasFields_Image_Read>;
  update?: Maybe<ExtrasFields_Image_Update>;
};

export type ExtrasFields_Image_Create = {
  __typename?: 'ExtrasFields_image_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Image_Delete = {
  __typename?: 'ExtrasFields_image_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Image_Read = {
  __typename?: 'ExtrasFields_image_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Image_Update = {
  __typename?: 'ExtrasFields_image_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Name = {
  __typename?: 'ExtrasFields_name';
  create?: Maybe<ExtrasFields_Name_Create>;
  delete?: Maybe<ExtrasFields_Name_Delete>;
  read?: Maybe<ExtrasFields_Name_Read>;
  update?: Maybe<ExtrasFields_Name_Update>;
};

export type ExtrasFields_Name_Create = {
  __typename?: 'ExtrasFields_name_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Name_Delete = {
  __typename?: 'ExtrasFields_name_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Name_Read = {
  __typename?: 'ExtrasFields_name_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Name_Update = {
  __typename?: 'ExtrasFields_name_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Price = {
  __typename?: 'ExtrasFields_price';
  create?: Maybe<ExtrasFields_Price_Create>;
  delete?: Maybe<ExtrasFields_Price_Delete>;
  read?: Maybe<ExtrasFields_Price_Read>;
  update?: Maybe<ExtrasFields_Price_Update>;
};

export type ExtrasFields_Price_Create = {
  __typename?: 'ExtrasFields_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Price_Delete = {
  __typename?: 'ExtrasFields_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Price_Read = {
  __typename?: 'ExtrasFields_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Price_Update = {
  __typename?: 'ExtrasFields_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_ShortDescription = {
  __typename?: 'ExtrasFields_shortDescription';
  create?: Maybe<ExtrasFields_ShortDescription_Create>;
  delete?: Maybe<ExtrasFields_ShortDescription_Delete>;
  read?: Maybe<ExtrasFields_ShortDescription_Read>;
  update?: Maybe<ExtrasFields_ShortDescription_Update>;
};

export type ExtrasFields_ShortDescription_Create = {
  __typename?: 'ExtrasFields_shortDescription_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_ShortDescription_Delete = {
  __typename?: 'ExtrasFields_shortDescription_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_ShortDescription_Read = {
  __typename?: 'ExtrasFields_shortDescription_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_ShortDescription_Update = {
  __typename?: 'ExtrasFields_shortDescription_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_ShortDescription_En = {
  __typename?: 'ExtrasFields_shortDescription_en';
  create?: Maybe<ExtrasFields_ShortDescription_En_Create>;
  delete?: Maybe<ExtrasFields_ShortDescription_En_Delete>;
  read?: Maybe<ExtrasFields_ShortDescription_En_Read>;
  update?: Maybe<ExtrasFields_ShortDescription_En_Update>;
};

export type ExtrasFields_ShortDescription_En_Create = {
  __typename?: 'ExtrasFields_shortDescription_en_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_ShortDescription_En_Delete = {
  __typename?: 'ExtrasFields_shortDescription_en_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_ShortDescription_En_Read = {
  __typename?: 'ExtrasFields_shortDescription_en_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_ShortDescription_En_Update = {
  __typename?: 'ExtrasFields_shortDescription_en_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_ShortDescription_Original = {
  __typename?: 'ExtrasFields_shortDescription_original';
  create?: Maybe<ExtrasFields_ShortDescription_Original_Create>;
  delete?: Maybe<ExtrasFields_ShortDescription_Original_Delete>;
  read?: Maybe<ExtrasFields_ShortDescription_Original_Read>;
  update?: Maybe<ExtrasFields_ShortDescription_Original_Update>;
};

export type ExtrasFields_ShortDescription_Original_Create = {
  __typename?: 'ExtrasFields_shortDescription_original_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_ShortDescription_Original_Delete = {
  __typename?: 'ExtrasFields_shortDescription_original_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_ShortDescription_Original_Read = {
  __typename?: 'ExtrasFields_shortDescription_original_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_ShortDescription_Original_Update = {
  __typename?: 'ExtrasFields_shortDescription_original_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_StripeId = {
  __typename?: 'ExtrasFields_stripeID';
  create?: Maybe<ExtrasFields_StripeId_Create>;
  delete?: Maybe<ExtrasFields_StripeId_Delete>;
  read?: Maybe<ExtrasFields_StripeId_Read>;
  update?: Maybe<ExtrasFields_StripeId_Update>;
};

export type ExtrasFields_StripeId_Create = {
  __typename?: 'ExtrasFields_stripeID_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_StripeId_Delete = {
  __typename?: 'ExtrasFields_stripeID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_StripeId_Read = {
  __typename?: 'ExtrasFields_stripeID_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_StripeId_Update = {
  __typename?: 'ExtrasFields_stripeID_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_StripePriceId = {
  __typename?: 'ExtrasFields_stripePriceID';
  create?: Maybe<ExtrasFields_StripePriceId_Create>;
  delete?: Maybe<ExtrasFields_StripePriceId_Delete>;
  read?: Maybe<ExtrasFields_StripePriceId_Read>;
  update?: Maybe<ExtrasFields_StripePriceId_Update>;
};

export type ExtrasFields_StripePriceId_Create = {
  __typename?: 'ExtrasFields_stripePriceID_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_StripePriceId_Delete = {
  __typename?: 'ExtrasFields_stripePriceID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_StripePriceId_Read = {
  __typename?: 'ExtrasFields_stripePriceID_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_StripePriceId_Update = {
  __typename?: 'ExtrasFields_stripePriceID_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Tenant = {
  __typename?: 'ExtrasFields_tenant';
  create?: Maybe<ExtrasFields_Tenant_Create>;
  delete?: Maybe<ExtrasFields_Tenant_Delete>;
  read?: Maybe<ExtrasFields_Tenant_Read>;
  update?: Maybe<ExtrasFields_Tenant_Update>;
};

export type ExtrasFields_Tenant_Create = {
  __typename?: 'ExtrasFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Tenant_Delete = {
  __typename?: 'ExtrasFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Tenant_Read = {
  __typename?: 'ExtrasFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_Tenant_Update = {
  __typename?: 'ExtrasFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_UpdatedAt = {
  __typename?: 'ExtrasFields_updatedAt';
  create?: Maybe<ExtrasFields_UpdatedAt_Create>;
  delete?: Maybe<ExtrasFields_UpdatedAt_Delete>;
  read?: Maybe<ExtrasFields_UpdatedAt_Read>;
  update?: Maybe<ExtrasFields_UpdatedAt_Update>;
};

export type ExtrasFields_UpdatedAt_Create = {
  __typename?: 'ExtrasFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_UpdatedAt_Delete = {
  __typename?: 'ExtrasFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_UpdatedAt_Read = {
  __typename?: 'ExtrasFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasFields_UpdatedAt_Update = {
  __typename?: 'ExtrasFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ExtrasReadAccess = {
  __typename?: 'ExtrasReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ExtrasReadDocAccess = {
  __typename?: 'ExtrasReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ExtrasUpdateAccess = {
  __typename?: 'ExtrasUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ExtrasUpdateDocAccess = {
  __typename?: 'ExtrasUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export enum FallbackLocaleInputType {
  En = 'en',
  None = 'none',
  Original = 'original'
}

export type Integration = {
  __typename?: 'Integration';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  googleAnalytics?: Maybe<Integration_GoogleAnalytics>;
  googleCal?: Maybe<Integration_GoogleCal>;
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type IntegrationTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type Integration_GoogleAnalytics = {
  __typename?: 'Integration_GoogleAnalytics';
  measurementID?: Maybe<Scalars['String']['output']>;
};

export type Integration_GoogleCal = {
  __typename?: 'Integration_GoogleCal';
  authTokens?: Maybe<Scalars['JSON']['output']>;
  isAuthenticated?: Maybe<Scalars['Boolean']['output']>;
};

export type IntegrationsDocAccessFields = {
  __typename?: 'IntegrationsDocAccessFields';
  createdAt?: Maybe<IntegrationsDocAccessFields_CreatedAt>;
  isAuthenticated?: Maybe<IntegrationsDocAccessFields_IsAuthenticated>;
  measurementID?: Maybe<IntegrationsDocAccessFields_MeasurementId>;
  tenant?: Maybe<IntegrationsDocAccessFields_Tenant>;
  updatedAt?: Maybe<IntegrationsDocAccessFields_UpdatedAt>;
};

export type IntegrationsDocAccessFields_CreatedAt = {
  __typename?: 'IntegrationsDocAccessFields_createdAt';
  create?: Maybe<IntegrationsDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<IntegrationsDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<IntegrationsDocAccessFields_CreatedAt_Read>;
  update?: Maybe<IntegrationsDocAccessFields_CreatedAt_Update>;
};

export type IntegrationsDocAccessFields_CreatedAt_Create = {
  __typename?: 'IntegrationsDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_CreatedAt_Delete = {
  __typename?: 'IntegrationsDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_CreatedAt_Read = {
  __typename?: 'IntegrationsDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_CreatedAt_Update = {
  __typename?: 'IntegrationsDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_IsAuthenticated = {
  __typename?: 'IntegrationsDocAccessFields_isAuthenticated';
  create?: Maybe<IntegrationsDocAccessFields_IsAuthenticated_Create>;
  delete?: Maybe<IntegrationsDocAccessFields_IsAuthenticated_Delete>;
  read?: Maybe<IntegrationsDocAccessFields_IsAuthenticated_Read>;
  update?: Maybe<IntegrationsDocAccessFields_IsAuthenticated_Update>;
};

export type IntegrationsDocAccessFields_IsAuthenticated_Create = {
  __typename?: 'IntegrationsDocAccessFields_isAuthenticated_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_IsAuthenticated_Delete = {
  __typename?: 'IntegrationsDocAccessFields_isAuthenticated_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_IsAuthenticated_Read = {
  __typename?: 'IntegrationsDocAccessFields_isAuthenticated_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_IsAuthenticated_Update = {
  __typename?: 'IntegrationsDocAccessFields_isAuthenticated_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_MeasurementId = {
  __typename?: 'IntegrationsDocAccessFields_measurementID';
  create?: Maybe<IntegrationsDocAccessFields_MeasurementId_Create>;
  delete?: Maybe<IntegrationsDocAccessFields_MeasurementId_Delete>;
  read?: Maybe<IntegrationsDocAccessFields_MeasurementId_Read>;
  update?: Maybe<IntegrationsDocAccessFields_MeasurementId_Update>;
};

export type IntegrationsDocAccessFields_MeasurementId_Create = {
  __typename?: 'IntegrationsDocAccessFields_measurementID_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_MeasurementId_Delete = {
  __typename?: 'IntegrationsDocAccessFields_measurementID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_MeasurementId_Read = {
  __typename?: 'IntegrationsDocAccessFields_measurementID_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_MeasurementId_Update = {
  __typename?: 'IntegrationsDocAccessFields_measurementID_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_Tenant = {
  __typename?: 'IntegrationsDocAccessFields_tenant';
  create?: Maybe<IntegrationsDocAccessFields_Tenant_Create>;
  delete?: Maybe<IntegrationsDocAccessFields_Tenant_Delete>;
  read?: Maybe<IntegrationsDocAccessFields_Tenant_Read>;
  update?: Maybe<IntegrationsDocAccessFields_Tenant_Update>;
};

export type IntegrationsDocAccessFields_Tenant_Create = {
  __typename?: 'IntegrationsDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_Tenant_Delete = {
  __typename?: 'IntegrationsDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_Tenant_Read = {
  __typename?: 'IntegrationsDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_Tenant_Update = {
  __typename?: 'IntegrationsDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_UpdatedAt = {
  __typename?: 'IntegrationsDocAccessFields_updatedAt';
  create?: Maybe<IntegrationsDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<IntegrationsDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<IntegrationsDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<IntegrationsDocAccessFields_UpdatedAt_Update>;
};

export type IntegrationsDocAccessFields_UpdatedAt_Create = {
  __typename?: 'IntegrationsDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'IntegrationsDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_UpdatedAt_Read = {
  __typename?: 'IntegrationsDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsDocAccessFields_UpdatedAt_Update = {
  __typename?: 'IntegrationsDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields = {
  __typename?: 'IntegrationsFields';
  createdAt?: Maybe<IntegrationsFields_CreatedAt>;
  isAuthenticated?: Maybe<IntegrationsFields_IsAuthenticated>;
  measurementID?: Maybe<IntegrationsFields_MeasurementId>;
  tenant?: Maybe<IntegrationsFields_Tenant>;
  updatedAt?: Maybe<IntegrationsFields_UpdatedAt>;
};

export type IntegrationsFields_CreatedAt = {
  __typename?: 'IntegrationsFields_createdAt';
  create?: Maybe<IntegrationsFields_CreatedAt_Create>;
  delete?: Maybe<IntegrationsFields_CreatedAt_Delete>;
  read?: Maybe<IntegrationsFields_CreatedAt_Read>;
  update?: Maybe<IntegrationsFields_CreatedAt_Update>;
};

export type IntegrationsFields_CreatedAt_Create = {
  __typename?: 'IntegrationsFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_CreatedAt_Delete = {
  __typename?: 'IntegrationsFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_CreatedAt_Read = {
  __typename?: 'IntegrationsFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_CreatedAt_Update = {
  __typename?: 'IntegrationsFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_IsAuthenticated = {
  __typename?: 'IntegrationsFields_isAuthenticated';
  create?: Maybe<IntegrationsFields_IsAuthenticated_Create>;
  delete?: Maybe<IntegrationsFields_IsAuthenticated_Delete>;
  read?: Maybe<IntegrationsFields_IsAuthenticated_Read>;
  update?: Maybe<IntegrationsFields_IsAuthenticated_Update>;
};

export type IntegrationsFields_IsAuthenticated_Create = {
  __typename?: 'IntegrationsFields_isAuthenticated_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_IsAuthenticated_Delete = {
  __typename?: 'IntegrationsFields_isAuthenticated_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_IsAuthenticated_Read = {
  __typename?: 'IntegrationsFields_isAuthenticated_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_IsAuthenticated_Update = {
  __typename?: 'IntegrationsFields_isAuthenticated_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_MeasurementId = {
  __typename?: 'IntegrationsFields_measurementID';
  create?: Maybe<IntegrationsFields_MeasurementId_Create>;
  delete?: Maybe<IntegrationsFields_MeasurementId_Delete>;
  read?: Maybe<IntegrationsFields_MeasurementId_Read>;
  update?: Maybe<IntegrationsFields_MeasurementId_Update>;
};

export type IntegrationsFields_MeasurementId_Create = {
  __typename?: 'IntegrationsFields_measurementID_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_MeasurementId_Delete = {
  __typename?: 'IntegrationsFields_measurementID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_MeasurementId_Read = {
  __typename?: 'IntegrationsFields_measurementID_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_MeasurementId_Update = {
  __typename?: 'IntegrationsFields_measurementID_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_Tenant = {
  __typename?: 'IntegrationsFields_tenant';
  create?: Maybe<IntegrationsFields_Tenant_Create>;
  delete?: Maybe<IntegrationsFields_Tenant_Delete>;
  read?: Maybe<IntegrationsFields_Tenant_Read>;
  update?: Maybe<IntegrationsFields_Tenant_Update>;
};

export type IntegrationsFields_Tenant_Create = {
  __typename?: 'IntegrationsFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_Tenant_Delete = {
  __typename?: 'IntegrationsFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_Tenant_Read = {
  __typename?: 'IntegrationsFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_Tenant_Update = {
  __typename?: 'IntegrationsFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_UpdatedAt = {
  __typename?: 'IntegrationsFields_updatedAt';
  create?: Maybe<IntegrationsFields_UpdatedAt_Create>;
  delete?: Maybe<IntegrationsFields_UpdatedAt_Delete>;
  read?: Maybe<IntegrationsFields_UpdatedAt_Read>;
  update?: Maybe<IntegrationsFields_UpdatedAt_Update>;
};

export type IntegrationsFields_UpdatedAt_Create = {
  __typename?: 'IntegrationsFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_UpdatedAt_Delete = {
  __typename?: 'IntegrationsFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_UpdatedAt_Read = {
  __typename?: 'IntegrationsFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsFields_UpdatedAt_Update = {
  __typename?: 'IntegrationsFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobal = {
  __typename?: 'IntegrationsGlobal';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  googleAnalytics?: Maybe<IntegrationsGlobal_GoogleAnalytics>;
  googleCal?: Maybe<IntegrationsGlobal_GoogleCal>;
  id?: Maybe<Scalars['String']['output']>;
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type IntegrationsGlobalTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type IntegrationsGlobal_GoogleAnalytics = {
  __typename?: 'IntegrationsGlobal_GoogleAnalytics';
  measurementID?: Maybe<Scalars['String']['output']>;
};

export type IntegrationsGlobal_GoogleCal = {
  __typename?: 'IntegrationsGlobal_GoogleCal';
  authTokens?: Maybe<Scalars['JSON']['output']>;
  isAuthenticated?: Maybe<Scalars['Boolean']['output']>;
};

export type IntegrationsGlobal_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IntegrationsGlobal_GoogleAnalytics__MeasurementId_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type IntegrationsGlobal_GoogleCal__AuthTokens_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  intersects?: InputMaybe<Scalars['JSON']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  within?: InputMaybe<Scalars['JSON']['input']>;
};

export type IntegrationsGlobal_GoogleCal__IsAuthenticated_Operator = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntegrationsGlobal_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type IntegrationsGlobal_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type IntegrationsGlobal_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IntegrationsGlobal_Where = {
  AND?: InputMaybe<Array<InputMaybe<IntegrationsGlobal_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<IntegrationsGlobal_Where_Or>>>;
  createdAt?: InputMaybe<IntegrationsGlobal_CreatedAt_Operator>;
  googleAnalytics__measurementID?: InputMaybe<IntegrationsGlobal_GoogleAnalytics__MeasurementId_Operator>;
  googleCal__authTokens?: InputMaybe<IntegrationsGlobal_GoogleCal__AuthTokens_Operator>;
  googleCal__isAuthenticated?: InputMaybe<IntegrationsGlobal_GoogleCal__IsAuthenticated_Operator>;
  id?: InputMaybe<IntegrationsGlobal_Id_Operator>;
  tenant?: InputMaybe<IntegrationsGlobal_Tenant_Operator>;
  updatedAt?: InputMaybe<IntegrationsGlobal_UpdatedAt_Operator>;
};

export type IntegrationsGlobal_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<IntegrationsGlobal_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<IntegrationsGlobal_Where_Or>>>;
  createdAt?: InputMaybe<IntegrationsGlobal_CreatedAt_Operator>;
  googleAnalytics__measurementID?: InputMaybe<IntegrationsGlobal_GoogleAnalytics__MeasurementId_Operator>;
  googleCal__authTokens?: InputMaybe<IntegrationsGlobal_GoogleCal__AuthTokens_Operator>;
  googleCal__isAuthenticated?: InputMaybe<IntegrationsGlobal_GoogleCal__IsAuthenticated_Operator>;
  id?: InputMaybe<IntegrationsGlobal_Id_Operator>;
  tenant?: InputMaybe<IntegrationsGlobal_Tenant_Operator>;
  updatedAt?: InputMaybe<IntegrationsGlobal_UpdatedAt_Operator>;
};

export type IntegrationsGlobal_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<IntegrationsGlobal_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<IntegrationsGlobal_Where_Or>>>;
  createdAt?: InputMaybe<IntegrationsGlobal_CreatedAt_Operator>;
  googleAnalytics__measurementID?: InputMaybe<IntegrationsGlobal_GoogleAnalytics__MeasurementId_Operator>;
  googleCal__authTokens?: InputMaybe<IntegrationsGlobal_GoogleCal__AuthTokens_Operator>;
  googleCal__isAuthenticated?: InputMaybe<IntegrationsGlobal_GoogleCal__IsAuthenticated_Operator>;
  id?: InputMaybe<IntegrationsGlobal_Id_Operator>;
  tenant?: InputMaybe<IntegrationsGlobal_Tenant_Operator>;
  updatedAt?: InputMaybe<IntegrationsGlobal_UpdatedAt_Operator>;
};

export type IntegrationsGlobals = {
  __typename?: 'IntegrationsGlobals';
  docs?: Maybe<Array<Maybe<IntegrationsGlobal>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type IntegrationsGlobalsCreateAccess = {
  __typename?: 'IntegrationsGlobalsCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type IntegrationsGlobalsCreateDocAccess = {
  __typename?: 'IntegrationsGlobalsCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type IntegrationsGlobalsDeleteAccess = {
  __typename?: 'IntegrationsGlobalsDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type IntegrationsGlobalsDeleteDocAccess = {
  __typename?: 'IntegrationsGlobalsDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type IntegrationsGlobalsDocAccessFields = {
  __typename?: 'IntegrationsGlobalsDocAccessFields';
  createdAt?: Maybe<IntegrationsGlobalsDocAccessFields_CreatedAt>;
  isAuthenticated?: Maybe<IntegrationsGlobalsDocAccessFields_IsAuthenticated>;
  measurementID?: Maybe<IntegrationsGlobalsDocAccessFields_MeasurementId>;
  tenant?: Maybe<IntegrationsGlobalsDocAccessFields_Tenant>;
  updatedAt?: Maybe<IntegrationsGlobalsDocAccessFields_UpdatedAt>;
};

export type IntegrationsGlobalsDocAccessFields_CreatedAt = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_createdAt';
  create?: Maybe<IntegrationsGlobalsDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<IntegrationsGlobalsDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<IntegrationsGlobalsDocAccessFields_CreatedAt_Read>;
  update?: Maybe<IntegrationsGlobalsDocAccessFields_CreatedAt_Update>;
};

export type IntegrationsGlobalsDocAccessFields_CreatedAt_Create = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_CreatedAt_Delete = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_CreatedAt_Read = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_CreatedAt_Update = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_IsAuthenticated = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_isAuthenticated';
  create?: Maybe<IntegrationsGlobalsDocAccessFields_IsAuthenticated_Create>;
  delete?: Maybe<IntegrationsGlobalsDocAccessFields_IsAuthenticated_Delete>;
  read?: Maybe<IntegrationsGlobalsDocAccessFields_IsAuthenticated_Read>;
  update?: Maybe<IntegrationsGlobalsDocAccessFields_IsAuthenticated_Update>;
};

export type IntegrationsGlobalsDocAccessFields_IsAuthenticated_Create = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_isAuthenticated_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_IsAuthenticated_Delete = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_isAuthenticated_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_IsAuthenticated_Read = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_isAuthenticated_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_IsAuthenticated_Update = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_isAuthenticated_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_MeasurementId = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_measurementID';
  create?: Maybe<IntegrationsGlobalsDocAccessFields_MeasurementId_Create>;
  delete?: Maybe<IntegrationsGlobalsDocAccessFields_MeasurementId_Delete>;
  read?: Maybe<IntegrationsGlobalsDocAccessFields_MeasurementId_Read>;
  update?: Maybe<IntegrationsGlobalsDocAccessFields_MeasurementId_Update>;
};

export type IntegrationsGlobalsDocAccessFields_MeasurementId_Create = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_measurementID_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_MeasurementId_Delete = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_measurementID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_MeasurementId_Read = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_measurementID_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_MeasurementId_Update = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_measurementID_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_Tenant = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_tenant';
  create?: Maybe<IntegrationsGlobalsDocAccessFields_Tenant_Create>;
  delete?: Maybe<IntegrationsGlobalsDocAccessFields_Tenant_Delete>;
  read?: Maybe<IntegrationsGlobalsDocAccessFields_Tenant_Read>;
  update?: Maybe<IntegrationsGlobalsDocAccessFields_Tenant_Update>;
};

export type IntegrationsGlobalsDocAccessFields_Tenant_Create = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_Tenant_Delete = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_Tenant_Read = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_Tenant_Update = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_UpdatedAt = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_updatedAt';
  create?: Maybe<IntegrationsGlobalsDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<IntegrationsGlobalsDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<IntegrationsGlobalsDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<IntegrationsGlobalsDocAccessFields_UpdatedAt_Update>;
};

export type IntegrationsGlobalsDocAccessFields_UpdatedAt_Create = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_UpdatedAt_Read = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsDocAccessFields_UpdatedAt_Update = {
  __typename?: 'IntegrationsGlobalsDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields = {
  __typename?: 'IntegrationsGlobalsFields';
  createdAt?: Maybe<IntegrationsGlobalsFields_CreatedAt>;
  isAuthenticated?: Maybe<IntegrationsGlobalsFields_IsAuthenticated>;
  measurementID?: Maybe<IntegrationsGlobalsFields_MeasurementId>;
  tenant?: Maybe<IntegrationsGlobalsFields_Tenant>;
  updatedAt?: Maybe<IntegrationsGlobalsFields_UpdatedAt>;
};

export type IntegrationsGlobalsFields_CreatedAt = {
  __typename?: 'IntegrationsGlobalsFields_createdAt';
  create?: Maybe<IntegrationsGlobalsFields_CreatedAt_Create>;
  delete?: Maybe<IntegrationsGlobalsFields_CreatedAt_Delete>;
  read?: Maybe<IntegrationsGlobalsFields_CreatedAt_Read>;
  update?: Maybe<IntegrationsGlobalsFields_CreatedAt_Update>;
};

export type IntegrationsGlobalsFields_CreatedAt_Create = {
  __typename?: 'IntegrationsGlobalsFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_CreatedAt_Delete = {
  __typename?: 'IntegrationsGlobalsFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_CreatedAt_Read = {
  __typename?: 'IntegrationsGlobalsFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_CreatedAt_Update = {
  __typename?: 'IntegrationsGlobalsFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_IsAuthenticated = {
  __typename?: 'IntegrationsGlobalsFields_isAuthenticated';
  create?: Maybe<IntegrationsGlobalsFields_IsAuthenticated_Create>;
  delete?: Maybe<IntegrationsGlobalsFields_IsAuthenticated_Delete>;
  read?: Maybe<IntegrationsGlobalsFields_IsAuthenticated_Read>;
  update?: Maybe<IntegrationsGlobalsFields_IsAuthenticated_Update>;
};

export type IntegrationsGlobalsFields_IsAuthenticated_Create = {
  __typename?: 'IntegrationsGlobalsFields_isAuthenticated_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_IsAuthenticated_Delete = {
  __typename?: 'IntegrationsGlobalsFields_isAuthenticated_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_IsAuthenticated_Read = {
  __typename?: 'IntegrationsGlobalsFields_isAuthenticated_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_IsAuthenticated_Update = {
  __typename?: 'IntegrationsGlobalsFields_isAuthenticated_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_MeasurementId = {
  __typename?: 'IntegrationsGlobalsFields_measurementID';
  create?: Maybe<IntegrationsGlobalsFields_MeasurementId_Create>;
  delete?: Maybe<IntegrationsGlobalsFields_MeasurementId_Delete>;
  read?: Maybe<IntegrationsGlobalsFields_MeasurementId_Read>;
  update?: Maybe<IntegrationsGlobalsFields_MeasurementId_Update>;
};

export type IntegrationsGlobalsFields_MeasurementId_Create = {
  __typename?: 'IntegrationsGlobalsFields_measurementID_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_MeasurementId_Delete = {
  __typename?: 'IntegrationsGlobalsFields_measurementID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_MeasurementId_Read = {
  __typename?: 'IntegrationsGlobalsFields_measurementID_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_MeasurementId_Update = {
  __typename?: 'IntegrationsGlobalsFields_measurementID_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_Tenant = {
  __typename?: 'IntegrationsGlobalsFields_tenant';
  create?: Maybe<IntegrationsGlobalsFields_Tenant_Create>;
  delete?: Maybe<IntegrationsGlobalsFields_Tenant_Delete>;
  read?: Maybe<IntegrationsGlobalsFields_Tenant_Read>;
  update?: Maybe<IntegrationsGlobalsFields_Tenant_Update>;
};

export type IntegrationsGlobalsFields_Tenant_Create = {
  __typename?: 'IntegrationsGlobalsFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_Tenant_Delete = {
  __typename?: 'IntegrationsGlobalsFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_Tenant_Read = {
  __typename?: 'IntegrationsGlobalsFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_Tenant_Update = {
  __typename?: 'IntegrationsGlobalsFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_UpdatedAt = {
  __typename?: 'IntegrationsGlobalsFields_updatedAt';
  create?: Maybe<IntegrationsGlobalsFields_UpdatedAt_Create>;
  delete?: Maybe<IntegrationsGlobalsFields_UpdatedAt_Delete>;
  read?: Maybe<IntegrationsGlobalsFields_UpdatedAt_Read>;
  update?: Maybe<IntegrationsGlobalsFields_UpdatedAt_Update>;
};

export type IntegrationsGlobalsFields_UpdatedAt_Create = {
  __typename?: 'IntegrationsGlobalsFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_UpdatedAt_Delete = {
  __typename?: 'IntegrationsGlobalsFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_UpdatedAt_Read = {
  __typename?: 'IntegrationsGlobalsFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsFields_UpdatedAt_Update = {
  __typename?: 'IntegrationsGlobalsFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type IntegrationsGlobalsReadAccess = {
  __typename?: 'IntegrationsGlobalsReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type IntegrationsGlobalsReadDocAccess = {
  __typename?: 'IntegrationsGlobalsReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type IntegrationsGlobalsUpdateAccess = {
  __typename?: 'IntegrationsGlobalsUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type IntegrationsGlobalsUpdateDocAccess = {
  __typename?: 'IntegrationsGlobalsUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type IntegrationsReadAccess = {
  __typename?: 'IntegrationsReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type IntegrationsReadDocAccess = {
  __typename?: 'IntegrationsReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type IntegrationsUpdateAccess = {
  __typename?: 'IntegrationsUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type IntegrationsUpdateDocAccess = {
  __typename?: 'IntegrationsUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export enum LocaleInputType {
  En = 'en',
  Original = 'original'
}

export type Media = {
  __typename?: 'Media';
  alt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  filesize?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  sizes?: Maybe<Media_Sizes>;
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};


export type MediaTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type MediaCreateAccess = {
  __typename?: 'MediaCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaCreateDocAccess = {
  __typename?: 'MediaCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaDeleteAccess = {
  __typename?: 'MediaDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaDeleteDocAccess = {
  __typename?: 'MediaDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaDocAccessFields = {
  __typename?: 'MediaDocAccessFields';
  alt?: Maybe<MediaDocAccessFields_Alt>;
  createdAt?: Maybe<MediaDocAccessFields_CreatedAt>;
  filename?: Maybe<MediaDocAccessFields_Filename>;
  filesize?: Maybe<MediaDocAccessFields_Filesize>;
  height?: Maybe<MediaDocAccessFields_Height>;
  mimeType?: Maybe<MediaDocAccessFields_MimeType>;
  sizes?: Maybe<MediaDocAccessFields_Sizes>;
  tenant?: Maybe<MediaDocAccessFields_Tenant>;
  updatedAt?: Maybe<MediaDocAccessFields_UpdatedAt>;
  url?: Maybe<MediaDocAccessFields_Url>;
  width?: Maybe<MediaDocAccessFields_Width>;
};

export type MediaDocAccessFields_Alt = {
  __typename?: 'MediaDocAccessFields_alt';
  create?: Maybe<MediaDocAccessFields_Alt_Create>;
  delete?: Maybe<MediaDocAccessFields_Alt_Delete>;
  read?: Maybe<MediaDocAccessFields_Alt_Read>;
  update?: Maybe<MediaDocAccessFields_Alt_Update>;
};

export type MediaDocAccessFields_Alt_Create = {
  __typename?: 'MediaDocAccessFields_alt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Alt_Delete = {
  __typename?: 'MediaDocAccessFields_alt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Alt_Read = {
  __typename?: 'MediaDocAccessFields_alt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Alt_Update = {
  __typename?: 'MediaDocAccessFields_alt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_CreatedAt = {
  __typename?: 'MediaDocAccessFields_createdAt';
  create?: Maybe<MediaDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<MediaDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<MediaDocAccessFields_CreatedAt_Read>;
  update?: Maybe<MediaDocAccessFields_CreatedAt_Update>;
};

export type MediaDocAccessFields_CreatedAt_Create = {
  __typename?: 'MediaDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_CreatedAt_Delete = {
  __typename?: 'MediaDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_CreatedAt_Read = {
  __typename?: 'MediaDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_CreatedAt_Update = {
  __typename?: 'MediaDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filename = {
  __typename?: 'MediaDocAccessFields_filename';
  create?: Maybe<MediaDocAccessFields_Filename_Create>;
  delete?: Maybe<MediaDocAccessFields_Filename_Delete>;
  read?: Maybe<MediaDocAccessFields_Filename_Read>;
  update?: Maybe<MediaDocAccessFields_Filename_Update>;
};

export type MediaDocAccessFields_Filename_Create = {
  __typename?: 'MediaDocAccessFields_filename_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filename_Delete = {
  __typename?: 'MediaDocAccessFields_filename_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filename_Read = {
  __typename?: 'MediaDocAccessFields_filename_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filename_Update = {
  __typename?: 'MediaDocAccessFields_filename_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filesize = {
  __typename?: 'MediaDocAccessFields_filesize';
  create?: Maybe<MediaDocAccessFields_Filesize_Create>;
  delete?: Maybe<MediaDocAccessFields_Filesize_Delete>;
  read?: Maybe<MediaDocAccessFields_Filesize_Read>;
  update?: Maybe<MediaDocAccessFields_Filesize_Update>;
};

export type MediaDocAccessFields_Filesize_Create = {
  __typename?: 'MediaDocAccessFields_filesize_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filesize_Delete = {
  __typename?: 'MediaDocAccessFields_filesize_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filesize_Read = {
  __typename?: 'MediaDocAccessFields_filesize_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filesize_Update = {
  __typename?: 'MediaDocAccessFields_filesize_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Height = {
  __typename?: 'MediaDocAccessFields_height';
  create?: Maybe<MediaDocAccessFields_Height_Create>;
  delete?: Maybe<MediaDocAccessFields_Height_Delete>;
  read?: Maybe<MediaDocAccessFields_Height_Read>;
  update?: Maybe<MediaDocAccessFields_Height_Update>;
};

export type MediaDocAccessFields_Height_Create = {
  __typename?: 'MediaDocAccessFields_height_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Height_Delete = {
  __typename?: 'MediaDocAccessFields_height_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Height_Read = {
  __typename?: 'MediaDocAccessFields_height_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Height_Update = {
  __typename?: 'MediaDocAccessFields_height_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_MimeType = {
  __typename?: 'MediaDocAccessFields_mimeType';
  create?: Maybe<MediaDocAccessFields_MimeType_Create>;
  delete?: Maybe<MediaDocAccessFields_MimeType_Delete>;
  read?: Maybe<MediaDocAccessFields_MimeType_Read>;
  update?: Maybe<MediaDocAccessFields_MimeType_Update>;
};

export type MediaDocAccessFields_MimeType_Create = {
  __typename?: 'MediaDocAccessFields_mimeType_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_MimeType_Delete = {
  __typename?: 'MediaDocAccessFields_mimeType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_MimeType_Read = {
  __typename?: 'MediaDocAccessFields_mimeType_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_MimeType_Update = {
  __typename?: 'MediaDocAccessFields_mimeType_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes = {
  __typename?: 'MediaDocAccessFields_sizes';
  create?: Maybe<MediaDocAccessFields_Sizes_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Delete>;
  fields?: Maybe<MediaDocAccessFields_Sizes_Fields>;
  read?: Maybe<MediaDocAccessFields_Sizes_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Update>;
};

export type MediaDocAccessFields_Sizes_Create = {
  __typename?: 'MediaDocAccessFields_sizes_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Fields = {
  __typename?: 'MediaDocAccessFields_sizes_Fields';
  card?: Maybe<MediaDocAccessFields_Sizes_Card>;
  tablet?: Maybe<MediaDocAccessFields_Sizes_Tablet>;
  thumbnail?: Maybe<MediaDocAccessFields_Sizes_Thumbnail>;
};

export type MediaDocAccessFields_Sizes_Read = {
  __typename?: 'MediaDocAccessFields_sizes_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Update = {
  __typename?: 'MediaDocAccessFields_sizes_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card = {
  __typename?: 'MediaDocAccessFields_sizes_card';
  create?: Maybe<MediaDocAccessFields_Sizes_Card_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Card_Delete>;
  fields?: Maybe<MediaDocAccessFields_Sizes_Card_Fields>;
  read?: Maybe<MediaDocAccessFields_Sizes_Card_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Card_Update>;
};

export type MediaDocAccessFields_Sizes_Card_Create = {
  __typename?: 'MediaDocAccessFields_sizes_card_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_card_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Fields = {
  __typename?: 'MediaDocAccessFields_sizes_card_Fields';
  filename?: Maybe<MediaDocAccessFields_Sizes_Card_Filename>;
  filesize?: Maybe<MediaDocAccessFields_Sizes_Card_Filesize>;
  height?: Maybe<MediaDocAccessFields_Sizes_Card_Height>;
  mimeType?: Maybe<MediaDocAccessFields_Sizes_Card_MimeType>;
  url?: Maybe<MediaDocAccessFields_Sizes_Card_Url>;
  width?: Maybe<MediaDocAccessFields_Sizes_Card_Width>;
};

export type MediaDocAccessFields_Sizes_Card_Read = {
  __typename?: 'MediaDocAccessFields_sizes_card_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Update = {
  __typename?: 'MediaDocAccessFields_sizes_card_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Filename = {
  __typename?: 'MediaDocAccessFields_sizes_card_filename';
  create?: Maybe<MediaDocAccessFields_Sizes_Card_Filename_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Card_Filename_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Card_Filename_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Card_Filename_Update>;
};

export type MediaDocAccessFields_Sizes_Card_Filename_Create = {
  __typename?: 'MediaDocAccessFields_sizes_card_filename_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Filename_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_card_filename_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Filename_Read = {
  __typename?: 'MediaDocAccessFields_sizes_card_filename_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Filename_Update = {
  __typename?: 'MediaDocAccessFields_sizes_card_filename_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Filesize = {
  __typename?: 'MediaDocAccessFields_sizes_card_filesize';
  create?: Maybe<MediaDocAccessFields_Sizes_Card_Filesize_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Card_Filesize_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Card_Filesize_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Card_Filesize_Update>;
};

export type MediaDocAccessFields_Sizes_Card_Filesize_Create = {
  __typename?: 'MediaDocAccessFields_sizes_card_filesize_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Filesize_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_card_filesize_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Filesize_Read = {
  __typename?: 'MediaDocAccessFields_sizes_card_filesize_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Filesize_Update = {
  __typename?: 'MediaDocAccessFields_sizes_card_filesize_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Height = {
  __typename?: 'MediaDocAccessFields_sizes_card_height';
  create?: Maybe<MediaDocAccessFields_Sizes_Card_Height_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Card_Height_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Card_Height_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Card_Height_Update>;
};

export type MediaDocAccessFields_Sizes_Card_Height_Create = {
  __typename?: 'MediaDocAccessFields_sizes_card_height_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Height_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_card_height_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Height_Read = {
  __typename?: 'MediaDocAccessFields_sizes_card_height_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Height_Update = {
  __typename?: 'MediaDocAccessFields_sizes_card_height_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_MimeType = {
  __typename?: 'MediaDocAccessFields_sizes_card_mimeType';
  create?: Maybe<MediaDocAccessFields_Sizes_Card_MimeType_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Card_MimeType_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Card_MimeType_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Card_MimeType_Update>;
};

export type MediaDocAccessFields_Sizes_Card_MimeType_Create = {
  __typename?: 'MediaDocAccessFields_sizes_card_mimeType_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_MimeType_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_card_mimeType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_MimeType_Read = {
  __typename?: 'MediaDocAccessFields_sizes_card_mimeType_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_MimeType_Update = {
  __typename?: 'MediaDocAccessFields_sizes_card_mimeType_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Url = {
  __typename?: 'MediaDocAccessFields_sizes_card_url';
  create?: Maybe<MediaDocAccessFields_Sizes_Card_Url_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Card_Url_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Card_Url_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Card_Url_Update>;
};

export type MediaDocAccessFields_Sizes_Card_Url_Create = {
  __typename?: 'MediaDocAccessFields_sizes_card_url_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Url_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_card_url_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Url_Read = {
  __typename?: 'MediaDocAccessFields_sizes_card_url_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Url_Update = {
  __typename?: 'MediaDocAccessFields_sizes_card_url_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Width = {
  __typename?: 'MediaDocAccessFields_sizes_card_width';
  create?: Maybe<MediaDocAccessFields_Sizes_Card_Width_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Card_Width_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Card_Width_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Card_Width_Update>;
};

export type MediaDocAccessFields_Sizes_Card_Width_Create = {
  __typename?: 'MediaDocAccessFields_sizes_card_width_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Width_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_card_width_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Width_Read = {
  __typename?: 'MediaDocAccessFields_sizes_card_width_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Card_Width_Update = {
  __typename?: 'MediaDocAccessFields_sizes_card_width_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet = {
  __typename?: 'MediaDocAccessFields_sizes_tablet';
  create?: Maybe<MediaDocAccessFields_Sizes_Tablet_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Tablet_Delete>;
  fields?: Maybe<MediaDocAccessFields_Sizes_Tablet_Fields>;
  read?: Maybe<MediaDocAccessFields_Sizes_Tablet_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Tablet_Update>;
};

export type MediaDocAccessFields_Sizes_Tablet_Create = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Fields = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_Fields';
  filename?: Maybe<MediaDocAccessFields_Sizes_Tablet_Filename>;
  filesize?: Maybe<MediaDocAccessFields_Sizes_Tablet_Filesize>;
  height?: Maybe<MediaDocAccessFields_Sizes_Tablet_Height>;
  mimeType?: Maybe<MediaDocAccessFields_Sizes_Tablet_MimeType>;
  url?: Maybe<MediaDocAccessFields_Sizes_Tablet_Url>;
  width?: Maybe<MediaDocAccessFields_Sizes_Tablet_Width>;
};

export type MediaDocAccessFields_Sizes_Tablet_Read = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Update = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Filename = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_filename';
  create?: Maybe<MediaDocAccessFields_Sizes_Tablet_Filename_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Tablet_Filename_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Tablet_Filename_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Tablet_Filename_Update>;
};

export type MediaDocAccessFields_Sizes_Tablet_Filename_Create = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_filename_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Filename_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_filename_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Filename_Read = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_filename_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Filename_Update = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_filename_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Filesize = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_filesize';
  create?: Maybe<MediaDocAccessFields_Sizes_Tablet_Filesize_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Tablet_Filesize_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Tablet_Filesize_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Tablet_Filesize_Update>;
};

export type MediaDocAccessFields_Sizes_Tablet_Filesize_Create = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_filesize_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Filesize_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_filesize_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Filesize_Read = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_filesize_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Filesize_Update = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_filesize_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Height = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_height';
  create?: Maybe<MediaDocAccessFields_Sizes_Tablet_Height_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Tablet_Height_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Tablet_Height_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Tablet_Height_Update>;
};

export type MediaDocAccessFields_Sizes_Tablet_Height_Create = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_height_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Height_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_height_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Height_Read = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_height_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Height_Update = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_height_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_MimeType = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_mimeType';
  create?: Maybe<MediaDocAccessFields_Sizes_Tablet_MimeType_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Tablet_MimeType_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Tablet_MimeType_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Tablet_MimeType_Update>;
};

export type MediaDocAccessFields_Sizes_Tablet_MimeType_Create = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_mimeType_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_MimeType_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_mimeType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_MimeType_Read = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_mimeType_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_MimeType_Update = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_mimeType_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Url = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_url';
  create?: Maybe<MediaDocAccessFields_Sizes_Tablet_Url_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Tablet_Url_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Tablet_Url_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Tablet_Url_Update>;
};

export type MediaDocAccessFields_Sizes_Tablet_Url_Create = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_url_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Url_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_url_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Url_Read = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_url_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Url_Update = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_url_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Width = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_width';
  create?: Maybe<MediaDocAccessFields_Sizes_Tablet_Width_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Tablet_Width_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Tablet_Width_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Tablet_Width_Update>;
};

export type MediaDocAccessFields_Sizes_Tablet_Width_Create = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_width_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Width_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_width_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Width_Read = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_width_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Tablet_Width_Update = {
  __typename?: 'MediaDocAccessFields_sizes_tablet_width_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail';
  create?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Delete>;
  fields?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Fields>;
  read?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Update>;
};

export type MediaDocAccessFields_Sizes_Thumbnail_Create = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Fields = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_Fields';
  filename?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Filename>;
  filesize?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Filesize>;
  height?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Height>;
  mimeType?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_MimeType>;
  url?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Url>;
  width?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Width>;
};

export type MediaDocAccessFields_Sizes_Thumbnail_Read = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Update = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Filename = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_filename';
  create?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Filename_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Filename_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Filename_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Filename_Update>;
};

export type MediaDocAccessFields_Sizes_Thumbnail_Filename_Create = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_filename_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Filename_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_filename_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Filename_Read = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_filename_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Filename_Update = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_filename_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Filesize = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_filesize';
  create?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Filesize_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Filesize_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Filesize_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Filesize_Update>;
};

export type MediaDocAccessFields_Sizes_Thumbnail_Filesize_Create = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_filesize_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Filesize_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_filesize_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Filesize_Read = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_filesize_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Filesize_Update = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_filesize_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Height = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_height';
  create?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Height_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Height_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Height_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Height_Update>;
};

export type MediaDocAccessFields_Sizes_Thumbnail_Height_Create = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_height_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Height_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_height_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Height_Read = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_height_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Height_Update = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_height_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_MimeType = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_mimeType';
  create?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_MimeType_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_MimeType_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_MimeType_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_MimeType_Update>;
};

export type MediaDocAccessFields_Sizes_Thumbnail_MimeType_Create = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_mimeType_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_MimeType_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_mimeType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_MimeType_Read = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_mimeType_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_MimeType_Update = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_mimeType_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Url = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_url';
  create?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Url_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Url_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Url_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Url_Update>;
};

export type MediaDocAccessFields_Sizes_Thumbnail_Url_Create = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_url_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Url_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_url_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Url_Read = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_url_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Url_Update = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_url_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Width = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_width';
  create?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Width_Create>;
  delete?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Width_Delete>;
  read?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Width_Read>;
  update?: Maybe<MediaDocAccessFields_Sizes_Thumbnail_Width_Update>;
};

export type MediaDocAccessFields_Sizes_Thumbnail_Width_Create = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_width_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Width_Delete = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_width_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Width_Read = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_width_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Sizes_Thumbnail_Width_Update = {
  __typename?: 'MediaDocAccessFields_sizes_thumbnail_width_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Tenant = {
  __typename?: 'MediaDocAccessFields_tenant';
  create?: Maybe<MediaDocAccessFields_Tenant_Create>;
  delete?: Maybe<MediaDocAccessFields_Tenant_Delete>;
  read?: Maybe<MediaDocAccessFields_Tenant_Read>;
  update?: Maybe<MediaDocAccessFields_Tenant_Update>;
};

export type MediaDocAccessFields_Tenant_Create = {
  __typename?: 'MediaDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Tenant_Delete = {
  __typename?: 'MediaDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Tenant_Read = {
  __typename?: 'MediaDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Tenant_Update = {
  __typename?: 'MediaDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_UpdatedAt = {
  __typename?: 'MediaDocAccessFields_updatedAt';
  create?: Maybe<MediaDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<MediaDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<MediaDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<MediaDocAccessFields_UpdatedAt_Update>;
};

export type MediaDocAccessFields_UpdatedAt_Create = {
  __typename?: 'MediaDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'MediaDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_UpdatedAt_Read = {
  __typename?: 'MediaDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_UpdatedAt_Update = {
  __typename?: 'MediaDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Url = {
  __typename?: 'MediaDocAccessFields_url';
  create?: Maybe<MediaDocAccessFields_Url_Create>;
  delete?: Maybe<MediaDocAccessFields_Url_Delete>;
  read?: Maybe<MediaDocAccessFields_Url_Read>;
  update?: Maybe<MediaDocAccessFields_Url_Update>;
};

export type MediaDocAccessFields_Url_Create = {
  __typename?: 'MediaDocAccessFields_url_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Url_Delete = {
  __typename?: 'MediaDocAccessFields_url_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Url_Read = {
  __typename?: 'MediaDocAccessFields_url_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Url_Update = {
  __typename?: 'MediaDocAccessFields_url_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Width = {
  __typename?: 'MediaDocAccessFields_width';
  create?: Maybe<MediaDocAccessFields_Width_Create>;
  delete?: Maybe<MediaDocAccessFields_Width_Delete>;
  read?: Maybe<MediaDocAccessFields_Width_Read>;
  update?: Maybe<MediaDocAccessFields_Width_Update>;
};

export type MediaDocAccessFields_Width_Create = {
  __typename?: 'MediaDocAccessFields_width_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Width_Delete = {
  __typename?: 'MediaDocAccessFields_width_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Width_Read = {
  __typename?: 'MediaDocAccessFields_width_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Width_Update = {
  __typename?: 'MediaDocAccessFields_width_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields = {
  __typename?: 'MediaFields';
  alt?: Maybe<MediaFields_Alt>;
  createdAt?: Maybe<MediaFields_CreatedAt>;
  filename?: Maybe<MediaFields_Filename>;
  filesize?: Maybe<MediaFields_Filesize>;
  height?: Maybe<MediaFields_Height>;
  mimeType?: Maybe<MediaFields_MimeType>;
  sizes?: Maybe<MediaFields_Sizes>;
  tenant?: Maybe<MediaFields_Tenant>;
  updatedAt?: Maybe<MediaFields_UpdatedAt>;
  url?: Maybe<MediaFields_Url>;
  width?: Maybe<MediaFields_Width>;
};

export type MediaFields_Alt = {
  __typename?: 'MediaFields_alt';
  create?: Maybe<MediaFields_Alt_Create>;
  delete?: Maybe<MediaFields_Alt_Delete>;
  read?: Maybe<MediaFields_Alt_Read>;
  update?: Maybe<MediaFields_Alt_Update>;
};

export type MediaFields_Alt_Create = {
  __typename?: 'MediaFields_alt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Alt_Delete = {
  __typename?: 'MediaFields_alt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Alt_Read = {
  __typename?: 'MediaFields_alt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Alt_Update = {
  __typename?: 'MediaFields_alt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_CreatedAt = {
  __typename?: 'MediaFields_createdAt';
  create?: Maybe<MediaFields_CreatedAt_Create>;
  delete?: Maybe<MediaFields_CreatedAt_Delete>;
  read?: Maybe<MediaFields_CreatedAt_Read>;
  update?: Maybe<MediaFields_CreatedAt_Update>;
};

export type MediaFields_CreatedAt_Create = {
  __typename?: 'MediaFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_CreatedAt_Delete = {
  __typename?: 'MediaFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_CreatedAt_Read = {
  __typename?: 'MediaFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_CreatedAt_Update = {
  __typename?: 'MediaFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filename = {
  __typename?: 'MediaFields_filename';
  create?: Maybe<MediaFields_Filename_Create>;
  delete?: Maybe<MediaFields_Filename_Delete>;
  read?: Maybe<MediaFields_Filename_Read>;
  update?: Maybe<MediaFields_Filename_Update>;
};

export type MediaFields_Filename_Create = {
  __typename?: 'MediaFields_filename_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filename_Delete = {
  __typename?: 'MediaFields_filename_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filename_Read = {
  __typename?: 'MediaFields_filename_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filename_Update = {
  __typename?: 'MediaFields_filename_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filesize = {
  __typename?: 'MediaFields_filesize';
  create?: Maybe<MediaFields_Filesize_Create>;
  delete?: Maybe<MediaFields_Filesize_Delete>;
  read?: Maybe<MediaFields_Filesize_Read>;
  update?: Maybe<MediaFields_Filesize_Update>;
};

export type MediaFields_Filesize_Create = {
  __typename?: 'MediaFields_filesize_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filesize_Delete = {
  __typename?: 'MediaFields_filesize_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filesize_Read = {
  __typename?: 'MediaFields_filesize_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filesize_Update = {
  __typename?: 'MediaFields_filesize_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Height = {
  __typename?: 'MediaFields_height';
  create?: Maybe<MediaFields_Height_Create>;
  delete?: Maybe<MediaFields_Height_Delete>;
  read?: Maybe<MediaFields_Height_Read>;
  update?: Maybe<MediaFields_Height_Update>;
};

export type MediaFields_Height_Create = {
  __typename?: 'MediaFields_height_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Height_Delete = {
  __typename?: 'MediaFields_height_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Height_Read = {
  __typename?: 'MediaFields_height_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Height_Update = {
  __typename?: 'MediaFields_height_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_MimeType = {
  __typename?: 'MediaFields_mimeType';
  create?: Maybe<MediaFields_MimeType_Create>;
  delete?: Maybe<MediaFields_MimeType_Delete>;
  read?: Maybe<MediaFields_MimeType_Read>;
  update?: Maybe<MediaFields_MimeType_Update>;
};

export type MediaFields_MimeType_Create = {
  __typename?: 'MediaFields_mimeType_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_MimeType_Delete = {
  __typename?: 'MediaFields_mimeType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_MimeType_Read = {
  __typename?: 'MediaFields_mimeType_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_MimeType_Update = {
  __typename?: 'MediaFields_mimeType_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes = {
  __typename?: 'MediaFields_sizes';
  create?: Maybe<MediaFields_Sizes_Create>;
  delete?: Maybe<MediaFields_Sizes_Delete>;
  fields?: Maybe<MediaFields_Sizes_Fields>;
  read?: Maybe<MediaFields_Sizes_Read>;
  update?: Maybe<MediaFields_Sizes_Update>;
};

export type MediaFields_Sizes_Create = {
  __typename?: 'MediaFields_sizes_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Delete = {
  __typename?: 'MediaFields_sizes_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Fields = {
  __typename?: 'MediaFields_sizes_Fields';
  card?: Maybe<MediaFields_Sizes_Card>;
  tablet?: Maybe<MediaFields_Sizes_Tablet>;
  thumbnail?: Maybe<MediaFields_Sizes_Thumbnail>;
};

export type MediaFields_Sizes_Read = {
  __typename?: 'MediaFields_sizes_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Update = {
  __typename?: 'MediaFields_sizes_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card = {
  __typename?: 'MediaFields_sizes_card';
  create?: Maybe<MediaFields_Sizes_Card_Create>;
  delete?: Maybe<MediaFields_Sizes_Card_Delete>;
  fields?: Maybe<MediaFields_Sizes_Card_Fields>;
  read?: Maybe<MediaFields_Sizes_Card_Read>;
  update?: Maybe<MediaFields_Sizes_Card_Update>;
};

export type MediaFields_Sizes_Card_Create = {
  __typename?: 'MediaFields_sizes_card_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Delete = {
  __typename?: 'MediaFields_sizes_card_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Fields = {
  __typename?: 'MediaFields_sizes_card_Fields';
  filename?: Maybe<MediaFields_Sizes_Card_Filename>;
  filesize?: Maybe<MediaFields_Sizes_Card_Filesize>;
  height?: Maybe<MediaFields_Sizes_Card_Height>;
  mimeType?: Maybe<MediaFields_Sizes_Card_MimeType>;
  url?: Maybe<MediaFields_Sizes_Card_Url>;
  width?: Maybe<MediaFields_Sizes_Card_Width>;
};

export type MediaFields_Sizes_Card_Read = {
  __typename?: 'MediaFields_sizes_card_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Update = {
  __typename?: 'MediaFields_sizes_card_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Filename = {
  __typename?: 'MediaFields_sizes_card_filename';
  create?: Maybe<MediaFields_Sizes_Card_Filename_Create>;
  delete?: Maybe<MediaFields_Sizes_Card_Filename_Delete>;
  read?: Maybe<MediaFields_Sizes_Card_Filename_Read>;
  update?: Maybe<MediaFields_Sizes_Card_Filename_Update>;
};

export type MediaFields_Sizes_Card_Filename_Create = {
  __typename?: 'MediaFields_sizes_card_filename_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Filename_Delete = {
  __typename?: 'MediaFields_sizes_card_filename_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Filename_Read = {
  __typename?: 'MediaFields_sizes_card_filename_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Filename_Update = {
  __typename?: 'MediaFields_sizes_card_filename_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Filesize = {
  __typename?: 'MediaFields_sizes_card_filesize';
  create?: Maybe<MediaFields_Sizes_Card_Filesize_Create>;
  delete?: Maybe<MediaFields_Sizes_Card_Filesize_Delete>;
  read?: Maybe<MediaFields_Sizes_Card_Filesize_Read>;
  update?: Maybe<MediaFields_Sizes_Card_Filesize_Update>;
};

export type MediaFields_Sizes_Card_Filesize_Create = {
  __typename?: 'MediaFields_sizes_card_filesize_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Filesize_Delete = {
  __typename?: 'MediaFields_sizes_card_filesize_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Filesize_Read = {
  __typename?: 'MediaFields_sizes_card_filesize_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Filesize_Update = {
  __typename?: 'MediaFields_sizes_card_filesize_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Height = {
  __typename?: 'MediaFields_sizes_card_height';
  create?: Maybe<MediaFields_Sizes_Card_Height_Create>;
  delete?: Maybe<MediaFields_Sizes_Card_Height_Delete>;
  read?: Maybe<MediaFields_Sizes_Card_Height_Read>;
  update?: Maybe<MediaFields_Sizes_Card_Height_Update>;
};

export type MediaFields_Sizes_Card_Height_Create = {
  __typename?: 'MediaFields_sizes_card_height_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Height_Delete = {
  __typename?: 'MediaFields_sizes_card_height_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Height_Read = {
  __typename?: 'MediaFields_sizes_card_height_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Height_Update = {
  __typename?: 'MediaFields_sizes_card_height_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_MimeType = {
  __typename?: 'MediaFields_sizes_card_mimeType';
  create?: Maybe<MediaFields_Sizes_Card_MimeType_Create>;
  delete?: Maybe<MediaFields_Sizes_Card_MimeType_Delete>;
  read?: Maybe<MediaFields_Sizes_Card_MimeType_Read>;
  update?: Maybe<MediaFields_Sizes_Card_MimeType_Update>;
};

export type MediaFields_Sizes_Card_MimeType_Create = {
  __typename?: 'MediaFields_sizes_card_mimeType_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_MimeType_Delete = {
  __typename?: 'MediaFields_sizes_card_mimeType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_MimeType_Read = {
  __typename?: 'MediaFields_sizes_card_mimeType_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_MimeType_Update = {
  __typename?: 'MediaFields_sizes_card_mimeType_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Url = {
  __typename?: 'MediaFields_sizes_card_url';
  create?: Maybe<MediaFields_Sizes_Card_Url_Create>;
  delete?: Maybe<MediaFields_Sizes_Card_Url_Delete>;
  read?: Maybe<MediaFields_Sizes_Card_Url_Read>;
  update?: Maybe<MediaFields_Sizes_Card_Url_Update>;
};

export type MediaFields_Sizes_Card_Url_Create = {
  __typename?: 'MediaFields_sizes_card_url_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Url_Delete = {
  __typename?: 'MediaFields_sizes_card_url_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Url_Read = {
  __typename?: 'MediaFields_sizes_card_url_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Url_Update = {
  __typename?: 'MediaFields_sizes_card_url_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Width = {
  __typename?: 'MediaFields_sizes_card_width';
  create?: Maybe<MediaFields_Sizes_Card_Width_Create>;
  delete?: Maybe<MediaFields_Sizes_Card_Width_Delete>;
  read?: Maybe<MediaFields_Sizes_Card_Width_Read>;
  update?: Maybe<MediaFields_Sizes_Card_Width_Update>;
};

export type MediaFields_Sizes_Card_Width_Create = {
  __typename?: 'MediaFields_sizes_card_width_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Width_Delete = {
  __typename?: 'MediaFields_sizes_card_width_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Width_Read = {
  __typename?: 'MediaFields_sizes_card_width_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Card_Width_Update = {
  __typename?: 'MediaFields_sizes_card_width_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet = {
  __typename?: 'MediaFields_sizes_tablet';
  create?: Maybe<MediaFields_Sizes_Tablet_Create>;
  delete?: Maybe<MediaFields_Sizes_Tablet_Delete>;
  fields?: Maybe<MediaFields_Sizes_Tablet_Fields>;
  read?: Maybe<MediaFields_Sizes_Tablet_Read>;
  update?: Maybe<MediaFields_Sizes_Tablet_Update>;
};

export type MediaFields_Sizes_Tablet_Create = {
  __typename?: 'MediaFields_sizes_tablet_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Delete = {
  __typename?: 'MediaFields_sizes_tablet_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Fields = {
  __typename?: 'MediaFields_sizes_tablet_Fields';
  filename?: Maybe<MediaFields_Sizes_Tablet_Filename>;
  filesize?: Maybe<MediaFields_Sizes_Tablet_Filesize>;
  height?: Maybe<MediaFields_Sizes_Tablet_Height>;
  mimeType?: Maybe<MediaFields_Sizes_Tablet_MimeType>;
  url?: Maybe<MediaFields_Sizes_Tablet_Url>;
  width?: Maybe<MediaFields_Sizes_Tablet_Width>;
};

export type MediaFields_Sizes_Tablet_Read = {
  __typename?: 'MediaFields_sizes_tablet_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Update = {
  __typename?: 'MediaFields_sizes_tablet_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Filename = {
  __typename?: 'MediaFields_sizes_tablet_filename';
  create?: Maybe<MediaFields_Sizes_Tablet_Filename_Create>;
  delete?: Maybe<MediaFields_Sizes_Tablet_Filename_Delete>;
  read?: Maybe<MediaFields_Sizes_Tablet_Filename_Read>;
  update?: Maybe<MediaFields_Sizes_Tablet_Filename_Update>;
};

export type MediaFields_Sizes_Tablet_Filename_Create = {
  __typename?: 'MediaFields_sizes_tablet_filename_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Filename_Delete = {
  __typename?: 'MediaFields_sizes_tablet_filename_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Filename_Read = {
  __typename?: 'MediaFields_sizes_tablet_filename_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Filename_Update = {
  __typename?: 'MediaFields_sizes_tablet_filename_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Filesize = {
  __typename?: 'MediaFields_sizes_tablet_filesize';
  create?: Maybe<MediaFields_Sizes_Tablet_Filesize_Create>;
  delete?: Maybe<MediaFields_Sizes_Tablet_Filesize_Delete>;
  read?: Maybe<MediaFields_Sizes_Tablet_Filesize_Read>;
  update?: Maybe<MediaFields_Sizes_Tablet_Filesize_Update>;
};

export type MediaFields_Sizes_Tablet_Filesize_Create = {
  __typename?: 'MediaFields_sizes_tablet_filesize_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Filesize_Delete = {
  __typename?: 'MediaFields_sizes_tablet_filesize_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Filesize_Read = {
  __typename?: 'MediaFields_sizes_tablet_filesize_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Filesize_Update = {
  __typename?: 'MediaFields_sizes_tablet_filesize_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Height = {
  __typename?: 'MediaFields_sizes_tablet_height';
  create?: Maybe<MediaFields_Sizes_Tablet_Height_Create>;
  delete?: Maybe<MediaFields_Sizes_Tablet_Height_Delete>;
  read?: Maybe<MediaFields_Sizes_Tablet_Height_Read>;
  update?: Maybe<MediaFields_Sizes_Tablet_Height_Update>;
};

export type MediaFields_Sizes_Tablet_Height_Create = {
  __typename?: 'MediaFields_sizes_tablet_height_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Height_Delete = {
  __typename?: 'MediaFields_sizes_tablet_height_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Height_Read = {
  __typename?: 'MediaFields_sizes_tablet_height_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Height_Update = {
  __typename?: 'MediaFields_sizes_tablet_height_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_MimeType = {
  __typename?: 'MediaFields_sizes_tablet_mimeType';
  create?: Maybe<MediaFields_Sizes_Tablet_MimeType_Create>;
  delete?: Maybe<MediaFields_Sizes_Tablet_MimeType_Delete>;
  read?: Maybe<MediaFields_Sizes_Tablet_MimeType_Read>;
  update?: Maybe<MediaFields_Sizes_Tablet_MimeType_Update>;
};

export type MediaFields_Sizes_Tablet_MimeType_Create = {
  __typename?: 'MediaFields_sizes_tablet_mimeType_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_MimeType_Delete = {
  __typename?: 'MediaFields_sizes_tablet_mimeType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_MimeType_Read = {
  __typename?: 'MediaFields_sizes_tablet_mimeType_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_MimeType_Update = {
  __typename?: 'MediaFields_sizes_tablet_mimeType_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Url = {
  __typename?: 'MediaFields_sizes_tablet_url';
  create?: Maybe<MediaFields_Sizes_Tablet_Url_Create>;
  delete?: Maybe<MediaFields_Sizes_Tablet_Url_Delete>;
  read?: Maybe<MediaFields_Sizes_Tablet_Url_Read>;
  update?: Maybe<MediaFields_Sizes_Tablet_Url_Update>;
};

export type MediaFields_Sizes_Tablet_Url_Create = {
  __typename?: 'MediaFields_sizes_tablet_url_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Url_Delete = {
  __typename?: 'MediaFields_sizes_tablet_url_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Url_Read = {
  __typename?: 'MediaFields_sizes_tablet_url_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Url_Update = {
  __typename?: 'MediaFields_sizes_tablet_url_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Width = {
  __typename?: 'MediaFields_sizes_tablet_width';
  create?: Maybe<MediaFields_Sizes_Tablet_Width_Create>;
  delete?: Maybe<MediaFields_Sizes_Tablet_Width_Delete>;
  read?: Maybe<MediaFields_Sizes_Tablet_Width_Read>;
  update?: Maybe<MediaFields_Sizes_Tablet_Width_Update>;
};

export type MediaFields_Sizes_Tablet_Width_Create = {
  __typename?: 'MediaFields_sizes_tablet_width_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Width_Delete = {
  __typename?: 'MediaFields_sizes_tablet_width_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Width_Read = {
  __typename?: 'MediaFields_sizes_tablet_width_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Tablet_Width_Update = {
  __typename?: 'MediaFields_sizes_tablet_width_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail = {
  __typename?: 'MediaFields_sizes_thumbnail';
  create?: Maybe<MediaFields_Sizes_Thumbnail_Create>;
  delete?: Maybe<MediaFields_Sizes_Thumbnail_Delete>;
  fields?: Maybe<MediaFields_Sizes_Thumbnail_Fields>;
  read?: Maybe<MediaFields_Sizes_Thumbnail_Read>;
  update?: Maybe<MediaFields_Sizes_Thumbnail_Update>;
};

export type MediaFields_Sizes_Thumbnail_Create = {
  __typename?: 'MediaFields_sizes_thumbnail_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Delete = {
  __typename?: 'MediaFields_sizes_thumbnail_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Fields = {
  __typename?: 'MediaFields_sizes_thumbnail_Fields';
  filename?: Maybe<MediaFields_Sizes_Thumbnail_Filename>;
  filesize?: Maybe<MediaFields_Sizes_Thumbnail_Filesize>;
  height?: Maybe<MediaFields_Sizes_Thumbnail_Height>;
  mimeType?: Maybe<MediaFields_Sizes_Thumbnail_MimeType>;
  url?: Maybe<MediaFields_Sizes_Thumbnail_Url>;
  width?: Maybe<MediaFields_Sizes_Thumbnail_Width>;
};

export type MediaFields_Sizes_Thumbnail_Read = {
  __typename?: 'MediaFields_sizes_thumbnail_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Update = {
  __typename?: 'MediaFields_sizes_thumbnail_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Filename = {
  __typename?: 'MediaFields_sizes_thumbnail_filename';
  create?: Maybe<MediaFields_Sizes_Thumbnail_Filename_Create>;
  delete?: Maybe<MediaFields_Sizes_Thumbnail_Filename_Delete>;
  read?: Maybe<MediaFields_Sizes_Thumbnail_Filename_Read>;
  update?: Maybe<MediaFields_Sizes_Thumbnail_Filename_Update>;
};

export type MediaFields_Sizes_Thumbnail_Filename_Create = {
  __typename?: 'MediaFields_sizes_thumbnail_filename_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Filename_Delete = {
  __typename?: 'MediaFields_sizes_thumbnail_filename_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Filename_Read = {
  __typename?: 'MediaFields_sizes_thumbnail_filename_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Filename_Update = {
  __typename?: 'MediaFields_sizes_thumbnail_filename_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Filesize = {
  __typename?: 'MediaFields_sizes_thumbnail_filesize';
  create?: Maybe<MediaFields_Sizes_Thumbnail_Filesize_Create>;
  delete?: Maybe<MediaFields_Sizes_Thumbnail_Filesize_Delete>;
  read?: Maybe<MediaFields_Sizes_Thumbnail_Filesize_Read>;
  update?: Maybe<MediaFields_Sizes_Thumbnail_Filesize_Update>;
};

export type MediaFields_Sizes_Thumbnail_Filesize_Create = {
  __typename?: 'MediaFields_sizes_thumbnail_filesize_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Filesize_Delete = {
  __typename?: 'MediaFields_sizes_thumbnail_filesize_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Filesize_Read = {
  __typename?: 'MediaFields_sizes_thumbnail_filesize_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Filesize_Update = {
  __typename?: 'MediaFields_sizes_thumbnail_filesize_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Height = {
  __typename?: 'MediaFields_sizes_thumbnail_height';
  create?: Maybe<MediaFields_Sizes_Thumbnail_Height_Create>;
  delete?: Maybe<MediaFields_Sizes_Thumbnail_Height_Delete>;
  read?: Maybe<MediaFields_Sizes_Thumbnail_Height_Read>;
  update?: Maybe<MediaFields_Sizes_Thumbnail_Height_Update>;
};

export type MediaFields_Sizes_Thumbnail_Height_Create = {
  __typename?: 'MediaFields_sizes_thumbnail_height_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Height_Delete = {
  __typename?: 'MediaFields_sizes_thumbnail_height_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Height_Read = {
  __typename?: 'MediaFields_sizes_thumbnail_height_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Height_Update = {
  __typename?: 'MediaFields_sizes_thumbnail_height_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_MimeType = {
  __typename?: 'MediaFields_sizes_thumbnail_mimeType';
  create?: Maybe<MediaFields_Sizes_Thumbnail_MimeType_Create>;
  delete?: Maybe<MediaFields_Sizes_Thumbnail_MimeType_Delete>;
  read?: Maybe<MediaFields_Sizes_Thumbnail_MimeType_Read>;
  update?: Maybe<MediaFields_Sizes_Thumbnail_MimeType_Update>;
};

export type MediaFields_Sizes_Thumbnail_MimeType_Create = {
  __typename?: 'MediaFields_sizes_thumbnail_mimeType_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_MimeType_Delete = {
  __typename?: 'MediaFields_sizes_thumbnail_mimeType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_MimeType_Read = {
  __typename?: 'MediaFields_sizes_thumbnail_mimeType_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_MimeType_Update = {
  __typename?: 'MediaFields_sizes_thumbnail_mimeType_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Url = {
  __typename?: 'MediaFields_sizes_thumbnail_url';
  create?: Maybe<MediaFields_Sizes_Thumbnail_Url_Create>;
  delete?: Maybe<MediaFields_Sizes_Thumbnail_Url_Delete>;
  read?: Maybe<MediaFields_Sizes_Thumbnail_Url_Read>;
  update?: Maybe<MediaFields_Sizes_Thumbnail_Url_Update>;
};

export type MediaFields_Sizes_Thumbnail_Url_Create = {
  __typename?: 'MediaFields_sizes_thumbnail_url_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Url_Delete = {
  __typename?: 'MediaFields_sizes_thumbnail_url_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Url_Read = {
  __typename?: 'MediaFields_sizes_thumbnail_url_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Url_Update = {
  __typename?: 'MediaFields_sizes_thumbnail_url_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Width = {
  __typename?: 'MediaFields_sizes_thumbnail_width';
  create?: Maybe<MediaFields_Sizes_Thumbnail_Width_Create>;
  delete?: Maybe<MediaFields_Sizes_Thumbnail_Width_Delete>;
  read?: Maybe<MediaFields_Sizes_Thumbnail_Width_Read>;
  update?: Maybe<MediaFields_Sizes_Thumbnail_Width_Update>;
};

export type MediaFields_Sizes_Thumbnail_Width_Create = {
  __typename?: 'MediaFields_sizes_thumbnail_width_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Width_Delete = {
  __typename?: 'MediaFields_sizes_thumbnail_width_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Width_Read = {
  __typename?: 'MediaFields_sizes_thumbnail_width_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Sizes_Thumbnail_Width_Update = {
  __typename?: 'MediaFields_sizes_thumbnail_width_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Tenant = {
  __typename?: 'MediaFields_tenant';
  create?: Maybe<MediaFields_Tenant_Create>;
  delete?: Maybe<MediaFields_Tenant_Delete>;
  read?: Maybe<MediaFields_Tenant_Read>;
  update?: Maybe<MediaFields_Tenant_Update>;
};

export type MediaFields_Tenant_Create = {
  __typename?: 'MediaFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Tenant_Delete = {
  __typename?: 'MediaFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Tenant_Read = {
  __typename?: 'MediaFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Tenant_Update = {
  __typename?: 'MediaFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_UpdatedAt = {
  __typename?: 'MediaFields_updatedAt';
  create?: Maybe<MediaFields_UpdatedAt_Create>;
  delete?: Maybe<MediaFields_UpdatedAt_Delete>;
  read?: Maybe<MediaFields_UpdatedAt_Read>;
  update?: Maybe<MediaFields_UpdatedAt_Update>;
};

export type MediaFields_UpdatedAt_Create = {
  __typename?: 'MediaFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_UpdatedAt_Delete = {
  __typename?: 'MediaFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_UpdatedAt_Read = {
  __typename?: 'MediaFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_UpdatedAt_Update = {
  __typename?: 'MediaFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Url = {
  __typename?: 'MediaFields_url';
  create?: Maybe<MediaFields_Url_Create>;
  delete?: Maybe<MediaFields_Url_Delete>;
  read?: Maybe<MediaFields_Url_Read>;
  update?: Maybe<MediaFields_Url_Update>;
};

export type MediaFields_Url_Create = {
  __typename?: 'MediaFields_url_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Url_Delete = {
  __typename?: 'MediaFields_url_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Url_Read = {
  __typename?: 'MediaFields_url_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Url_Update = {
  __typename?: 'MediaFields_url_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Width = {
  __typename?: 'MediaFields_width';
  create?: Maybe<MediaFields_Width_Create>;
  delete?: Maybe<MediaFields_Width_Delete>;
  read?: Maybe<MediaFields_Width_Read>;
  update?: Maybe<MediaFields_Width_Update>;
};

export type MediaFields_Width_Create = {
  __typename?: 'MediaFields_width_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Width_Delete = {
  __typename?: 'MediaFields_width_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Width_Read = {
  __typename?: 'MediaFields_width_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Width_Update = {
  __typename?: 'MediaFields_width_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaReadAccess = {
  __typename?: 'MediaReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaReadDocAccess = {
  __typename?: 'MediaReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaUpdateAccess = {
  __typename?: 'MediaUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaUpdateDocAccess = {
  __typename?: 'MediaUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type Media_Sizes = {
  __typename?: 'Media_Sizes';
  card?: Maybe<Media_Sizes_Card>;
  tablet?: Maybe<Media_Sizes_Tablet>;
  thumbnail?: Maybe<Media_Sizes_Thumbnail>;
};

export type Media_Sizes_Card = {
  __typename?: 'Media_Sizes_Card';
  filename?: Maybe<Scalars['String']['output']>;
  filesize?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type Media_Sizes_Tablet = {
  __typename?: 'Media_Sizes_Tablet';
  filename?: Maybe<Scalars['String']['output']>;
  filesize?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type Media_Sizes_Thumbnail = {
  __typename?: 'Media_Sizes_Thumbnail';
  filename?: Maybe<Scalars['String']['output']>;
  filesize?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type Media_Alt_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Media_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Media_Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Media_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Sizes__Card__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Sizes__Card__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Media_Sizes__Card__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Media_Sizes__Card__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Sizes__Card__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Sizes__Card__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Media_Sizes__Tablet__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Sizes__Tablet__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Media_Sizes__Tablet__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Media_Sizes__Tablet__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Sizes__Tablet__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Sizes__Tablet__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Media_Sizes__Thumbnail__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Sizes__Thumbnail__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Media_Sizes__Thumbnail__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Media_Sizes__Thumbnail__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Sizes__Thumbnail__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Sizes__Thumbnail__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Media_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Media_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Media_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Where = {
  AND?: InputMaybe<Array<InputMaybe<Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Media_Where_Or>>>;
  alt?: InputMaybe<Media_Alt_Operator>;
  createdAt?: InputMaybe<Media_CreatedAt_Operator>;
  filename?: InputMaybe<Media_Filename_Operator>;
  filesize?: InputMaybe<Media_Filesize_Operator>;
  height?: InputMaybe<Media_Height_Operator>;
  id?: InputMaybe<Media_Id_Operator>;
  mimeType?: InputMaybe<Media_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<Media_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<Media_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<Media_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<Media_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<Media_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<Media_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<Media_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<Media_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<Media_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<Media_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<Media_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<Media_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<Media_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<Media_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<Media_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<Media_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<Media_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<Media_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<Media_Tenant_Operator>;
  updatedAt?: InputMaybe<Media_UpdatedAt_Operator>;
  url?: InputMaybe<Media_Url_Operator>;
  width?: InputMaybe<Media_Width_Operator>;
};

export type Media_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Media_Where_Or>>>;
  alt?: InputMaybe<Media_Alt_Operator>;
  createdAt?: InputMaybe<Media_CreatedAt_Operator>;
  filename?: InputMaybe<Media_Filename_Operator>;
  filesize?: InputMaybe<Media_Filesize_Operator>;
  height?: InputMaybe<Media_Height_Operator>;
  id?: InputMaybe<Media_Id_Operator>;
  mimeType?: InputMaybe<Media_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<Media_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<Media_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<Media_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<Media_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<Media_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<Media_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<Media_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<Media_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<Media_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<Media_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<Media_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<Media_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<Media_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<Media_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<Media_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<Media_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<Media_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<Media_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<Media_Tenant_Operator>;
  updatedAt?: InputMaybe<Media_UpdatedAt_Operator>;
  url?: InputMaybe<Media_Url_Operator>;
  width?: InputMaybe<Media_Width_Operator>;
};

export type Media_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Media_Where_Or>>>;
  alt?: InputMaybe<Media_Alt_Operator>;
  createdAt?: InputMaybe<Media_CreatedAt_Operator>;
  filename?: InputMaybe<Media_Filename_Operator>;
  filesize?: InputMaybe<Media_Filesize_Operator>;
  height?: InputMaybe<Media_Height_Operator>;
  id?: InputMaybe<Media_Id_Operator>;
  mimeType?: InputMaybe<Media_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<Media_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<Media_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<Media_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<Media_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<Media_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<Media_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<Media_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<Media_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<Media_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<Media_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<Media_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<Media_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<Media_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<Media_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<Media_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<Media_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<Media_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<Media_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<Media_Tenant_Operator>;
  updatedAt?: InputMaybe<Media_UpdatedAt_Operator>;
  url?: InputMaybe<Media_Url_Operator>;
  width?: InputMaybe<Media_Width_Operator>;
};

export type Media_Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Message = {
  __typename?: 'Message';
  content: Scalars['JSON']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  messageType: Message_MessageType;
  recipients: Array<Message_Recipients_Relationship>;
  subject: Scalars['String']['output'];
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type MessageContentArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
};


export type MessageRecipientsArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MessageTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type MessageUpdate_RecipientsRelationshipInput = {
  relationTo?: InputMaybe<MessageUpdate_RecipientsRelationshipInputRelationTo>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export enum MessageUpdate_RecipientsRelationshipInputRelationTo {
  Contacts = 'contacts',
  Events = 'events'
}

export enum MessageUpdate_MessageType_MutationInput {
  Email = 'EMAIL'
}

export type Message_Recipients = Contact | Event;

export type Message_RecipientsRelationshipInput = {
  relationTo?: InputMaybe<Message_RecipientsRelationshipInputRelationTo>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export enum Message_RecipientsRelationshipInputRelationTo {
  Contacts = 'contacts',
  Events = 'events'
}

export enum Message_Recipients_RelationTo {
  Contacts = 'contacts',
  Events = 'events'
}

export type Message_Recipients_Relationship = {
  __typename?: 'Message_Recipients_Relationship';
  relationTo?: Maybe<Message_Recipients_RelationTo>;
  value?: Maybe<Message_Recipients>;
};

export type Message_Content_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type Message_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Message_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum Message_MessageType {
  Email = 'EMAIL'
}

export enum Message_MessageType_Input {
  Email = 'EMAIL'
}

export enum Message_MessageType_MutationInput {
  Email = 'EMAIL'
}

export type Message_MessageType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Message_MessageType_Input>>>;
  equals?: InputMaybe<Message_MessageType_Input>;
  in?: InputMaybe<Array<InputMaybe<Message_MessageType_Input>>>;
  not_equals?: InputMaybe<Message_MessageType_Input>;
  not_in?: InputMaybe<Array<InputMaybe<Message_MessageType_Input>>>;
};

export type Message_Recipients_Relation = {
  relationTo?: InputMaybe<Message_Recipients_Relation_RelationTo>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export enum Message_Recipients_Relation_RelationTo {
  Contacts = 'contacts',
  Events = 'events'
}

export type Message_Subject_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Message_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Message_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Message_Where = {
  AND?: InputMaybe<Array<InputMaybe<Message_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Message_Where_Or>>>;
  content?: InputMaybe<Message_Content_Operator>;
  createdAt?: InputMaybe<Message_CreatedAt_Operator>;
  id?: InputMaybe<Message_Id_Operator>;
  messageType?: InputMaybe<Message_MessageType_Operator>;
  recipients?: InputMaybe<Message_Recipients_Relation>;
  subject?: InputMaybe<Message_Subject_Operator>;
  tenant?: InputMaybe<Message_Tenant_Operator>;
  updatedAt?: InputMaybe<Message_UpdatedAt_Operator>;
};

export type Message_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Message_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Message_Where_Or>>>;
  content?: InputMaybe<Message_Content_Operator>;
  createdAt?: InputMaybe<Message_CreatedAt_Operator>;
  id?: InputMaybe<Message_Id_Operator>;
  messageType?: InputMaybe<Message_MessageType_Operator>;
  recipients?: InputMaybe<Message_Recipients_Relation>;
  subject?: InputMaybe<Message_Subject_Operator>;
  tenant?: InputMaybe<Message_Tenant_Operator>;
  updatedAt?: InputMaybe<Message_UpdatedAt_Operator>;
};

export type Message_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Message_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Message_Where_Or>>>;
  content?: InputMaybe<Message_Content_Operator>;
  createdAt?: InputMaybe<Message_CreatedAt_Operator>;
  id?: InputMaybe<Message_Id_Operator>;
  messageType?: InputMaybe<Message_MessageType_Operator>;
  recipients?: InputMaybe<Message_Recipients_Relation>;
  subject?: InputMaybe<Message_Subject_Operator>;
  tenant?: InputMaybe<Message_Tenant_Operator>;
  updatedAt?: InputMaybe<Message_UpdatedAt_Operator>;
};

export type Messages = {
  __typename?: 'Messages';
  docs?: Maybe<Array<Maybe<Message>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type MessagesCreateAccess = {
  __typename?: 'MessagesCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MessagesCreateDocAccess = {
  __typename?: 'MessagesCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MessagesDeleteAccess = {
  __typename?: 'MessagesDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MessagesDeleteDocAccess = {
  __typename?: 'MessagesDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MessagesDocAccessFields = {
  __typename?: 'MessagesDocAccessFields';
  content?: Maybe<MessagesDocAccessFields_Content>;
  createdAt?: Maybe<MessagesDocAccessFields_CreatedAt>;
  messageType?: Maybe<MessagesDocAccessFields_MessageType>;
  recipients?: Maybe<MessagesDocAccessFields_Recipients>;
  subject?: Maybe<MessagesDocAccessFields_Subject>;
  tenant?: Maybe<MessagesDocAccessFields_Tenant>;
  updatedAt?: Maybe<MessagesDocAccessFields_UpdatedAt>;
};

export type MessagesDocAccessFields_Content = {
  __typename?: 'MessagesDocAccessFields_content';
  create?: Maybe<MessagesDocAccessFields_Content_Create>;
  delete?: Maybe<MessagesDocAccessFields_Content_Delete>;
  read?: Maybe<MessagesDocAccessFields_Content_Read>;
  update?: Maybe<MessagesDocAccessFields_Content_Update>;
};

export type MessagesDocAccessFields_Content_Create = {
  __typename?: 'MessagesDocAccessFields_content_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Content_Delete = {
  __typename?: 'MessagesDocAccessFields_content_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Content_Read = {
  __typename?: 'MessagesDocAccessFields_content_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Content_Update = {
  __typename?: 'MessagesDocAccessFields_content_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_CreatedAt = {
  __typename?: 'MessagesDocAccessFields_createdAt';
  create?: Maybe<MessagesDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<MessagesDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<MessagesDocAccessFields_CreatedAt_Read>;
  update?: Maybe<MessagesDocAccessFields_CreatedAt_Update>;
};

export type MessagesDocAccessFields_CreatedAt_Create = {
  __typename?: 'MessagesDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_CreatedAt_Delete = {
  __typename?: 'MessagesDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_CreatedAt_Read = {
  __typename?: 'MessagesDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_CreatedAt_Update = {
  __typename?: 'MessagesDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_MessageType = {
  __typename?: 'MessagesDocAccessFields_messageType';
  create?: Maybe<MessagesDocAccessFields_MessageType_Create>;
  delete?: Maybe<MessagesDocAccessFields_MessageType_Delete>;
  read?: Maybe<MessagesDocAccessFields_MessageType_Read>;
  update?: Maybe<MessagesDocAccessFields_MessageType_Update>;
};

export type MessagesDocAccessFields_MessageType_Create = {
  __typename?: 'MessagesDocAccessFields_messageType_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_MessageType_Delete = {
  __typename?: 'MessagesDocAccessFields_messageType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_MessageType_Read = {
  __typename?: 'MessagesDocAccessFields_messageType_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_MessageType_Update = {
  __typename?: 'MessagesDocAccessFields_messageType_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Recipients = {
  __typename?: 'MessagesDocAccessFields_recipients';
  create?: Maybe<MessagesDocAccessFields_Recipients_Create>;
  delete?: Maybe<MessagesDocAccessFields_Recipients_Delete>;
  read?: Maybe<MessagesDocAccessFields_Recipients_Read>;
  update?: Maybe<MessagesDocAccessFields_Recipients_Update>;
};

export type MessagesDocAccessFields_Recipients_Create = {
  __typename?: 'MessagesDocAccessFields_recipients_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Recipients_Delete = {
  __typename?: 'MessagesDocAccessFields_recipients_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Recipients_Read = {
  __typename?: 'MessagesDocAccessFields_recipients_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Recipients_Update = {
  __typename?: 'MessagesDocAccessFields_recipients_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Subject = {
  __typename?: 'MessagesDocAccessFields_subject';
  create?: Maybe<MessagesDocAccessFields_Subject_Create>;
  delete?: Maybe<MessagesDocAccessFields_Subject_Delete>;
  read?: Maybe<MessagesDocAccessFields_Subject_Read>;
  update?: Maybe<MessagesDocAccessFields_Subject_Update>;
};

export type MessagesDocAccessFields_Subject_Create = {
  __typename?: 'MessagesDocAccessFields_subject_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Subject_Delete = {
  __typename?: 'MessagesDocAccessFields_subject_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Subject_Read = {
  __typename?: 'MessagesDocAccessFields_subject_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Subject_Update = {
  __typename?: 'MessagesDocAccessFields_subject_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Tenant = {
  __typename?: 'MessagesDocAccessFields_tenant';
  create?: Maybe<MessagesDocAccessFields_Tenant_Create>;
  delete?: Maybe<MessagesDocAccessFields_Tenant_Delete>;
  read?: Maybe<MessagesDocAccessFields_Tenant_Read>;
  update?: Maybe<MessagesDocAccessFields_Tenant_Update>;
};

export type MessagesDocAccessFields_Tenant_Create = {
  __typename?: 'MessagesDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Tenant_Delete = {
  __typename?: 'MessagesDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Tenant_Read = {
  __typename?: 'MessagesDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_Tenant_Update = {
  __typename?: 'MessagesDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_UpdatedAt = {
  __typename?: 'MessagesDocAccessFields_updatedAt';
  create?: Maybe<MessagesDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<MessagesDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<MessagesDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<MessagesDocAccessFields_UpdatedAt_Update>;
};

export type MessagesDocAccessFields_UpdatedAt_Create = {
  __typename?: 'MessagesDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'MessagesDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_UpdatedAt_Read = {
  __typename?: 'MessagesDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesDocAccessFields_UpdatedAt_Update = {
  __typename?: 'MessagesDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields = {
  __typename?: 'MessagesFields';
  content?: Maybe<MessagesFields_Content>;
  createdAt?: Maybe<MessagesFields_CreatedAt>;
  messageType?: Maybe<MessagesFields_MessageType>;
  recipients?: Maybe<MessagesFields_Recipients>;
  subject?: Maybe<MessagesFields_Subject>;
  tenant?: Maybe<MessagesFields_Tenant>;
  updatedAt?: Maybe<MessagesFields_UpdatedAt>;
};

export type MessagesFields_Content = {
  __typename?: 'MessagesFields_content';
  create?: Maybe<MessagesFields_Content_Create>;
  delete?: Maybe<MessagesFields_Content_Delete>;
  read?: Maybe<MessagesFields_Content_Read>;
  update?: Maybe<MessagesFields_Content_Update>;
};

export type MessagesFields_Content_Create = {
  __typename?: 'MessagesFields_content_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Content_Delete = {
  __typename?: 'MessagesFields_content_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Content_Read = {
  __typename?: 'MessagesFields_content_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Content_Update = {
  __typename?: 'MessagesFields_content_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_CreatedAt = {
  __typename?: 'MessagesFields_createdAt';
  create?: Maybe<MessagesFields_CreatedAt_Create>;
  delete?: Maybe<MessagesFields_CreatedAt_Delete>;
  read?: Maybe<MessagesFields_CreatedAt_Read>;
  update?: Maybe<MessagesFields_CreatedAt_Update>;
};

export type MessagesFields_CreatedAt_Create = {
  __typename?: 'MessagesFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_CreatedAt_Delete = {
  __typename?: 'MessagesFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_CreatedAt_Read = {
  __typename?: 'MessagesFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_CreatedAt_Update = {
  __typename?: 'MessagesFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_MessageType = {
  __typename?: 'MessagesFields_messageType';
  create?: Maybe<MessagesFields_MessageType_Create>;
  delete?: Maybe<MessagesFields_MessageType_Delete>;
  read?: Maybe<MessagesFields_MessageType_Read>;
  update?: Maybe<MessagesFields_MessageType_Update>;
};

export type MessagesFields_MessageType_Create = {
  __typename?: 'MessagesFields_messageType_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_MessageType_Delete = {
  __typename?: 'MessagesFields_messageType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_MessageType_Read = {
  __typename?: 'MessagesFields_messageType_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_MessageType_Update = {
  __typename?: 'MessagesFields_messageType_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Recipients = {
  __typename?: 'MessagesFields_recipients';
  create?: Maybe<MessagesFields_Recipients_Create>;
  delete?: Maybe<MessagesFields_Recipients_Delete>;
  read?: Maybe<MessagesFields_Recipients_Read>;
  update?: Maybe<MessagesFields_Recipients_Update>;
};

export type MessagesFields_Recipients_Create = {
  __typename?: 'MessagesFields_recipients_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Recipients_Delete = {
  __typename?: 'MessagesFields_recipients_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Recipients_Read = {
  __typename?: 'MessagesFields_recipients_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Recipients_Update = {
  __typename?: 'MessagesFields_recipients_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Subject = {
  __typename?: 'MessagesFields_subject';
  create?: Maybe<MessagesFields_Subject_Create>;
  delete?: Maybe<MessagesFields_Subject_Delete>;
  read?: Maybe<MessagesFields_Subject_Read>;
  update?: Maybe<MessagesFields_Subject_Update>;
};

export type MessagesFields_Subject_Create = {
  __typename?: 'MessagesFields_subject_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Subject_Delete = {
  __typename?: 'MessagesFields_subject_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Subject_Read = {
  __typename?: 'MessagesFields_subject_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Subject_Update = {
  __typename?: 'MessagesFields_subject_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Tenant = {
  __typename?: 'MessagesFields_tenant';
  create?: Maybe<MessagesFields_Tenant_Create>;
  delete?: Maybe<MessagesFields_Tenant_Delete>;
  read?: Maybe<MessagesFields_Tenant_Read>;
  update?: Maybe<MessagesFields_Tenant_Update>;
};

export type MessagesFields_Tenant_Create = {
  __typename?: 'MessagesFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Tenant_Delete = {
  __typename?: 'MessagesFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Tenant_Read = {
  __typename?: 'MessagesFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_Tenant_Update = {
  __typename?: 'MessagesFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_UpdatedAt = {
  __typename?: 'MessagesFields_updatedAt';
  create?: Maybe<MessagesFields_UpdatedAt_Create>;
  delete?: Maybe<MessagesFields_UpdatedAt_Delete>;
  read?: Maybe<MessagesFields_UpdatedAt_Read>;
  update?: Maybe<MessagesFields_UpdatedAt_Update>;
};

export type MessagesFields_UpdatedAt_Create = {
  __typename?: 'MessagesFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_UpdatedAt_Delete = {
  __typename?: 'MessagesFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_UpdatedAt_Read = {
  __typename?: 'MessagesFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MessagesFields_UpdatedAt_Update = {
  __typename?: 'MessagesFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MessagesReadAccess = {
  __typename?: 'MessagesReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MessagesReadDocAccess = {
  __typename?: 'MessagesReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MessagesUpdateAccess = {
  __typename?: 'MessagesUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MessagesUpdateDocAccess = {
  __typename?: 'MessagesUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBooking?: Maybe<StringOrError>;
  createCheckout?: Maybe<Checkout>;
  createCompanyDetailsGlobal?: Maybe<CompanyDetailsGlobal>;
  createContact?: Maybe<Contact>;
  createEvent?: Maybe<Event>;
  createExtra?: Maybe<Extra>;
  createGoogleAuthLink?: Maybe<Scalars['String']['output']>;
  createIntegrationsGlobal?: Maybe<IntegrationsGlobal>;
  createMedia?: Maybe<Media>;
  createMessage?: Maybe<Message>;
  createOutgoingMessage?: Maybe<OutgoingMessage>;
  createPayloadPreference?: Maybe<PayloadPreference>;
  createPaymentAccountLink?: Maybe<Scalars['String']['output']>;
  createPaymentDetailsGlobal?: Maybe<PaymentDetailsGlobal>;
  createService?: Maybe<Service>;
  createTenant?: Maybe<Tenant>;
  createTicketType?: Maybe<TicketType>;
  createUser?: Maybe<User>;
  createVoucher?: Maybe<Voucher>;
  createVoucherCheckout?: Maybe<StringOrError>;
  deleteBooking?: Maybe<Booking>;
  deleteCheckout?: Maybe<Checkout>;
  deleteCompanyDetailsGlobal?: Maybe<CompanyDetailsGlobal>;
  deleteContact?: Maybe<Contact>;
  deleteEvent?: Maybe<Event>;
  deleteExtra?: Maybe<Extra>;
  deleteIntegrationsGlobal?: Maybe<IntegrationsGlobal>;
  deleteMedia?: Maybe<Media>;
  deleteMessage?: Maybe<Message>;
  deleteOutgoingMessage?: Maybe<OutgoingMessage>;
  deletePayloadPreference?: Maybe<PayloadPreference>;
  deletePaymentDetailsGlobal?: Maybe<PaymentDetailsGlobal>;
  deleteService?: Maybe<Service>;
  deleteTenant?: Maybe<Tenant>;
  deleteTicketType?: Maybe<TicketType>;
  deleteUser?: Maybe<User>;
  deleteVoucher?: Maybe<Voucher>;
  forgotPasswordUser: Scalars['Boolean']['output'];
  loginUser?: Maybe<UsersLoginResult>;
  logoutUser?: Maybe<Scalars['String']['output']>;
  refreshTokenUser?: Maybe<UsersRefreshedUser>;
  registerServiceProvider?: Maybe<StringOrError>;
  resetPasswordUser?: Maybe<UsersResetPassword>;
  setAdminUserTenant?: Maybe<StringOrError>;
  unlockUser: Scalars['Boolean']['output'];
  updateBooking?: Maybe<Booking>;
  updateCheckout?: Maybe<Checkout>;
  updateCompanyDetail?: Maybe<CompanyDetail>;
  updateCompanyDetailsGlobal?: Maybe<CompanyDetailsGlobal>;
  updateContact?: Maybe<Contact>;
  updateEvent?: Maybe<Event>;
  updateExtra?: Maybe<Extra>;
  updateIntegration?: Maybe<Integration>;
  updateIntegrationsGlobal?: Maybe<IntegrationsGlobal>;
  updateMedia?: Maybe<Media>;
  updateMessage?: Maybe<Message>;
  updateOutgoingMessage?: Maybe<OutgoingMessage>;
  updatePayloadPreference?: Maybe<PayloadPreference>;
  updatePaymentDetail?: Maybe<PaymentDetail>;
  updatePaymentDetailsGlobal?: Maybe<PaymentDetailsGlobal>;
  updateService?: Maybe<Service>;
  updateTenant?: Maybe<Tenant>;
  updateTicketType?: Maybe<TicketType>;
  updateUser?: Maybe<User>;
  updateVoucher?: Maybe<Voucher>;
  verifyEmailUser?: Maybe<Scalars['Boolean']['output']>;
};


export type MutationCreateBookingArgs = {
  input?: InputMaybe<CreateBookingInput>;
};


export type MutationCreateCheckoutArgs = {
  data: MutationCheckoutInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateCompanyDetailsGlobalArgs = {
  data: MutationCompanyDetailsGlobalInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateContactArgs = {
  data: MutationContactInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateEventArgs = {
  data: MutationEventInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateExtraArgs = {
  data: MutationExtraInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateIntegrationsGlobalArgs = {
  data: MutationIntegrationsGlobalInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateMediaArgs = {
  data: MutationMediaInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateMessageArgs = {
  data: MutationMessageInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateOutgoingMessageArgs = {
  data: MutationOutgoingMessageInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreatePayloadPreferenceArgs = {
  data: MutationPayloadPreferenceInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreatePaymentDetailsGlobalArgs = {
  data: MutationPaymentDetailsGlobalInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateServiceArgs = {
  data: MutationServiceInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateTenantArgs = {
  data: MutationTenantInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateTicketTypeArgs = {
  data: MutationTicketTypeInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateUserArgs = {
  data: MutationUserInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateVoucherArgs = {
  data: MutationVoucherInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationCreateVoucherCheckoutArgs = {
  input?: InputMaybe<CreateVoucherCheckoutInput>;
};


export type MutationDeleteBookingArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCheckoutArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCompanyDetailsGlobalArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteContactArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteExtraArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteIntegrationsGlobalArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMediaArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMessageArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOutgoingMessageArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePayloadPreferenceArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePaymentDetailsGlobalArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteServiceArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTenantArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTicketTypeArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteVoucherArgs = {
  id: Scalars['String']['input'];
};


export type MutationForgotPasswordUserArgs = {
  disableEmail?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationLoginUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRefreshTokenUserArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterServiceProviderArgs = {
  input?: InputMaybe<ServiceProviderInput>;
};


export type MutationResetPasswordUserArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetAdminUserTenantArgs = {
  tenantId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUnlockUserArgs = {
  email: Scalars['String']['input'];
};


export type MutationUpdateBookingArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationBookingUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateCheckoutArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationCheckoutUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateCompanyDetailArgs = {
  data: MutationCompanyDetailInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateCompanyDetailsGlobalArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationCompanyDetailsGlobalUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateContactArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationContactUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateEventArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationEventUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateExtraArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationExtraUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateIntegrationArgs = {
  data: MutationIntegrationInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateIntegrationsGlobalArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationIntegrationsGlobalUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateMediaArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationMediaUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateMessageArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationMessageUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateOutgoingMessageArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationOutgoingMessageUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdatePayloadPreferenceArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationPayloadPreferenceUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdatePaymentDetailArgs = {
  data: MutationPaymentDetailInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdatePaymentDetailsGlobalArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationPaymentDetailsGlobalUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateServiceArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationServiceUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateTenantArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationTenantUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateTicketTypeArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationTicketTypeUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateUserArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationUserUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationUpdateVoucherArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationVoucherUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type MutationVerifyEmailUserArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingStepsResponse = {
  __typename?: 'OnboardingStepsResponse';
  addressFilledOut?: Maybe<Scalars['Boolean']['output']>;
  canAcceptPayments?: Maybe<Scalars['Boolean']['output']>;
  companyDetailsFilledOut?: Maybe<Scalars['Boolean']['output']>;
  serviceAdded?: Maybe<Scalars['Boolean']['output']>;
};

export type OnboardingStepsResponseOrError = Error | OnboardingStepsResponse;

export type OutgoingMessage = {
  __typename?: 'OutgoingMessage';
  content: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  messageType: OutgoingMessage_MessageType;
  recipient: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type OutgoingMessageTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export enum OutgoingMessageUpdate_MessageType_MutationInput {
  Email = 'EMAIL'
}

export type OutgoingMessage_Content_Operator = {
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type OutgoingMessage_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OutgoingMessage_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum OutgoingMessage_MessageType {
  Email = 'EMAIL'
}

export enum OutgoingMessage_MessageType_Input {
  Email = 'EMAIL'
}

export enum OutgoingMessage_MessageType_MutationInput {
  Email = 'EMAIL'
}

export type OutgoingMessage_MessageType_Operator = {
  all?: InputMaybe<Array<InputMaybe<OutgoingMessage_MessageType_Input>>>;
  equals?: InputMaybe<OutgoingMessage_MessageType_Input>;
  in?: InputMaybe<Array<InputMaybe<OutgoingMessage_MessageType_Input>>>;
  not_equals?: InputMaybe<OutgoingMessage_MessageType_Input>;
  not_in?: InputMaybe<Array<InputMaybe<OutgoingMessage_MessageType_Input>>>;
};

export type OutgoingMessage_Recipient_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OutgoingMessage_Subject_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OutgoingMessage_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type OutgoingMessage_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OutgoingMessage_Where = {
  AND?: InputMaybe<Array<InputMaybe<OutgoingMessage_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<OutgoingMessage_Where_Or>>>;
  content?: InputMaybe<OutgoingMessage_Content_Operator>;
  createdAt?: InputMaybe<OutgoingMessage_CreatedAt_Operator>;
  id?: InputMaybe<OutgoingMessage_Id_Operator>;
  messageType?: InputMaybe<OutgoingMessage_MessageType_Operator>;
  recipient?: InputMaybe<OutgoingMessage_Recipient_Operator>;
  subject?: InputMaybe<OutgoingMessage_Subject_Operator>;
  tenant?: InputMaybe<OutgoingMessage_Tenant_Operator>;
  updatedAt?: InputMaybe<OutgoingMessage_UpdatedAt_Operator>;
};

export type OutgoingMessage_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<OutgoingMessage_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<OutgoingMessage_Where_Or>>>;
  content?: InputMaybe<OutgoingMessage_Content_Operator>;
  createdAt?: InputMaybe<OutgoingMessage_CreatedAt_Operator>;
  id?: InputMaybe<OutgoingMessage_Id_Operator>;
  messageType?: InputMaybe<OutgoingMessage_MessageType_Operator>;
  recipient?: InputMaybe<OutgoingMessage_Recipient_Operator>;
  subject?: InputMaybe<OutgoingMessage_Subject_Operator>;
  tenant?: InputMaybe<OutgoingMessage_Tenant_Operator>;
  updatedAt?: InputMaybe<OutgoingMessage_UpdatedAt_Operator>;
};

export type OutgoingMessage_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<OutgoingMessage_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<OutgoingMessage_Where_Or>>>;
  content?: InputMaybe<OutgoingMessage_Content_Operator>;
  createdAt?: InputMaybe<OutgoingMessage_CreatedAt_Operator>;
  id?: InputMaybe<OutgoingMessage_Id_Operator>;
  messageType?: InputMaybe<OutgoingMessage_MessageType_Operator>;
  recipient?: InputMaybe<OutgoingMessage_Recipient_Operator>;
  subject?: InputMaybe<OutgoingMessage_Subject_Operator>;
  tenant?: InputMaybe<OutgoingMessage_Tenant_Operator>;
  updatedAt?: InputMaybe<OutgoingMessage_UpdatedAt_Operator>;
};

export type OutgoingMessages = {
  __typename?: 'OutgoingMessages';
  docs?: Maybe<Array<Maybe<OutgoingMessage>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type OutgoingMessagesCreateAccess = {
  __typename?: 'OutgoingMessagesCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type OutgoingMessagesCreateDocAccess = {
  __typename?: 'OutgoingMessagesCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type OutgoingMessagesDeleteAccess = {
  __typename?: 'OutgoingMessagesDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type OutgoingMessagesDeleteDocAccess = {
  __typename?: 'OutgoingMessagesDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type OutgoingMessagesDocAccessFields = {
  __typename?: 'OutgoingMessagesDocAccessFields';
  content?: Maybe<OutgoingMessagesDocAccessFields_Content>;
  createdAt?: Maybe<OutgoingMessagesDocAccessFields_CreatedAt>;
  messageType?: Maybe<OutgoingMessagesDocAccessFields_MessageType>;
  recipient?: Maybe<OutgoingMessagesDocAccessFields_Recipient>;
  subject?: Maybe<OutgoingMessagesDocAccessFields_Subject>;
  tenant?: Maybe<OutgoingMessagesDocAccessFields_Tenant>;
  updatedAt?: Maybe<OutgoingMessagesDocAccessFields_UpdatedAt>;
};

export type OutgoingMessagesDocAccessFields_Content = {
  __typename?: 'OutgoingMessagesDocAccessFields_content';
  create?: Maybe<OutgoingMessagesDocAccessFields_Content_Create>;
  delete?: Maybe<OutgoingMessagesDocAccessFields_Content_Delete>;
  read?: Maybe<OutgoingMessagesDocAccessFields_Content_Read>;
  update?: Maybe<OutgoingMessagesDocAccessFields_Content_Update>;
};

export type OutgoingMessagesDocAccessFields_Content_Create = {
  __typename?: 'OutgoingMessagesDocAccessFields_content_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Content_Delete = {
  __typename?: 'OutgoingMessagesDocAccessFields_content_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Content_Read = {
  __typename?: 'OutgoingMessagesDocAccessFields_content_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Content_Update = {
  __typename?: 'OutgoingMessagesDocAccessFields_content_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_CreatedAt = {
  __typename?: 'OutgoingMessagesDocAccessFields_createdAt';
  create?: Maybe<OutgoingMessagesDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<OutgoingMessagesDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<OutgoingMessagesDocAccessFields_CreatedAt_Read>;
  update?: Maybe<OutgoingMessagesDocAccessFields_CreatedAt_Update>;
};

export type OutgoingMessagesDocAccessFields_CreatedAt_Create = {
  __typename?: 'OutgoingMessagesDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_CreatedAt_Delete = {
  __typename?: 'OutgoingMessagesDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_CreatedAt_Read = {
  __typename?: 'OutgoingMessagesDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_CreatedAt_Update = {
  __typename?: 'OutgoingMessagesDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_MessageType = {
  __typename?: 'OutgoingMessagesDocAccessFields_messageType';
  create?: Maybe<OutgoingMessagesDocAccessFields_MessageType_Create>;
  delete?: Maybe<OutgoingMessagesDocAccessFields_MessageType_Delete>;
  read?: Maybe<OutgoingMessagesDocAccessFields_MessageType_Read>;
  update?: Maybe<OutgoingMessagesDocAccessFields_MessageType_Update>;
};

export type OutgoingMessagesDocAccessFields_MessageType_Create = {
  __typename?: 'OutgoingMessagesDocAccessFields_messageType_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_MessageType_Delete = {
  __typename?: 'OutgoingMessagesDocAccessFields_messageType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_MessageType_Read = {
  __typename?: 'OutgoingMessagesDocAccessFields_messageType_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_MessageType_Update = {
  __typename?: 'OutgoingMessagesDocAccessFields_messageType_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Recipient = {
  __typename?: 'OutgoingMessagesDocAccessFields_recipient';
  create?: Maybe<OutgoingMessagesDocAccessFields_Recipient_Create>;
  delete?: Maybe<OutgoingMessagesDocAccessFields_Recipient_Delete>;
  read?: Maybe<OutgoingMessagesDocAccessFields_Recipient_Read>;
  update?: Maybe<OutgoingMessagesDocAccessFields_Recipient_Update>;
};

export type OutgoingMessagesDocAccessFields_Recipient_Create = {
  __typename?: 'OutgoingMessagesDocAccessFields_recipient_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Recipient_Delete = {
  __typename?: 'OutgoingMessagesDocAccessFields_recipient_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Recipient_Read = {
  __typename?: 'OutgoingMessagesDocAccessFields_recipient_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Recipient_Update = {
  __typename?: 'OutgoingMessagesDocAccessFields_recipient_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Subject = {
  __typename?: 'OutgoingMessagesDocAccessFields_subject';
  create?: Maybe<OutgoingMessagesDocAccessFields_Subject_Create>;
  delete?: Maybe<OutgoingMessagesDocAccessFields_Subject_Delete>;
  read?: Maybe<OutgoingMessagesDocAccessFields_Subject_Read>;
  update?: Maybe<OutgoingMessagesDocAccessFields_Subject_Update>;
};

export type OutgoingMessagesDocAccessFields_Subject_Create = {
  __typename?: 'OutgoingMessagesDocAccessFields_subject_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Subject_Delete = {
  __typename?: 'OutgoingMessagesDocAccessFields_subject_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Subject_Read = {
  __typename?: 'OutgoingMessagesDocAccessFields_subject_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Subject_Update = {
  __typename?: 'OutgoingMessagesDocAccessFields_subject_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Tenant = {
  __typename?: 'OutgoingMessagesDocAccessFields_tenant';
  create?: Maybe<OutgoingMessagesDocAccessFields_Tenant_Create>;
  delete?: Maybe<OutgoingMessagesDocAccessFields_Tenant_Delete>;
  read?: Maybe<OutgoingMessagesDocAccessFields_Tenant_Read>;
  update?: Maybe<OutgoingMessagesDocAccessFields_Tenant_Update>;
};

export type OutgoingMessagesDocAccessFields_Tenant_Create = {
  __typename?: 'OutgoingMessagesDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Tenant_Delete = {
  __typename?: 'OutgoingMessagesDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Tenant_Read = {
  __typename?: 'OutgoingMessagesDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_Tenant_Update = {
  __typename?: 'OutgoingMessagesDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_UpdatedAt = {
  __typename?: 'OutgoingMessagesDocAccessFields_updatedAt';
  create?: Maybe<OutgoingMessagesDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<OutgoingMessagesDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<OutgoingMessagesDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<OutgoingMessagesDocAccessFields_UpdatedAt_Update>;
};

export type OutgoingMessagesDocAccessFields_UpdatedAt_Create = {
  __typename?: 'OutgoingMessagesDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'OutgoingMessagesDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_UpdatedAt_Read = {
  __typename?: 'OutgoingMessagesDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesDocAccessFields_UpdatedAt_Update = {
  __typename?: 'OutgoingMessagesDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields = {
  __typename?: 'OutgoingMessagesFields';
  content?: Maybe<OutgoingMessagesFields_Content>;
  createdAt?: Maybe<OutgoingMessagesFields_CreatedAt>;
  messageType?: Maybe<OutgoingMessagesFields_MessageType>;
  recipient?: Maybe<OutgoingMessagesFields_Recipient>;
  subject?: Maybe<OutgoingMessagesFields_Subject>;
  tenant?: Maybe<OutgoingMessagesFields_Tenant>;
  updatedAt?: Maybe<OutgoingMessagesFields_UpdatedAt>;
};

export type OutgoingMessagesFields_Content = {
  __typename?: 'OutgoingMessagesFields_content';
  create?: Maybe<OutgoingMessagesFields_Content_Create>;
  delete?: Maybe<OutgoingMessagesFields_Content_Delete>;
  read?: Maybe<OutgoingMessagesFields_Content_Read>;
  update?: Maybe<OutgoingMessagesFields_Content_Update>;
};

export type OutgoingMessagesFields_Content_Create = {
  __typename?: 'OutgoingMessagesFields_content_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Content_Delete = {
  __typename?: 'OutgoingMessagesFields_content_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Content_Read = {
  __typename?: 'OutgoingMessagesFields_content_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Content_Update = {
  __typename?: 'OutgoingMessagesFields_content_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_CreatedAt = {
  __typename?: 'OutgoingMessagesFields_createdAt';
  create?: Maybe<OutgoingMessagesFields_CreatedAt_Create>;
  delete?: Maybe<OutgoingMessagesFields_CreatedAt_Delete>;
  read?: Maybe<OutgoingMessagesFields_CreatedAt_Read>;
  update?: Maybe<OutgoingMessagesFields_CreatedAt_Update>;
};

export type OutgoingMessagesFields_CreatedAt_Create = {
  __typename?: 'OutgoingMessagesFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_CreatedAt_Delete = {
  __typename?: 'OutgoingMessagesFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_CreatedAt_Read = {
  __typename?: 'OutgoingMessagesFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_CreatedAt_Update = {
  __typename?: 'OutgoingMessagesFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_MessageType = {
  __typename?: 'OutgoingMessagesFields_messageType';
  create?: Maybe<OutgoingMessagesFields_MessageType_Create>;
  delete?: Maybe<OutgoingMessagesFields_MessageType_Delete>;
  read?: Maybe<OutgoingMessagesFields_MessageType_Read>;
  update?: Maybe<OutgoingMessagesFields_MessageType_Update>;
};

export type OutgoingMessagesFields_MessageType_Create = {
  __typename?: 'OutgoingMessagesFields_messageType_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_MessageType_Delete = {
  __typename?: 'OutgoingMessagesFields_messageType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_MessageType_Read = {
  __typename?: 'OutgoingMessagesFields_messageType_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_MessageType_Update = {
  __typename?: 'OutgoingMessagesFields_messageType_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Recipient = {
  __typename?: 'OutgoingMessagesFields_recipient';
  create?: Maybe<OutgoingMessagesFields_Recipient_Create>;
  delete?: Maybe<OutgoingMessagesFields_Recipient_Delete>;
  read?: Maybe<OutgoingMessagesFields_Recipient_Read>;
  update?: Maybe<OutgoingMessagesFields_Recipient_Update>;
};

export type OutgoingMessagesFields_Recipient_Create = {
  __typename?: 'OutgoingMessagesFields_recipient_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Recipient_Delete = {
  __typename?: 'OutgoingMessagesFields_recipient_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Recipient_Read = {
  __typename?: 'OutgoingMessagesFields_recipient_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Recipient_Update = {
  __typename?: 'OutgoingMessagesFields_recipient_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Subject = {
  __typename?: 'OutgoingMessagesFields_subject';
  create?: Maybe<OutgoingMessagesFields_Subject_Create>;
  delete?: Maybe<OutgoingMessagesFields_Subject_Delete>;
  read?: Maybe<OutgoingMessagesFields_Subject_Read>;
  update?: Maybe<OutgoingMessagesFields_Subject_Update>;
};

export type OutgoingMessagesFields_Subject_Create = {
  __typename?: 'OutgoingMessagesFields_subject_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Subject_Delete = {
  __typename?: 'OutgoingMessagesFields_subject_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Subject_Read = {
  __typename?: 'OutgoingMessagesFields_subject_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Subject_Update = {
  __typename?: 'OutgoingMessagesFields_subject_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Tenant = {
  __typename?: 'OutgoingMessagesFields_tenant';
  create?: Maybe<OutgoingMessagesFields_Tenant_Create>;
  delete?: Maybe<OutgoingMessagesFields_Tenant_Delete>;
  read?: Maybe<OutgoingMessagesFields_Tenant_Read>;
  update?: Maybe<OutgoingMessagesFields_Tenant_Update>;
};

export type OutgoingMessagesFields_Tenant_Create = {
  __typename?: 'OutgoingMessagesFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Tenant_Delete = {
  __typename?: 'OutgoingMessagesFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Tenant_Read = {
  __typename?: 'OutgoingMessagesFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_Tenant_Update = {
  __typename?: 'OutgoingMessagesFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_UpdatedAt = {
  __typename?: 'OutgoingMessagesFields_updatedAt';
  create?: Maybe<OutgoingMessagesFields_UpdatedAt_Create>;
  delete?: Maybe<OutgoingMessagesFields_UpdatedAt_Delete>;
  read?: Maybe<OutgoingMessagesFields_UpdatedAt_Read>;
  update?: Maybe<OutgoingMessagesFields_UpdatedAt_Update>;
};

export type OutgoingMessagesFields_UpdatedAt_Create = {
  __typename?: 'OutgoingMessagesFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_UpdatedAt_Delete = {
  __typename?: 'OutgoingMessagesFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_UpdatedAt_Read = {
  __typename?: 'OutgoingMessagesFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesFields_UpdatedAt_Update = {
  __typename?: 'OutgoingMessagesFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type OutgoingMessagesReadAccess = {
  __typename?: 'OutgoingMessagesReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type OutgoingMessagesReadDocAccess = {
  __typename?: 'OutgoingMessagesReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type OutgoingMessagesUpdateAccess = {
  __typename?: 'OutgoingMessagesUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type OutgoingMessagesUpdateDocAccess = {
  __typename?: 'OutgoingMessagesUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreference = {
  __typename?: 'PayloadPreference';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: PayloadPreference_User_Relationship;
  value?: Maybe<Scalars['JSON']['output']>;
};


export type PayloadPreferenceUserArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type PayloadPreferenceUpdate_UserRelationshipInput = {
  relationTo?: InputMaybe<PayloadPreferenceUpdate_UserRelationshipInputRelationTo>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export enum PayloadPreferenceUpdate_UserRelationshipInputRelationTo {
  Users = 'users'
}

export type PayloadPreference_User = User;

export type PayloadPreference_UserRelationshipInput = {
  relationTo?: InputMaybe<PayloadPreference_UserRelationshipInputRelationTo>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export enum PayloadPreference_UserRelationshipInputRelationTo {
  Users = 'users'
}

export enum PayloadPreference_User_RelationTo {
  Users = 'users'
}

export type PayloadPreference_User_Relationship = {
  __typename?: 'PayloadPreference_User_Relationship';
  relationTo?: Maybe<PayloadPreference_User_RelationTo>;
  value?: Maybe<PayloadPreference_User>;
};

export type PayloadPreference_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PayloadPreference_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PayloadPreference_Key_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PayloadPreference_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PayloadPreference_User_Relation = {
  relationTo?: InputMaybe<PayloadPreference_User_Relation_RelationTo>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export enum PayloadPreference_User_Relation_RelationTo {
  Users = 'users'
}

export type PayloadPreference_Value_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  intersects?: InputMaybe<Scalars['JSON']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  within?: InputMaybe<Scalars['JSON']['input']>;
};

export type PayloadPreference_Where = {
  AND?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_Or>>>;
  createdAt?: InputMaybe<PayloadPreference_CreatedAt_Operator>;
  id?: InputMaybe<PayloadPreference_Id_Operator>;
  key?: InputMaybe<PayloadPreference_Key_Operator>;
  updatedAt?: InputMaybe<PayloadPreference_UpdatedAt_Operator>;
  user?: InputMaybe<PayloadPreference_User_Relation>;
  value?: InputMaybe<PayloadPreference_Value_Operator>;
};

export type PayloadPreference_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_Or>>>;
  createdAt?: InputMaybe<PayloadPreference_CreatedAt_Operator>;
  id?: InputMaybe<PayloadPreference_Id_Operator>;
  key?: InputMaybe<PayloadPreference_Key_Operator>;
  updatedAt?: InputMaybe<PayloadPreference_UpdatedAt_Operator>;
  user?: InputMaybe<PayloadPreference_User_Relation>;
  value?: InputMaybe<PayloadPreference_Value_Operator>;
};

export type PayloadPreference_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_Or>>>;
  createdAt?: InputMaybe<PayloadPreference_CreatedAt_Operator>;
  id?: InputMaybe<PayloadPreference_Id_Operator>;
  key?: InputMaybe<PayloadPreference_Key_Operator>;
  updatedAt?: InputMaybe<PayloadPreference_UpdatedAt_Operator>;
  user?: InputMaybe<PayloadPreference_User_Relation>;
  value?: InputMaybe<PayloadPreference_Value_Operator>;
};

export type PayloadPreferences = {
  __typename?: 'PayloadPreferences';
  docs?: Maybe<Array<Maybe<PayloadPreference>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type PayloadPreferencesCreateAccess = {
  __typename?: 'PayloadPreferencesCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesCreateDocAccess = {
  __typename?: 'PayloadPreferencesCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesDeleteAccess = {
  __typename?: 'PayloadPreferencesDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesDeleteDocAccess = {
  __typename?: 'PayloadPreferencesDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesDocAccessFields = {
  __typename?: 'PayloadPreferencesDocAccessFields';
  createdAt?: Maybe<PayloadPreferencesDocAccessFields_CreatedAt>;
  key?: Maybe<PayloadPreferencesDocAccessFields_Key>;
  updatedAt?: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt>;
  user?: Maybe<PayloadPreferencesDocAccessFields_User>;
  value?: Maybe<PayloadPreferencesDocAccessFields_Value>;
};

export type PayloadPreferencesDocAccessFields_CreatedAt = {
  __typename?: 'PayloadPreferencesDocAccessFields_createdAt';
  create?: Maybe<PayloadPreferencesDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<PayloadPreferencesDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<PayloadPreferencesDocAccessFields_CreatedAt_Read>;
  update?: Maybe<PayloadPreferencesDocAccessFields_CreatedAt_Update>;
};

export type PayloadPreferencesDocAccessFields_CreatedAt_Create = {
  __typename?: 'PayloadPreferencesDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_CreatedAt_Delete = {
  __typename?: 'PayloadPreferencesDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_CreatedAt_Read = {
  __typename?: 'PayloadPreferencesDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_CreatedAt_Update = {
  __typename?: 'PayloadPreferencesDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Key = {
  __typename?: 'PayloadPreferencesDocAccessFields_key';
  create?: Maybe<PayloadPreferencesDocAccessFields_Key_Create>;
  delete?: Maybe<PayloadPreferencesDocAccessFields_Key_Delete>;
  read?: Maybe<PayloadPreferencesDocAccessFields_Key_Read>;
  update?: Maybe<PayloadPreferencesDocAccessFields_Key_Update>;
};

export type PayloadPreferencesDocAccessFields_Key_Create = {
  __typename?: 'PayloadPreferencesDocAccessFields_key_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Key_Delete = {
  __typename?: 'PayloadPreferencesDocAccessFields_key_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Key_Read = {
  __typename?: 'PayloadPreferencesDocAccessFields_key_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Key_Update = {
  __typename?: 'PayloadPreferencesDocAccessFields_key_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_UpdatedAt = {
  __typename?: 'PayloadPreferencesDocAccessFields_updatedAt';
  create?: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt_Update>;
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_Create = {
  __typename?: 'PayloadPreferencesDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'PayloadPreferencesDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_Read = {
  __typename?: 'PayloadPreferencesDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_Update = {
  __typename?: 'PayloadPreferencesDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_User = {
  __typename?: 'PayloadPreferencesDocAccessFields_user';
  create?: Maybe<PayloadPreferencesDocAccessFields_User_Create>;
  delete?: Maybe<PayloadPreferencesDocAccessFields_User_Delete>;
  read?: Maybe<PayloadPreferencesDocAccessFields_User_Read>;
  update?: Maybe<PayloadPreferencesDocAccessFields_User_Update>;
};

export type PayloadPreferencesDocAccessFields_User_Create = {
  __typename?: 'PayloadPreferencesDocAccessFields_user_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_User_Delete = {
  __typename?: 'PayloadPreferencesDocAccessFields_user_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_User_Read = {
  __typename?: 'PayloadPreferencesDocAccessFields_user_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_User_Update = {
  __typename?: 'PayloadPreferencesDocAccessFields_user_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Value = {
  __typename?: 'PayloadPreferencesDocAccessFields_value';
  create?: Maybe<PayloadPreferencesDocAccessFields_Value_Create>;
  delete?: Maybe<PayloadPreferencesDocAccessFields_Value_Delete>;
  read?: Maybe<PayloadPreferencesDocAccessFields_Value_Read>;
  update?: Maybe<PayloadPreferencesDocAccessFields_Value_Update>;
};

export type PayloadPreferencesDocAccessFields_Value_Create = {
  __typename?: 'PayloadPreferencesDocAccessFields_value_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Value_Delete = {
  __typename?: 'PayloadPreferencesDocAccessFields_value_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Value_Read = {
  __typename?: 'PayloadPreferencesDocAccessFields_value_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Value_Update = {
  __typename?: 'PayloadPreferencesDocAccessFields_value_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields = {
  __typename?: 'PayloadPreferencesFields';
  createdAt?: Maybe<PayloadPreferencesFields_CreatedAt>;
  key?: Maybe<PayloadPreferencesFields_Key>;
  updatedAt?: Maybe<PayloadPreferencesFields_UpdatedAt>;
  user?: Maybe<PayloadPreferencesFields_User>;
  value?: Maybe<PayloadPreferencesFields_Value>;
};

export type PayloadPreferencesFields_CreatedAt = {
  __typename?: 'PayloadPreferencesFields_createdAt';
  create?: Maybe<PayloadPreferencesFields_CreatedAt_Create>;
  delete?: Maybe<PayloadPreferencesFields_CreatedAt_Delete>;
  read?: Maybe<PayloadPreferencesFields_CreatedAt_Read>;
  update?: Maybe<PayloadPreferencesFields_CreatedAt_Update>;
};

export type PayloadPreferencesFields_CreatedAt_Create = {
  __typename?: 'PayloadPreferencesFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_CreatedAt_Delete = {
  __typename?: 'PayloadPreferencesFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_CreatedAt_Read = {
  __typename?: 'PayloadPreferencesFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_CreatedAt_Update = {
  __typename?: 'PayloadPreferencesFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Key = {
  __typename?: 'PayloadPreferencesFields_key';
  create?: Maybe<PayloadPreferencesFields_Key_Create>;
  delete?: Maybe<PayloadPreferencesFields_Key_Delete>;
  read?: Maybe<PayloadPreferencesFields_Key_Read>;
  update?: Maybe<PayloadPreferencesFields_Key_Update>;
};

export type PayloadPreferencesFields_Key_Create = {
  __typename?: 'PayloadPreferencesFields_key_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Key_Delete = {
  __typename?: 'PayloadPreferencesFields_key_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Key_Read = {
  __typename?: 'PayloadPreferencesFields_key_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Key_Update = {
  __typename?: 'PayloadPreferencesFields_key_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_UpdatedAt = {
  __typename?: 'PayloadPreferencesFields_updatedAt';
  create?: Maybe<PayloadPreferencesFields_UpdatedAt_Create>;
  delete?: Maybe<PayloadPreferencesFields_UpdatedAt_Delete>;
  read?: Maybe<PayloadPreferencesFields_UpdatedAt_Read>;
  update?: Maybe<PayloadPreferencesFields_UpdatedAt_Update>;
};

export type PayloadPreferencesFields_UpdatedAt_Create = {
  __typename?: 'PayloadPreferencesFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_UpdatedAt_Delete = {
  __typename?: 'PayloadPreferencesFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_UpdatedAt_Read = {
  __typename?: 'PayloadPreferencesFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_UpdatedAt_Update = {
  __typename?: 'PayloadPreferencesFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_User = {
  __typename?: 'PayloadPreferencesFields_user';
  create?: Maybe<PayloadPreferencesFields_User_Create>;
  delete?: Maybe<PayloadPreferencesFields_User_Delete>;
  read?: Maybe<PayloadPreferencesFields_User_Read>;
  update?: Maybe<PayloadPreferencesFields_User_Update>;
};

export type PayloadPreferencesFields_User_Create = {
  __typename?: 'PayloadPreferencesFields_user_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_User_Delete = {
  __typename?: 'PayloadPreferencesFields_user_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_User_Read = {
  __typename?: 'PayloadPreferencesFields_user_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_User_Update = {
  __typename?: 'PayloadPreferencesFields_user_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Value = {
  __typename?: 'PayloadPreferencesFields_value';
  create?: Maybe<PayloadPreferencesFields_Value_Create>;
  delete?: Maybe<PayloadPreferencesFields_Value_Delete>;
  read?: Maybe<PayloadPreferencesFields_Value_Read>;
  update?: Maybe<PayloadPreferencesFields_Value_Update>;
};

export type PayloadPreferencesFields_Value_Create = {
  __typename?: 'PayloadPreferencesFields_value_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Value_Delete = {
  __typename?: 'PayloadPreferencesFields_value_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Value_Read = {
  __typename?: 'PayloadPreferencesFields_value_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Value_Update = {
  __typename?: 'PayloadPreferencesFields_value_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesReadAccess = {
  __typename?: 'PayloadPreferencesReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesReadDocAccess = {
  __typename?: 'PayloadPreferencesReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesUpdateAccess = {
  __typename?: 'PayloadPreferencesUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesUpdateDocAccess = {
  __typename?: 'PayloadPreferencesUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PaymentDetail = {
  __typename?: 'PaymentDetail';
  canAcceptPayments?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<PaymentDetail_Currency>;
  stripeAccountId?: Maybe<Scalars['String']['output']>;
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type PaymentDetailTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export enum PaymentDetail_Currency {
  Eur = 'EUR',
  Huf = 'HUF'
}

export enum PaymentDetail_Currency_MutationInput {
  Eur = 'EUR',
  Huf = 'HUF'
}

export type PaymentDetailsDocAccessFields = {
  __typename?: 'PaymentDetailsDocAccessFields';
  canAcceptPayments?: Maybe<PaymentDetailsDocAccessFields_CanAcceptPayments>;
  createdAt?: Maybe<PaymentDetailsDocAccessFields_CreatedAt>;
  currency?: Maybe<PaymentDetailsDocAccessFields_Currency>;
  stripeAccountId?: Maybe<PaymentDetailsDocAccessFields_StripeAccountId>;
  tenant?: Maybe<PaymentDetailsDocAccessFields_Tenant>;
  updatedAt?: Maybe<PaymentDetailsDocAccessFields_UpdatedAt>;
};

export type PaymentDetailsDocAccessFields_CanAcceptPayments = {
  __typename?: 'PaymentDetailsDocAccessFields_canAcceptPayments';
  create?: Maybe<PaymentDetailsDocAccessFields_CanAcceptPayments_Create>;
  delete?: Maybe<PaymentDetailsDocAccessFields_CanAcceptPayments_Delete>;
  read?: Maybe<PaymentDetailsDocAccessFields_CanAcceptPayments_Read>;
  update?: Maybe<PaymentDetailsDocAccessFields_CanAcceptPayments_Update>;
};

export type PaymentDetailsDocAccessFields_CanAcceptPayments_Create = {
  __typename?: 'PaymentDetailsDocAccessFields_canAcceptPayments_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_CanAcceptPayments_Delete = {
  __typename?: 'PaymentDetailsDocAccessFields_canAcceptPayments_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_CanAcceptPayments_Read = {
  __typename?: 'PaymentDetailsDocAccessFields_canAcceptPayments_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_CanAcceptPayments_Update = {
  __typename?: 'PaymentDetailsDocAccessFields_canAcceptPayments_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_CreatedAt = {
  __typename?: 'PaymentDetailsDocAccessFields_createdAt';
  create?: Maybe<PaymentDetailsDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<PaymentDetailsDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<PaymentDetailsDocAccessFields_CreatedAt_Read>;
  update?: Maybe<PaymentDetailsDocAccessFields_CreatedAt_Update>;
};

export type PaymentDetailsDocAccessFields_CreatedAt_Create = {
  __typename?: 'PaymentDetailsDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_CreatedAt_Delete = {
  __typename?: 'PaymentDetailsDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_CreatedAt_Read = {
  __typename?: 'PaymentDetailsDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_CreatedAt_Update = {
  __typename?: 'PaymentDetailsDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_Currency = {
  __typename?: 'PaymentDetailsDocAccessFields_currency';
  create?: Maybe<PaymentDetailsDocAccessFields_Currency_Create>;
  delete?: Maybe<PaymentDetailsDocAccessFields_Currency_Delete>;
  read?: Maybe<PaymentDetailsDocAccessFields_Currency_Read>;
  update?: Maybe<PaymentDetailsDocAccessFields_Currency_Update>;
};

export type PaymentDetailsDocAccessFields_Currency_Create = {
  __typename?: 'PaymentDetailsDocAccessFields_currency_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_Currency_Delete = {
  __typename?: 'PaymentDetailsDocAccessFields_currency_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_Currency_Read = {
  __typename?: 'PaymentDetailsDocAccessFields_currency_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_Currency_Update = {
  __typename?: 'PaymentDetailsDocAccessFields_currency_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_StripeAccountId = {
  __typename?: 'PaymentDetailsDocAccessFields_stripeAccountId';
  create?: Maybe<PaymentDetailsDocAccessFields_StripeAccountId_Create>;
  delete?: Maybe<PaymentDetailsDocAccessFields_StripeAccountId_Delete>;
  read?: Maybe<PaymentDetailsDocAccessFields_StripeAccountId_Read>;
  update?: Maybe<PaymentDetailsDocAccessFields_StripeAccountId_Update>;
};

export type PaymentDetailsDocAccessFields_StripeAccountId_Create = {
  __typename?: 'PaymentDetailsDocAccessFields_stripeAccountId_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_StripeAccountId_Delete = {
  __typename?: 'PaymentDetailsDocAccessFields_stripeAccountId_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_StripeAccountId_Read = {
  __typename?: 'PaymentDetailsDocAccessFields_stripeAccountId_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_StripeAccountId_Update = {
  __typename?: 'PaymentDetailsDocAccessFields_stripeAccountId_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_Tenant = {
  __typename?: 'PaymentDetailsDocAccessFields_tenant';
  create?: Maybe<PaymentDetailsDocAccessFields_Tenant_Create>;
  delete?: Maybe<PaymentDetailsDocAccessFields_Tenant_Delete>;
  read?: Maybe<PaymentDetailsDocAccessFields_Tenant_Read>;
  update?: Maybe<PaymentDetailsDocAccessFields_Tenant_Update>;
};

export type PaymentDetailsDocAccessFields_Tenant_Create = {
  __typename?: 'PaymentDetailsDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_Tenant_Delete = {
  __typename?: 'PaymentDetailsDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_Tenant_Read = {
  __typename?: 'PaymentDetailsDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_Tenant_Update = {
  __typename?: 'PaymentDetailsDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_UpdatedAt = {
  __typename?: 'PaymentDetailsDocAccessFields_updatedAt';
  create?: Maybe<PaymentDetailsDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<PaymentDetailsDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<PaymentDetailsDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<PaymentDetailsDocAccessFields_UpdatedAt_Update>;
};

export type PaymentDetailsDocAccessFields_UpdatedAt_Create = {
  __typename?: 'PaymentDetailsDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'PaymentDetailsDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_UpdatedAt_Read = {
  __typename?: 'PaymentDetailsDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsDocAccessFields_UpdatedAt_Update = {
  __typename?: 'PaymentDetailsDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields = {
  __typename?: 'PaymentDetailsFields';
  canAcceptPayments?: Maybe<PaymentDetailsFields_CanAcceptPayments>;
  createdAt?: Maybe<PaymentDetailsFields_CreatedAt>;
  currency?: Maybe<PaymentDetailsFields_Currency>;
  stripeAccountId?: Maybe<PaymentDetailsFields_StripeAccountId>;
  tenant?: Maybe<PaymentDetailsFields_Tenant>;
  updatedAt?: Maybe<PaymentDetailsFields_UpdatedAt>;
};

export type PaymentDetailsFields_CanAcceptPayments = {
  __typename?: 'PaymentDetailsFields_canAcceptPayments';
  create?: Maybe<PaymentDetailsFields_CanAcceptPayments_Create>;
  delete?: Maybe<PaymentDetailsFields_CanAcceptPayments_Delete>;
  read?: Maybe<PaymentDetailsFields_CanAcceptPayments_Read>;
  update?: Maybe<PaymentDetailsFields_CanAcceptPayments_Update>;
};

export type PaymentDetailsFields_CanAcceptPayments_Create = {
  __typename?: 'PaymentDetailsFields_canAcceptPayments_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_CanAcceptPayments_Delete = {
  __typename?: 'PaymentDetailsFields_canAcceptPayments_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_CanAcceptPayments_Read = {
  __typename?: 'PaymentDetailsFields_canAcceptPayments_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_CanAcceptPayments_Update = {
  __typename?: 'PaymentDetailsFields_canAcceptPayments_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_CreatedAt = {
  __typename?: 'PaymentDetailsFields_createdAt';
  create?: Maybe<PaymentDetailsFields_CreatedAt_Create>;
  delete?: Maybe<PaymentDetailsFields_CreatedAt_Delete>;
  read?: Maybe<PaymentDetailsFields_CreatedAt_Read>;
  update?: Maybe<PaymentDetailsFields_CreatedAt_Update>;
};

export type PaymentDetailsFields_CreatedAt_Create = {
  __typename?: 'PaymentDetailsFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_CreatedAt_Delete = {
  __typename?: 'PaymentDetailsFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_CreatedAt_Read = {
  __typename?: 'PaymentDetailsFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_CreatedAt_Update = {
  __typename?: 'PaymentDetailsFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_Currency = {
  __typename?: 'PaymentDetailsFields_currency';
  create?: Maybe<PaymentDetailsFields_Currency_Create>;
  delete?: Maybe<PaymentDetailsFields_Currency_Delete>;
  read?: Maybe<PaymentDetailsFields_Currency_Read>;
  update?: Maybe<PaymentDetailsFields_Currency_Update>;
};

export type PaymentDetailsFields_Currency_Create = {
  __typename?: 'PaymentDetailsFields_currency_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_Currency_Delete = {
  __typename?: 'PaymentDetailsFields_currency_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_Currency_Read = {
  __typename?: 'PaymentDetailsFields_currency_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_Currency_Update = {
  __typename?: 'PaymentDetailsFields_currency_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_StripeAccountId = {
  __typename?: 'PaymentDetailsFields_stripeAccountId';
  create?: Maybe<PaymentDetailsFields_StripeAccountId_Create>;
  delete?: Maybe<PaymentDetailsFields_StripeAccountId_Delete>;
  read?: Maybe<PaymentDetailsFields_StripeAccountId_Read>;
  update?: Maybe<PaymentDetailsFields_StripeAccountId_Update>;
};

export type PaymentDetailsFields_StripeAccountId_Create = {
  __typename?: 'PaymentDetailsFields_stripeAccountId_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_StripeAccountId_Delete = {
  __typename?: 'PaymentDetailsFields_stripeAccountId_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_StripeAccountId_Read = {
  __typename?: 'PaymentDetailsFields_stripeAccountId_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_StripeAccountId_Update = {
  __typename?: 'PaymentDetailsFields_stripeAccountId_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_Tenant = {
  __typename?: 'PaymentDetailsFields_tenant';
  create?: Maybe<PaymentDetailsFields_Tenant_Create>;
  delete?: Maybe<PaymentDetailsFields_Tenant_Delete>;
  read?: Maybe<PaymentDetailsFields_Tenant_Read>;
  update?: Maybe<PaymentDetailsFields_Tenant_Update>;
};

export type PaymentDetailsFields_Tenant_Create = {
  __typename?: 'PaymentDetailsFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_Tenant_Delete = {
  __typename?: 'PaymentDetailsFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_Tenant_Read = {
  __typename?: 'PaymentDetailsFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_Tenant_Update = {
  __typename?: 'PaymentDetailsFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_UpdatedAt = {
  __typename?: 'PaymentDetailsFields_updatedAt';
  create?: Maybe<PaymentDetailsFields_UpdatedAt_Create>;
  delete?: Maybe<PaymentDetailsFields_UpdatedAt_Delete>;
  read?: Maybe<PaymentDetailsFields_UpdatedAt_Read>;
  update?: Maybe<PaymentDetailsFields_UpdatedAt_Update>;
};

export type PaymentDetailsFields_UpdatedAt_Create = {
  __typename?: 'PaymentDetailsFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_UpdatedAt_Delete = {
  __typename?: 'PaymentDetailsFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_UpdatedAt_Read = {
  __typename?: 'PaymentDetailsFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsFields_UpdatedAt_Update = {
  __typename?: 'PaymentDetailsFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobal = {
  __typename?: 'PaymentDetailsGlobal';
  canAcceptPayments?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<PaymentDetailsGlobal_Currency>;
  id?: Maybe<Scalars['String']['output']>;
  stripeAccountId?: Maybe<Scalars['String']['output']>;
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type PaymentDetailsGlobalTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export enum PaymentDetailsGlobalUpdate_Currency_MutationInput {
  Eur = 'EUR',
  Huf = 'HUF'
}

export type PaymentDetailsGlobal_CanAcceptPayments_Operator = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PaymentDetailsGlobal_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum PaymentDetailsGlobal_Currency {
  Eur = 'EUR',
  Huf = 'HUF'
}

export enum PaymentDetailsGlobal_Currency_Input {
  Eur = 'EUR',
  Huf = 'HUF'
}

export enum PaymentDetailsGlobal_Currency_MutationInput {
  Eur = 'EUR',
  Huf = 'HUF'
}

export type PaymentDetailsGlobal_Currency_Operator = {
  all?: InputMaybe<Array<InputMaybe<PaymentDetailsGlobal_Currency_Input>>>;
  equals?: InputMaybe<PaymentDetailsGlobal_Currency_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<PaymentDetailsGlobal_Currency_Input>>>;
  not_equals?: InputMaybe<PaymentDetailsGlobal_Currency_Input>;
  not_in?: InputMaybe<Array<InputMaybe<PaymentDetailsGlobal_Currency_Input>>>;
};

export type PaymentDetailsGlobal_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PaymentDetailsGlobal_StripeAccountId_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PaymentDetailsGlobal_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type PaymentDetailsGlobal_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PaymentDetailsGlobal_Where = {
  AND?: InputMaybe<Array<InputMaybe<PaymentDetailsGlobal_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PaymentDetailsGlobal_Where_Or>>>;
  canAcceptPayments?: InputMaybe<PaymentDetailsGlobal_CanAcceptPayments_Operator>;
  createdAt?: InputMaybe<PaymentDetailsGlobal_CreatedAt_Operator>;
  currency?: InputMaybe<PaymentDetailsGlobal_Currency_Operator>;
  id?: InputMaybe<PaymentDetailsGlobal_Id_Operator>;
  stripeAccountId?: InputMaybe<PaymentDetailsGlobal_StripeAccountId_Operator>;
  tenant?: InputMaybe<PaymentDetailsGlobal_Tenant_Operator>;
  updatedAt?: InputMaybe<PaymentDetailsGlobal_UpdatedAt_Operator>;
};

export type PaymentDetailsGlobal_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<PaymentDetailsGlobal_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PaymentDetailsGlobal_Where_Or>>>;
  canAcceptPayments?: InputMaybe<PaymentDetailsGlobal_CanAcceptPayments_Operator>;
  createdAt?: InputMaybe<PaymentDetailsGlobal_CreatedAt_Operator>;
  currency?: InputMaybe<PaymentDetailsGlobal_Currency_Operator>;
  id?: InputMaybe<PaymentDetailsGlobal_Id_Operator>;
  stripeAccountId?: InputMaybe<PaymentDetailsGlobal_StripeAccountId_Operator>;
  tenant?: InputMaybe<PaymentDetailsGlobal_Tenant_Operator>;
  updatedAt?: InputMaybe<PaymentDetailsGlobal_UpdatedAt_Operator>;
};

export type PaymentDetailsGlobal_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<PaymentDetailsGlobal_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PaymentDetailsGlobal_Where_Or>>>;
  canAcceptPayments?: InputMaybe<PaymentDetailsGlobal_CanAcceptPayments_Operator>;
  createdAt?: InputMaybe<PaymentDetailsGlobal_CreatedAt_Operator>;
  currency?: InputMaybe<PaymentDetailsGlobal_Currency_Operator>;
  id?: InputMaybe<PaymentDetailsGlobal_Id_Operator>;
  stripeAccountId?: InputMaybe<PaymentDetailsGlobal_StripeAccountId_Operator>;
  tenant?: InputMaybe<PaymentDetailsGlobal_Tenant_Operator>;
  updatedAt?: InputMaybe<PaymentDetailsGlobal_UpdatedAt_Operator>;
};

export type PaymentDetailsGlobals = {
  __typename?: 'PaymentDetailsGlobals';
  docs?: Maybe<Array<Maybe<PaymentDetailsGlobal>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type PaymentDetailsGlobalsCreateAccess = {
  __typename?: 'PaymentDetailsGlobalsCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PaymentDetailsGlobalsCreateDocAccess = {
  __typename?: 'PaymentDetailsGlobalsCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PaymentDetailsGlobalsDeleteAccess = {
  __typename?: 'PaymentDetailsGlobalsDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PaymentDetailsGlobalsDeleteDocAccess = {
  __typename?: 'PaymentDetailsGlobalsDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PaymentDetailsGlobalsDocAccessFields = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields';
  canAcceptPayments?: Maybe<PaymentDetailsGlobalsDocAccessFields_CanAcceptPayments>;
  createdAt?: Maybe<PaymentDetailsGlobalsDocAccessFields_CreatedAt>;
  currency?: Maybe<PaymentDetailsGlobalsDocAccessFields_Currency>;
  stripeAccountId?: Maybe<PaymentDetailsGlobalsDocAccessFields_StripeAccountId>;
  tenant?: Maybe<PaymentDetailsGlobalsDocAccessFields_Tenant>;
  updatedAt?: Maybe<PaymentDetailsGlobalsDocAccessFields_UpdatedAt>;
};

export type PaymentDetailsGlobalsDocAccessFields_CanAcceptPayments = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_canAcceptPayments';
  create?: Maybe<PaymentDetailsGlobalsDocAccessFields_CanAcceptPayments_Create>;
  delete?: Maybe<PaymentDetailsGlobalsDocAccessFields_CanAcceptPayments_Delete>;
  read?: Maybe<PaymentDetailsGlobalsDocAccessFields_CanAcceptPayments_Read>;
  update?: Maybe<PaymentDetailsGlobalsDocAccessFields_CanAcceptPayments_Update>;
};

export type PaymentDetailsGlobalsDocAccessFields_CanAcceptPayments_Create = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_canAcceptPayments_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_CanAcceptPayments_Delete = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_canAcceptPayments_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_CanAcceptPayments_Read = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_canAcceptPayments_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_CanAcceptPayments_Update = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_canAcceptPayments_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_CreatedAt = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_createdAt';
  create?: Maybe<PaymentDetailsGlobalsDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<PaymentDetailsGlobalsDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<PaymentDetailsGlobalsDocAccessFields_CreatedAt_Read>;
  update?: Maybe<PaymentDetailsGlobalsDocAccessFields_CreatedAt_Update>;
};

export type PaymentDetailsGlobalsDocAccessFields_CreatedAt_Create = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_CreatedAt_Delete = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_CreatedAt_Read = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_CreatedAt_Update = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_Currency = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_currency';
  create?: Maybe<PaymentDetailsGlobalsDocAccessFields_Currency_Create>;
  delete?: Maybe<PaymentDetailsGlobalsDocAccessFields_Currency_Delete>;
  read?: Maybe<PaymentDetailsGlobalsDocAccessFields_Currency_Read>;
  update?: Maybe<PaymentDetailsGlobalsDocAccessFields_Currency_Update>;
};

export type PaymentDetailsGlobalsDocAccessFields_Currency_Create = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_currency_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_Currency_Delete = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_currency_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_Currency_Read = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_currency_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_Currency_Update = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_currency_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_StripeAccountId = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_stripeAccountId';
  create?: Maybe<PaymentDetailsGlobalsDocAccessFields_StripeAccountId_Create>;
  delete?: Maybe<PaymentDetailsGlobalsDocAccessFields_StripeAccountId_Delete>;
  read?: Maybe<PaymentDetailsGlobalsDocAccessFields_StripeAccountId_Read>;
  update?: Maybe<PaymentDetailsGlobalsDocAccessFields_StripeAccountId_Update>;
};

export type PaymentDetailsGlobalsDocAccessFields_StripeAccountId_Create = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_stripeAccountId_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_StripeAccountId_Delete = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_stripeAccountId_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_StripeAccountId_Read = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_stripeAccountId_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_StripeAccountId_Update = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_stripeAccountId_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_Tenant = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_tenant';
  create?: Maybe<PaymentDetailsGlobalsDocAccessFields_Tenant_Create>;
  delete?: Maybe<PaymentDetailsGlobalsDocAccessFields_Tenant_Delete>;
  read?: Maybe<PaymentDetailsGlobalsDocAccessFields_Tenant_Read>;
  update?: Maybe<PaymentDetailsGlobalsDocAccessFields_Tenant_Update>;
};

export type PaymentDetailsGlobalsDocAccessFields_Tenant_Create = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_Tenant_Delete = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_Tenant_Read = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_Tenant_Update = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_UpdatedAt = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_updatedAt';
  create?: Maybe<PaymentDetailsGlobalsDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<PaymentDetailsGlobalsDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<PaymentDetailsGlobalsDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<PaymentDetailsGlobalsDocAccessFields_UpdatedAt_Update>;
};

export type PaymentDetailsGlobalsDocAccessFields_UpdatedAt_Create = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_UpdatedAt_Read = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsDocAccessFields_UpdatedAt_Update = {
  __typename?: 'PaymentDetailsGlobalsDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields = {
  __typename?: 'PaymentDetailsGlobalsFields';
  canAcceptPayments?: Maybe<PaymentDetailsGlobalsFields_CanAcceptPayments>;
  createdAt?: Maybe<PaymentDetailsGlobalsFields_CreatedAt>;
  currency?: Maybe<PaymentDetailsGlobalsFields_Currency>;
  stripeAccountId?: Maybe<PaymentDetailsGlobalsFields_StripeAccountId>;
  tenant?: Maybe<PaymentDetailsGlobalsFields_Tenant>;
  updatedAt?: Maybe<PaymentDetailsGlobalsFields_UpdatedAt>;
};

export type PaymentDetailsGlobalsFields_CanAcceptPayments = {
  __typename?: 'PaymentDetailsGlobalsFields_canAcceptPayments';
  create?: Maybe<PaymentDetailsGlobalsFields_CanAcceptPayments_Create>;
  delete?: Maybe<PaymentDetailsGlobalsFields_CanAcceptPayments_Delete>;
  read?: Maybe<PaymentDetailsGlobalsFields_CanAcceptPayments_Read>;
  update?: Maybe<PaymentDetailsGlobalsFields_CanAcceptPayments_Update>;
};

export type PaymentDetailsGlobalsFields_CanAcceptPayments_Create = {
  __typename?: 'PaymentDetailsGlobalsFields_canAcceptPayments_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_CanAcceptPayments_Delete = {
  __typename?: 'PaymentDetailsGlobalsFields_canAcceptPayments_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_CanAcceptPayments_Read = {
  __typename?: 'PaymentDetailsGlobalsFields_canAcceptPayments_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_CanAcceptPayments_Update = {
  __typename?: 'PaymentDetailsGlobalsFields_canAcceptPayments_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_CreatedAt = {
  __typename?: 'PaymentDetailsGlobalsFields_createdAt';
  create?: Maybe<PaymentDetailsGlobalsFields_CreatedAt_Create>;
  delete?: Maybe<PaymentDetailsGlobalsFields_CreatedAt_Delete>;
  read?: Maybe<PaymentDetailsGlobalsFields_CreatedAt_Read>;
  update?: Maybe<PaymentDetailsGlobalsFields_CreatedAt_Update>;
};

export type PaymentDetailsGlobalsFields_CreatedAt_Create = {
  __typename?: 'PaymentDetailsGlobalsFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_CreatedAt_Delete = {
  __typename?: 'PaymentDetailsGlobalsFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_CreatedAt_Read = {
  __typename?: 'PaymentDetailsGlobalsFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_CreatedAt_Update = {
  __typename?: 'PaymentDetailsGlobalsFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_Currency = {
  __typename?: 'PaymentDetailsGlobalsFields_currency';
  create?: Maybe<PaymentDetailsGlobalsFields_Currency_Create>;
  delete?: Maybe<PaymentDetailsGlobalsFields_Currency_Delete>;
  read?: Maybe<PaymentDetailsGlobalsFields_Currency_Read>;
  update?: Maybe<PaymentDetailsGlobalsFields_Currency_Update>;
};

export type PaymentDetailsGlobalsFields_Currency_Create = {
  __typename?: 'PaymentDetailsGlobalsFields_currency_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_Currency_Delete = {
  __typename?: 'PaymentDetailsGlobalsFields_currency_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_Currency_Read = {
  __typename?: 'PaymentDetailsGlobalsFields_currency_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_Currency_Update = {
  __typename?: 'PaymentDetailsGlobalsFields_currency_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_StripeAccountId = {
  __typename?: 'PaymentDetailsGlobalsFields_stripeAccountId';
  create?: Maybe<PaymentDetailsGlobalsFields_StripeAccountId_Create>;
  delete?: Maybe<PaymentDetailsGlobalsFields_StripeAccountId_Delete>;
  read?: Maybe<PaymentDetailsGlobalsFields_StripeAccountId_Read>;
  update?: Maybe<PaymentDetailsGlobalsFields_StripeAccountId_Update>;
};

export type PaymentDetailsGlobalsFields_StripeAccountId_Create = {
  __typename?: 'PaymentDetailsGlobalsFields_stripeAccountId_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_StripeAccountId_Delete = {
  __typename?: 'PaymentDetailsGlobalsFields_stripeAccountId_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_StripeAccountId_Read = {
  __typename?: 'PaymentDetailsGlobalsFields_stripeAccountId_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_StripeAccountId_Update = {
  __typename?: 'PaymentDetailsGlobalsFields_stripeAccountId_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_Tenant = {
  __typename?: 'PaymentDetailsGlobalsFields_tenant';
  create?: Maybe<PaymentDetailsGlobalsFields_Tenant_Create>;
  delete?: Maybe<PaymentDetailsGlobalsFields_Tenant_Delete>;
  read?: Maybe<PaymentDetailsGlobalsFields_Tenant_Read>;
  update?: Maybe<PaymentDetailsGlobalsFields_Tenant_Update>;
};

export type PaymentDetailsGlobalsFields_Tenant_Create = {
  __typename?: 'PaymentDetailsGlobalsFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_Tenant_Delete = {
  __typename?: 'PaymentDetailsGlobalsFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_Tenant_Read = {
  __typename?: 'PaymentDetailsGlobalsFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_Tenant_Update = {
  __typename?: 'PaymentDetailsGlobalsFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_UpdatedAt = {
  __typename?: 'PaymentDetailsGlobalsFields_updatedAt';
  create?: Maybe<PaymentDetailsGlobalsFields_UpdatedAt_Create>;
  delete?: Maybe<PaymentDetailsGlobalsFields_UpdatedAt_Delete>;
  read?: Maybe<PaymentDetailsGlobalsFields_UpdatedAt_Read>;
  update?: Maybe<PaymentDetailsGlobalsFields_UpdatedAt_Update>;
};

export type PaymentDetailsGlobalsFields_UpdatedAt_Create = {
  __typename?: 'PaymentDetailsGlobalsFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_UpdatedAt_Delete = {
  __typename?: 'PaymentDetailsGlobalsFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_UpdatedAt_Read = {
  __typename?: 'PaymentDetailsGlobalsFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsFields_UpdatedAt_Update = {
  __typename?: 'PaymentDetailsGlobalsFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PaymentDetailsGlobalsReadAccess = {
  __typename?: 'PaymentDetailsGlobalsReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PaymentDetailsGlobalsReadDocAccess = {
  __typename?: 'PaymentDetailsGlobalsReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PaymentDetailsGlobalsUpdateAccess = {
  __typename?: 'PaymentDetailsGlobalsUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PaymentDetailsGlobalsUpdateDocAccess = {
  __typename?: 'PaymentDetailsGlobalsUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PaymentDetailsReadAccess = {
  __typename?: 'PaymentDetailsReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PaymentDetailsReadDocAccess = {
  __typename?: 'PaymentDetailsReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PaymentDetailsUpdateAccess = {
  __typename?: 'PaymentDetailsUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PaymentDetailsUpdateDocAccess = {
  __typename?: 'PaymentDetailsUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type Query = {
  __typename?: 'Query';
  Access?: Maybe<Access>;
  Booking?: Maybe<Booking>;
  Bookings?: Maybe<Bookings>;
  Checkout?: Maybe<Checkout>;
  Checkouts?: Maybe<Checkouts>;
  CompanyDetail?: Maybe<CompanyDetail>;
  CompanyDetailsGlobal?: Maybe<CompanyDetailsGlobal>;
  CompanyDetailsGlobals?: Maybe<CompanyDetailsGlobals>;
  Contact?: Maybe<Contact>;
  Contacts?: Maybe<Contacts>;
  Event?: Maybe<Event>;
  Events?: Maybe<Events>;
  Extra?: Maybe<Extra>;
  Extras?: Maybe<Extras>;
  Integration?: Maybe<Integration>;
  IntegrationsGlobal?: Maybe<IntegrationsGlobal>;
  IntegrationsGlobals?: Maybe<IntegrationsGlobals>;
  Media?: Maybe<Media>;
  Message?: Maybe<Message>;
  Messages?: Maybe<Messages>;
  OutgoingMessage?: Maybe<OutgoingMessage>;
  OutgoingMessages?: Maybe<OutgoingMessages>;
  PayloadPreference?: Maybe<PayloadPreference>;
  PayloadPreferences?: Maybe<PayloadPreferences>;
  PaymentDetail?: Maybe<PaymentDetail>;
  PaymentDetailsGlobal?: Maybe<PaymentDetailsGlobal>;
  PaymentDetailsGlobals?: Maybe<PaymentDetailsGlobals>;
  Service?: Maybe<Service>;
  Services?: Maybe<Services>;
  Tenant?: Maybe<Tenant>;
  Tenants?: Maybe<Tenants>;
  TicketType?: Maybe<TicketType>;
  TicketTypes?: Maybe<TicketTypes>;
  User?: Maybe<User>;
  Users?: Maybe<Users>;
  Voucher?: Maybe<Voucher>;
  Vouchers?: Maybe<Vouchers>;
  adminTenants?: Maybe<Array<Maybe<AdminTenantsResponse>>>;
  allMedia?: Maybe<AllMedia>;
  availableTimeslots?: Maybe<Array<Maybe<AvailableTimeslotResponse>>>;
  bookingsForEvent?: Maybe<Array<Maybe<Booking>>>;
  checkoutStatus?: Maybe<CheckoutStatusResponse>;
  docAccessBooking?: Maybe<BookingsDocAccess>;
  docAccessCheckout?: Maybe<CheckoutsDocAccess>;
  docAccessCompanyDetail?: Maybe<CompanyDetailsDocAccess>;
  docAccessCompanyDetailsGlobal?: Maybe<CompanyDetailsGlobalsDocAccess>;
  docAccessContact?: Maybe<ContactsDocAccess>;
  docAccessEvent?: Maybe<EventsDocAccess>;
  docAccessExtra?: Maybe<ExtrasDocAccess>;
  docAccessIntegration?: Maybe<IntegrationsDocAccess>;
  docAccessIntegrationsGlobal?: Maybe<IntegrationsGlobalsDocAccess>;
  docAccessMedia?: Maybe<MediaDocAccess>;
  docAccessMessage?: Maybe<MessagesDocAccess>;
  docAccessOutgoingMessage?: Maybe<OutgoingMessagesDocAccess>;
  docAccessPayloadPreference?: Maybe<Payload_PreferencesDocAccess>;
  docAccessPaymentDetail?: Maybe<PaymentDetailsDocAccess>;
  docAccessPaymentDetailsGlobal?: Maybe<PaymentDetailsGlobalsDocAccess>;
  docAccessService?: Maybe<ServicesDocAccess>;
  docAccessTenant?: Maybe<TenantsDocAccess>;
  docAccessTicketType?: Maybe<TicketTypesDocAccess>;
  docAccessUser?: Maybe<UsersDocAccess>;
  docAccessVoucher?: Maybe<VouchersDocAccess>;
  eventsByDate?: Maybe<Array<Maybe<Event>>>;
  initializedUser?: Maybe<Scalars['Boolean']['output']>;
  meUser?: Maybe<UsersMe>;
  onboardingSteps?: Maybe<OnboardingStepsResponseOrError>;
  servicesByTenant: Array<Service>;
  tenantDetails?: Maybe<TenantDetailsResponseOrErrorOrError>;
  voucherDetails?: Maybe<VoucherOrError>;
};


export type QueryBookingArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryBookingsArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Booking_Where>;
};


export type QueryCheckoutArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryCheckoutsArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Checkout_Where>;
};


export type QueryCompanyDetailArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryCompanyDetailsGlobalArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryCompanyDetailsGlobalsArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<CompanyDetailsGlobal_Where>;
};


export type QueryContactArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryContactsArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Contact_Where>;
};


export type QueryEventArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryEventsArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Event_Where>;
};


export type QueryExtraArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryExtrasArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Extra_Where>;
};


export type QueryIntegrationArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryIntegrationsGlobalArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryIntegrationsGlobalsArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<IntegrationsGlobal_Where>;
};


export type QueryMediaArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryMessageArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryMessagesArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Message_Where>;
};


export type QueryOutgoingMessageArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryOutgoingMessagesArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<OutgoingMessage_Where>;
};


export type QueryPayloadPreferenceArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryPayloadPreferencesArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<PayloadPreference_Where>;
};


export type QueryPaymentDetailArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryPaymentDetailsGlobalArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryPaymentDetailsGlobalsArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<PaymentDetailsGlobal_Where>;
};


export type QueryServiceArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryServicesArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Service_Where>;
};


export type QueryTenantArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryTenantsArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Tenant_Where>;
};


export type QueryTicketTypeArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryTicketTypesArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<TicketType_Where>;
};


export type QueryUserArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryUsersArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<User_Where>;
};


export type QueryVoucherArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
};


export type QueryVouchersArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Voucher_Where>;
};


export type QueryAllMediaArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<LocaleInputType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Media_Where>;
};


export type QueryAvailableTimeslotsArgs = {
  endDate: Scalars['String']['input'];
  serviceId?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['String']['input'];
  tenant: Scalars['String']['input'];
  voucherPurchase?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBookingsForEventArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCheckoutStatusArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  tenant?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDocAccessBookingArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessCheckoutArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessCompanyDetailsGlobalArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessContactArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessExtraArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessIntegrationsGlobalArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessMediaArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessMessageArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessOutgoingMessageArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessPayloadPreferenceArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessPaymentDetailsGlobalArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessServiceArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessTenantArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessTicketTypeArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessVoucherArgs = {
  id: Scalars['String']['input'];
};


export type QueryEventsByDateArgs = {
  end: Scalars['String']['input'];
  start: Scalars['String']['input'];
};


export type QueryServicesByTenantArgs = {
  locale?: InputMaybe<LocaleInputType>;
  tenant: Scalars['String']['input'];
};


export type QueryTenantDetailsArgs = {
  tenant: Scalars['String']['input'];
};


export type QueryVoucherDetailsArgs = {
  voucherId: Scalars['ID']['input'];
};

export type Service = {
  __typename?: 'Service';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  extras?: Maybe<Array<Service_Extras>>;
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Media>;
  location: Scalars['String']['output'];
  longDescription?: Maybe<Scalars['JSON']['output']>;
  longDescription_en?: Maybe<Scalars['JSON']['output']>;
  longDescription_original?: Maybe<Scalars['JSON']['output']>;
  maxAheadBookableDays?: Maybe<Scalars['Float']['output']>;
  maxTicketCount?: Maybe<Scalars['Float']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  shortDescription_en?: Maybe<Scalars['String']['output']>;
  shortDescription_original?: Maybe<Scalars['String']['output']>;
  tenant?: Maybe<Tenant>;
  ticketTypes: Array<Service_TicketTypes>;
  timeslots?: Maybe<Array<Service_Timeslots>>;
  title?: Maybe<Scalars['String']['output']>;
  title_en: Scalars['String']['output'];
  title_original: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vouchersEnabled?: Maybe<Scalars['Boolean']['output']>;
};


export type ServiceImageArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
  where?: InputMaybe<Service_Image_Where>;
};


export type ServiceLongDescriptionArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
};


export type ServiceLongDescription_EnArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
};


export type ServiceLongDescription_OriginalArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
};


export type ServiceTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type ServiceProviderInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export enum ServiceUpdate_Timeslots_Weekdays_MutationInput {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type Service_Extras = {
  __typename?: 'Service_Extras';
  extra?: Maybe<Extra>;
  id?: Maybe<Scalars['String']['output']>;
};


export type Service_ExtrasExtraArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type Service_Image_Alt_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Service_Image_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_Image_Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_Image_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_Sizes__Card__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_Sizes__Card__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_Image_Sizes__Card__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_Image_Sizes__Card__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_Sizes__Card__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_Sizes__Card__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_Image_Sizes__Tablet__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_Sizes__Tablet__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_Image_Sizes__Tablet__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_Image_Sizes__Tablet__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_Sizes__Tablet__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_Sizes__Tablet__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_Image_Sizes__Thumbnail__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_Sizes__Thumbnail__Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_Image_Sizes__Thumbnail__Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_Image_Sizes__Thumbnail__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_Sizes__Thumbnail__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_Sizes__Thumbnail__Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_Image_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Service_Image_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Service_Image_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_Where = {
  AND?: InputMaybe<Array<InputMaybe<Service_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Service_Image_Where_Or>>>;
  alt?: InputMaybe<Service_Image_Alt_Operator>;
  createdAt?: InputMaybe<Service_Image_CreatedAt_Operator>;
  filename?: InputMaybe<Service_Image_Filename_Operator>;
  filesize?: InputMaybe<Service_Image_Filesize_Operator>;
  height?: InputMaybe<Service_Image_Height_Operator>;
  id?: InputMaybe<Service_Image_Id_Operator>;
  mimeType?: InputMaybe<Service_Image_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<Service_Image_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<Service_Image_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<Service_Image_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<Service_Image_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<Service_Image_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<Service_Image_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<Service_Image_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<Service_Image_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<Service_Image_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<Service_Image_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<Service_Image_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<Service_Image_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<Service_Image_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<Service_Image_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<Service_Image_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<Service_Image_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<Service_Image_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<Service_Image_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<Service_Image_Tenant_Operator>;
  updatedAt?: InputMaybe<Service_Image_UpdatedAt_Operator>;
  url?: InputMaybe<Service_Image_Url_Operator>;
  width?: InputMaybe<Service_Image_Width_Operator>;
};

export type Service_Image_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Service_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Service_Image_Where_Or>>>;
  alt?: InputMaybe<Service_Image_Alt_Operator>;
  createdAt?: InputMaybe<Service_Image_CreatedAt_Operator>;
  filename?: InputMaybe<Service_Image_Filename_Operator>;
  filesize?: InputMaybe<Service_Image_Filesize_Operator>;
  height?: InputMaybe<Service_Image_Height_Operator>;
  id?: InputMaybe<Service_Image_Id_Operator>;
  mimeType?: InputMaybe<Service_Image_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<Service_Image_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<Service_Image_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<Service_Image_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<Service_Image_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<Service_Image_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<Service_Image_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<Service_Image_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<Service_Image_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<Service_Image_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<Service_Image_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<Service_Image_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<Service_Image_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<Service_Image_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<Service_Image_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<Service_Image_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<Service_Image_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<Service_Image_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<Service_Image_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<Service_Image_Tenant_Operator>;
  updatedAt?: InputMaybe<Service_Image_UpdatedAt_Operator>;
  url?: InputMaybe<Service_Image_Url_Operator>;
  width?: InputMaybe<Service_Image_Width_Operator>;
};

export type Service_Image_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Service_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Service_Image_Where_Or>>>;
  alt?: InputMaybe<Service_Image_Alt_Operator>;
  createdAt?: InputMaybe<Service_Image_CreatedAt_Operator>;
  filename?: InputMaybe<Service_Image_Filename_Operator>;
  filesize?: InputMaybe<Service_Image_Filesize_Operator>;
  height?: InputMaybe<Service_Image_Height_Operator>;
  id?: InputMaybe<Service_Image_Id_Operator>;
  mimeType?: InputMaybe<Service_Image_MimeType_Operator>;
  sizes__card__filename?: InputMaybe<Service_Image_Sizes__Card__Filename_Operator>;
  sizes__card__filesize?: InputMaybe<Service_Image_Sizes__Card__Filesize_Operator>;
  sizes__card__height?: InputMaybe<Service_Image_Sizes__Card__Height_Operator>;
  sizes__card__mimeType?: InputMaybe<Service_Image_Sizes__Card__MimeType_Operator>;
  sizes__card__url?: InputMaybe<Service_Image_Sizes__Card__Url_Operator>;
  sizes__card__width?: InputMaybe<Service_Image_Sizes__Card__Width_Operator>;
  sizes__tablet__filename?: InputMaybe<Service_Image_Sizes__Tablet__Filename_Operator>;
  sizes__tablet__filesize?: InputMaybe<Service_Image_Sizes__Tablet__Filesize_Operator>;
  sizes__tablet__height?: InputMaybe<Service_Image_Sizes__Tablet__Height_Operator>;
  sizes__tablet__mimeType?: InputMaybe<Service_Image_Sizes__Tablet__MimeType_Operator>;
  sizes__tablet__url?: InputMaybe<Service_Image_Sizes__Tablet__Url_Operator>;
  sizes__tablet__width?: InputMaybe<Service_Image_Sizes__Tablet__Width_Operator>;
  sizes__thumbnail__filename?: InputMaybe<Service_Image_Sizes__Thumbnail__Filename_Operator>;
  sizes__thumbnail__filesize?: InputMaybe<Service_Image_Sizes__Thumbnail__Filesize_Operator>;
  sizes__thumbnail__height?: InputMaybe<Service_Image_Sizes__Thumbnail__Height_Operator>;
  sizes__thumbnail__mimeType?: InputMaybe<Service_Image_Sizes__Thumbnail__MimeType_Operator>;
  sizes__thumbnail__url?: InputMaybe<Service_Image_Sizes__Thumbnail__Url_Operator>;
  sizes__thumbnail__width?: InputMaybe<Service_Image_Sizes__Thumbnail__Width_Operator>;
  tenant?: InputMaybe<Service_Image_Tenant_Operator>;
  updatedAt?: InputMaybe<Service_Image_UpdatedAt_Operator>;
  url?: InputMaybe<Service_Image_Url_Operator>;
  width?: InputMaybe<Service_Image_Width_Operator>;
};

export type Service_Image_Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_TicketTypes = {
  __typename?: 'Service_TicketTypes';
  id?: Maybe<Scalars['String']['output']>;
  primary?: Maybe<Scalars['Boolean']['output']>;
  ticketType?: Maybe<TicketType>;
};


export type Service_TicketTypesTicketTypeArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type Service_Timeslots = {
  __typename?: 'Service_Timeslots';
  end?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  weekdays?: Maybe<Array<Service_Timeslots_Weekdays>>;
};

export enum Service_Timeslots_Weekdays {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export enum Service_Timeslots_Weekdays_MutationInput {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type Service_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Service_Extras__Extra_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Service_Extras__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Image_Operator = {
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type Service_Location_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_LongDescription_En_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type Service_LongDescription_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type Service_LongDescription_Original_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type Service_MaxAheadBookableDays_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_MaxTicketCount_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Service_PreviewUrl_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_ShortDescription_En_Operator = {
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type Service_ShortDescription_Operator = {
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type Service_ShortDescription_Original_Operator = {
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type Service_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Service_TicketTypes__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_TicketTypes__Primary_Operator = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Service_TicketTypes__TicketType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Service_Timeslots__EndDate_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Service_Timeslots__End_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Service_Timeslots__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Timeslots__StartDate_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Service_Timeslots__Start_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum Service_Timeslots__Weekdays_Input {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type Service_Timeslots__Weekdays_Operator = {
  all?: InputMaybe<Array<InputMaybe<Service_Timeslots__Weekdays_Input>>>;
  equals?: InputMaybe<Service_Timeslots__Weekdays_Input>;
  in?: InputMaybe<Array<InputMaybe<Service_Timeslots__Weekdays_Input>>>;
  not_equals?: InputMaybe<Service_Timeslots__Weekdays_Input>;
  not_in?: InputMaybe<Array<InputMaybe<Service_Timeslots__Weekdays_Input>>>;
};

export type Service_Title_En_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Title_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_Title_Original_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Service_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Service_VouchersEnabled_Operator = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Service_Where = {
  AND?: InputMaybe<Array<InputMaybe<Service_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Service_Where_Or>>>;
  createdAt?: InputMaybe<Service_CreatedAt_Operator>;
  extras__extra?: InputMaybe<Service_Extras__Extra_Operator>;
  extras__id?: InputMaybe<Service_Extras__Id_Operator>;
  id?: InputMaybe<Service_Id_Operator>;
  image?: InputMaybe<Service_Image_Operator>;
  location?: InputMaybe<Service_Location_Operator>;
  longDescription?: InputMaybe<Service_LongDescription_Operator>;
  longDescription_en?: InputMaybe<Service_LongDescription_En_Operator>;
  longDescription_original?: InputMaybe<Service_LongDescription_Original_Operator>;
  maxAheadBookableDays?: InputMaybe<Service_MaxAheadBookableDays_Operator>;
  maxTicketCount?: InputMaybe<Service_MaxTicketCount_Operator>;
  previewUrl?: InputMaybe<Service_PreviewUrl_Operator>;
  shortDescription?: InputMaybe<Service_ShortDescription_Operator>;
  shortDescription_en?: InputMaybe<Service_ShortDescription_En_Operator>;
  shortDescription_original?: InputMaybe<Service_ShortDescription_Original_Operator>;
  tenant?: InputMaybe<Service_Tenant_Operator>;
  ticketTypes__id?: InputMaybe<Service_TicketTypes__Id_Operator>;
  ticketTypes__primary?: InputMaybe<Service_TicketTypes__Primary_Operator>;
  ticketTypes__ticketType?: InputMaybe<Service_TicketTypes__TicketType_Operator>;
  timeslots__end?: InputMaybe<Service_Timeslots__End_Operator>;
  timeslots__endDate?: InputMaybe<Service_Timeslots__EndDate_Operator>;
  timeslots__id?: InputMaybe<Service_Timeslots__Id_Operator>;
  timeslots__start?: InputMaybe<Service_Timeslots__Start_Operator>;
  timeslots__startDate?: InputMaybe<Service_Timeslots__StartDate_Operator>;
  timeslots__weekdays?: InputMaybe<Service_Timeslots__Weekdays_Operator>;
  title?: InputMaybe<Service_Title_Operator>;
  title_en?: InputMaybe<Service_Title_En_Operator>;
  title_original?: InputMaybe<Service_Title_Original_Operator>;
  updatedAt?: InputMaybe<Service_UpdatedAt_Operator>;
  vouchersEnabled?: InputMaybe<Service_VouchersEnabled_Operator>;
};

export type Service_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Service_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Service_Where_Or>>>;
  createdAt?: InputMaybe<Service_CreatedAt_Operator>;
  extras__extra?: InputMaybe<Service_Extras__Extra_Operator>;
  extras__id?: InputMaybe<Service_Extras__Id_Operator>;
  id?: InputMaybe<Service_Id_Operator>;
  image?: InputMaybe<Service_Image_Operator>;
  location?: InputMaybe<Service_Location_Operator>;
  longDescription?: InputMaybe<Service_LongDescription_Operator>;
  longDescription_en?: InputMaybe<Service_LongDescription_En_Operator>;
  longDescription_original?: InputMaybe<Service_LongDescription_Original_Operator>;
  maxAheadBookableDays?: InputMaybe<Service_MaxAheadBookableDays_Operator>;
  maxTicketCount?: InputMaybe<Service_MaxTicketCount_Operator>;
  previewUrl?: InputMaybe<Service_PreviewUrl_Operator>;
  shortDescription?: InputMaybe<Service_ShortDescription_Operator>;
  shortDescription_en?: InputMaybe<Service_ShortDescription_En_Operator>;
  shortDescription_original?: InputMaybe<Service_ShortDescription_Original_Operator>;
  tenant?: InputMaybe<Service_Tenant_Operator>;
  ticketTypes__id?: InputMaybe<Service_TicketTypes__Id_Operator>;
  ticketTypes__primary?: InputMaybe<Service_TicketTypes__Primary_Operator>;
  ticketTypes__ticketType?: InputMaybe<Service_TicketTypes__TicketType_Operator>;
  timeslots__end?: InputMaybe<Service_Timeslots__End_Operator>;
  timeslots__endDate?: InputMaybe<Service_Timeslots__EndDate_Operator>;
  timeslots__id?: InputMaybe<Service_Timeslots__Id_Operator>;
  timeslots__start?: InputMaybe<Service_Timeslots__Start_Operator>;
  timeslots__startDate?: InputMaybe<Service_Timeslots__StartDate_Operator>;
  timeslots__weekdays?: InputMaybe<Service_Timeslots__Weekdays_Operator>;
  title?: InputMaybe<Service_Title_Operator>;
  title_en?: InputMaybe<Service_Title_En_Operator>;
  title_original?: InputMaybe<Service_Title_Original_Operator>;
  updatedAt?: InputMaybe<Service_UpdatedAt_Operator>;
  vouchersEnabled?: InputMaybe<Service_VouchersEnabled_Operator>;
};

export type Service_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Service_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Service_Where_Or>>>;
  createdAt?: InputMaybe<Service_CreatedAt_Operator>;
  extras__extra?: InputMaybe<Service_Extras__Extra_Operator>;
  extras__id?: InputMaybe<Service_Extras__Id_Operator>;
  id?: InputMaybe<Service_Id_Operator>;
  image?: InputMaybe<Service_Image_Operator>;
  location?: InputMaybe<Service_Location_Operator>;
  longDescription?: InputMaybe<Service_LongDescription_Operator>;
  longDescription_en?: InputMaybe<Service_LongDescription_En_Operator>;
  longDescription_original?: InputMaybe<Service_LongDescription_Original_Operator>;
  maxAheadBookableDays?: InputMaybe<Service_MaxAheadBookableDays_Operator>;
  maxTicketCount?: InputMaybe<Service_MaxTicketCount_Operator>;
  previewUrl?: InputMaybe<Service_PreviewUrl_Operator>;
  shortDescription?: InputMaybe<Service_ShortDescription_Operator>;
  shortDescription_en?: InputMaybe<Service_ShortDescription_En_Operator>;
  shortDescription_original?: InputMaybe<Service_ShortDescription_Original_Operator>;
  tenant?: InputMaybe<Service_Tenant_Operator>;
  ticketTypes__id?: InputMaybe<Service_TicketTypes__Id_Operator>;
  ticketTypes__primary?: InputMaybe<Service_TicketTypes__Primary_Operator>;
  ticketTypes__ticketType?: InputMaybe<Service_TicketTypes__TicketType_Operator>;
  timeslots__end?: InputMaybe<Service_Timeslots__End_Operator>;
  timeslots__endDate?: InputMaybe<Service_Timeslots__EndDate_Operator>;
  timeslots__id?: InputMaybe<Service_Timeslots__Id_Operator>;
  timeslots__start?: InputMaybe<Service_Timeslots__Start_Operator>;
  timeslots__startDate?: InputMaybe<Service_Timeslots__StartDate_Operator>;
  timeslots__weekdays?: InputMaybe<Service_Timeslots__Weekdays_Operator>;
  title?: InputMaybe<Service_Title_Operator>;
  title_en?: InputMaybe<Service_Title_En_Operator>;
  title_original?: InputMaybe<Service_Title_Original_Operator>;
  updatedAt?: InputMaybe<Service_UpdatedAt_Operator>;
  vouchersEnabled?: InputMaybe<Service_VouchersEnabled_Operator>;
};

export type Services = {
  __typename?: 'Services';
  docs?: Maybe<Array<Maybe<Service>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type ServicesCreateAccess = {
  __typename?: 'ServicesCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ServicesCreateDocAccess = {
  __typename?: 'ServicesCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ServicesDeleteAccess = {
  __typename?: 'ServicesDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ServicesDeleteDocAccess = {
  __typename?: 'ServicesDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ServicesDocAccessFields = {
  __typename?: 'ServicesDocAccessFields';
  createdAt?: Maybe<ServicesDocAccessFields_CreatedAt>;
  extras?: Maybe<ServicesDocAccessFields_Extras>;
  image?: Maybe<ServicesDocAccessFields_Image>;
  location?: Maybe<ServicesDocAccessFields_Location>;
  longDescription?: Maybe<ServicesDocAccessFields_LongDescription>;
  longDescription_en?: Maybe<ServicesDocAccessFields_LongDescription_En>;
  longDescription_original?: Maybe<ServicesDocAccessFields_LongDescription_Original>;
  maxAheadBookableDays?: Maybe<ServicesDocAccessFields_MaxAheadBookableDays>;
  maxTicketCount?: Maybe<ServicesDocAccessFields_MaxTicketCount>;
  previewUrl?: Maybe<ServicesDocAccessFields_PreviewUrl>;
  shortDescription?: Maybe<ServicesDocAccessFields_ShortDescription>;
  shortDescription_en?: Maybe<ServicesDocAccessFields_ShortDescription_En>;
  shortDescription_original?: Maybe<ServicesDocAccessFields_ShortDescription_Original>;
  tenant?: Maybe<ServicesDocAccessFields_Tenant>;
  ticketTypes?: Maybe<ServicesDocAccessFields_TicketTypes>;
  timeslots?: Maybe<ServicesDocAccessFields_Timeslots>;
  title?: Maybe<ServicesDocAccessFields_Title>;
  title_en?: Maybe<ServicesDocAccessFields_Title_En>;
  title_original?: Maybe<ServicesDocAccessFields_Title_Original>;
  updatedAt?: Maybe<ServicesDocAccessFields_UpdatedAt>;
  vouchersEnabled?: Maybe<ServicesDocAccessFields_VouchersEnabled>;
};

export type ServicesDocAccessFields_CreatedAt = {
  __typename?: 'ServicesDocAccessFields_createdAt';
  create?: Maybe<ServicesDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<ServicesDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<ServicesDocAccessFields_CreatedAt_Read>;
  update?: Maybe<ServicesDocAccessFields_CreatedAt_Update>;
};

export type ServicesDocAccessFields_CreatedAt_Create = {
  __typename?: 'ServicesDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_CreatedAt_Delete = {
  __typename?: 'ServicesDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_CreatedAt_Read = {
  __typename?: 'ServicesDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_CreatedAt_Update = {
  __typename?: 'ServicesDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Extras = {
  __typename?: 'ServicesDocAccessFields_extras';
  create?: Maybe<ServicesDocAccessFields_Extras_Create>;
  delete?: Maybe<ServicesDocAccessFields_Extras_Delete>;
  fields?: Maybe<ServicesDocAccessFields_Extras_Fields>;
  read?: Maybe<ServicesDocAccessFields_Extras_Read>;
  update?: Maybe<ServicesDocAccessFields_Extras_Update>;
};

export type ServicesDocAccessFields_Extras_Create = {
  __typename?: 'ServicesDocAccessFields_extras_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Extras_Delete = {
  __typename?: 'ServicesDocAccessFields_extras_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Extras_Fields = {
  __typename?: 'ServicesDocAccessFields_extras_Fields';
  extra?: Maybe<ServicesDocAccessFields_Extras_Extra>;
  id?: Maybe<ServicesDocAccessFields_Extras_Id>;
};

export type ServicesDocAccessFields_Extras_Read = {
  __typename?: 'ServicesDocAccessFields_extras_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Extras_Update = {
  __typename?: 'ServicesDocAccessFields_extras_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Extras_Extra = {
  __typename?: 'ServicesDocAccessFields_extras_extra';
  create?: Maybe<ServicesDocAccessFields_Extras_Extra_Create>;
  delete?: Maybe<ServicesDocAccessFields_Extras_Extra_Delete>;
  read?: Maybe<ServicesDocAccessFields_Extras_Extra_Read>;
  update?: Maybe<ServicesDocAccessFields_Extras_Extra_Update>;
};

export type ServicesDocAccessFields_Extras_Extra_Create = {
  __typename?: 'ServicesDocAccessFields_extras_extra_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Extras_Extra_Delete = {
  __typename?: 'ServicesDocAccessFields_extras_extra_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Extras_Extra_Read = {
  __typename?: 'ServicesDocAccessFields_extras_extra_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Extras_Extra_Update = {
  __typename?: 'ServicesDocAccessFields_extras_extra_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Extras_Id = {
  __typename?: 'ServicesDocAccessFields_extras_id';
  create?: Maybe<ServicesDocAccessFields_Extras_Id_Create>;
  delete?: Maybe<ServicesDocAccessFields_Extras_Id_Delete>;
  read?: Maybe<ServicesDocAccessFields_Extras_Id_Read>;
  update?: Maybe<ServicesDocAccessFields_Extras_Id_Update>;
};

export type ServicesDocAccessFields_Extras_Id_Create = {
  __typename?: 'ServicesDocAccessFields_extras_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Extras_Id_Delete = {
  __typename?: 'ServicesDocAccessFields_extras_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Extras_Id_Read = {
  __typename?: 'ServicesDocAccessFields_extras_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Extras_Id_Update = {
  __typename?: 'ServicesDocAccessFields_extras_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Image = {
  __typename?: 'ServicesDocAccessFields_image';
  create?: Maybe<ServicesDocAccessFields_Image_Create>;
  delete?: Maybe<ServicesDocAccessFields_Image_Delete>;
  read?: Maybe<ServicesDocAccessFields_Image_Read>;
  update?: Maybe<ServicesDocAccessFields_Image_Update>;
};

export type ServicesDocAccessFields_Image_Create = {
  __typename?: 'ServicesDocAccessFields_image_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Image_Delete = {
  __typename?: 'ServicesDocAccessFields_image_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Image_Read = {
  __typename?: 'ServicesDocAccessFields_image_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Image_Update = {
  __typename?: 'ServicesDocAccessFields_image_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Location = {
  __typename?: 'ServicesDocAccessFields_location';
  create?: Maybe<ServicesDocAccessFields_Location_Create>;
  delete?: Maybe<ServicesDocAccessFields_Location_Delete>;
  read?: Maybe<ServicesDocAccessFields_Location_Read>;
  update?: Maybe<ServicesDocAccessFields_Location_Update>;
};

export type ServicesDocAccessFields_Location_Create = {
  __typename?: 'ServicesDocAccessFields_location_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Location_Delete = {
  __typename?: 'ServicesDocAccessFields_location_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Location_Read = {
  __typename?: 'ServicesDocAccessFields_location_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Location_Update = {
  __typename?: 'ServicesDocAccessFields_location_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_LongDescription = {
  __typename?: 'ServicesDocAccessFields_longDescription';
  create?: Maybe<ServicesDocAccessFields_LongDescription_Create>;
  delete?: Maybe<ServicesDocAccessFields_LongDescription_Delete>;
  read?: Maybe<ServicesDocAccessFields_LongDescription_Read>;
  update?: Maybe<ServicesDocAccessFields_LongDescription_Update>;
};

export type ServicesDocAccessFields_LongDescription_Create = {
  __typename?: 'ServicesDocAccessFields_longDescription_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_LongDescription_Delete = {
  __typename?: 'ServicesDocAccessFields_longDescription_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_LongDescription_Read = {
  __typename?: 'ServicesDocAccessFields_longDescription_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_LongDescription_Update = {
  __typename?: 'ServicesDocAccessFields_longDescription_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_LongDescription_En = {
  __typename?: 'ServicesDocAccessFields_longDescription_en';
  create?: Maybe<ServicesDocAccessFields_LongDescription_En_Create>;
  delete?: Maybe<ServicesDocAccessFields_LongDescription_En_Delete>;
  read?: Maybe<ServicesDocAccessFields_LongDescription_En_Read>;
  update?: Maybe<ServicesDocAccessFields_LongDescription_En_Update>;
};

export type ServicesDocAccessFields_LongDescription_En_Create = {
  __typename?: 'ServicesDocAccessFields_longDescription_en_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_LongDescription_En_Delete = {
  __typename?: 'ServicesDocAccessFields_longDescription_en_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_LongDescription_En_Read = {
  __typename?: 'ServicesDocAccessFields_longDescription_en_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_LongDescription_En_Update = {
  __typename?: 'ServicesDocAccessFields_longDescription_en_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_LongDescription_Original = {
  __typename?: 'ServicesDocAccessFields_longDescription_original';
  create?: Maybe<ServicesDocAccessFields_LongDescription_Original_Create>;
  delete?: Maybe<ServicesDocAccessFields_LongDescription_Original_Delete>;
  read?: Maybe<ServicesDocAccessFields_LongDescription_Original_Read>;
  update?: Maybe<ServicesDocAccessFields_LongDescription_Original_Update>;
};

export type ServicesDocAccessFields_LongDescription_Original_Create = {
  __typename?: 'ServicesDocAccessFields_longDescription_original_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_LongDescription_Original_Delete = {
  __typename?: 'ServicesDocAccessFields_longDescription_original_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_LongDescription_Original_Read = {
  __typename?: 'ServicesDocAccessFields_longDescription_original_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_LongDescription_Original_Update = {
  __typename?: 'ServicesDocAccessFields_longDescription_original_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_MaxAheadBookableDays = {
  __typename?: 'ServicesDocAccessFields_maxAheadBookableDays';
  create?: Maybe<ServicesDocAccessFields_MaxAheadBookableDays_Create>;
  delete?: Maybe<ServicesDocAccessFields_MaxAheadBookableDays_Delete>;
  read?: Maybe<ServicesDocAccessFields_MaxAheadBookableDays_Read>;
  update?: Maybe<ServicesDocAccessFields_MaxAheadBookableDays_Update>;
};

export type ServicesDocAccessFields_MaxAheadBookableDays_Create = {
  __typename?: 'ServicesDocAccessFields_maxAheadBookableDays_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_MaxAheadBookableDays_Delete = {
  __typename?: 'ServicesDocAccessFields_maxAheadBookableDays_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_MaxAheadBookableDays_Read = {
  __typename?: 'ServicesDocAccessFields_maxAheadBookableDays_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_MaxAheadBookableDays_Update = {
  __typename?: 'ServicesDocAccessFields_maxAheadBookableDays_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_MaxTicketCount = {
  __typename?: 'ServicesDocAccessFields_maxTicketCount';
  create?: Maybe<ServicesDocAccessFields_MaxTicketCount_Create>;
  delete?: Maybe<ServicesDocAccessFields_MaxTicketCount_Delete>;
  read?: Maybe<ServicesDocAccessFields_MaxTicketCount_Read>;
  update?: Maybe<ServicesDocAccessFields_MaxTicketCount_Update>;
};

export type ServicesDocAccessFields_MaxTicketCount_Create = {
  __typename?: 'ServicesDocAccessFields_maxTicketCount_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_MaxTicketCount_Delete = {
  __typename?: 'ServicesDocAccessFields_maxTicketCount_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_MaxTicketCount_Read = {
  __typename?: 'ServicesDocAccessFields_maxTicketCount_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_MaxTicketCount_Update = {
  __typename?: 'ServicesDocAccessFields_maxTicketCount_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_PreviewUrl = {
  __typename?: 'ServicesDocAccessFields_previewUrl';
  create?: Maybe<ServicesDocAccessFields_PreviewUrl_Create>;
  delete?: Maybe<ServicesDocAccessFields_PreviewUrl_Delete>;
  read?: Maybe<ServicesDocAccessFields_PreviewUrl_Read>;
  update?: Maybe<ServicesDocAccessFields_PreviewUrl_Update>;
};

export type ServicesDocAccessFields_PreviewUrl_Create = {
  __typename?: 'ServicesDocAccessFields_previewUrl_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_PreviewUrl_Delete = {
  __typename?: 'ServicesDocAccessFields_previewUrl_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_PreviewUrl_Read = {
  __typename?: 'ServicesDocAccessFields_previewUrl_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_PreviewUrl_Update = {
  __typename?: 'ServicesDocAccessFields_previewUrl_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_ShortDescription = {
  __typename?: 'ServicesDocAccessFields_shortDescription';
  create?: Maybe<ServicesDocAccessFields_ShortDescription_Create>;
  delete?: Maybe<ServicesDocAccessFields_ShortDescription_Delete>;
  read?: Maybe<ServicesDocAccessFields_ShortDescription_Read>;
  update?: Maybe<ServicesDocAccessFields_ShortDescription_Update>;
};

export type ServicesDocAccessFields_ShortDescription_Create = {
  __typename?: 'ServicesDocAccessFields_shortDescription_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_ShortDescription_Delete = {
  __typename?: 'ServicesDocAccessFields_shortDescription_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_ShortDescription_Read = {
  __typename?: 'ServicesDocAccessFields_shortDescription_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_ShortDescription_Update = {
  __typename?: 'ServicesDocAccessFields_shortDescription_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_ShortDescription_En = {
  __typename?: 'ServicesDocAccessFields_shortDescription_en';
  create?: Maybe<ServicesDocAccessFields_ShortDescription_En_Create>;
  delete?: Maybe<ServicesDocAccessFields_ShortDescription_En_Delete>;
  read?: Maybe<ServicesDocAccessFields_ShortDescription_En_Read>;
  update?: Maybe<ServicesDocAccessFields_ShortDescription_En_Update>;
};

export type ServicesDocAccessFields_ShortDescription_En_Create = {
  __typename?: 'ServicesDocAccessFields_shortDescription_en_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_ShortDescription_En_Delete = {
  __typename?: 'ServicesDocAccessFields_shortDescription_en_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_ShortDescription_En_Read = {
  __typename?: 'ServicesDocAccessFields_shortDescription_en_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_ShortDescription_En_Update = {
  __typename?: 'ServicesDocAccessFields_shortDescription_en_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_ShortDescription_Original = {
  __typename?: 'ServicesDocAccessFields_shortDescription_original';
  create?: Maybe<ServicesDocAccessFields_ShortDescription_Original_Create>;
  delete?: Maybe<ServicesDocAccessFields_ShortDescription_Original_Delete>;
  read?: Maybe<ServicesDocAccessFields_ShortDescription_Original_Read>;
  update?: Maybe<ServicesDocAccessFields_ShortDescription_Original_Update>;
};

export type ServicesDocAccessFields_ShortDescription_Original_Create = {
  __typename?: 'ServicesDocAccessFields_shortDescription_original_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_ShortDescription_Original_Delete = {
  __typename?: 'ServicesDocAccessFields_shortDescription_original_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_ShortDescription_Original_Read = {
  __typename?: 'ServicesDocAccessFields_shortDescription_original_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_ShortDescription_Original_Update = {
  __typename?: 'ServicesDocAccessFields_shortDescription_original_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Tenant = {
  __typename?: 'ServicesDocAccessFields_tenant';
  create?: Maybe<ServicesDocAccessFields_Tenant_Create>;
  delete?: Maybe<ServicesDocAccessFields_Tenant_Delete>;
  read?: Maybe<ServicesDocAccessFields_Tenant_Read>;
  update?: Maybe<ServicesDocAccessFields_Tenant_Update>;
};

export type ServicesDocAccessFields_Tenant_Create = {
  __typename?: 'ServicesDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Tenant_Delete = {
  __typename?: 'ServicesDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Tenant_Read = {
  __typename?: 'ServicesDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Tenant_Update = {
  __typename?: 'ServicesDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes = {
  __typename?: 'ServicesDocAccessFields_ticketTypes';
  create?: Maybe<ServicesDocAccessFields_TicketTypes_Create>;
  delete?: Maybe<ServicesDocAccessFields_TicketTypes_Delete>;
  fields?: Maybe<ServicesDocAccessFields_TicketTypes_Fields>;
  read?: Maybe<ServicesDocAccessFields_TicketTypes_Read>;
  update?: Maybe<ServicesDocAccessFields_TicketTypes_Update>;
};

export type ServicesDocAccessFields_TicketTypes_Create = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_Delete = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_Fields = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_Fields';
  id?: Maybe<ServicesDocAccessFields_TicketTypes_Id>;
  primary?: Maybe<ServicesDocAccessFields_TicketTypes_Primary>;
  ticketType?: Maybe<ServicesDocAccessFields_TicketTypes_TicketType>;
};

export type ServicesDocAccessFields_TicketTypes_Read = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_Update = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_Id = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_id';
  create?: Maybe<ServicesDocAccessFields_TicketTypes_Id_Create>;
  delete?: Maybe<ServicesDocAccessFields_TicketTypes_Id_Delete>;
  read?: Maybe<ServicesDocAccessFields_TicketTypes_Id_Read>;
  update?: Maybe<ServicesDocAccessFields_TicketTypes_Id_Update>;
};

export type ServicesDocAccessFields_TicketTypes_Id_Create = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_Id_Delete = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_Id_Read = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_Id_Update = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_Primary = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_primary';
  create?: Maybe<ServicesDocAccessFields_TicketTypes_Primary_Create>;
  delete?: Maybe<ServicesDocAccessFields_TicketTypes_Primary_Delete>;
  read?: Maybe<ServicesDocAccessFields_TicketTypes_Primary_Read>;
  update?: Maybe<ServicesDocAccessFields_TicketTypes_Primary_Update>;
};

export type ServicesDocAccessFields_TicketTypes_Primary_Create = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_primary_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_Primary_Delete = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_primary_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_Primary_Read = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_primary_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_Primary_Update = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_primary_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_TicketType = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_ticketType';
  create?: Maybe<ServicesDocAccessFields_TicketTypes_TicketType_Create>;
  delete?: Maybe<ServicesDocAccessFields_TicketTypes_TicketType_Delete>;
  read?: Maybe<ServicesDocAccessFields_TicketTypes_TicketType_Read>;
  update?: Maybe<ServicesDocAccessFields_TicketTypes_TicketType_Update>;
};

export type ServicesDocAccessFields_TicketTypes_TicketType_Create = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_ticketType_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_TicketType_Delete = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_ticketType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_TicketType_Read = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_ticketType_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_TicketTypes_TicketType_Update = {
  __typename?: 'ServicesDocAccessFields_ticketTypes_ticketType_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots = {
  __typename?: 'ServicesDocAccessFields_timeslots';
  create?: Maybe<ServicesDocAccessFields_Timeslots_Create>;
  delete?: Maybe<ServicesDocAccessFields_Timeslots_Delete>;
  fields?: Maybe<ServicesDocAccessFields_Timeslots_Fields>;
  read?: Maybe<ServicesDocAccessFields_Timeslots_Read>;
  update?: Maybe<ServicesDocAccessFields_Timeslots_Update>;
};

export type ServicesDocAccessFields_Timeslots_Create = {
  __typename?: 'ServicesDocAccessFields_timeslots_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Delete = {
  __typename?: 'ServicesDocAccessFields_timeslots_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Fields = {
  __typename?: 'ServicesDocAccessFields_timeslots_Fields';
  end?: Maybe<ServicesDocAccessFields_Timeslots_End>;
  endDate?: Maybe<ServicesDocAccessFields_Timeslots_EndDate>;
  id?: Maybe<ServicesDocAccessFields_Timeslots_Id>;
  start?: Maybe<ServicesDocAccessFields_Timeslots_Start>;
  startDate?: Maybe<ServicesDocAccessFields_Timeslots_StartDate>;
  weekdays?: Maybe<ServicesDocAccessFields_Timeslots_Weekdays>;
};

export type ServicesDocAccessFields_Timeslots_Read = {
  __typename?: 'ServicesDocAccessFields_timeslots_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Update = {
  __typename?: 'ServicesDocAccessFields_timeslots_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_End = {
  __typename?: 'ServicesDocAccessFields_timeslots_end';
  create?: Maybe<ServicesDocAccessFields_Timeslots_End_Create>;
  delete?: Maybe<ServicesDocAccessFields_Timeslots_End_Delete>;
  read?: Maybe<ServicesDocAccessFields_Timeslots_End_Read>;
  update?: Maybe<ServicesDocAccessFields_Timeslots_End_Update>;
};

export type ServicesDocAccessFields_Timeslots_EndDate = {
  __typename?: 'ServicesDocAccessFields_timeslots_endDate';
  create?: Maybe<ServicesDocAccessFields_Timeslots_EndDate_Create>;
  delete?: Maybe<ServicesDocAccessFields_Timeslots_EndDate_Delete>;
  read?: Maybe<ServicesDocAccessFields_Timeslots_EndDate_Read>;
  update?: Maybe<ServicesDocAccessFields_Timeslots_EndDate_Update>;
};

export type ServicesDocAccessFields_Timeslots_EndDate_Create = {
  __typename?: 'ServicesDocAccessFields_timeslots_endDate_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_EndDate_Delete = {
  __typename?: 'ServicesDocAccessFields_timeslots_endDate_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_EndDate_Read = {
  __typename?: 'ServicesDocAccessFields_timeslots_endDate_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_EndDate_Update = {
  __typename?: 'ServicesDocAccessFields_timeslots_endDate_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_End_Create = {
  __typename?: 'ServicesDocAccessFields_timeslots_end_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_End_Delete = {
  __typename?: 'ServicesDocAccessFields_timeslots_end_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_End_Read = {
  __typename?: 'ServicesDocAccessFields_timeslots_end_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_End_Update = {
  __typename?: 'ServicesDocAccessFields_timeslots_end_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Id = {
  __typename?: 'ServicesDocAccessFields_timeslots_id';
  create?: Maybe<ServicesDocAccessFields_Timeslots_Id_Create>;
  delete?: Maybe<ServicesDocAccessFields_Timeslots_Id_Delete>;
  read?: Maybe<ServicesDocAccessFields_Timeslots_Id_Read>;
  update?: Maybe<ServicesDocAccessFields_Timeslots_Id_Update>;
};

export type ServicesDocAccessFields_Timeslots_Id_Create = {
  __typename?: 'ServicesDocAccessFields_timeslots_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Id_Delete = {
  __typename?: 'ServicesDocAccessFields_timeslots_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Id_Read = {
  __typename?: 'ServicesDocAccessFields_timeslots_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Id_Update = {
  __typename?: 'ServicesDocAccessFields_timeslots_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Start = {
  __typename?: 'ServicesDocAccessFields_timeslots_start';
  create?: Maybe<ServicesDocAccessFields_Timeslots_Start_Create>;
  delete?: Maybe<ServicesDocAccessFields_Timeslots_Start_Delete>;
  read?: Maybe<ServicesDocAccessFields_Timeslots_Start_Read>;
  update?: Maybe<ServicesDocAccessFields_Timeslots_Start_Update>;
};

export type ServicesDocAccessFields_Timeslots_StartDate = {
  __typename?: 'ServicesDocAccessFields_timeslots_startDate';
  create?: Maybe<ServicesDocAccessFields_Timeslots_StartDate_Create>;
  delete?: Maybe<ServicesDocAccessFields_Timeslots_StartDate_Delete>;
  read?: Maybe<ServicesDocAccessFields_Timeslots_StartDate_Read>;
  update?: Maybe<ServicesDocAccessFields_Timeslots_StartDate_Update>;
};

export type ServicesDocAccessFields_Timeslots_StartDate_Create = {
  __typename?: 'ServicesDocAccessFields_timeslots_startDate_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_StartDate_Delete = {
  __typename?: 'ServicesDocAccessFields_timeslots_startDate_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_StartDate_Read = {
  __typename?: 'ServicesDocAccessFields_timeslots_startDate_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_StartDate_Update = {
  __typename?: 'ServicesDocAccessFields_timeslots_startDate_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Start_Create = {
  __typename?: 'ServicesDocAccessFields_timeslots_start_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Start_Delete = {
  __typename?: 'ServicesDocAccessFields_timeslots_start_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Start_Read = {
  __typename?: 'ServicesDocAccessFields_timeslots_start_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Start_Update = {
  __typename?: 'ServicesDocAccessFields_timeslots_start_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Weekdays = {
  __typename?: 'ServicesDocAccessFields_timeslots_weekdays';
  create?: Maybe<ServicesDocAccessFields_Timeslots_Weekdays_Create>;
  delete?: Maybe<ServicesDocAccessFields_Timeslots_Weekdays_Delete>;
  read?: Maybe<ServicesDocAccessFields_Timeslots_Weekdays_Read>;
  update?: Maybe<ServicesDocAccessFields_Timeslots_Weekdays_Update>;
};

export type ServicesDocAccessFields_Timeslots_Weekdays_Create = {
  __typename?: 'ServicesDocAccessFields_timeslots_weekdays_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Weekdays_Delete = {
  __typename?: 'ServicesDocAccessFields_timeslots_weekdays_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Weekdays_Read = {
  __typename?: 'ServicesDocAccessFields_timeslots_weekdays_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Timeslots_Weekdays_Update = {
  __typename?: 'ServicesDocAccessFields_timeslots_weekdays_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Title = {
  __typename?: 'ServicesDocAccessFields_title';
  create?: Maybe<ServicesDocAccessFields_Title_Create>;
  delete?: Maybe<ServicesDocAccessFields_Title_Delete>;
  read?: Maybe<ServicesDocAccessFields_Title_Read>;
  update?: Maybe<ServicesDocAccessFields_Title_Update>;
};

export type ServicesDocAccessFields_Title_Create = {
  __typename?: 'ServicesDocAccessFields_title_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Title_Delete = {
  __typename?: 'ServicesDocAccessFields_title_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Title_Read = {
  __typename?: 'ServicesDocAccessFields_title_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Title_Update = {
  __typename?: 'ServicesDocAccessFields_title_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Title_En = {
  __typename?: 'ServicesDocAccessFields_title_en';
  create?: Maybe<ServicesDocAccessFields_Title_En_Create>;
  delete?: Maybe<ServicesDocAccessFields_Title_En_Delete>;
  read?: Maybe<ServicesDocAccessFields_Title_En_Read>;
  update?: Maybe<ServicesDocAccessFields_Title_En_Update>;
};

export type ServicesDocAccessFields_Title_En_Create = {
  __typename?: 'ServicesDocAccessFields_title_en_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Title_En_Delete = {
  __typename?: 'ServicesDocAccessFields_title_en_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Title_En_Read = {
  __typename?: 'ServicesDocAccessFields_title_en_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Title_En_Update = {
  __typename?: 'ServicesDocAccessFields_title_en_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Title_Original = {
  __typename?: 'ServicesDocAccessFields_title_original';
  create?: Maybe<ServicesDocAccessFields_Title_Original_Create>;
  delete?: Maybe<ServicesDocAccessFields_Title_Original_Delete>;
  read?: Maybe<ServicesDocAccessFields_Title_Original_Read>;
  update?: Maybe<ServicesDocAccessFields_Title_Original_Update>;
};

export type ServicesDocAccessFields_Title_Original_Create = {
  __typename?: 'ServicesDocAccessFields_title_original_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Title_Original_Delete = {
  __typename?: 'ServicesDocAccessFields_title_original_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Title_Original_Read = {
  __typename?: 'ServicesDocAccessFields_title_original_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_Title_Original_Update = {
  __typename?: 'ServicesDocAccessFields_title_original_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_UpdatedAt = {
  __typename?: 'ServicesDocAccessFields_updatedAt';
  create?: Maybe<ServicesDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<ServicesDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<ServicesDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<ServicesDocAccessFields_UpdatedAt_Update>;
};

export type ServicesDocAccessFields_UpdatedAt_Create = {
  __typename?: 'ServicesDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'ServicesDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_UpdatedAt_Read = {
  __typename?: 'ServicesDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_UpdatedAt_Update = {
  __typename?: 'ServicesDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_VouchersEnabled = {
  __typename?: 'ServicesDocAccessFields_vouchersEnabled';
  create?: Maybe<ServicesDocAccessFields_VouchersEnabled_Create>;
  delete?: Maybe<ServicesDocAccessFields_VouchersEnabled_Delete>;
  read?: Maybe<ServicesDocAccessFields_VouchersEnabled_Read>;
  update?: Maybe<ServicesDocAccessFields_VouchersEnabled_Update>;
};

export type ServicesDocAccessFields_VouchersEnabled_Create = {
  __typename?: 'ServicesDocAccessFields_vouchersEnabled_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_VouchersEnabled_Delete = {
  __typename?: 'ServicesDocAccessFields_vouchersEnabled_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_VouchersEnabled_Read = {
  __typename?: 'ServicesDocAccessFields_vouchersEnabled_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesDocAccessFields_VouchersEnabled_Update = {
  __typename?: 'ServicesDocAccessFields_vouchersEnabled_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields = {
  __typename?: 'ServicesFields';
  createdAt?: Maybe<ServicesFields_CreatedAt>;
  extras?: Maybe<ServicesFields_Extras>;
  image?: Maybe<ServicesFields_Image>;
  location?: Maybe<ServicesFields_Location>;
  longDescription?: Maybe<ServicesFields_LongDescription>;
  longDescription_en?: Maybe<ServicesFields_LongDescription_En>;
  longDescription_original?: Maybe<ServicesFields_LongDescription_Original>;
  maxAheadBookableDays?: Maybe<ServicesFields_MaxAheadBookableDays>;
  maxTicketCount?: Maybe<ServicesFields_MaxTicketCount>;
  previewUrl?: Maybe<ServicesFields_PreviewUrl>;
  shortDescription?: Maybe<ServicesFields_ShortDescription>;
  shortDescription_en?: Maybe<ServicesFields_ShortDescription_En>;
  shortDescription_original?: Maybe<ServicesFields_ShortDescription_Original>;
  tenant?: Maybe<ServicesFields_Tenant>;
  ticketTypes?: Maybe<ServicesFields_TicketTypes>;
  timeslots?: Maybe<ServicesFields_Timeslots>;
  title?: Maybe<ServicesFields_Title>;
  title_en?: Maybe<ServicesFields_Title_En>;
  title_original?: Maybe<ServicesFields_Title_Original>;
  updatedAt?: Maybe<ServicesFields_UpdatedAt>;
  vouchersEnabled?: Maybe<ServicesFields_VouchersEnabled>;
};

export type ServicesFields_CreatedAt = {
  __typename?: 'ServicesFields_createdAt';
  create?: Maybe<ServicesFields_CreatedAt_Create>;
  delete?: Maybe<ServicesFields_CreatedAt_Delete>;
  read?: Maybe<ServicesFields_CreatedAt_Read>;
  update?: Maybe<ServicesFields_CreatedAt_Update>;
};

export type ServicesFields_CreatedAt_Create = {
  __typename?: 'ServicesFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_CreatedAt_Delete = {
  __typename?: 'ServicesFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_CreatedAt_Read = {
  __typename?: 'ServicesFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_CreatedAt_Update = {
  __typename?: 'ServicesFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Extras = {
  __typename?: 'ServicesFields_extras';
  create?: Maybe<ServicesFields_Extras_Create>;
  delete?: Maybe<ServicesFields_Extras_Delete>;
  fields?: Maybe<ServicesFields_Extras_Fields>;
  read?: Maybe<ServicesFields_Extras_Read>;
  update?: Maybe<ServicesFields_Extras_Update>;
};

export type ServicesFields_Extras_Create = {
  __typename?: 'ServicesFields_extras_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Extras_Delete = {
  __typename?: 'ServicesFields_extras_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Extras_Fields = {
  __typename?: 'ServicesFields_extras_Fields';
  extra?: Maybe<ServicesFields_Extras_Extra>;
  id?: Maybe<ServicesFields_Extras_Id>;
};

export type ServicesFields_Extras_Read = {
  __typename?: 'ServicesFields_extras_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Extras_Update = {
  __typename?: 'ServicesFields_extras_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Extras_Extra = {
  __typename?: 'ServicesFields_extras_extra';
  create?: Maybe<ServicesFields_Extras_Extra_Create>;
  delete?: Maybe<ServicesFields_Extras_Extra_Delete>;
  read?: Maybe<ServicesFields_Extras_Extra_Read>;
  update?: Maybe<ServicesFields_Extras_Extra_Update>;
};

export type ServicesFields_Extras_Extra_Create = {
  __typename?: 'ServicesFields_extras_extra_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Extras_Extra_Delete = {
  __typename?: 'ServicesFields_extras_extra_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Extras_Extra_Read = {
  __typename?: 'ServicesFields_extras_extra_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Extras_Extra_Update = {
  __typename?: 'ServicesFields_extras_extra_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Extras_Id = {
  __typename?: 'ServicesFields_extras_id';
  create?: Maybe<ServicesFields_Extras_Id_Create>;
  delete?: Maybe<ServicesFields_Extras_Id_Delete>;
  read?: Maybe<ServicesFields_Extras_Id_Read>;
  update?: Maybe<ServicesFields_Extras_Id_Update>;
};

export type ServicesFields_Extras_Id_Create = {
  __typename?: 'ServicesFields_extras_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Extras_Id_Delete = {
  __typename?: 'ServicesFields_extras_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Extras_Id_Read = {
  __typename?: 'ServicesFields_extras_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Extras_Id_Update = {
  __typename?: 'ServicesFields_extras_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Image = {
  __typename?: 'ServicesFields_image';
  create?: Maybe<ServicesFields_Image_Create>;
  delete?: Maybe<ServicesFields_Image_Delete>;
  read?: Maybe<ServicesFields_Image_Read>;
  update?: Maybe<ServicesFields_Image_Update>;
};

export type ServicesFields_Image_Create = {
  __typename?: 'ServicesFields_image_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Image_Delete = {
  __typename?: 'ServicesFields_image_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Image_Read = {
  __typename?: 'ServicesFields_image_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Image_Update = {
  __typename?: 'ServicesFields_image_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Location = {
  __typename?: 'ServicesFields_location';
  create?: Maybe<ServicesFields_Location_Create>;
  delete?: Maybe<ServicesFields_Location_Delete>;
  read?: Maybe<ServicesFields_Location_Read>;
  update?: Maybe<ServicesFields_Location_Update>;
};

export type ServicesFields_Location_Create = {
  __typename?: 'ServicesFields_location_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Location_Delete = {
  __typename?: 'ServicesFields_location_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Location_Read = {
  __typename?: 'ServicesFields_location_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Location_Update = {
  __typename?: 'ServicesFields_location_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_LongDescription = {
  __typename?: 'ServicesFields_longDescription';
  create?: Maybe<ServicesFields_LongDescription_Create>;
  delete?: Maybe<ServicesFields_LongDescription_Delete>;
  read?: Maybe<ServicesFields_LongDescription_Read>;
  update?: Maybe<ServicesFields_LongDescription_Update>;
};

export type ServicesFields_LongDescription_Create = {
  __typename?: 'ServicesFields_longDescription_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_LongDescription_Delete = {
  __typename?: 'ServicesFields_longDescription_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_LongDescription_Read = {
  __typename?: 'ServicesFields_longDescription_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_LongDescription_Update = {
  __typename?: 'ServicesFields_longDescription_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_LongDescription_En = {
  __typename?: 'ServicesFields_longDescription_en';
  create?: Maybe<ServicesFields_LongDescription_En_Create>;
  delete?: Maybe<ServicesFields_LongDescription_En_Delete>;
  read?: Maybe<ServicesFields_LongDescription_En_Read>;
  update?: Maybe<ServicesFields_LongDescription_En_Update>;
};

export type ServicesFields_LongDescription_En_Create = {
  __typename?: 'ServicesFields_longDescription_en_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_LongDescription_En_Delete = {
  __typename?: 'ServicesFields_longDescription_en_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_LongDescription_En_Read = {
  __typename?: 'ServicesFields_longDescription_en_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_LongDescription_En_Update = {
  __typename?: 'ServicesFields_longDescription_en_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_LongDescription_Original = {
  __typename?: 'ServicesFields_longDescription_original';
  create?: Maybe<ServicesFields_LongDescription_Original_Create>;
  delete?: Maybe<ServicesFields_LongDescription_Original_Delete>;
  read?: Maybe<ServicesFields_LongDescription_Original_Read>;
  update?: Maybe<ServicesFields_LongDescription_Original_Update>;
};

export type ServicesFields_LongDescription_Original_Create = {
  __typename?: 'ServicesFields_longDescription_original_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_LongDescription_Original_Delete = {
  __typename?: 'ServicesFields_longDescription_original_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_LongDescription_Original_Read = {
  __typename?: 'ServicesFields_longDescription_original_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_LongDescription_Original_Update = {
  __typename?: 'ServicesFields_longDescription_original_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_MaxAheadBookableDays = {
  __typename?: 'ServicesFields_maxAheadBookableDays';
  create?: Maybe<ServicesFields_MaxAheadBookableDays_Create>;
  delete?: Maybe<ServicesFields_MaxAheadBookableDays_Delete>;
  read?: Maybe<ServicesFields_MaxAheadBookableDays_Read>;
  update?: Maybe<ServicesFields_MaxAheadBookableDays_Update>;
};

export type ServicesFields_MaxAheadBookableDays_Create = {
  __typename?: 'ServicesFields_maxAheadBookableDays_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_MaxAheadBookableDays_Delete = {
  __typename?: 'ServicesFields_maxAheadBookableDays_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_MaxAheadBookableDays_Read = {
  __typename?: 'ServicesFields_maxAheadBookableDays_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_MaxAheadBookableDays_Update = {
  __typename?: 'ServicesFields_maxAheadBookableDays_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_MaxTicketCount = {
  __typename?: 'ServicesFields_maxTicketCount';
  create?: Maybe<ServicesFields_MaxTicketCount_Create>;
  delete?: Maybe<ServicesFields_MaxTicketCount_Delete>;
  read?: Maybe<ServicesFields_MaxTicketCount_Read>;
  update?: Maybe<ServicesFields_MaxTicketCount_Update>;
};

export type ServicesFields_MaxTicketCount_Create = {
  __typename?: 'ServicesFields_maxTicketCount_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_MaxTicketCount_Delete = {
  __typename?: 'ServicesFields_maxTicketCount_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_MaxTicketCount_Read = {
  __typename?: 'ServicesFields_maxTicketCount_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_MaxTicketCount_Update = {
  __typename?: 'ServicesFields_maxTicketCount_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_PreviewUrl = {
  __typename?: 'ServicesFields_previewUrl';
  create?: Maybe<ServicesFields_PreviewUrl_Create>;
  delete?: Maybe<ServicesFields_PreviewUrl_Delete>;
  read?: Maybe<ServicesFields_PreviewUrl_Read>;
  update?: Maybe<ServicesFields_PreviewUrl_Update>;
};

export type ServicesFields_PreviewUrl_Create = {
  __typename?: 'ServicesFields_previewUrl_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_PreviewUrl_Delete = {
  __typename?: 'ServicesFields_previewUrl_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_PreviewUrl_Read = {
  __typename?: 'ServicesFields_previewUrl_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_PreviewUrl_Update = {
  __typename?: 'ServicesFields_previewUrl_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_ShortDescription = {
  __typename?: 'ServicesFields_shortDescription';
  create?: Maybe<ServicesFields_ShortDescription_Create>;
  delete?: Maybe<ServicesFields_ShortDescription_Delete>;
  read?: Maybe<ServicesFields_ShortDescription_Read>;
  update?: Maybe<ServicesFields_ShortDescription_Update>;
};

export type ServicesFields_ShortDescription_Create = {
  __typename?: 'ServicesFields_shortDescription_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_ShortDescription_Delete = {
  __typename?: 'ServicesFields_shortDescription_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_ShortDescription_Read = {
  __typename?: 'ServicesFields_shortDescription_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_ShortDescription_Update = {
  __typename?: 'ServicesFields_shortDescription_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_ShortDescription_En = {
  __typename?: 'ServicesFields_shortDescription_en';
  create?: Maybe<ServicesFields_ShortDescription_En_Create>;
  delete?: Maybe<ServicesFields_ShortDescription_En_Delete>;
  read?: Maybe<ServicesFields_ShortDescription_En_Read>;
  update?: Maybe<ServicesFields_ShortDescription_En_Update>;
};

export type ServicesFields_ShortDescription_En_Create = {
  __typename?: 'ServicesFields_shortDescription_en_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_ShortDescription_En_Delete = {
  __typename?: 'ServicesFields_shortDescription_en_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_ShortDescription_En_Read = {
  __typename?: 'ServicesFields_shortDescription_en_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_ShortDescription_En_Update = {
  __typename?: 'ServicesFields_shortDescription_en_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_ShortDescription_Original = {
  __typename?: 'ServicesFields_shortDescription_original';
  create?: Maybe<ServicesFields_ShortDescription_Original_Create>;
  delete?: Maybe<ServicesFields_ShortDescription_Original_Delete>;
  read?: Maybe<ServicesFields_ShortDescription_Original_Read>;
  update?: Maybe<ServicesFields_ShortDescription_Original_Update>;
};

export type ServicesFields_ShortDescription_Original_Create = {
  __typename?: 'ServicesFields_shortDescription_original_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_ShortDescription_Original_Delete = {
  __typename?: 'ServicesFields_shortDescription_original_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_ShortDescription_Original_Read = {
  __typename?: 'ServicesFields_shortDescription_original_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_ShortDescription_Original_Update = {
  __typename?: 'ServicesFields_shortDescription_original_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Tenant = {
  __typename?: 'ServicesFields_tenant';
  create?: Maybe<ServicesFields_Tenant_Create>;
  delete?: Maybe<ServicesFields_Tenant_Delete>;
  read?: Maybe<ServicesFields_Tenant_Read>;
  update?: Maybe<ServicesFields_Tenant_Update>;
};

export type ServicesFields_Tenant_Create = {
  __typename?: 'ServicesFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Tenant_Delete = {
  __typename?: 'ServicesFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Tenant_Read = {
  __typename?: 'ServicesFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Tenant_Update = {
  __typename?: 'ServicesFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes = {
  __typename?: 'ServicesFields_ticketTypes';
  create?: Maybe<ServicesFields_TicketTypes_Create>;
  delete?: Maybe<ServicesFields_TicketTypes_Delete>;
  fields?: Maybe<ServicesFields_TicketTypes_Fields>;
  read?: Maybe<ServicesFields_TicketTypes_Read>;
  update?: Maybe<ServicesFields_TicketTypes_Update>;
};

export type ServicesFields_TicketTypes_Create = {
  __typename?: 'ServicesFields_ticketTypes_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_Delete = {
  __typename?: 'ServicesFields_ticketTypes_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_Fields = {
  __typename?: 'ServicesFields_ticketTypes_Fields';
  id?: Maybe<ServicesFields_TicketTypes_Id>;
  primary?: Maybe<ServicesFields_TicketTypes_Primary>;
  ticketType?: Maybe<ServicesFields_TicketTypes_TicketType>;
};

export type ServicesFields_TicketTypes_Read = {
  __typename?: 'ServicesFields_ticketTypes_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_Update = {
  __typename?: 'ServicesFields_ticketTypes_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_Id = {
  __typename?: 'ServicesFields_ticketTypes_id';
  create?: Maybe<ServicesFields_TicketTypes_Id_Create>;
  delete?: Maybe<ServicesFields_TicketTypes_Id_Delete>;
  read?: Maybe<ServicesFields_TicketTypes_Id_Read>;
  update?: Maybe<ServicesFields_TicketTypes_Id_Update>;
};

export type ServicesFields_TicketTypes_Id_Create = {
  __typename?: 'ServicesFields_ticketTypes_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_Id_Delete = {
  __typename?: 'ServicesFields_ticketTypes_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_Id_Read = {
  __typename?: 'ServicesFields_ticketTypes_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_Id_Update = {
  __typename?: 'ServicesFields_ticketTypes_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_Primary = {
  __typename?: 'ServicesFields_ticketTypes_primary';
  create?: Maybe<ServicesFields_TicketTypes_Primary_Create>;
  delete?: Maybe<ServicesFields_TicketTypes_Primary_Delete>;
  read?: Maybe<ServicesFields_TicketTypes_Primary_Read>;
  update?: Maybe<ServicesFields_TicketTypes_Primary_Update>;
};

export type ServicesFields_TicketTypes_Primary_Create = {
  __typename?: 'ServicesFields_ticketTypes_primary_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_Primary_Delete = {
  __typename?: 'ServicesFields_ticketTypes_primary_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_Primary_Read = {
  __typename?: 'ServicesFields_ticketTypes_primary_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_Primary_Update = {
  __typename?: 'ServicesFields_ticketTypes_primary_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_TicketType = {
  __typename?: 'ServicesFields_ticketTypes_ticketType';
  create?: Maybe<ServicesFields_TicketTypes_TicketType_Create>;
  delete?: Maybe<ServicesFields_TicketTypes_TicketType_Delete>;
  read?: Maybe<ServicesFields_TicketTypes_TicketType_Read>;
  update?: Maybe<ServicesFields_TicketTypes_TicketType_Update>;
};

export type ServicesFields_TicketTypes_TicketType_Create = {
  __typename?: 'ServicesFields_ticketTypes_ticketType_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_TicketType_Delete = {
  __typename?: 'ServicesFields_ticketTypes_ticketType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_TicketType_Read = {
  __typename?: 'ServicesFields_ticketTypes_ticketType_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_TicketTypes_TicketType_Update = {
  __typename?: 'ServicesFields_ticketTypes_ticketType_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots = {
  __typename?: 'ServicesFields_timeslots';
  create?: Maybe<ServicesFields_Timeslots_Create>;
  delete?: Maybe<ServicesFields_Timeslots_Delete>;
  fields?: Maybe<ServicesFields_Timeslots_Fields>;
  read?: Maybe<ServicesFields_Timeslots_Read>;
  update?: Maybe<ServicesFields_Timeslots_Update>;
};

export type ServicesFields_Timeslots_Create = {
  __typename?: 'ServicesFields_timeslots_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Delete = {
  __typename?: 'ServicesFields_timeslots_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Fields = {
  __typename?: 'ServicesFields_timeslots_Fields';
  end?: Maybe<ServicesFields_Timeslots_End>;
  endDate?: Maybe<ServicesFields_Timeslots_EndDate>;
  id?: Maybe<ServicesFields_Timeslots_Id>;
  start?: Maybe<ServicesFields_Timeslots_Start>;
  startDate?: Maybe<ServicesFields_Timeslots_StartDate>;
  weekdays?: Maybe<ServicesFields_Timeslots_Weekdays>;
};

export type ServicesFields_Timeslots_Read = {
  __typename?: 'ServicesFields_timeslots_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Update = {
  __typename?: 'ServicesFields_timeslots_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_End = {
  __typename?: 'ServicesFields_timeslots_end';
  create?: Maybe<ServicesFields_Timeslots_End_Create>;
  delete?: Maybe<ServicesFields_Timeslots_End_Delete>;
  read?: Maybe<ServicesFields_Timeslots_End_Read>;
  update?: Maybe<ServicesFields_Timeslots_End_Update>;
};

export type ServicesFields_Timeslots_EndDate = {
  __typename?: 'ServicesFields_timeslots_endDate';
  create?: Maybe<ServicesFields_Timeslots_EndDate_Create>;
  delete?: Maybe<ServicesFields_Timeslots_EndDate_Delete>;
  read?: Maybe<ServicesFields_Timeslots_EndDate_Read>;
  update?: Maybe<ServicesFields_Timeslots_EndDate_Update>;
};

export type ServicesFields_Timeslots_EndDate_Create = {
  __typename?: 'ServicesFields_timeslots_endDate_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_EndDate_Delete = {
  __typename?: 'ServicesFields_timeslots_endDate_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_EndDate_Read = {
  __typename?: 'ServicesFields_timeslots_endDate_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_EndDate_Update = {
  __typename?: 'ServicesFields_timeslots_endDate_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_End_Create = {
  __typename?: 'ServicesFields_timeslots_end_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_End_Delete = {
  __typename?: 'ServicesFields_timeslots_end_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_End_Read = {
  __typename?: 'ServicesFields_timeslots_end_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_End_Update = {
  __typename?: 'ServicesFields_timeslots_end_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Id = {
  __typename?: 'ServicesFields_timeslots_id';
  create?: Maybe<ServicesFields_Timeslots_Id_Create>;
  delete?: Maybe<ServicesFields_Timeslots_Id_Delete>;
  read?: Maybe<ServicesFields_Timeslots_Id_Read>;
  update?: Maybe<ServicesFields_Timeslots_Id_Update>;
};

export type ServicesFields_Timeslots_Id_Create = {
  __typename?: 'ServicesFields_timeslots_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Id_Delete = {
  __typename?: 'ServicesFields_timeslots_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Id_Read = {
  __typename?: 'ServicesFields_timeslots_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Id_Update = {
  __typename?: 'ServicesFields_timeslots_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Start = {
  __typename?: 'ServicesFields_timeslots_start';
  create?: Maybe<ServicesFields_Timeslots_Start_Create>;
  delete?: Maybe<ServicesFields_Timeslots_Start_Delete>;
  read?: Maybe<ServicesFields_Timeslots_Start_Read>;
  update?: Maybe<ServicesFields_Timeslots_Start_Update>;
};

export type ServicesFields_Timeslots_StartDate = {
  __typename?: 'ServicesFields_timeslots_startDate';
  create?: Maybe<ServicesFields_Timeslots_StartDate_Create>;
  delete?: Maybe<ServicesFields_Timeslots_StartDate_Delete>;
  read?: Maybe<ServicesFields_Timeslots_StartDate_Read>;
  update?: Maybe<ServicesFields_Timeslots_StartDate_Update>;
};

export type ServicesFields_Timeslots_StartDate_Create = {
  __typename?: 'ServicesFields_timeslots_startDate_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_StartDate_Delete = {
  __typename?: 'ServicesFields_timeslots_startDate_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_StartDate_Read = {
  __typename?: 'ServicesFields_timeslots_startDate_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_StartDate_Update = {
  __typename?: 'ServicesFields_timeslots_startDate_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Start_Create = {
  __typename?: 'ServicesFields_timeslots_start_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Start_Delete = {
  __typename?: 'ServicesFields_timeslots_start_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Start_Read = {
  __typename?: 'ServicesFields_timeslots_start_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Start_Update = {
  __typename?: 'ServicesFields_timeslots_start_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Weekdays = {
  __typename?: 'ServicesFields_timeslots_weekdays';
  create?: Maybe<ServicesFields_Timeslots_Weekdays_Create>;
  delete?: Maybe<ServicesFields_Timeslots_Weekdays_Delete>;
  read?: Maybe<ServicesFields_Timeslots_Weekdays_Read>;
  update?: Maybe<ServicesFields_Timeslots_Weekdays_Update>;
};

export type ServicesFields_Timeslots_Weekdays_Create = {
  __typename?: 'ServicesFields_timeslots_weekdays_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Weekdays_Delete = {
  __typename?: 'ServicesFields_timeslots_weekdays_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Weekdays_Read = {
  __typename?: 'ServicesFields_timeslots_weekdays_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Timeslots_Weekdays_Update = {
  __typename?: 'ServicesFields_timeslots_weekdays_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Title = {
  __typename?: 'ServicesFields_title';
  create?: Maybe<ServicesFields_Title_Create>;
  delete?: Maybe<ServicesFields_Title_Delete>;
  read?: Maybe<ServicesFields_Title_Read>;
  update?: Maybe<ServicesFields_Title_Update>;
};

export type ServicesFields_Title_Create = {
  __typename?: 'ServicesFields_title_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Title_Delete = {
  __typename?: 'ServicesFields_title_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Title_Read = {
  __typename?: 'ServicesFields_title_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Title_Update = {
  __typename?: 'ServicesFields_title_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Title_En = {
  __typename?: 'ServicesFields_title_en';
  create?: Maybe<ServicesFields_Title_En_Create>;
  delete?: Maybe<ServicesFields_Title_En_Delete>;
  read?: Maybe<ServicesFields_Title_En_Read>;
  update?: Maybe<ServicesFields_Title_En_Update>;
};

export type ServicesFields_Title_En_Create = {
  __typename?: 'ServicesFields_title_en_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Title_En_Delete = {
  __typename?: 'ServicesFields_title_en_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Title_En_Read = {
  __typename?: 'ServicesFields_title_en_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Title_En_Update = {
  __typename?: 'ServicesFields_title_en_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Title_Original = {
  __typename?: 'ServicesFields_title_original';
  create?: Maybe<ServicesFields_Title_Original_Create>;
  delete?: Maybe<ServicesFields_Title_Original_Delete>;
  read?: Maybe<ServicesFields_Title_Original_Read>;
  update?: Maybe<ServicesFields_Title_Original_Update>;
};

export type ServicesFields_Title_Original_Create = {
  __typename?: 'ServicesFields_title_original_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Title_Original_Delete = {
  __typename?: 'ServicesFields_title_original_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Title_Original_Read = {
  __typename?: 'ServicesFields_title_original_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_Title_Original_Update = {
  __typename?: 'ServicesFields_title_original_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_UpdatedAt = {
  __typename?: 'ServicesFields_updatedAt';
  create?: Maybe<ServicesFields_UpdatedAt_Create>;
  delete?: Maybe<ServicesFields_UpdatedAt_Delete>;
  read?: Maybe<ServicesFields_UpdatedAt_Read>;
  update?: Maybe<ServicesFields_UpdatedAt_Update>;
};

export type ServicesFields_UpdatedAt_Create = {
  __typename?: 'ServicesFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_UpdatedAt_Delete = {
  __typename?: 'ServicesFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_UpdatedAt_Read = {
  __typename?: 'ServicesFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_UpdatedAt_Update = {
  __typename?: 'ServicesFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_VouchersEnabled = {
  __typename?: 'ServicesFields_vouchersEnabled';
  create?: Maybe<ServicesFields_VouchersEnabled_Create>;
  delete?: Maybe<ServicesFields_VouchersEnabled_Delete>;
  read?: Maybe<ServicesFields_VouchersEnabled_Read>;
  update?: Maybe<ServicesFields_VouchersEnabled_Update>;
};

export type ServicesFields_VouchersEnabled_Create = {
  __typename?: 'ServicesFields_vouchersEnabled_Create';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_VouchersEnabled_Delete = {
  __typename?: 'ServicesFields_vouchersEnabled_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_VouchersEnabled_Read = {
  __typename?: 'ServicesFields_vouchersEnabled_Read';
  permission: Scalars['Boolean']['output'];
};

export type ServicesFields_VouchersEnabled_Update = {
  __typename?: 'ServicesFields_vouchersEnabled_Update';
  permission: Scalars['Boolean']['output'];
};

export type ServicesReadAccess = {
  __typename?: 'ServicesReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ServicesReadDocAccess = {
  __typename?: 'ServicesReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ServicesUpdateAccess = {
  __typename?: 'ServicesUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ServicesUpdateDocAccess = {
  __typename?: 'ServicesUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type StringOrError = Error | StringResponse;

export type StringResponse = {
  __typename?: 'StringResponse';
  response?: Maybe<Scalars['String']['output']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  domains?: Maybe<Array<Tenant_Domains>>;
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parent?: Maybe<Tenant>;
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type TenantParentArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type TenantDetailsResponse = {
  __typename?: 'TenantDetailsResponse';
  contactEmail?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  displayName?: Maybe<Scalars['String']['output']>;
  googleAnalyticsMeasurementID?: Maybe<Scalars['String']['output']>;
  logoURL?: Maybe<Scalars['String']['output']>;
  originalLanguage?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  websiteURL?: Maybe<Scalars['String']['output']>;
};

export type TenantDetailsResponseOrErrorOrError = Error | TenantDetailsResponse;

export type Tenant_Domains = {
  __typename?: 'Tenant_Domains';
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Tenant_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Tenant_Domains__Domain_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Tenant_Domains__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Tenant_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Tenant_Name_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Tenant_Parent_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Tenant_Slug_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Tenant_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Tenant_Where = {
  AND?: InputMaybe<Array<InputMaybe<Tenant_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Tenant_Where_Or>>>;
  createdAt?: InputMaybe<Tenant_CreatedAt_Operator>;
  domains__domain?: InputMaybe<Tenant_Domains__Domain_Operator>;
  domains__id?: InputMaybe<Tenant_Domains__Id_Operator>;
  id?: InputMaybe<Tenant_Id_Operator>;
  name?: InputMaybe<Tenant_Name_Operator>;
  parent?: InputMaybe<Tenant_Parent_Operator>;
  slug?: InputMaybe<Tenant_Slug_Operator>;
  updatedAt?: InputMaybe<Tenant_UpdatedAt_Operator>;
};

export type Tenant_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Tenant_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Tenant_Where_Or>>>;
  createdAt?: InputMaybe<Tenant_CreatedAt_Operator>;
  domains__domain?: InputMaybe<Tenant_Domains__Domain_Operator>;
  domains__id?: InputMaybe<Tenant_Domains__Id_Operator>;
  id?: InputMaybe<Tenant_Id_Operator>;
  name?: InputMaybe<Tenant_Name_Operator>;
  parent?: InputMaybe<Tenant_Parent_Operator>;
  slug?: InputMaybe<Tenant_Slug_Operator>;
  updatedAt?: InputMaybe<Tenant_UpdatedAt_Operator>;
};

export type Tenant_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Tenant_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Tenant_Where_Or>>>;
  createdAt?: InputMaybe<Tenant_CreatedAt_Operator>;
  domains__domain?: InputMaybe<Tenant_Domains__Domain_Operator>;
  domains__id?: InputMaybe<Tenant_Domains__Id_Operator>;
  id?: InputMaybe<Tenant_Id_Operator>;
  name?: InputMaybe<Tenant_Name_Operator>;
  parent?: InputMaybe<Tenant_Parent_Operator>;
  slug?: InputMaybe<Tenant_Slug_Operator>;
  updatedAt?: InputMaybe<Tenant_UpdatedAt_Operator>;
};

export type Tenants = {
  __typename?: 'Tenants';
  docs?: Maybe<Array<Maybe<Tenant>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type TenantsCreateAccess = {
  __typename?: 'TenantsCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TenantsCreateDocAccess = {
  __typename?: 'TenantsCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TenantsDeleteAccess = {
  __typename?: 'TenantsDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TenantsDeleteDocAccess = {
  __typename?: 'TenantsDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TenantsDocAccessFields = {
  __typename?: 'TenantsDocAccessFields';
  createdAt?: Maybe<TenantsDocAccessFields_CreatedAt>;
  domains?: Maybe<TenantsDocAccessFields_Domains>;
  name?: Maybe<TenantsDocAccessFields_Name>;
  parent?: Maybe<TenantsDocAccessFields_Parent>;
  slug?: Maybe<TenantsDocAccessFields_Slug>;
  updatedAt?: Maybe<TenantsDocAccessFields_UpdatedAt>;
};

export type TenantsDocAccessFields_CreatedAt = {
  __typename?: 'TenantsDocAccessFields_createdAt';
  create?: Maybe<TenantsDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<TenantsDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<TenantsDocAccessFields_CreatedAt_Read>;
  update?: Maybe<TenantsDocAccessFields_CreatedAt_Update>;
};

export type TenantsDocAccessFields_CreatedAt_Create = {
  __typename?: 'TenantsDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_CreatedAt_Delete = {
  __typename?: 'TenantsDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_CreatedAt_Read = {
  __typename?: 'TenantsDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_CreatedAt_Update = {
  __typename?: 'TenantsDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Domains = {
  __typename?: 'TenantsDocAccessFields_domains';
  create?: Maybe<TenantsDocAccessFields_Domains_Create>;
  delete?: Maybe<TenantsDocAccessFields_Domains_Delete>;
  fields?: Maybe<TenantsDocAccessFields_Domains_Fields>;
  read?: Maybe<TenantsDocAccessFields_Domains_Read>;
  update?: Maybe<TenantsDocAccessFields_Domains_Update>;
};

export type TenantsDocAccessFields_Domains_Create = {
  __typename?: 'TenantsDocAccessFields_domains_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Domains_Delete = {
  __typename?: 'TenantsDocAccessFields_domains_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Domains_Fields = {
  __typename?: 'TenantsDocAccessFields_domains_Fields';
  domain?: Maybe<TenantsDocAccessFields_Domains_Domain>;
  id?: Maybe<TenantsDocAccessFields_Domains_Id>;
};

export type TenantsDocAccessFields_Domains_Read = {
  __typename?: 'TenantsDocAccessFields_domains_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Domains_Update = {
  __typename?: 'TenantsDocAccessFields_domains_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Domains_Domain = {
  __typename?: 'TenantsDocAccessFields_domains_domain';
  create?: Maybe<TenantsDocAccessFields_Domains_Domain_Create>;
  delete?: Maybe<TenantsDocAccessFields_Domains_Domain_Delete>;
  read?: Maybe<TenantsDocAccessFields_Domains_Domain_Read>;
  update?: Maybe<TenantsDocAccessFields_Domains_Domain_Update>;
};

export type TenantsDocAccessFields_Domains_Domain_Create = {
  __typename?: 'TenantsDocAccessFields_domains_domain_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Domains_Domain_Delete = {
  __typename?: 'TenantsDocAccessFields_domains_domain_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Domains_Domain_Read = {
  __typename?: 'TenantsDocAccessFields_domains_domain_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Domains_Domain_Update = {
  __typename?: 'TenantsDocAccessFields_domains_domain_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Domains_Id = {
  __typename?: 'TenantsDocAccessFields_domains_id';
  create?: Maybe<TenantsDocAccessFields_Domains_Id_Create>;
  delete?: Maybe<TenantsDocAccessFields_Domains_Id_Delete>;
  read?: Maybe<TenantsDocAccessFields_Domains_Id_Read>;
  update?: Maybe<TenantsDocAccessFields_Domains_Id_Update>;
};

export type TenantsDocAccessFields_Domains_Id_Create = {
  __typename?: 'TenantsDocAccessFields_domains_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Domains_Id_Delete = {
  __typename?: 'TenantsDocAccessFields_domains_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Domains_Id_Read = {
  __typename?: 'TenantsDocAccessFields_domains_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Domains_Id_Update = {
  __typename?: 'TenantsDocAccessFields_domains_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Name = {
  __typename?: 'TenantsDocAccessFields_name';
  create?: Maybe<TenantsDocAccessFields_Name_Create>;
  delete?: Maybe<TenantsDocAccessFields_Name_Delete>;
  read?: Maybe<TenantsDocAccessFields_Name_Read>;
  update?: Maybe<TenantsDocAccessFields_Name_Update>;
};

export type TenantsDocAccessFields_Name_Create = {
  __typename?: 'TenantsDocAccessFields_name_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Name_Delete = {
  __typename?: 'TenantsDocAccessFields_name_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Name_Read = {
  __typename?: 'TenantsDocAccessFields_name_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Name_Update = {
  __typename?: 'TenantsDocAccessFields_name_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Parent = {
  __typename?: 'TenantsDocAccessFields_parent';
  create?: Maybe<TenantsDocAccessFields_Parent_Create>;
  delete?: Maybe<TenantsDocAccessFields_Parent_Delete>;
  read?: Maybe<TenantsDocAccessFields_Parent_Read>;
  update?: Maybe<TenantsDocAccessFields_Parent_Update>;
};

export type TenantsDocAccessFields_Parent_Create = {
  __typename?: 'TenantsDocAccessFields_parent_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Parent_Delete = {
  __typename?: 'TenantsDocAccessFields_parent_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Parent_Read = {
  __typename?: 'TenantsDocAccessFields_parent_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Parent_Update = {
  __typename?: 'TenantsDocAccessFields_parent_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Slug = {
  __typename?: 'TenantsDocAccessFields_slug';
  create?: Maybe<TenantsDocAccessFields_Slug_Create>;
  delete?: Maybe<TenantsDocAccessFields_Slug_Delete>;
  read?: Maybe<TenantsDocAccessFields_Slug_Read>;
  update?: Maybe<TenantsDocAccessFields_Slug_Update>;
};

export type TenantsDocAccessFields_Slug_Create = {
  __typename?: 'TenantsDocAccessFields_slug_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Slug_Delete = {
  __typename?: 'TenantsDocAccessFields_slug_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Slug_Read = {
  __typename?: 'TenantsDocAccessFields_slug_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_Slug_Update = {
  __typename?: 'TenantsDocAccessFields_slug_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_UpdatedAt = {
  __typename?: 'TenantsDocAccessFields_updatedAt';
  create?: Maybe<TenantsDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<TenantsDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<TenantsDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<TenantsDocAccessFields_UpdatedAt_Update>;
};

export type TenantsDocAccessFields_UpdatedAt_Create = {
  __typename?: 'TenantsDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'TenantsDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_UpdatedAt_Read = {
  __typename?: 'TenantsDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsDocAccessFields_UpdatedAt_Update = {
  __typename?: 'TenantsDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields = {
  __typename?: 'TenantsFields';
  createdAt?: Maybe<TenantsFields_CreatedAt>;
  domains?: Maybe<TenantsFields_Domains>;
  name?: Maybe<TenantsFields_Name>;
  parent?: Maybe<TenantsFields_Parent>;
  slug?: Maybe<TenantsFields_Slug>;
  updatedAt?: Maybe<TenantsFields_UpdatedAt>;
};

export type TenantsFields_CreatedAt = {
  __typename?: 'TenantsFields_createdAt';
  create?: Maybe<TenantsFields_CreatedAt_Create>;
  delete?: Maybe<TenantsFields_CreatedAt_Delete>;
  read?: Maybe<TenantsFields_CreatedAt_Read>;
  update?: Maybe<TenantsFields_CreatedAt_Update>;
};

export type TenantsFields_CreatedAt_Create = {
  __typename?: 'TenantsFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_CreatedAt_Delete = {
  __typename?: 'TenantsFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_CreatedAt_Read = {
  __typename?: 'TenantsFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_CreatedAt_Update = {
  __typename?: 'TenantsFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Domains = {
  __typename?: 'TenantsFields_domains';
  create?: Maybe<TenantsFields_Domains_Create>;
  delete?: Maybe<TenantsFields_Domains_Delete>;
  fields?: Maybe<TenantsFields_Domains_Fields>;
  read?: Maybe<TenantsFields_Domains_Read>;
  update?: Maybe<TenantsFields_Domains_Update>;
};

export type TenantsFields_Domains_Create = {
  __typename?: 'TenantsFields_domains_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Domains_Delete = {
  __typename?: 'TenantsFields_domains_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Domains_Fields = {
  __typename?: 'TenantsFields_domains_Fields';
  domain?: Maybe<TenantsFields_Domains_Domain>;
  id?: Maybe<TenantsFields_Domains_Id>;
};

export type TenantsFields_Domains_Read = {
  __typename?: 'TenantsFields_domains_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Domains_Update = {
  __typename?: 'TenantsFields_domains_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Domains_Domain = {
  __typename?: 'TenantsFields_domains_domain';
  create?: Maybe<TenantsFields_Domains_Domain_Create>;
  delete?: Maybe<TenantsFields_Domains_Domain_Delete>;
  read?: Maybe<TenantsFields_Domains_Domain_Read>;
  update?: Maybe<TenantsFields_Domains_Domain_Update>;
};

export type TenantsFields_Domains_Domain_Create = {
  __typename?: 'TenantsFields_domains_domain_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Domains_Domain_Delete = {
  __typename?: 'TenantsFields_domains_domain_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Domains_Domain_Read = {
  __typename?: 'TenantsFields_domains_domain_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Domains_Domain_Update = {
  __typename?: 'TenantsFields_domains_domain_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Domains_Id = {
  __typename?: 'TenantsFields_domains_id';
  create?: Maybe<TenantsFields_Domains_Id_Create>;
  delete?: Maybe<TenantsFields_Domains_Id_Delete>;
  read?: Maybe<TenantsFields_Domains_Id_Read>;
  update?: Maybe<TenantsFields_Domains_Id_Update>;
};

export type TenantsFields_Domains_Id_Create = {
  __typename?: 'TenantsFields_domains_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Domains_Id_Delete = {
  __typename?: 'TenantsFields_domains_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Domains_Id_Read = {
  __typename?: 'TenantsFields_domains_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Domains_Id_Update = {
  __typename?: 'TenantsFields_domains_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Name = {
  __typename?: 'TenantsFields_name';
  create?: Maybe<TenantsFields_Name_Create>;
  delete?: Maybe<TenantsFields_Name_Delete>;
  read?: Maybe<TenantsFields_Name_Read>;
  update?: Maybe<TenantsFields_Name_Update>;
};

export type TenantsFields_Name_Create = {
  __typename?: 'TenantsFields_name_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Name_Delete = {
  __typename?: 'TenantsFields_name_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Name_Read = {
  __typename?: 'TenantsFields_name_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Name_Update = {
  __typename?: 'TenantsFields_name_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Parent = {
  __typename?: 'TenantsFields_parent';
  create?: Maybe<TenantsFields_Parent_Create>;
  delete?: Maybe<TenantsFields_Parent_Delete>;
  read?: Maybe<TenantsFields_Parent_Read>;
  update?: Maybe<TenantsFields_Parent_Update>;
};

export type TenantsFields_Parent_Create = {
  __typename?: 'TenantsFields_parent_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Parent_Delete = {
  __typename?: 'TenantsFields_parent_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Parent_Read = {
  __typename?: 'TenantsFields_parent_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Parent_Update = {
  __typename?: 'TenantsFields_parent_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Slug = {
  __typename?: 'TenantsFields_slug';
  create?: Maybe<TenantsFields_Slug_Create>;
  delete?: Maybe<TenantsFields_Slug_Delete>;
  read?: Maybe<TenantsFields_Slug_Read>;
  update?: Maybe<TenantsFields_Slug_Update>;
};

export type TenantsFields_Slug_Create = {
  __typename?: 'TenantsFields_slug_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Slug_Delete = {
  __typename?: 'TenantsFields_slug_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Slug_Read = {
  __typename?: 'TenantsFields_slug_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_Slug_Update = {
  __typename?: 'TenantsFields_slug_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_UpdatedAt = {
  __typename?: 'TenantsFields_updatedAt';
  create?: Maybe<TenantsFields_UpdatedAt_Create>;
  delete?: Maybe<TenantsFields_UpdatedAt_Delete>;
  read?: Maybe<TenantsFields_UpdatedAt_Read>;
  update?: Maybe<TenantsFields_UpdatedAt_Update>;
};

export type TenantsFields_UpdatedAt_Create = {
  __typename?: 'TenantsFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_UpdatedAt_Delete = {
  __typename?: 'TenantsFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_UpdatedAt_Read = {
  __typename?: 'TenantsFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type TenantsFields_UpdatedAt_Update = {
  __typename?: 'TenantsFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type TenantsReadAccess = {
  __typename?: 'TenantsReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TenantsReadDocAccess = {
  __typename?: 'TenantsReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TenantsUpdateAccess = {
  __typename?: 'TenantsUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TenantsUpdateDocAccess = {
  __typename?: 'TenantsUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TicketType = {
  __typename?: 'TicketType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  displayName_en?: Maybe<Scalars['String']['output']>;
  displayName_original?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  stripeID?: Maybe<Scalars['String']['output']>;
  stripePriceID?: Maybe<Scalars['String']['output']>;
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type TicketTypeTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type TicketType_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TicketType_DisplayName_En_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TicketType_DisplayName_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TicketType_DisplayName_Original_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TicketType_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TicketType_Name_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TicketType_Price_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type TicketType_StripeId_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TicketType_StripePriceId_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TicketType_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type TicketType_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TicketType_Where = {
  AND?: InputMaybe<Array<InputMaybe<TicketType_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<TicketType_Where_Or>>>;
  createdAt?: InputMaybe<TicketType_CreatedAt_Operator>;
  displayName?: InputMaybe<TicketType_DisplayName_Operator>;
  displayName_en?: InputMaybe<TicketType_DisplayName_En_Operator>;
  displayName_original?: InputMaybe<TicketType_DisplayName_Original_Operator>;
  id?: InputMaybe<TicketType_Id_Operator>;
  name?: InputMaybe<TicketType_Name_Operator>;
  price?: InputMaybe<TicketType_Price_Operator>;
  stripeID?: InputMaybe<TicketType_StripeId_Operator>;
  stripePriceID?: InputMaybe<TicketType_StripePriceId_Operator>;
  tenant?: InputMaybe<TicketType_Tenant_Operator>;
  updatedAt?: InputMaybe<TicketType_UpdatedAt_Operator>;
};

export type TicketType_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<TicketType_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<TicketType_Where_Or>>>;
  createdAt?: InputMaybe<TicketType_CreatedAt_Operator>;
  displayName?: InputMaybe<TicketType_DisplayName_Operator>;
  displayName_en?: InputMaybe<TicketType_DisplayName_En_Operator>;
  displayName_original?: InputMaybe<TicketType_DisplayName_Original_Operator>;
  id?: InputMaybe<TicketType_Id_Operator>;
  name?: InputMaybe<TicketType_Name_Operator>;
  price?: InputMaybe<TicketType_Price_Operator>;
  stripeID?: InputMaybe<TicketType_StripeId_Operator>;
  stripePriceID?: InputMaybe<TicketType_StripePriceId_Operator>;
  tenant?: InputMaybe<TicketType_Tenant_Operator>;
  updatedAt?: InputMaybe<TicketType_UpdatedAt_Operator>;
};

export type TicketType_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<TicketType_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<TicketType_Where_Or>>>;
  createdAt?: InputMaybe<TicketType_CreatedAt_Operator>;
  displayName?: InputMaybe<TicketType_DisplayName_Operator>;
  displayName_en?: InputMaybe<TicketType_DisplayName_En_Operator>;
  displayName_original?: InputMaybe<TicketType_DisplayName_Original_Operator>;
  id?: InputMaybe<TicketType_Id_Operator>;
  name?: InputMaybe<TicketType_Name_Operator>;
  price?: InputMaybe<TicketType_Price_Operator>;
  stripeID?: InputMaybe<TicketType_StripeId_Operator>;
  stripePriceID?: InputMaybe<TicketType_StripePriceId_Operator>;
  tenant?: InputMaybe<TicketType_Tenant_Operator>;
  updatedAt?: InputMaybe<TicketType_UpdatedAt_Operator>;
};

export type TicketTypes = {
  __typename?: 'TicketTypes';
  docs?: Maybe<Array<Maybe<TicketType>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type TicketTypesCreateAccess = {
  __typename?: 'TicketTypesCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TicketTypesCreateDocAccess = {
  __typename?: 'TicketTypesCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TicketTypesDeleteAccess = {
  __typename?: 'TicketTypesDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TicketTypesDeleteDocAccess = {
  __typename?: 'TicketTypesDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TicketTypesDocAccessFields = {
  __typename?: 'TicketTypesDocAccessFields';
  createdAt?: Maybe<TicketTypesDocAccessFields_CreatedAt>;
  displayName?: Maybe<TicketTypesDocAccessFields_DisplayName>;
  displayName_en?: Maybe<TicketTypesDocAccessFields_DisplayName_En>;
  displayName_original?: Maybe<TicketTypesDocAccessFields_DisplayName_Original>;
  name?: Maybe<TicketTypesDocAccessFields_Name>;
  price?: Maybe<TicketTypesDocAccessFields_Price>;
  stripeID?: Maybe<TicketTypesDocAccessFields_StripeId>;
  stripePriceID?: Maybe<TicketTypesDocAccessFields_StripePriceId>;
  tenant?: Maybe<TicketTypesDocAccessFields_Tenant>;
  updatedAt?: Maybe<TicketTypesDocAccessFields_UpdatedAt>;
};

export type TicketTypesDocAccessFields_CreatedAt = {
  __typename?: 'TicketTypesDocAccessFields_createdAt';
  create?: Maybe<TicketTypesDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<TicketTypesDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<TicketTypesDocAccessFields_CreatedAt_Read>;
  update?: Maybe<TicketTypesDocAccessFields_CreatedAt_Update>;
};

export type TicketTypesDocAccessFields_CreatedAt_Create = {
  __typename?: 'TicketTypesDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_CreatedAt_Delete = {
  __typename?: 'TicketTypesDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_CreatedAt_Read = {
  __typename?: 'TicketTypesDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_CreatedAt_Update = {
  __typename?: 'TicketTypesDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_DisplayName = {
  __typename?: 'TicketTypesDocAccessFields_displayName';
  create?: Maybe<TicketTypesDocAccessFields_DisplayName_Create>;
  delete?: Maybe<TicketTypesDocAccessFields_DisplayName_Delete>;
  read?: Maybe<TicketTypesDocAccessFields_DisplayName_Read>;
  update?: Maybe<TicketTypesDocAccessFields_DisplayName_Update>;
};

export type TicketTypesDocAccessFields_DisplayName_Create = {
  __typename?: 'TicketTypesDocAccessFields_displayName_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_DisplayName_Delete = {
  __typename?: 'TicketTypesDocAccessFields_displayName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_DisplayName_Read = {
  __typename?: 'TicketTypesDocAccessFields_displayName_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_DisplayName_Update = {
  __typename?: 'TicketTypesDocAccessFields_displayName_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_DisplayName_En = {
  __typename?: 'TicketTypesDocAccessFields_displayName_en';
  create?: Maybe<TicketTypesDocAccessFields_DisplayName_En_Create>;
  delete?: Maybe<TicketTypesDocAccessFields_DisplayName_En_Delete>;
  read?: Maybe<TicketTypesDocAccessFields_DisplayName_En_Read>;
  update?: Maybe<TicketTypesDocAccessFields_DisplayName_En_Update>;
};

export type TicketTypesDocAccessFields_DisplayName_En_Create = {
  __typename?: 'TicketTypesDocAccessFields_displayName_en_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_DisplayName_En_Delete = {
  __typename?: 'TicketTypesDocAccessFields_displayName_en_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_DisplayName_En_Read = {
  __typename?: 'TicketTypesDocAccessFields_displayName_en_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_DisplayName_En_Update = {
  __typename?: 'TicketTypesDocAccessFields_displayName_en_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_DisplayName_Original = {
  __typename?: 'TicketTypesDocAccessFields_displayName_original';
  create?: Maybe<TicketTypesDocAccessFields_DisplayName_Original_Create>;
  delete?: Maybe<TicketTypesDocAccessFields_DisplayName_Original_Delete>;
  read?: Maybe<TicketTypesDocAccessFields_DisplayName_Original_Read>;
  update?: Maybe<TicketTypesDocAccessFields_DisplayName_Original_Update>;
};

export type TicketTypesDocAccessFields_DisplayName_Original_Create = {
  __typename?: 'TicketTypesDocAccessFields_displayName_original_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_DisplayName_Original_Delete = {
  __typename?: 'TicketTypesDocAccessFields_displayName_original_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_DisplayName_Original_Read = {
  __typename?: 'TicketTypesDocAccessFields_displayName_original_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_DisplayName_Original_Update = {
  __typename?: 'TicketTypesDocAccessFields_displayName_original_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_Name = {
  __typename?: 'TicketTypesDocAccessFields_name';
  create?: Maybe<TicketTypesDocAccessFields_Name_Create>;
  delete?: Maybe<TicketTypesDocAccessFields_Name_Delete>;
  read?: Maybe<TicketTypesDocAccessFields_Name_Read>;
  update?: Maybe<TicketTypesDocAccessFields_Name_Update>;
};

export type TicketTypesDocAccessFields_Name_Create = {
  __typename?: 'TicketTypesDocAccessFields_name_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_Name_Delete = {
  __typename?: 'TicketTypesDocAccessFields_name_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_Name_Read = {
  __typename?: 'TicketTypesDocAccessFields_name_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_Name_Update = {
  __typename?: 'TicketTypesDocAccessFields_name_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_Price = {
  __typename?: 'TicketTypesDocAccessFields_price';
  create?: Maybe<TicketTypesDocAccessFields_Price_Create>;
  delete?: Maybe<TicketTypesDocAccessFields_Price_Delete>;
  read?: Maybe<TicketTypesDocAccessFields_Price_Read>;
  update?: Maybe<TicketTypesDocAccessFields_Price_Update>;
};

export type TicketTypesDocAccessFields_Price_Create = {
  __typename?: 'TicketTypesDocAccessFields_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_Price_Delete = {
  __typename?: 'TicketTypesDocAccessFields_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_Price_Read = {
  __typename?: 'TicketTypesDocAccessFields_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_Price_Update = {
  __typename?: 'TicketTypesDocAccessFields_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_StripeId = {
  __typename?: 'TicketTypesDocAccessFields_stripeID';
  create?: Maybe<TicketTypesDocAccessFields_StripeId_Create>;
  delete?: Maybe<TicketTypesDocAccessFields_StripeId_Delete>;
  read?: Maybe<TicketTypesDocAccessFields_StripeId_Read>;
  update?: Maybe<TicketTypesDocAccessFields_StripeId_Update>;
};

export type TicketTypesDocAccessFields_StripeId_Create = {
  __typename?: 'TicketTypesDocAccessFields_stripeID_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_StripeId_Delete = {
  __typename?: 'TicketTypesDocAccessFields_stripeID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_StripeId_Read = {
  __typename?: 'TicketTypesDocAccessFields_stripeID_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_StripeId_Update = {
  __typename?: 'TicketTypesDocAccessFields_stripeID_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_StripePriceId = {
  __typename?: 'TicketTypesDocAccessFields_stripePriceID';
  create?: Maybe<TicketTypesDocAccessFields_StripePriceId_Create>;
  delete?: Maybe<TicketTypesDocAccessFields_StripePriceId_Delete>;
  read?: Maybe<TicketTypesDocAccessFields_StripePriceId_Read>;
  update?: Maybe<TicketTypesDocAccessFields_StripePriceId_Update>;
};

export type TicketTypesDocAccessFields_StripePriceId_Create = {
  __typename?: 'TicketTypesDocAccessFields_stripePriceID_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_StripePriceId_Delete = {
  __typename?: 'TicketTypesDocAccessFields_stripePriceID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_StripePriceId_Read = {
  __typename?: 'TicketTypesDocAccessFields_stripePriceID_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_StripePriceId_Update = {
  __typename?: 'TicketTypesDocAccessFields_stripePriceID_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_Tenant = {
  __typename?: 'TicketTypesDocAccessFields_tenant';
  create?: Maybe<TicketTypesDocAccessFields_Tenant_Create>;
  delete?: Maybe<TicketTypesDocAccessFields_Tenant_Delete>;
  read?: Maybe<TicketTypesDocAccessFields_Tenant_Read>;
  update?: Maybe<TicketTypesDocAccessFields_Tenant_Update>;
};

export type TicketTypesDocAccessFields_Tenant_Create = {
  __typename?: 'TicketTypesDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_Tenant_Delete = {
  __typename?: 'TicketTypesDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_Tenant_Read = {
  __typename?: 'TicketTypesDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_Tenant_Update = {
  __typename?: 'TicketTypesDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_UpdatedAt = {
  __typename?: 'TicketTypesDocAccessFields_updatedAt';
  create?: Maybe<TicketTypesDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<TicketTypesDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<TicketTypesDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<TicketTypesDocAccessFields_UpdatedAt_Update>;
};

export type TicketTypesDocAccessFields_UpdatedAt_Create = {
  __typename?: 'TicketTypesDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'TicketTypesDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_UpdatedAt_Read = {
  __typename?: 'TicketTypesDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesDocAccessFields_UpdatedAt_Update = {
  __typename?: 'TicketTypesDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields = {
  __typename?: 'TicketTypesFields';
  createdAt?: Maybe<TicketTypesFields_CreatedAt>;
  displayName?: Maybe<TicketTypesFields_DisplayName>;
  displayName_en?: Maybe<TicketTypesFields_DisplayName_En>;
  displayName_original?: Maybe<TicketTypesFields_DisplayName_Original>;
  name?: Maybe<TicketTypesFields_Name>;
  price?: Maybe<TicketTypesFields_Price>;
  stripeID?: Maybe<TicketTypesFields_StripeId>;
  stripePriceID?: Maybe<TicketTypesFields_StripePriceId>;
  tenant?: Maybe<TicketTypesFields_Tenant>;
  updatedAt?: Maybe<TicketTypesFields_UpdatedAt>;
};

export type TicketTypesFields_CreatedAt = {
  __typename?: 'TicketTypesFields_createdAt';
  create?: Maybe<TicketTypesFields_CreatedAt_Create>;
  delete?: Maybe<TicketTypesFields_CreatedAt_Delete>;
  read?: Maybe<TicketTypesFields_CreatedAt_Read>;
  update?: Maybe<TicketTypesFields_CreatedAt_Update>;
};

export type TicketTypesFields_CreatedAt_Create = {
  __typename?: 'TicketTypesFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_CreatedAt_Delete = {
  __typename?: 'TicketTypesFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_CreatedAt_Read = {
  __typename?: 'TicketTypesFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_CreatedAt_Update = {
  __typename?: 'TicketTypesFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_DisplayName = {
  __typename?: 'TicketTypesFields_displayName';
  create?: Maybe<TicketTypesFields_DisplayName_Create>;
  delete?: Maybe<TicketTypesFields_DisplayName_Delete>;
  read?: Maybe<TicketTypesFields_DisplayName_Read>;
  update?: Maybe<TicketTypesFields_DisplayName_Update>;
};

export type TicketTypesFields_DisplayName_Create = {
  __typename?: 'TicketTypesFields_displayName_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_DisplayName_Delete = {
  __typename?: 'TicketTypesFields_displayName_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_DisplayName_Read = {
  __typename?: 'TicketTypesFields_displayName_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_DisplayName_Update = {
  __typename?: 'TicketTypesFields_displayName_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_DisplayName_En = {
  __typename?: 'TicketTypesFields_displayName_en';
  create?: Maybe<TicketTypesFields_DisplayName_En_Create>;
  delete?: Maybe<TicketTypesFields_DisplayName_En_Delete>;
  read?: Maybe<TicketTypesFields_DisplayName_En_Read>;
  update?: Maybe<TicketTypesFields_DisplayName_En_Update>;
};

export type TicketTypesFields_DisplayName_En_Create = {
  __typename?: 'TicketTypesFields_displayName_en_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_DisplayName_En_Delete = {
  __typename?: 'TicketTypesFields_displayName_en_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_DisplayName_En_Read = {
  __typename?: 'TicketTypesFields_displayName_en_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_DisplayName_En_Update = {
  __typename?: 'TicketTypesFields_displayName_en_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_DisplayName_Original = {
  __typename?: 'TicketTypesFields_displayName_original';
  create?: Maybe<TicketTypesFields_DisplayName_Original_Create>;
  delete?: Maybe<TicketTypesFields_DisplayName_Original_Delete>;
  read?: Maybe<TicketTypesFields_DisplayName_Original_Read>;
  update?: Maybe<TicketTypesFields_DisplayName_Original_Update>;
};

export type TicketTypesFields_DisplayName_Original_Create = {
  __typename?: 'TicketTypesFields_displayName_original_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_DisplayName_Original_Delete = {
  __typename?: 'TicketTypesFields_displayName_original_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_DisplayName_Original_Read = {
  __typename?: 'TicketTypesFields_displayName_original_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_DisplayName_Original_Update = {
  __typename?: 'TicketTypesFields_displayName_original_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_Name = {
  __typename?: 'TicketTypesFields_name';
  create?: Maybe<TicketTypesFields_Name_Create>;
  delete?: Maybe<TicketTypesFields_Name_Delete>;
  read?: Maybe<TicketTypesFields_Name_Read>;
  update?: Maybe<TicketTypesFields_Name_Update>;
};

export type TicketTypesFields_Name_Create = {
  __typename?: 'TicketTypesFields_name_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_Name_Delete = {
  __typename?: 'TicketTypesFields_name_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_Name_Read = {
  __typename?: 'TicketTypesFields_name_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_Name_Update = {
  __typename?: 'TicketTypesFields_name_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_Price = {
  __typename?: 'TicketTypesFields_price';
  create?: Maybe<TicketTypesFields_Price_Create>;
  delete?: Maybe<TicketTypesFields_Price_Delete>;
  read?: Maybe<TicketTypesFields_Price_Read>;
  update?: Maybe<TicketTypesFields_Price_Update>;
};

export type TicketTypesFields_Price_Create = {
  __typename?: 'TicketTypesFields_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_Price_Delete = {
  __typename?: 'TicketTypesFields_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_Price_Read = {
  __typename?: 'TicketTypesFields_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_Price_Update = {
  __typename?: 'TicketTypesFields_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_StripeId = {
  __typename?: 'TicketTypesFields_stripeID';
  create?: Maybe<TicketTypesFields_StripeId_Create>;
  delete?: Maybe<TicketTypesFields_StripeId_Delete>;
  read?: Maybe<TicketTypesFields_StripeId_Read>;
  update?: Maybe<TicketTypesFields_StripeId_Update>;
};

export type TicketTypesFields_StripeId_Create = {
  __typename?: 'TicketTypesFields_stripeID_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_StripeId_Delete = {
  __typename?: 'TicketTypesFields_stripeID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_StripeId_Read = {
  __typename?: 'TicketTypesFields_stripeID_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_StripeId_Update = {
  __typename?: 'TicketTypesFields_stripeID_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_StripePriceId = {
  __typename?: 'TicketTypesFields_stripePriceID';
  create?: Maybe<TicketTypesFields_StripePriceId_Create>;
  delete?: Maybe<TicketTypesFields_StripePriceId_Delete>;
  read?: Maybe<TicketTypesFields_StripePriceId_Read>;
  update?: Maybe<TicketTypesFields_StripePriceId_Update>;
};

export type TicketTypesFields_StripePriceId_Create = {
  __typename?: 'TicketTypesFields_stripePriceID_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_StripePriceId_Delete = {
  __typename?: 'TicketTypesFields_stripePriceID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_StripePriceId_Read = {
  __typename?: 'TicketTypesFields_stripePriceID_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_StripePriceId_Update = {
  __typename?: 'TicketTypesFields_stripePriceID_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_Tenant = {
  __typename?: 'TicketTypesFields_tenant';
  create?: Maybe<TicketTypesFields_Tenant_Create>;
  delete?: Maybe<TicketTypesFields_Tenant_Delete>;
  read?: Maybe<TicketTypesFields_Tenant_Read>;
  update?: Maybe<TicketTypesFields_Tenant_Update>;
};

export type TicketTypesFields_Tenant_Create = {
  __typename?: 'TicketTypesFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_Tenant_Delete = {
  __typename?: 'TicketTypesFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_Tenant_Read = {
  __typename?: 'TicketTypesFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_Tenant_Update = {
  __typename?: 'TicketTypesFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_UpdatedAt = {
  __typename?: 'TicketTypesFields_updatedAt';
  create?: Maybe<TicketTypesFields_UpdatedAt_Create>;
  delete?: Maybe<TicketTypesFields_UpdatedAt_Delete>;
  read?: Maybe<TicketTypesFields_UpdatedAt_Read>;
  update?: Maybe<TicketTypesFields_UpdatedAt_Update>;
};

export type TicketTypesFields_UpdatedAt_Create = {
  __typename?: 'TicketTypesFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_UpdatedAt_Delete = {
  __typename?: 'TicketTypesFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_UpdatedAt_Read = {
  __typename?: 'TicketTypesFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesFields_UpdatedAt_Update = {
  __typename?: 'TicketTypesFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type TicketTypesReadAccess = {
  __typename?: 'TicketTypesReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TicketTypesReadDocAccess = {
  __typename?: 'TicketTypesReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TicketTypesUpdateAccess = {
  __typename?: 'TicketTypesUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TicketTypesUpdateDocAccess = {
  __typename?: 'TicketTypesUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TimeslotExtrasResponse = {
  __typename?: 'TimeslotExtrasResponse';
  bookedCount?: Maybe<Scalars['Int']['output']>;
  extra?: Maybe<Extra>;
};

export type TimeslotResponse = {
  __typename?: 'TimeslotResponse';
  end?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export type TimeslotTicketsResponse = {
  __typename?: 'TimeslotTicketsResponse';
  bookedCount?: Maybe<Scalars['Int']['output']>;
  ticket?: Maybe<TicketType>;
};

export type User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['EmailAddress']['output'];
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lockUntil?: Maybe<Scalars['DateTime']['output']>;
  loginAttempts?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  resetPasswordExpiration?: Maybe<Scalars['DateTime']['output']>;
  resetPasswordToken?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  salt?: Maybe<Scalars['String']['output']>;
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type UserTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type User_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type User_Email_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
  contains?: InputMaybe<Scalars['EmailAddress']['input']>;
  equals?: InputMaybe<Scalars['EmailAddress']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
  like?: InputMaybe<Scalars['EmailAddress']['input']>;
  not_equals?: InputMaybe<Scalars['EmailAddress']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
};

export type User_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type User_Name_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type User_Role_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type User_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type User_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type User_Where = {
  AND?: InputMaybe<Array<InputMaybe<User_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<User_Where_Or>>>;
  createdAt?: InputMaybe<User_CreatedAt_Operator>;
  email?: InputMaybe<User_Email_Operator>;
  id?: InputMaybe<User_Id_Operator>;
  name?: InputMaybe<User_Name_Operator>;
  role?: InputMaybe<User_Role_Operator>;
  tenant?: InputMaybe<User_Tenant_Operator>;
  updatedAt?: InputMaybe<User_UpdatedAt_Operator>;
};

export type User_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<User_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<User_Where_Or>>>;
  createdAt?: InputMaybe<User_CreatedAt_Operator>;
  email?: InputMaybe<User_Email_Operator>;
  id?: InputMaybe<User_Id_Operator>;
  name?: InputMaybe<User_Name_Operator>;
  role?: InputMaybe<User_Role_Operator>;
  tenant?: InputMaybe<User_Tenant_Operator>;
  updatedAt?: InputMaybe<User_UpdatedAt_Operator>;
};

export type User_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<User_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<User_Where_Or>>>;
  createdAt?: InputMaybe<User_CreatedAt_Operator>;
  email?: InputMaybe<User_Email_Operator>;
  id?: InputMaybe<User_Id_Operator>;
  name?: InputMaybe<User_Name_Operator>;
  role?: InputMaybe<User_Role_Operator>;
  tenant?: InputMaybe<User_Tenant_Operator>;
  updatedAt?: InputMaybe<User_UpdatedAt_Operator>;
};

export type Users = {
  __typename?: 'Users';
  docs?: Maybe<Array<Maybe<User>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type UsersCreateAccess = {
  __typename?: 'UsersCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersCreateDocAccess = {
  __typename?: 'UsersCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersDeleteAccess = {
  __typename?: 'UsersDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersDeleteDocAccess = {
  __typename?: 'UsersDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersDocAccessFields = {
  __typename?: 'UsersDocAccessFields';
  createdAt?: Maybe<UsersDocAccessFields_CreatedAt>;
  email?: Maybe<UsersDocAccessFields_Email>;
  name?: Maybe<UsersDocAccessFields_Name>;
  password?: Maybe<UsersDocAccessFields_Password>;
  role?: Maybe<UsersDocAccessFields_Role>;
  tenant?: Maybe<UsersDocAccessFields_Tenant>;
  updatedAt?: Maybe<UsersDocAccessFields_UpdatedAt>;
};

export type UsersDocAccessFields_CreatedAt = {
  __typename?: 'UsersDocAccessFields_createdAt';
  create?: Maybe<UsersDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<UsersDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<UsersDocAccessFields_CreatedAt_Read>;
  update?: Maybe<UsersDocAccessFields_CreatedAt_Update>;
};

export type UsersDocAccessFields_CreatedAt_Create = {
  __typename?: 'UsersDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_CreatedAt_Delete = {
  __typename?: 'UsersDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_CreatedAt_Read = {
  __typename?: 'UsersDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_CreatedAt_Update = {
  __typename?: 'UsersDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Email = {
  __typename?: 'UsersDocAccessFields_email';
  create?: Maybe<UsersDocAccessFields_Email_Create>;
  delete?: Maybe<UsersDocAccessFields_Email_Delete>;
  read?: Maybe<UsersDocAccessFields_Email_Read>;
  update?: Maybe<UsersDocAccessFields_Email_Update>;
};

export type UsersDocAccessFields_Email_Create = {
  __typename?: 'UsersDocAccessFields_email_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Email_Delete = {
  __typename?: 'UsersDocAccessFields_email_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Email_Read = {
  __typename?: 'UsersDocAccessFields_email_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Email_Update = {
  __typename?: 'UsersDocAccessFields_email_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Name = {
  __typename?: 'UsersDocAccessFields_name';
  create?: Maybe<UsersDocAccessFields_Name_Create>;
  delete?: Maybe<UsersDocAccessFields_Name_Delete>;
  read?: Maybe<UsersDocAccessFields_Name_Read>;
  update?: Maybe<UsersDocAccessFields_Name_Update>;
};

export type UsersDocAccessFields_Name_Create = {
  __typename?: 'UsersDocAccessFields_name_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Name_Delete = {
  __typename?: 'UsersDocAccessFields_name_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Name_Read = {
  __typename?: 'UsersDocAccessFields_name_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Name_Update = {
  __typename?: 'UsersDocAccessFields_name_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Password = {
  __typename?: 'UsersDocAccessFields_password';
  create?: Maybe<UsersDocAccessFields_Password_Create>;
  delete?: Maybe<UsersDocAccessFields_Password_Delete>;
  read?: Maybe<UsersDocAccessFields_Password_Read>;
  update?: Maybe<UsersDocAccessFields_Password_Update>;
};

export type UsersDocAccessFields_Password_Create = {
  __typename?: 'UsersDocAccessFields_password_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Password_Delete = {
  __typename?: 'UsersDocAccessFields_password_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Password_Read = {
  __typename?: 'UsersDocAccessFields_password_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Password_Update = {
  __typename?: 'UsersDocAccessFields_password_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Role = {
  __typename?: 'UsersDocAccessFields_role';
  create?: Maybe<UsersDocAccessFields_Role_Create>;
  delete?: Maybe<UsersDocAccessFields_Role_Delete>;
  read?: Maybe<UsersDocAccessFields_Role_Read>;
  update?: Maybe<UsersDocAccessFields_Role_Update>;
};

export type UsersDocAccessFields_Role_Create = {
  __typename?: 'UsersDocAccessFields_role_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Role_Delete = {
  __typename?: 'UsersDocAccessFields_role_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Role_Read = {
  __typename?: 'UsersDocAccessFields_role_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Role_Update = {
  __typename?: 'UsersDocAccessFields_role_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Tenant = {
  __typename?: 'UsersDocAccessFields_tenant';
  create?: Maybe<UsersDocAccessFields_Tenant_Create>;
  delete?: Maybe<UsersDocAccessFields_Tenant_Delete>;
  read?: Maybe<UsersDocAccessFields_Tenant_Read>;
  update?: Maybe<UsersDocAccessFields_Tenant_Update>;
};

export type UsersDocAccessFields_Tenant_Create = {
  __typename?: 'UsersDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Tenant_Delete = {
  __typename?: 'UsersDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Tenant_Read = {
  __typename?: 'UsersDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Tenant_Update = {
  __typename?: 'UsersDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_UpdatedAt = {
  __typename?: 'UsersDocAccessFields_updatedAt';
  create?: Maybe<UsersDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<UsersDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<UsersDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<UsersDocAccessFields_UpdatedAt_Update>;
};

export type UsersDocAccessFields_UpdatedAt_Create = {
  __typename?: 'UsersDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'UsersDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_UpdatedAt_Read = {
  __typename?: 'UsersDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_UpdatedAt_Update = {
  __typename?: 'UsersDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields = {
  __typename?: 'UsersFields';
  createdAt?: Maybe<UsersFields_CreatedAt>;
  email?: Maybe<UsersFields_Email>;
  name?: Maybe<UsersFields_Name>;
  password?: Maybe<UsersFields_Password>;
  role?: Maybe<UsersFields_Role>;
  tenant?: Maybe<UsersFields_Tenant>;
  updatedAt?: Maybe<UsersFields_UpdatedAt>;
};

export type UsersFields_CreatedAt = {
  __typename?: 'UsersFields_createdAt';
  create?: Maybe<UsersFields_CreatedAt_Create>;
  delete?: Maybe<UsersFields_CreatedAt_Delete>;
  read?: Maybe<UsersFields_CreatedAt_Read>;
  update?: Maybe<UsersFields_CreatedAt_Update>;
};

export type UsersFields_CreatedAt_Create = {
  __typename?: 'UsersFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_CreatedAt_Delete = {
  __typename?: 'UsersFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_CreatedAt_Read = {
  __typename?: 'UsersFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_CreatedAt_Update = {
  __typename?: 'UsersFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Email = {
  __typename?: 'UsersFields_email';
  create?: Maybe<UsersFields_Email_Create>;
  delete?: Maybe<UsersFields_Email_Delete>;
  read?: Maybe<UsersFields_Email_Read>;
  update?: Maybe<UsersFields_Email_Update>;
};

export type UsersFields_Email_Create = {
  __typename?: 'UsersFields_email_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Email_Delete = {
  __typename?: 'UsersFields_email_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Email_Read = {
  __typename?: 'UsersFields_email_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Email_Update = {
  __typename?: 'UsersFields_email_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Name = {
  __typename?: 'UsersFields_name';
  create?: Maybe<UsersFields_Name_Create>;
  delete?: Maybe<UsersFields_Name_Delete>;
  read?: Maybe<UsersFields_Name_Read>;
  update?: Maybe<UsersFields_Name_Update>;
};

export type UsersFields_Name_Create = {
  __typename?: 'UsersFields_name_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Name_Delete = {
  __typename?: 'UsersFields_name_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Name_Read = {
  __typename?: 'UsersFields_name_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Name_Update = {
  __typename?: 'UsersFields_name_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Password = {
  __typename?: 'UsersFields_password';
  create?: Maybe<UsersFields_Password_Create>;
  delete?: Maybe<UsersFields_Password_Delete>;
  read?: Maybe<UsersFields_Password_Read>;
  update?: Maybe<UsersFields_Password_Update>;
};

export type UsersFields_Password_Create = {
  __typename?: 'UsersFields_password_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Password_Delete = {
  __typename?: 'UsersFields_password_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Password_Read = {
  __typename?: 'UsersFields_password_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Password_Update = {
  __typename?: 'UsersFields_password_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Role = {
  __typename?: 'UsersFields_role';
  create?: Maybe<UsersFields_Role_Create>;
  delete?: Maybe<UsersFields_Role_Delete>;
  read?: Maybe<UsersFields_Role_Read>;
  update?: Maybe<UsersFields_Role_Update>;
};

export type UsersFields_Role_Create = {
  __typename?: 'UsersFields_role_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Role_Delete = {
  __typename?: 'UsersFields_role_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Role_Read = {
  __typename?: 'UsersFields_role_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Role_Update = {
  __typename?: 'UsersFields_role_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Tenant = {
  __typename?: 'UsersFields_tenant';
  create?: Maybe<UsersFields_Tenant_Create>;
  delete?: Maybe<UsersFields_Tenant_Delete>;
  read?: Maybe<UsersFields_Tenant_Read>;
  update?: Maybe<UsersFields_Tenant_Update>;
};

export type UsersFields_Tenant_Create = {
  __typename?: 'UsersFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Tenant_Delete = {
  __typename?: 'UsersFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Tenant_Read = {
  __typename?: 'UsersFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Tenant_Update = {
  __typename?: 'UsersFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_UpdatedAt = {
  __typename?: 'UsersFields_updatedAt';
  create?: Maybe<UsersFields_UpdatedAt_Create>;
  delete?: Maybe<UsersFields_UpdatedAt_Delete>;
  read?: Maybe<UsersFields_UpdatedAt_Read>;
  update?: Maybe<UsersFields_UpdatedAt_Update>;
};

export type UsersFields_UpdatedAt_Create = {
  __typename?: 'UsersFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_UpdatedAt_Delete = {
  __typename?: 'UsersFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_UpdatedAt_Read = {
  __typename?: 'UsersFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_UpdatedAt_Update = {
  __typename?: 'UsersFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersReadAccess = {
  __typename?: 'UsersReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersReadDocAccess = {
  __typename?: 'UsersReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersUnlockAccess = {
  __typename?: 'UsersUnlockAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersUnlockDocAccess = {
  __typename?: 'UsersUnlockDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersUpdateAccess = {
  __typename?: 'UsersUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersUpdateDocAccess = {
  __typename?: 'UsersUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type Voucher = {
  __typename?: 'Voucher';
  bookingStatus?: Maybe<Voucher_BookingStatus>;
  checkoutID?: Maybe<Scalars['String']['output']>;
  contact: Contact;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  extras?: Maybe<Array<BookingExtras>>;
  id?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  paidTimestamp?: Maybe<Scalars['DateTime']['output']>;
  service?: Maybe<Service>;
  stripePaymentIntentID?: Maybe<Scalars['String']['output']>;
  tenant?: Maybe<Tenant>;
  tickets?: Maybe<Array<BookingTickets>>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type VoucherContactArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};


export type VoucherServiceArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};


export type VoucherTenantArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type VoucherOrError = Error | Voucher;

export enum VoucherUpdate_BookingStatus_MutationInput {
  Paid = 'PAID',
  Used = 'USED'
}

export enum Voucher_BookingStatus {
  Paid = 'PAID',
  Used = 'USED'
}

export enum Voucher_BookingStatus_Input {
  Paid = 'PAID',
  Used = 'USED'
}

export enum Voucher_BookingStatus_MutationInput {
  Paid = 'PAID',
  Used = 'USED'
}

export type Voucher_BookingStatus_Operator = {
  all?: InputMaybe<Array<InputMaybe<Voucher_BookingStatus_Input>>>;
  equals?: InputMaybe<Voucher_BookingStatus_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Voucher_BookingStatus_Input>>>;
  not_equals?: InputMaybe<Voucher_BookingStatus_Input>;
  not_in?: InputMaybe<Array<InputMaybe<Voucher_BookingStatus_Input>>>;
};

export type Voucher_CheckoutId_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Voucher_Contact_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Voucher_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Voucher_Extras__Count_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Voucher_Extras__Extra_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Voucher_Extras__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Voucher_Extras__Price_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Voucher_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Voucher_Locale_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Voucher_PaidTimestamp_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Voucher_Service_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Voucher_StripePaymentIntentId_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Voucher_Tenant_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Voucher_Tickets__Count_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Voucher_Tickets__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Voucher_Tickets__Price_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Voucher_Tickets__Ticket_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Voucher_TotalPrice_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Voucher_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Voucher_Where = {
  AND?: InputMaybe<Array<InputMaybe<Voucher_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Voucher_Where_Or>>>;
  bookingStatus?: InputMaybe<Voucher_BookingStatus_Operator>;
  checkoutID?: InputMaybe<Voucher_CheckoutId_Operator>;
  contact?: InputMaybe<Voucher_Contact_Operator>;
  createdAt?: InputMaybe<Voucher_CreatedAt_Operator>;
  extras__count?: InputMaybe<Voucher_Extras__Count_Operator>;
  extras__extra?: InputMaybe<Voucher_Extras__Extra_Operator>;
  extras__id?: InputMaybe<Voucher_Extras__Id_Operator>;
  extras__price?: InputMaybe<Voucher_Extras__Price_Operator>;
  id?: InputMaybe<Voucher_Id_Operator>;
  locale?: InputMaybe<Voucher_Locale_Operator>;
  paidTimestamp?: InputMaybe<Voucher_PaidTimestamp_Operator>;
  service?: InputMaybe<Voucher_Service_Operator>;
  stripePaymentIntentID?: InputMaybe<Voucher_StripePaymentIntentId_Operator>;
  tenant?: InputMaybe<Voucher_Tenant_Operator>;
  tickets__count?: InputMaybe<Voucher_Tickets__Count_Operator>;
  tickets__id?: InputMaybe<Voucher_Tickets__Id_Operator>;
  tickets__price?: InputMaybe<Voucher_Tickets__Price_Operator>;
  tickets__ticket?: InputMaybe<Voucher_Tickets__Ticket_Operator>;
  totalPrice?: InputMaybe<Voucher_TotalPrice_Operator>;
  updatedAt?: InputMaybe<Voucher_UpdatedAt_Operator>;
};

export type Voucher_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Voucher_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Voucher_Where_Or>>>;
  bookingStatus?: InputMaybe<Voucher_BookingStatus_Operator>;
  checkoutID?: InputMaybe<Voucher_CheckoutId_Operator>;
  contact?: InputMaybe<Voucher_Contact_Operator>;
  createdAt?: InputMaybe<Voucher_CreatedAt_Operator>;
  extras__count?: InputMaybe<Voucher_Extras__Count_Operator>;
  extras__extra?: InputMaybe<Voucher_Extras__Extra_Operator>;
  extras__id?: InputMaybe<Voucher_Extras__Id_Operator>;
  extras__price?: InputMaybe<Voucher_Extras__Price_Operator>;
  id?: InputMaybe<Voucher_Id_Operator>;
  locale?: InputMaybe<Voucher_Locale_Operator>;
  paidTimestamp?: InputMaybe<Voucher_PaidTimestamp_Operator>;
  service?: InputMaybe<Voucher_Service_Operator>;
  stripePaymentIntentID?: InputMaybe<Voucher_StripePaymentIntentId_Operator>;
  tenant?: InputMaybe<Voucher_Tenant_Operator>;
  tickets__count?: InputMaybe<Voucher_Tickets__Count_Operator>;
  tickets__id?: InputMaybe<Voucher_Tickets__Id_Operator>;
  tickets__price?: InputMaybe<Voucher_Tickets__Price_Operator>;
  tickets__ticket?: InputMaybe<Voucher_Tickets__Ticket_Operator>;
  totalPrice?: InputMaybe<Voucher_TotalPrice_Operator>;
  updatedAt?: InputMaybe<Voucher_UpdatedAt_Operator>;
};

export type Voucher_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Voucher_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Voucher_Where_Or>>>;
  bookingStatus?: InputMaybe<Voucher_BookingStatus_Operator>;
  checkoutID?: InputMaybe<Voucher_CheckoutId_Operator>;
  contact?: InputMaybe<Voucher_Contact_Operator>;
  createdAt?: InputMaybe<Voucher_CreatedAt_Operator>;
  extras__count?: InputMaybe<Voucher_Extras__Count_Operator>;
  extras__extra?: InputMaybe<Voucher_Extras__Extra_Operator>;
  extras__id?: InputMaybe<Voucher_Extras__Id_Operator>;
  extras__price?: InputMaybe<Voucher_Extras__Price_Operator>;
  id?: InputMaybe<Voucher_Id_Operator>;
  locale?: InputMaybe<Voucher_Locale_Operator>;
  paidTimestamp?: InputMaybe<Voucher_PaidTimestamp_Operator>;
  service?: InputMaybe<Voucher_Service_Operator>;
  stripePaymentIntentID?: InputMaybe<Voucher_StripePaymentIntentId_Operator>;
  tenant?: InputMaybe<Voucher_Tenant_Operator>;
  tickets__count?: InputMaybe<Voucher_Tickets__Count_Operator>;
  tickets__id?: InputMaybe<Voucher_Tickets__Id_Operator>;
  tickets__price?: InputMaybe<Voucher_Tickets__Price_Operator>;
  tickets__ticket?: InputMaybe<Voucher_Tickets__Ticket_Operator>;
  totalPrice?: InputMaybe<Voucher_TotalPrice_Operator>;
  updatedAt?: InputMaybe<Voucher_UpdatedAt_Operator>;
};

export type Vouchers = {
  __typename?: 'Vouchers';
  docs?: Maybe<Array<Maybe<Voucher>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type VouchersCreateAccess = {
  __typename?: 'VouchersCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type VouchersCreateDocAccess = {
  __typename?: 'VouchersCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type VouchersDeleteAccess = {
  __typename?: 'VouchersDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type VouchersDeleteDocAccess = {
  __typename?: 'VouchersDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type VouchersDocAccessFields = {
  __typename?: 'VouchersDocAccessFields';
  bookingStatus?: Maybe<VouchersDocAccessFields_BookingStatus>;
  checkoutID?: Maybe<VouchersDocAccessFields_CheckoutId>;
  contact?: Maybe<VouchersDocAccessFields_Contact>;
  createdAt?: Maybe<VouchersDocAccessFields_CreatedAt>;
  extras?: Maybe<VouchersDocAccessFields_Extras>;
  locale?: Maybe<VouchersDocAccessFields_Locale>;
  paidTimestamp?: Maybe<VouchersDocAccessFields_PaidTimestamp>;
  service?: Maybe<VouchersDocAccessFields_Service>;
  stripePaymentIntentID?: Maybe<VouchersDocAccessFields_StripePaymentIntentId>;
  tenant?: Maybe<VouchersDocAccessFields_Tenant>;
  tickets?: Maybe<VouchersDocAccessFields_Tickets>;
  totalPrice?: Maybe<VouchersDocAccessFields_TotalPrice>;
  updatedAt?: Maybe<VouchersDocAccessFields_UpdatedAt>;
};

export type VouchersDocAccessFields_BookingStatus = {
  __typename?: 'VouchersDocAccessFields_bookingStatus';
  create?: Maybe<VouchersDocAccessFields_BookingStatus_Create>;
  delete?: Maybe<VouchersDocAccessFields_BookingStatus_Delete>;
  read?: Maybe<VouchersDocAccessFields_BookingStatus_Read>;
  update?: Maybe<VouchersDocAccessFields_BookingStatus_Update>;
};

export type VouchersDocAccessFields_BookingStatus_Create = {
  __typename?: 'VouchersDocAccessFields_bookingStatus_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_BookingStatus_Delete = {
  __typename?: 'VouchersDocAccessFields_bookingStatus_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_BookingStatus_Read = {
  __typename?: 'VouchersDocAccessFields_bookingStatus_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_BookingStatus_Update = {
  __typename?: 'VouchersDocAccessFields_bookingStatus_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_CheckoutId = {
  __typename?: 'VouchersDocAccessFields_checkoutID';
  create?: Maybe<VouchersDocAccessFields_CheckoutId_Create>;
  delete?: Maybe<VouchersDocAccessFields_CheckoutId_Delete>;
  read?: Maybe<VouchersDocAccessFields_CheckoutId_Read>;
  update?: Maybe<VouchersDocAccessFields_CheckoutId_Update>;
};

export type VouchersDocAccessFields_CheckoutId_Create = {
  __typename?: 'VouchersDocAccessFields_checkoutID_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_CheckoutId_Delete = {
  __typename?: 'VouchersDocAccessFields_checkoutID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_CheckoutId_Read = {
  __typename?: 'VouchersDocAccessFields_checkoutID_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_CheckoutId_Update = {
  __typename?: 'VouchersDocAccessFields_checkoutID_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Contact = {
  __typename?: 'VouchersDocAccessFields_contact';
  create?: Maybe<VouchersDocAccessFields_Contact_Create>;
  delete?: Maybe<VouchersDocAccessFields_Contact_Delete>;
  read?: Maybe<VouchersDocAccessFields_Contact_Read>;
  update?: Maybe<VouchersDocAccessFields_Contact_Update>;
};

export type VouchersDocAccessFields_Contact_Create = {
  __typename?: 'VouchersDocAccessFields_contact_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Contact_Delete = {
  __typename?: 'VouchersDocAccessFields_contact_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Contact_Read = {
  __typename?: 'VouchersDocAccessFields_contact_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Contact_Update = {
  __typename?: 'VouchersDocAccessFields_contact_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_CreatedAt = {
  __typename?: 'VouchersDocAccessFields_createdAt';
  create?: Maybe<VouchersDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<VouchersDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<VouchersDocAccessFields_CreatedAt_Read>;
  update?: Maybe<VouchersDocAccessFields_CreatedAt_Update>;
};

export type VouchersDocAccessFields_CreatedAt_Create = {
  __typename?: 'VouchersDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_CreatedAt_Delete = {
  __typename?: 'VouchersDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_CreatedAt_Read = {
  __typename?: 'VouchersDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_CreatedAt_Update = {
  __typename?: 'VouchersDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras = {
  __typename?: 'VouchersDocAccessFields_extras';
  create?: Maybe<VouchersDocAccessFields_Extras_Create>;
  delete?: Maybe<VouchersDocAccessFields_Extras_Delete>;
  fields?: Maybe<VouchersDocAccessFields_Extras_Fields>;
  read?: Maybe<VouchersDocAccessFields_Extras_Read>;
  update?: Maybe<VouchersDocAccessFields_Extras_Update>;
};

export type VouchersDocAccessFields_Extras_Create = {
  __typename?: 'VouchersDocAccessFields_extras_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Delete = {
  __typename?: 'VouchersDocAccessFields_extras_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Fields = {
  __typename?: 'VouchersDocAccessFields_extras_Fields';
  count?: Maybe<VouchersDocAccessFields_Extras_Count>;
  extra?: Maybe<VouchersDocAccessFields_Extras_Extra>;
  id?: Maybe<VouchersDocAccessFields_Extras_Id>;
  price?: Maybe<VouchersDocAccessFields_Extras_Price>;
};

export type VouchersDocAccessFields_Extras_Read = {
  __typename?: 'VouchersDocAccessFields_extras_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Update = {
  __typename?: 'VouchersDocAccessFields_extras_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Count = {
  __typename?: 'VouchersDocAccessFields_extras_count';
  create?: Maybe<VouchersDocAccessFields_Extras_Count_Create>;
  delete?: Maybe<VouchersDocAccessFields_Extras_Count_Delete>;
  read?: Maybe<VouchersDocAccessFields_Extras_Count_Read>;
  update?: Maybe<VouchersDocAccessFields_Extras_Count_Update>;
};

export type VouchersDocAccessFields_Extras_Count_Create = {
  __typename?: 'VouchersDocAccessFields_extras_count_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Count_Delete = {
  __typename?: 'VouchersDocAccessFields_extras_count_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Count_Read = {
  __typename?: 'VouchersDocAccessFields_extras_count_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Count_Update = {
  __typename?: 'VouchersDocAccessFields_extras_count_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Extra = {
  __typename?: 'VouchersDocAccessFields_extras_extra';
  create?: Maybe<VouchersDocAccessFields_Extras_Extra_Create>;
  delete?: Maybe<VouchersDocAccessFields_Extras_Extra_Delete>;
  read?: Maybe<VouchersDocAccessFields_Extras_Extra_Read>;
  update?: Maybe<VouchersDocAccessFields_Extras_Extra_Update>;
};

export type VouchersDocAccessFields_Extras_Extra_Create = {
  __typename?: 'VouchersDocAccessFields_extras_extra_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Extra_Delete = {
  __typename?: 'VouchersDocAccessFields_extras_extra_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Extra_Read = {
  __typename?: 'VouchersDocAccessFields_extras_extra_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Extra_Update = {
  __typename?: 'VouchersDocAccessFields_extras_extra_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Id = {
  __typename?: 'VouchersDocAccessFields_extras_id';
  create?: Maybe<VouchersDocAccessFields_Extras_Id_Create>;
  delete?: Maybe<VouchersDocAccessFields_Extras_Id_Delete>;
  read?: Maybe<VouchersDocAccessFields_Extras_Id_Read>;
  update?: Maybe<VouchersDocAccessFields_Extras_Id_Update>;
};

export type VouchersDocAccessFields_Extras_Id_Create = {
  __typename?: 'VouchersDocAccessFields_extras_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Id_Delete = {
  __typename?: 'VouchersDocAccessFields_extras_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Id_Read = {
  __typename?: 'VouchersDocAccessFields_extras_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Id_Update = {
  __typename?: 'VouchersDocAccessFields_extras_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Price = {
  __typename?: 'VouchersDocAccessFields_extras_price';
  create?: Maybe<VouchersDocAccessFields_Extras_Price_Create>;
  delete?: Maybe<VouchersDocAccessFields_Extras_Price_Delete>;
  read?: Maybe<VouchersDocAccessFields_Extras_Price_Read>;
  update?: Maybe<VouchersDocAccessFields_Extras_Price_Update>;
};

export type VouchersDocAccessFields_Extras_Price_Create = {
  __typename?: 'VouchersDocAccessFields_extras_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Price_Delete = {
  __typename?: 'VouchersDocAccessFields_extras_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Price_Read = {
  __typename?: 'VouchersDocAccessFields_extras_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Extras_Price_Update = {
  __typename?: 'VouchersDocAccessFields_extras_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Locale = {
  __typename?: 'VouchersDocAccessFields_locale';
  create?: Maybe<VouchersDocAccessFields_Locale_Create>;
  delete?: Maybe<VouchersDocAccessFields_Locale_Delete>;
  read?: Maybe<VouchersDocAccessFields_Locale_Read>;
  update?: Maybe<VouchersDocAccessFields_Locale_Update>;
};

export type VouchersDocAccessFields_Locale_Create = {
  __typename?: 'VouchersDocAccessFields_locale_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Locale_Delete = {
  __typename?: 'VouchersDocAccessFields_locale_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Locale_Read = {
  __typename?: 'VouchersDocAccessFields_locale_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Locale_Update = {
  __typename?: 'VouchersDocAccessFields_locale_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_PaidTimestamp = {
  __typename?: 'VouchersDocAccessFields_paidTimestamp';
  create?: Maybe<VouchersDocAccessFields_PaidTimestamp_Create>;
  delete?: Maybe<VouchersDocAccessFields_PaidTimestamp_Delete>;
  read?: Maybe<VouchersDocAccessFields_PaidTimestamp_Read>;
  update?: Maybe<VouchersDocAccessFields_PaidTimestamp_Update>;
};

export type VouchersDocAccessFields_PaidTimestamp_Create = {
  __typename?: 'VouchersDocAccessFields_paidTimestamp_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_PaidTimestamp_Delete = {
  __typename?: 'VouchersDocAccessFields_paidTimestamp_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_PaidTimestamp_Read = {
  __typename?: 'VouchersDocAccessFields_paidTimestamp_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_PaidTimestamp_Update = {
  __typename?: 'VouchersDocAccessFields_paidTimestamp_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Service = {
  __typename?: 'VouchersDocAccessFields_service';
  create?: Maybe<VouchersDocAccessFields_Service_Create>;
  delete?: Maybe<VouchersDocAccessFields_Service_Delete>;
  read?: Maybe<VouchersDocAccessFields_Service_Read>;
  update?: Maybe<VouchersDocAccessFields_Service_Update>;
};

export type VouchersDocAccessFields_Service_Create = {
  __typename?: 'VouchersDocAccessFields_service_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Service_Delete = {
  __typename?: 'VouchersDocAccessFields_service_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Service_Read = {
  __typename?: 'VouchersDocAccessFields_service_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Service_Update = {
  __typename?: 'VouchersDocAccessFields_service_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_StripePaymentIntentId = {
  __typename?: 'VouchersDocAccessFields_stripePaymentIntentID';
  create?: Maybe<VouchersDocAccessFields_StripePaymentIntentId_Create>;
  delete?: Maybe<VouchersDocAccessFields_StripePaymentIntentId_Delete>;
  read?: Maybe<VouchersDocAccessFields_StripePaymentIntentId_Read>;
  update?: Maybe<VouchersDocAccessFields_StripePaymentIntentId_Update>;
};

export type VouchersDocAccessFields_StripePaymentIntentId_Create = {
  __typename?: 'VouchersDocAccessFields_stripePaymentIntentID_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_StripePaymentIntentId_Delete = {
  __typename?: 'VouchersDocAccessFields_stripePaymentIntentID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_StripePaymentIntentId_Read = {
  __typename?: 'VouchersDocAccessFields_stripePaymentIntentID_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_StripePaymentIntentId_Update = {
  __typename?: 'VouchersDocAccessFields_stripePaymentIntentID_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tenant = {
  __typename?: 'VouchersDocAccessFields_tenant';
  create?: Maybe<VouchersDocAccessFields_Tenant_Create>;
  delete?: Maybe<VouchersDocAccessFields_Tenant_Delete>;
  read?: Maybe<VouchersDocAccessFields_Tenant_Read>;
  update?: Maybe<VouchersDocAccessFields_Tenant_Update>;
};

export type VouchersDocAccessFields_Tenant_Create = {
  __typename?: 'VouchersDocAccessFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tenant_Delete = {
  __typename?: 'VouchersDocAccessFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tenant_Read = {
  __typename?: 'VouchersDocAccessFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tenant_Update = {
  __typename?: 'VouchersDocAccessFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets = {
  __typename?: 'VouchersDocAccessFields_tickets';
  create?: Maybe<VouchersDocAccessFields_Tickets_Create>;
  delete?: Maybe<VouchersDocAccessFields_Tickets_Delete>;
  fields?: Maybe<VouchersDocAccessFields_Tickets_Fields>;
  read?: Maybe<VouchersDocAccessFields_Tickets_Read>;
  update?: Maybe<VouchersDocAccessFields_Tickets_Update>;
};

export type VouchersDocAccessFields_Tickets_Create = {
  __typename?: 'VouchersDocAccessFields_tickets_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Delete = {
  __typename?: 'VouchersDocAccessFields_tickets_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Fields = {
  __typename?: 'VouchersDocAccessFields_tickets_Fields';
  count?: Maybe<VouchersDocAccessFields_Tickets_Count>;
  id?: Maybe<VouchersDocAccessFields_Tickets_Id>;
  price?: Maybe<VouchersDocAccessFields_Tickets_Price>;
  ticket?: Maybe<VouchersDocAccessFields_Tickets_Ticket>;
};

export type VouchersDocAccessFields_Tickets_Read = {
  __typename?: 'VouchersDocAccessFields_tickets_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Update = {
  __typename?: 'VouchersDocAccessFields_tickets_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Count = {
  __typename?: 'VouchersDocAccessFields_tickets_count';
  create?: Maybe<VouchersDocAccessFields_Tickets_Count_Create>;
  delete?: Maybe<VouchersDocAccessFields_Tickets_Count_Delete>;
  read?: Maybe<VouchersDocAccessFields_Tickets_Count_Read>;
  update?: Maybe<VouchersDocAccessFields_Tickets_Count_Update>;
};

export type VouchersDocAccessFields_Tickets_Count_Create = {
  __typename?: 'VouchersDocAccessFields_tickets_count_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Count_Delete = {
  __typename?: 'VouchersDocAccessFields_tickets_count_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Count_Read = {
  __typename?: 'VouchersDocAccessFields_tickets_count_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Count_Update = {
  __typename?: 'VouchersDocAccessFields_tickets_count_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Id = {
  __typename?: 'VouchersDocAccessFields_tickets_id';
  create?: Maybe<VouchersDocAccessFields_Tickets_Id_Create>;
  delete?: Maybe<VouchersDocAccessFields_Tickets_Id_Delete>;
  read?: Maybe<VouchersDocAccessFields_Tickets_Id_Read>;
  update?: Maybe<VouchersDocAccessFields_Tickets_Id_Update>;
};

export type VouchersDocAccessFields_Tickets_Id_Create = {
  __typename?: 'VouchersDocAccessFields_tickets_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Id_Delete = {
  __typename?: 'VouchersDocAccessFields_tickets_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Id_Read = {
  __typename?: 'VouchersDocAccessFields_tickets_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Id_Update = {
  __typename?: 'VouchersDocAccessFields_tickets_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Price = {
  __typename?: 'VouchersDocAccessFields_tickets_price';
  create?: Maybe<VouchersDocAccessFields_Tickets_Price_Create>;
  delete?: Maybe<VouchersDocAccessFields_Tickets_Price_Delete>;
  read?: Maybe<VouchersDocAccessFields_Tickets_Price_Read>;
  update?: Maybe<VouchersDocAccessFields_Tickets_Price_Update>;
};

export type VouchersDocAccessFields_Tickets_Price_Create = {
  __typename?: 'VouchersDocAccessFields_tickets_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Price_Delete = {
  __typename?: 'VouchersDocAccessFields_tickets_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Price_Read = {
  __typename?: 'VouchersDocAccessFields_tickets_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Price_Update = {
  __typename?: 'VouchersDocAccessFields_tickets_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Ticket = {
  __typename?: 'VouchersDocAccessFields_tickets_ticket';
  create?: Maybe<VouchersDocAccessFields_Tickets_Ticket_Create>;
  delete?: Maybe<VouchersDocAccessFields_Tickets_Ticket_Delete>;
  read?: Maybe<VouchersDocAccessFields_Tickets_Ticket_Read>;
  update?: Maybe<VouchersDocAccessFields_Tickets_Ticket_Update>;
};

export type VouchersDocAccessFields_Tickets_Ticket_Create = {
  __typename?: 'VouchersDocAccessFields_tickets_ticket_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Ticket_Delete = {
  __typename?: 'VouchersDocAccessFields_tickets_ticket_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Ticket_Read = {
  __typename?: 'VouchersDocAccessFields_tickets_ticket_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_Tickets_Ticket_Update = {
  __typename?: 'VouchersDocAccessFields_tickets_ticket_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_TotalPrice = {
  __typename?: 'VouchersDocAccessFields_totalPrice';
  create?: Maybe<VouchersDocAccessFields_TotalPrice_Create>;
  delete?: Maybe<VouchersDocAccessFields_TotalPrice_Delete>;
  read?: Maybe<VouchersDocAccessFields_TotalPrice_Read>;
  update?: Maybe<VouchersDocAccessFields_TotalPrice_Update>;
};

export type VouchersDocAccessFields_TotalPrice_Create = {
  __typename?: 'VouchersDocAccessFields_totalPrice_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_TotalPrice_Delete = {
  __typename?: 'VouchersDocAccessFields_totalPrice_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_TotalPrice_Read = {
  __typename?: 'VouchersDocAccessFields_totalPrice_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_TotalPrice_Update = {
  __typename?: 'VouchersDocAccessFields_totalPrice_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_UpdatedAt = {
  __typename?: 'VouchersDocAccessFields_updatedAt';
  create?: Maybe<VouchersDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<VouchersDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<VouchersDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<VouchersDocAccessFields_UpdatedAt_Update>;
};

export type VouchersDocAccessFields_UpdatedAt_Create = {
  __typename?: 'VouchersDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'VouchersDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_UpdatedAt_Read = {
  __typename?: 'VouchersDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersDocAccessFields_UpdatedAt_Update = {
  __typename?: 'VouchersDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields = {
  __typename?: 'VouchersFields';
  bookingStatus?: Maybe<VouchersFields_BookingStatus>;
  checkoutID?: Maybe<VouchersFields_CheckoutId>;
  contact?: Maybe<VouchersFields_Contact>;
  createdAt?: Maybe<VouchersFields_CreatedAt>;
  extras?: Maybe<VouchersFields_Extras>;
  locale?: Maybe<VouchersFields_Locale>;
  paidTimestamp?: Maybe<VouchersFields_PaidTimestamp>;
  service?: Maybe<VouchersFields_Service>;
  stripePaymentIntentID?: Maybe<VouchersFields_StripePaymentIntentId>;
  tenant?: Maybe<VouchersFields_Tenant>;
  tickets?: Maybe<VouchersFields_Tickets>;
  totalPrice?: Maybe<VouchersFields_TotalPrice>;
  updatedAt?: Maybe<VouchersFields_UpdatedAt>;
};

export type VouchersFields_BookingStatus = {
  __typename?: 'VouchersFields_bookingStatus';
  create?: Maybe<VouchersFields_BookingStatus_Create>;
  delete?: Maybe<VouchersFields_BookingStatus_Delete>;
  read?: Maybe<VouchersFields_BookingStatus_Read>;
  update?: Maybe<VouchersFields_BookingStatus_Update>;
};

export type VouchersFields_BookingStatus_Create = {
  __typename?: 'VouchersFields_bookingStatus_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_BookingStatus_Delete = {
  __typename?: 'VouchersFields_bookingStatus_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_BookingStatus_Read = {
  __typename?: 'VouchersFields_bookingStatus_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_BookingStatus_Update = {
  __typename?: 'VouchersFields_bookingStatus_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_CheckoutId = {
  __typename?: 'VouchersFields_checkoutID';
  create?: Maybe<VouchersFields_CheckoutId_Create>;
  delete?: Maybe<VouchersFields_CheckoutId_Delete>;
  read?: Maybe<VouchersFields_CheckoutId_Read>;
  update?: Maybe<VouchersFields_CheckoutId_Update>;
};

export type VouchersFields_CheckoutId_Create = {
  __typename?: 'VouchersFields_checkoutID_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_CheckoutId_Delete = {
  __typename?: 'VouchersFields_checkoutID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_CheckoutId_Read = {
  __typename?: 'VouchersFields_checkoutID_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_CheckoutId_Update = {
  __typename?: 'VouchersFields_checkoutID_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Contact = {
  __typename?: 'VouchersFields_contact';
  create?: Maybe<VouchersFields_Contact_Create>;
  delete?: Maybe<VouchersFields_Contact_Delete>;
  read?: Maybe<VouchersFields_Contact_Read>;
  update?: Maybe<VouchersFields_Contact_Update>;
};

export type VouchersFields_Contact_Create = {
  __typename?: 'VouchersFields_contact_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Contact_Delete = {
  __typename?: 'VouchersFields_contact_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Contact_Read = {
  __typename?: 'VouchersFields_contact_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Contact_Update = {
  __typename?: 'VouchersFields_contact_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_CreatedAt = {
  __typename?: 'VouchersFields_createdAt';
  create?: Maybe<VouchersFields_CreatedAt_Create>;
  delete?: Maybe<VouchersFields_CreatedAt_Delete>;
  read?: Maybe<VouchersFields_CreatedAt_Read>;
  update?: Maybe<VouchersFields_CreatedAt_Update>;
};

export type VouchersFields_CreatedAt_Create = {
  __typename?: 'VouchersFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_CreatedAt_Delete = {
  __typename?: 'VouchersFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_CreatedAt_Read = {
  __typename?: 'VouchersFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_CreatedAt_Update = {
  __typename?: 'VouchersFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras = {
  __typename?: 'VouchersFields_extras';
  create?: Maybe<VouchersFields_Extras_Create>;
  delete?: Maybe<VouchersFields_Extras_Delete>;
  fields?: Maybe<VouchersFields_Extras_Fields>;
  read?: Maybe<VouchersFields_Extras_Read>;
  update?: Maybe<VouchersFields_Extras_Update>;
};

export type VouchersFields_Extras_Create = {
  __typename?: 'VouchersFields_extras_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Delete = {
  __typename?: 'VouchersFields_extras_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Fields = {
  __typename?: 'VouchersFields_extras_Fields';
  count?: Maybe<VouchersFields_Extras_Count>;
  extra?: Maybe<VouchersFields_Extras_Extra>;
  id?: Maybe<VouchersFields_Extras_Id>;
  price?: Maybe<VouchersFields_Extras_Price>;
};

export type VouchersFields_Extras_Read = {
  __typename?: 'VouchersFields_extras_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Update = {
  __typename?: 'VouchersFields_extras_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Count = {
  __typename?: 'VouchersFields_extras_count';
  create?: Maybe<VouchersFields_Extras_Count_Create>;
  delete?: Maybe<VouchersFields_Extras_Count_Delete>;
  read?: Maybe<VouchersFields_Extras_Count_Read>;
  update?: Maybe<VouchersFields_Extras_Count_Update>;
};

export type VouchersFields_Extras_Count_Create = {
  __typename?: 'VouchersFields_extras_count_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Count_Delete = {
  __typename?: 'VouchersFields_extras_count_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Count_Read = {
  __typename?: 'VouchersFields_extras_count_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Count_Update = {
  __typename?: 'VouchersFields_extras_count_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Extra = {
  __typename?: 'VouchersFields_extras_extra';
  create?: Maybe<VouchersFields_Extras_Extra_Create>;
  delete?: Maybe<VouchersFields_Extras_Extra_Delete>;
  read?: Maybe<VouchersFields_Extras_Extra_Read>;
  update?: Maybe<VouchersFields_Extras_Extra_Update>;
};

export type VouchersFields_Extras_Extra_Create = {
  __typename?: 'VouchersFields_extras_extra_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Extra_Delete = {
  __typename?: 'VouchersFields_extras_extra_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Extra_Read = {
  __typename?: 'VouchersFields_extras_extra_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Extra_Update = {
  __typename?: 'VouchersFields_extras_extra_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Id = {
  __typename?: 'VouchersFields_extras_id';
  create?: Maybe<VouchersFields_Extras_Id_Create>;
  delete?: Maybe<VouchersFields_Extras_Id_Delete>;
  read?: Maybe<VouchersFields_Extras_Id_Read>;
  update?: Maybe<VouchersFields_Extras_Id_Update>;
};

export type VouchersFields_Extras_Id_Create = {
  __typename?: 'VouchersFields_extras_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Id_Delete = {
  __typename?: 'VouchersFields_extras_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Id_Read = {
  __typename?: 'VouchersFields_extras_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Id_Update = {
  __typename?: 'VouchersFields_extras_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Price = {
  __typename?: 'VouchersFields_extras_price';
  create?: Maybe<VouchersFields_Extras_Price_Create>;
  delete?: Maybe<VouchersFields_Extras_Price_Delete>;
  read?: Maybe<VouchersFields_Extras_Price_Read>;
  update?: Maybe<VouchersFields_Extras_Price_Update>;
};

export type VouchersFields_Extras_Price_Create = {
  __typename?: 'VouchersFields_extras_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Price_Delete = {
  __typename?: 'VouchersFields_extras_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Price_Read = {
  __typename?: 'VouchersFields_extras_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Extras_Price_Update = {
  __typename?: 'VouchersFields_extras_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Locale = {
  __typename?: 'VouchersFields_locale';
  create?: Maybe<VouchersFields_Locale_Create>;
  delete?: Maybe<VouchersFields_Locale_Delete>;
  read?: Maybe<VouchersFields_Locale_Read>;
  update?: Maybe<VouchersFields_Locale_Update>;
};

export type VouchersFields_Locale_Create = {
  __typename?: 'VouchersFields_locale_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Locale_Delete = {
  __typename?: 'VouchersFields_locale_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Locale_Read = {
  __typename?: 'VouchersFields_locale_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Locale_Update = {
  __typename?: 'VouchersFields_locale_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_PaidTimestamp = {
  __typename?: 'VouchersFields_paidTimestamp';
  create?: Maybe<VouchersFields_PaidTimestamp_Create>;
  delete?: Maybe<VouchersFields_PaidTimestamp_Delete>;
  read?: Maybe<VouchersFields_PaidTimestamp_Read>;
  update?: Maybe<VouchersFields_PaidTimestamp_Update>;
};

export type VouchersFields_PaidTimestamp_Create = {
  __typename?: 'VouchersFields_paidTimestamp_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_PaidTimestamp_Delete = {
  __typename?: 'VouchersFields_paidTimestamp_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_PaidTimestamp_Read = {
  __typename?: 'VouchersFields_paidTimestamp_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_PaidTimestamp_Update = {
  __typename?: 'VouchersFields_paidTimestamp_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Service = {
  __typename?: 'VouchersFields_service';
  create?: Maybe<VouchersFields_Service_Create>;
  delete?: Maybe<VouchersFields_Service_Delete>;
  read?: Maybe<VouchersFields_Service_Read>;
  update?: Maybe<VouchersFields_Service_Update>;
};

export type VouchersFields_Service_Create = {
  __typename?: 'VouchersFields_service_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Service_Delete = {
  __typename?: 'VouchersFields_service_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Service_Read = {
  __typename?: 'VouchersFields_service_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Service_Update = {
  __typename?: 'VouchersFields_service_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_StripePaymentIntentId = {
  __typename?: 'VouchersFields_stripePaymentIntentID';
  create?: Maybe<VouchersFields_StripePaymentIntentId_Create>;
  delete?: Maybe<VouchersFields_StripePaymentIntentId_Delete>;
  read?: Maybe<VouchersFields_StripePaymentIntentId_Read>;
  update?: Maybe<VouchersFields_StripePaymentIntentId_Update>;
};

export type VouchersFields_StripePaymentIntentId_Create = {
  __typename?: 'VouchersFields_stripePaymentIntentID_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_StripePaymentIntentId_Delete = {
  __typename?: 'VouchersFields_stripePaymentIntentID_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_StripePaymentIntentId_Read = {
  __typename?: 'VouchersFields_stripePaymentIntentID_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_StripePaymentIntentId_Update = {
  __typename?: 'VouchersFields_stripePaymentIntentID_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tenant = {
  __typename?: 'VouchersFields_tenant';
  create?: Maybe<VouchersFields_Tenant_Create>;
  delete?: Maybe<VouchersFields_Tenant_Delete>;
  read?: Maybe<VouchersFields_Tenant_Read>;
  update?: Maybe<VouchersFields_Tenant_Update>;
};

export type VouchersFields_Tenant_Create = {
  __typename?: 'VouchersFields_tenant_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tenant_Delete = {
  __typename?: 'VouchersFields_tenant_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tenant_Read = {
  __typename?: 'VouchersFields_tenant_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tenant_Update = {
  __typename?: 'VouchersFields_tenant_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets = {
  __typename?: 'VouchersFields_tickets';
  create?: Maybe<VouchersFields_Tickets_Create>;
  delete?: Maybe<VouchersFields_Tickets_Delete>;
  fields?: Maybe<VouchersFields_Tickets_Fields>;
  read?: Maybe<VouchersFields_Tickets_Read>;
  update?: Maybe<VouchersFields_Tickets_Update>;
};

export type VouchersFields_Tickets_Create = {
  __typename?: 'VouchersFields_tickets_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Delete = {
  __typename?: 'VouchersFields_tickets_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Fields = {
  __typename?: 'VouchersFields_tickets_Fields';
  count?: Maybe<VouchersFields_Tickets_Count>;
  id?: Maybe<VouchersFields_Tickets_Id>;
  price?: Maybe<VouchersFields_Tickets_Price>;
  ticket?: Maybe<VouchersFields_Tickets_Ticket>;
};

export type VouchersFields_Tickets_Read = {
  __typename?: 'VouchersFields_tickets_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Update = {
  __typename?: 'VouchersFields_tickets_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Count = {
  __typename?: 'VouchersFields_tickets_count';
  create?: Maybe<VouchersFields_Tickets_Count_Create>;
  delete?: Maybe<VouchersFields_Tickets_Count_Delete>;
  read?: Maybe<VouchersFields_Tickets_Count_Read>;
  update?: Maybe<VouchersFields_Tickets_Count_Update>;
};

export type VouchersFields_Tickets_Count_Create = {
  __typename?: 'VouchersFields_tickets_count_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Count_Delete = {
  __typename?: 'VouchersFields_tickets_count_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Count_Read = {
  __typename?: 'VouchersFields_tickets_count_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Count_Update = {
  __typename?: 'VouchersFields_tickets_count_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Id = {
  __typename?: 'VouchersFields_tickets_id';
  create?: Maybe<VouchersFields_Tickets_Id_Create>;
  delete?: Maybe<VouchersFields_Tickets_Id_Delete>;
  read?: Maybe<VouchersFields_Tickets_Id_Read>;
  update?: Maybe<VouchersFields_Tickets_Id_Update>;
};

export type VouchersFields_Tickets_Id_Create = {
  __typename?: 'VouchersFields_tickets_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Id_Delete = {
  __typename?: 'VouchersFields_tickets_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Id_Read = {
  __typename?: 'VouchersFields_tickets_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Id_Update = {
  __typename?: 'VouchersFields_tickets_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Price = {
  __typename?: 'VouchersFields_tickets_price';
  create?: Maybe<VouchersFields_Tickets_Price_Create>;
  delete?: Maybe<VouchersFields_Tickets_Price_Delete>;
  read?: Maybe<VouchersFields_Tickets_Price_Read>;
  update?: Maybe<VouchersFields_Tickets_Price_Update>;
};

export type VouchersFields_Tickets_Price_Create = {
  __typename?: 'VouchersFields_tickets_price_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Price_Delete = {
  __typename?: 'VouchersFields_tickets_price_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Price_Read = {
  __typename?: 'VouchersFields_tickets_price_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Price_Update = {
  __typename?: 'VouchersFields_tickets_price_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Ticket = {
  __typename?: 'VouchersFields_tickets_ticket';
  create?: Maybe<VouchersFields_Tickets_Ticket_Create>;
  delete?: Maybe<VouchersFields_Tickets_Ticket_Delete>;
  read?: Maybe<VouchersFields_Tickets_Ticket_Read>;
  update?: Maybe<VouchersFields_Tickets_Ticket_Update>;
};

export type VouchersFields_Tickets_Ticket_Create = {
  __typename?: 'VouchersFields_tickets_ticket_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Ticket_Delete = {
  __typename?: 'VouchersFields_tickets_ticket_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Ticket_Read = {
  __typename?: 'VouchersFields_tickets_ticket_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_Tickets_Ticket_Update = {
  __typename?: 'VouchersFields_tickets_ticket_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_TotalPrice = {
  __typename?: 'VouchersFields_totalPrice';
  create?: Maybe<VouchersFields_TotalPrice_Create>;
  delete?: Maybe<VouchersFields_TotalPrice_Delete>;
  read?: Maybe<VouchersFields_TotalPrice_Read>;
  update?: Maybe<VouchersFields_TotalPrice_Update>;
};

export type VouchersFields_TotalPrice_Create = {
  __typename?: 'VouchersFields_totalPrice_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_TotalPrice_Delete = {
  __typename?: 'VouchersFields_totalPrice_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_TotalPrice_Read = {
  __typename?: 'VouchersFields_totalPrice_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_TotalPrice_Update = {
  __typename?: 'VouchersFields_totalPrice_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_UpdatedAt = {
  __typename?: 'VouchersFields_updatedAt';
  create?: Maybe<VouchersFields_UpdatedAt_Create>;
  delete?: Maybe<VouchersFields_UpdatedAt_Delete>;
  read?: Maybe<VouchersFields_UpdatedAt_Read>;
  update?: Maybe<VouchersFields_UpdatedAt_Update>;
};

export type VouchersFields_UpdatedAt_Create = {
  __typename?: 'VouchersFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_UpdatedAt_Delete = {
  __typename?: 'VouchersFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_UpdatedAt_Read = {
  __typename?: 'VouchersFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type VouchersFields_UpdatedAt_Update = {
  __typename?: 'VouchersFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type VouchersReadAccess = {
  __typename?: 'VouchersReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type VouchersReadDocAccess = {
  __typename?: 'VouchersReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type VouchersUpdateAccess = {
  __typename?: 'VouchersUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type VouchersUpdateDocAccess = {
  __typename?: 'VouchersUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type AllMedia = {
  __typename?: 'allMedia';
  docs?: Maybe<Array<Maybe<Media>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type BookingExtras = {
  __typename?: 'bookingExtras';
  count?: Maybe<Scalars['Float']['output']>;
  extra?: Maybe<Extra>;
  id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};


export type BookingExtrasExtraArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type BookingTickets = {
  __typename?: 'bookingTickets';
  count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  ticket?: Maybe<TicketType>;
};


export type BookingTicketsTicketArgs = {
  fallbackLocale?: InputMaybe<FallbackLocaleInputType>;
  locale?: InputMaybe<LocaleInputType>;
};

export type BookingsAccess = {
  __typename?: 'bookingsAccess';
  create?: Maybe<BookingsCreateAccess>;
  delete?: Maybe<BookingsDeleteAccess>;
  fields?: Maybe<BookingsFields>;
  read?: Maybe<BookingsReadAccess>;
  update?: Maybe<BookingsUpdateAccess>;
};

export type BookingsDocAccess = {
  __typename?: 'bookingsDocAccess';
  create?: Maybe<BookingsCreateDocAccess>;
  delete?: Maybe<BookingsDeleteDocAccess>;
  fields?: Maybe<BookingsDocAccessFields>;
  read?: Maybe<BookingsReadDocAccess>;
  update?: Maybe<BookingsUpdateDocAccess>;
};

export type CheckoutsAccess = {
  __typename?: 'checkoutsAccess';
  create?: Maybe<CheckoutsCreateAccess>;
  delete?: Maybe<CheckoutsDeleteAccess>;
  fields?: Maybe<CheckoutsFields>;
  read?: Maybe<CheckoutsReadAccess>;
  update?: Maybe<CheckoutsUpdateAccess>;
};

export type CheckoutsDocAccess = {
  __typename?: 'checkoutsDocAccess';
  create?: Maybe<CheckoutsCreateDocAccess>;
  delete?: Maybe<CheckoutsDeleteDocAccess>;
  fields?: Maybe<CheckoutsDocAccessFields>;
  read?: Maybe<CheckoutsReadDocAccess>;
  update?: Maybe<CheckoutsUpdateDocAccess>;
};

export type CompanyDetailsAccess = {
  __typename?: 'companyDetailsAccess';
  fields?: Maybe<CompanyDetailsFields>;
  read?: Maybe<CompanyDetailsReadAccess>;
  update?: Maybe<CompanyDetailsUpdateAccess>;
};

export type CompanyDetailsDocAccess = {
  __typename?: 'companyDetailsDocAccess';
  fields?: Maybe<CompanyDetailsDocAccessFields>;
  read?: Maybe<CompanyDetailsReadDocAccess>;
  update?: Maybe<CompanyDetailsUpdateDocAccess>;
};

export type CompanyDetailsGlobalsAccess = {
  __typename?: 'companyDetailsGlobalsAccess';
  create?: Maybe<CompanyDetailsGlobalsCreateAccess>;
  delete?: Maybe<CompanyDetailsGlobalsDeleteAccess>;
  fields?: Maybe<CompanyDetailsGlobalsFields>;
  read?: Maybe<CompanyDetailsGlobalsReadAccess>;
  update?: Maybe<CompanyDetailsGlobalsUpdateAccess>;
};

export type CompanyDetailsGlobalsDocAccess = {
  __typename?: 'companyDetailsGlobalsDocAccess';
  create?: Maybe<CompanyDetailsGlobalsCreateDocAccess>;
  delete?: Maybe<CompanyDetailsGlobalsDeleteDocAccess>;
  fields?: Maybe<CompanyDetailsGlobalsDocAccessFields>;
  read?: Maybe<CompanyDetailsGlobalsReadDocAccess>;
  update?: Maybe<CompanyDetailsGlobalsUpdateDocAccess>;
};

export type ContactsAccess = {
  __typename?: 'contactsAccess';
  create?: Maybe<ContactsCreateAccess>;
  delete?: Maybe<ContactsDeleteAccess>;
  fields?: Maybe<ContactsFields>;
  read?: Maybe<ContactsReadAccess>;
  update?: Maybe<ContactsUpdateAccess>;
};

export type ContactsDocAccess = {
  __typename?: 'contactsDocAccess';
  create?: Maybe<ContactsCreateDocAccess>;
  delete?: Maybe<ContactsDeleteDocAccess>;
  fields?: Maybe<ContactsDocAccessFields>;
  read?: Maybe<ContactsReadDocAccess>;
  update?: Maybe<ContactsUpdateDocAccess>;
};

export type EventsAccess = {
  __typename?: 'eventsAccess';
  create?: Maybe<EventsCreateAccess>;
  delete?: Maybe<EventsDeleteAccess>;
  fields?: Maybe<EventsFields>;
  read?: Maybe<EventsReadAccess>;
  update?: Maybe<EventsUpdateAccess>;
};

export type EventsDocAccess = {
  __typename?: 'eventsDocAccess';
  create?: Maybe<EventsCreateDocAccess>;
  delete?: Maybe<EventsDeleteDocAccess>;
  fields?: Maybe<EventsDocAccessFields>;
  read?: Maybe<EventsReadDocAccess>;
  update?: Maybe<EventsUpdateDocAccess>;
};

export type ExtrasAccess = {
  __typename?: 'extrasAccess';
  create?: Maybe<ExtrasCreateAccess>;
  delete?: Maybe<ExtrasDeleteAccess>;
  fields?: Maybe<ExtrasFields>;
  read?: Maybe<ExtrasReadAccess>;
  update?: Maybe<ExtrasUpdateAccess>;
};

export type ExtrasDocAccess = {
  __typename?: 'extrasDocAccess';
  create?: Maybe<ExtrasCreateDocAccess>;
  delete?: Maybe<ExtrasDeleteDocAccess>;
  fields?: Maybe<ExtrasDocAccessFields>;
  read?: Maybe<ExtrasReadDocAccess>;
  update?: Maybe<ExtrasUpdateDocAccess>;
};

export type IntegrationsAccess = {
  __typename?: 'integrationsAccess';
  fields?: Maybe<IntegrationsFields>;
  read?: Maybe<IntegrationsReadAccess>;
  update?: Maybe<IntegrationsUpdateAccess>;
};

export type IntegrationsDocAccess = {
  __typename?: 'integrationsDocAccess';
  fields?: Maybe<IntegrationsDocAccessFields>;
  read?: Maybe<IntegrationsReadDocAccess>;
  update?: Maybe<IntegrationsUpdateDocAccess>;
};

export type IntegrationsGlobalsAccess = {
  __typename?: 'integrationsGlobalsAccess';
  create?: Maybe<IntegrationsGlobalsCreateAccess>;
  delete?: Maybe<IntegrationsGlobalsDeleteAccess>;
  fields?: Maybe<IntegrationsGlobalsFields>;
  read?: Maybe<IntegrationsGlobalsReadAccess>;
  update?: Maybe<IntegrationsGlobalsUpdateAccess>;
};

export type IntegrationsGlobalsDocAccess = {
  __typename?: 'integrationsGlobalsDocAccess';
  create?: Maybe<IntegrationsGlobalsCreateDocAccess>;
  delete?: Maybe<IntegrationsGlobalsDeleteDocAccess>;
  fields?: Maybe<IntegrationsGlobalsDocAccessFields>;
  read?: Maybe<IntegrationsGlobalsReadDocAccess>;
  update?: Maybe<IntegrationsGlobalsUpdateDocAccess>;
};

export type MediaAccess = {
  __typename?: 'mediaAccess';
  create?: Maybe<MediaCreateAccess>;
  delete?: Maybe<MediaDeleteAccess>;
  fields?: Maybe<MediaFields>;
  read?: Maybe<MediaReadAccess>;
  update?: Maybe<MediaUpdateAccess>;
};

export type MediaDocAccess = {
  __typename?: 'mediaDocAccess';
  create?: Maybe<MediaCreateDocAccess>;
  delete?: Maybe<MediaDeleteDocAccess>;
  fields?: Maybe<MediaDocAccessFields>;
  read?: Maybe<MediaReadDocAccess>;
  update?: Maybe<MediaUpdateDocAccess>;
};

export type MessagesAccess = {
  __typename?: 'messagesAccess';
  create?: Maybe<MessagesCreateAccess>;
  delete?: Maybe<MessagesDeleteAccess>;
  fields?: Maybe<MessagesFields>;
  read?: Maybe<MessagesReadAccess>;
  update?: Maybe<MessagesUpdateAccess>;
};

export type MessagesDocAccess = {
  __typename?: 'messagesDocAccess';
  create?: Maybe<MessagesCreateDocAccess>;
  delete?: Maybe<MessagesDeleteDocAccess>;
  fields?: Maybe<MessagesDocAccessFields>;
  read?: Maybe<MessagesReadDocAccess>;
  update?: Maybe<MessagesUpdateDocAccess>;
};

export type MutationBookingUpdateInput = {
  bookingNumber?: InputMaybe<Scalars['String']['input']>;
  bookingStatus?: InputMaybe<BookingUpdate_BookingStatus_MutationInput>;
  checkoutID?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  extras?: InputMaybe<Array<InputMaybe<MutationBookingUpdate_ExtrasInput>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  paidTimestamp?: InputMaybe<Scalars['String']['input']>;
  stripePaymentIntentID?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  tickets?: InputMaybe<Array<InputMaybe<MutationBookingUpdate_TicketsInput>>>;
  totalPrice?: InputMaybe<Scalars['Float']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  voucher?: InputMaybe<Scalars['String']['input']>;
};

export type MutationBookingUpdate_ExtrasInput = {
  count: Scalars['Float']['input'];
  extra?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
};

export type MutationBookingUpdate_TicketsInput = {
  count: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  ticket?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCheckoutInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  extras?: InputMaybe<Array<InputMaybe<MutationCheckout_ExtrasInput>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  tickets?: InputMaybe<Array<InputMaybe<MutationCheckout_TicketsInput>>>;
  totalPrice?: InputMaybe<Scalars['Float']['input']>;
  type: Checkout_Type_MutationInput;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  voucher?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCheckoutUpdateInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  extras?: InputMaybe<Array<InputMaybe<MutationCheckoutUpdate_ExtrasInput>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  tickets?: InputMaybe<Array<InputMaybe<MutationCheckoutUpdate_TicketsInput>>>;
  totalPrice?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<CheckoutUpdate_Type_MutationInput>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  voucher?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCheckoutUpdate_ExtrasInput = {
  count: Scalars['Float']['input'];
  extra?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
};

export type MutationCheckoutUpdate_TicketsInput = {
  count: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  ticket?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCheckout_ExtrasInput = {
  count: Scalars['Float']['input'];
  extra?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
};

export type MutationCheckout_TicketsInput = {
  count: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  ticket?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCompanyDetailInput = {
  address?: InputMaybe<MutationCompanyDetail_AddressInput>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  originalLanguage?: InputMaybe<CompanyDetail_OriginalLanguage_MutationInput>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCompanyDetail_AddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCompanyDetailsGlobalInput = {
  address: MutationCompanyDetailsGlobal_AddressInput;
  companyName: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  logo?: InputMaybe<Scalars['String']['input']>;
  originalLanguage?: InputMaybe<CompanyDetailsGlobal_OriginalLanguage_MutationInput>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  taxID: Scalars['String']['input'];
  tenant?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  website: Scalars['String']['input'];
};

export type MutationCompanyDetailsGlobalUpdateInput = {
  address: MutationCompanyDetailsGlobalUpdate_AddressInput;
  companyName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  originalLanguage?: InputMaybe<CompanyDetailsGlobalUpdate_OriginalLanguage_MutationInput>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCompanyDetailsGlobalUpdate_AddressInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  county?: InputMaybe<Scalars['String']['input']>;
  postcode: Scalars['String']['input'];
};

export type MutationCompanyDetailsGlobal_AddressInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  county?: InputMaybe<Scalars['String']['input']>;
  postcode: Scalars['String']['input'];
};

export type MutationContactInput = {
  billingAddress?: InputMaybe<MutationContact_BillingAddressInput>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  stripeID?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationContactUpdateInput = {
  billingAddress?: InputMaybe<MutationContactUpdate_BillingAddressInput>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  stripeID?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationContactUpdate_BillingAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<ContactUpdate_BillingAddress_Country_MutationInput>;
  county?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationContact_BillingAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Contact_BillingAddress_Country_MutationInput>;
  county?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationEventInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['String']['input'];
  eventStatus?: InputMaybe<Event_EventStatus_MutationInput>;
  location: Scalars['String']['input'];
  service?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['String']['input'];
  tenant?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  totalTickets?: InputMaybe<Scalars['Float']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationEventUpdateInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  eventStatus?: InputMaybe<EventUpdate_EventStatus_MutationInput>;
  location?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  totalTickets?: InputMaybe<Scalars['Float']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationExtraInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayName_en?: InputMaybe<Scalars['String']['input']>;
  displayName_original?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  shortDescription_en?: InputMaybe<Scalars['String']['input']>;
  shortDescription_original?: InputMaybe<Scalars['String']['input']>;
  stripeID?: InputMaybe<Scalars['String']['input']>;
  stripePriceID?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationExtraUpdateInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayName_en?: InputMaybe<Scalars['String']['input']>;
  displayName_original?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  shortDescription_en?: InputMaybe<Scalars['String']['input']>;
  shortDescription_original?: InputMaybe<Scalars['String']['input']>;
  stripeID?: InputMaybe<Scalars['String']['input']>;
  stripePriceID?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationIntegrationInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  googleAnalytics?: InputMaybe<MutationIntegration_GoogleAnalyticsInput>;
  googleCal?: InputMaybe<MutationIntegration_GoogleCalInput>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationIntegration_GoogleAnalyticsInput = {
  measurementID?: InputMaybe<Scalars['String']['input']>;
};

export type MutationIntegration_GoogleCalInput = {
  authTokens?: InputMaybe<Scalars['JSON']['input']>;
  isAuthenticated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationIntegrationsGlobalInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  googleAnalytics?: InputMaybe<MutationIntegrationsGlobal_GoogleAnalyticsInput>;
  googleCal?: InputMaybe<MutationIntegrationsGlobal_GoogleCalInput>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationIntegrationsGlobalUpdateInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  googleAnalytics?: InputMaybe<MutationIntegrationsGlobalUpdate_GoogleAnalyticsInput>;
  googleCal?: InputMaybe<MutationIntegrationsGlobalUpdate_GoogleCalInput>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationIntegrationsGlobalUpdate_GoogleAnalyticsInput = {
  measurementID?: InputMaybe<Scalars['String']['input']>;
};

export type MutationIntegrationsGlobalUpdate_GoogleCalInput = {
  authTokens?: InputMaybe<Scalars['JSON']['input']>;
  isAuthenticated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationIntegrationsGlobal_GoogleAnalyticsInput = {
  measurementID?: InputMaybe<Scalars['String']['input']>;
};

export type MutationIntegrationsGlobal_GoogleCalInput = {
  authTokens?: InputMaybe<Scalars['JSON']['input']>;
  isAuthenticated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationMediaInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  sizes?: InputMaybe<MutationMedia_SizesInput>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationMediaUpdateInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  sizes?: InputMaybe<MutationMediaUpdate_SizesInput>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationMediaUpdate_SizesInput = {
  card?: InputMaybe<MutationMediaUpdate_Sizes_CardInput>;
  tablet?: InputMaybe<MutationMediaUpdate_Sizes_TabletInput>;
  thumbnail?: InputMaybe<MutationMediaUpdate_Sizes_ThumbnailInput>;
};

export type MutationMediaUpdate_Sizes_CardInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationMediaUpdate_Sizes_TabletInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationMediaUpdate_Sizes_ThumbnailInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationMedia_SizesInput = {
  card?: InputMaybe<MutationMedia_Sizes_CardInput>;
  tablet?: InputMaybe<MutationMedia_Sizes_TabletInput>;
  thumbnail?: InputMaybe<MutationMedia_Sizes_ThumbnailInput>;
};

export type MutationMedia_Sizes_CardInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationMedia_Sizes_TabletInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationMedia_Sizes_ThumbnailInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationMessageInput = {
  content: Scalars['JSON']['input'];
  createdAt?: InputMaybe<Scalars['String']['input']>;
  messageType: Message_MessageType_MutationInput;
  recipients?: InputMaybe<Array<InputMaybe<Message_RecipientsRelationshipInput>>>;
  subject: Scalars['String']['input'];
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationMessageUpdateInput = {
  content?: InputMaybe<Scalars['JSON']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  messageType?: InputMaybe<MessageUpdate_MessageType_MutationInput>;
  recipients?: InputMaybe<Array<InputMaybe<MessageUpdate_RecipientsRelationshipInput>>>;
  subject?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationOutgoingMessageInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['String']['input']>;
  messageType: OutgoingMessage_MessageType_MutationInput;
  recipient: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationOutgoingMessageUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  messageType?: InputMaybe<OutgoingMessageUpdate_MessageType_MutationInput>;
  recipient?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationPayloadPreferenceInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<PayloadPreference_UserRelationshipInput>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type MutationPayloadPreferenceUpdateInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<PayloadPreferenceUpdate_UserRelationshipInput>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type MutationPaymentDetailInput = {
  canAcceptPayments?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<PaymentDetail_Currency_MutationInput>;
  stripeAccountId?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationPaymentDetailsGlobalInput = {
  canAcceptPayments?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<PaymentDetailsGlobal_Currency_MutationInput>;
  stripeAccountId?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationPaymentDetailsGlobalUpdateInput = {
  canAcceptPayments?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<PaymentDetailsGlobalUpdate_Currency_MutationInput>;
  stripeAccountId?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationServiceInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  extras?: InputMaybe<Array<InputMaybe<MutationService_ExtrasInput>>>;
  image?: InputMaybe<Scalars['String']['input']>;
  location: Scalars['String']['input'];
  longDescription?: InputMaybe<Scalars['JSON']['input']>;
  longDescription_en?: InputMaybe<Scalars['JSON']['input']>;
  longDescription_original?: InputMaybe<Scalars['JSON']['input']>;
  maxAheadBookableDays?: InputMaybe<Scalars['Float']['input']>;
  maxTicketCount?: InputMaybe<Scalars['Float']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  shortDescription_en?: InputMaybe<Scalars['String']['input']>;
  shortDescription_original?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  ticketTypes?: InputMaybe<Array<MutationService_TicketTypesInput>>;
  timeslots?: InputMaybe<Array<InputMaybe<MutationService_TimeslotsInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en: Scalars['String']['input'];
  title_original: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  vouchersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationServiceUpdateInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  extras?: InputMaybe<Array<InputMaybe<MutationServiceUpdate_ExtrasInput>>>;
  image?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  longDescription?: InputMaybe<Scalars['JSON']['input']>;
  longDescription_en?: InputMaybe<Scalars['JSON']['input']>;
  longDescription_original?: InputMaybe<Scalars['JSON']['input']>;
  maxAheadBookableDays?: InputMaybe<Scalars['Float']['input']>;
  maxTicketCount?: InputMaybe<Scalars['Float']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  shortDescription_en?: InputMaybe<Scalars['String']['input']>;
  shortDescription_original?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  ticketTypes?: InputMaybe<Array<InputMaybe<MutationServiceUpdate_TicketTypesInput>>>;
  timeslots?: InputMaybe<Array<InputMaybe<MutationServiceUpdate_TimeslotsInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_en?: InputMaybe<Scalars['String']['input']>;
  title_original?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  vouchersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationServiceUpdate_ExtrasInput = {
  extra?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationServiceUpdate_TicketTypesInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  ticketType?: InputMaybe<Scalars['String']['input']>;
};

export type MutationServiceUpdate_TimeslotsInput = {
  end: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  weekdays: Array<InputMaybe<ServiceUpdate_Timeslots_Weekdays_MutationInput>>;
};

export type MutationService_ExtrasInput = {
  extra?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationService_TicketTypesInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  ticketType?: InputMaybe<Scalars['String']['input']>;
};

export type MutationService_TimeslotsInput = {
  end: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  weekdays: Array<InputMaybe<Service_Timeslots_Weekdays_MutationInput>>;
};

export type MutationTenantInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<InputMaybe<MutationTenant_DomainsInput>>>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationTenantUpdateInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<InputMaybe<MutationTenantUpdate_DomainsInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationTenantUpdate_DomainsInput = {
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationTenant_DomainsInput = {
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationTicketTypeInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayName_en?: InputMaybe<Scalars['String']['input']>;
  displayName_original?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  stripeID?: InputMaybe<Scalars['String']['input']>;
  stripePriceID?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationTicketTypeUpdateInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayName_en?: InputMaybe<Scalars['String']['input']>;
  displayName_original?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  stripeID?: InputMaybe<Scalars['String']['input']>;
  stripePriceID?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  hash?: InputMaybe<Scalars['String']['input']>;
  lockUntil?: InputMaybe<Scalars['String']['input']>;
  loginAttempts?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  resetPasswordExpiration?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  salt?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserUpdateInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  lockUntil?: InputMaybe<Scalars['String']['input']>;
  loginAttempts?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  resetPasswordExpiration?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  salt?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationVoucherInput = {
  bookingStatus?: InputMaybe<Voucher_BookingStatus_MutationInput>;
  checkoutID?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  extras?: InputMaybe<Array<InputMaybe<MutationVoucher_ExtrasInput>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  paidTimestamp?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  stripePaymentIntentID?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  tickets?: InputMaybe<Array<InputMaybe<MutationVoucher_TicketsInput>>>;
  totalPrice?: InputMaybe<Scalars['Float']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationVoucherUpdateInput = {
  bookingStatus?: InputMaybe<VoucherUpdate_BookingStatus_MutationInput>;
  checkoutID?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  extras?: InputMaybe<Array<InputMaybe<MutationVoucherUpdate_ExtrasInput>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  paidTimestamp?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  stripePaymentIntentID?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  tickets?: InputMaybe<Array<InputMaybe<MutationVoucherUpdate_TicketsInput>>>;
  totalPrice?: InputMaybe<Scalars['Float']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationVoucherUpdate_ExtrasInput = {
  count: Scalars['Float']['input'];
  extra?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
};

export type MutationVoucherUpdate_TicketsInput = {
  count: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  ticket?: InputMaybe<Scalars['String']['input']>;
};

export type MutationVoucher_ExtrasInput = {
  count: Scalars['Float']['input'];
  extra?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
};

export type MutationVoucher_TicketsInput = {
  count: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  ticket?: InputMaybe<Scalars['String']['input']>;
};

export type OutgoingMessagesAccess = {
  __typename?: 'outgoingMessagesAccess';
  create?: Maybe<OutgoingMessagesCreateAccess>;
  delete?: Maybe<OutgoingMessagesDeleteAccess>;
  fields?: Maybe<OutgoingMessagesFields>;
  read?: Maybe<OutgoingMessagesReadAccess>;
  update?: Maybe<OutgoingMessagesUpdateAccess>;
};

export type OutgoingMessagesDocAccess = {
  __typename?: 'outgoingMessagesDocAccess';
  create?: Maybe<OutgoingMessagesCreateDocAccess>;
  delete?: Maybe<OutgoingMessagesDeleteDocAccess>;
  fields?: Maybe<OutgoingMessagesDocAccessFields>;
  read?: Maybe<OutgoingMessagesReadDocAccess>;
  update?: Maybe<OutgoingMessagesUpdateDocAccess>;
};

export type Payload_PreferencesAccess = {
  __typename?: 'payload_preferencesAccess';
  create?: Maybe<PayloadPreferencesCreateAccess>;
  delete?: Maybe<PayloadPreferencesDeleteAccess>;
  fields?: Maybe<PayloadPreferencesFields>;
  read?: Maybe<PayloadPreferencesReadAccess>;
  update?: Maybe<PayloadPreferencesUpdateAccess>;
};

export type Payload_PreferencesDocAccess = {
  __typename?: 'payload_preferencesDocAccess';
  create?: Maybe<PayloadPreferencesCreateDocAccess>;
  delete?: Maybe<PayloadPreferencesDeleteDocAccess>;
  fields?: Maybe<PayloadPreferencesDocAccessFields>;
  read?: Maybe<PayloadPreferencesReadDocAccess>;
  update?: Maybe<PayloadPreferencesUpdateDocAccess>;
};

export type PaymentDetailsAccess = {
  __typename?: 'paymentDetailsAccess';
  fields?: Maybe<PaymentDetailsFields>;
  read?: Maybe<PaymentDetailsReadAccess>;
  update?: Maybe<PaymentDetailsUpdateAccess>;
};

export type PaymentDetailsDocAccess = {
  __typename?: 'paymentDetailsDocAccess';
  fields?: Maybe<PaymentDetailsDocAccessFields>;
  read?: Maybe<PaymentDetailsReadDocAccess>;
  update?: Maybe<PaymentDetailsUpdateDocAccess>;
};

export type PaymentDetailsGlobalsAccess = {
  __typename?: 'paymentDetailsGlobalsAccess';
  create?: Maybe<PaymentDetailsGlobalsCreateAccess>;
  delete?: Maybe<PaymentDetailsGlobalsDeleteAccess>;
  fields?: Maybe<PaymentDetailsGlobalsFields>;
  read?: Maybe<PaymentDetailsGlobalsReadAccess>;
  update?: Maybe<PaymentDetailsGlobalsUpdateAccess>;
};

export type PaymentDetailsGlobalsDocAccess = {
  __typename?: 'paymentDetailsGlobalsDocAccess';
  create?: Maybe<PaymentDetailsGlobalsCreateDocAccess>;
  delete?: Maybe<PaymentDetailsGlobalsDeleteDocAccess>;
  fields?: Maybe<PaymentDetailsGlobalsDocAccessFields>;
  read?: Maybe<PaymentDetailsGlobalsReadDocAccess>;
  update?: Maybe<PaymentDetailsGlobalsUpdateDocAccess>;
};

export type ServicesAccess = {
  __typename?: 'servicesAccess';
  create?: Maybe<ServicesCreateAccess>;
  delete?: Maybe<ServicesDeleteAccess>;
  fields?: Maybe<ServicesFields>;
  read?: Maybe<ServicesReadAccess>;
  update?: Maybe<ServicesUpdateAccess>;
};

export type ServicesDocAccess = {
  __typename?: 'servicesDocAccess';
  create?: Maybe<ServicesCreateDocAccess>;
  delete?: Maybe<ServicesDeleteDocAccess>;
  fields?: Maybe<ServicesDocAccessFields>;
  read?: Maybe<ServicesReadDocAccess>;
  update?: Maybe<ServicesUpdateDocAccess>;
};

export type TenantsAccess = {
  __typename?: 'tenantsAccess';
  create?: Maybe<TenantsCreateAccess>;
  delete?: Maybe<TenantsDeleteAccess>;
  fields?: Maybe<TenantsFields>;
  read?: Maybe<TenantsReadAccess>;
  update?: Maybe<TenantsUpdateAccess>;
};

export type TenantsDocAccess = {
  __typename?: 'tenantsDocAccess';
  create?: Maybe<TenantsCreateDocAccess>;
  delete?: Maybe<TenantsDeleteDocAccess>;
  fields?: Maybe<TenantsDocAccessFields>;
  read?: Maybe<TenantsReadDocAccess>;
  update?: Maybe<TenantsUpdateDocAccess>;
};

export type TicketTypesAccess = {
  __typename?: 'ticketTypesAccess';
  create?: Maybe<TicketTypesCreateAccess>;
  delete?: Maybe<TicketTypesDeleteAccess>;
  fields?: Maybe<TicketTypesFields>;
  read?: Maybe<TicketTypesReadAccess>;
  update?: Maybe<TicketTypesUpdateAccess>;
};

export type TicketTypesDocAccess = {
  __typename?: 'ticketTypesDocAccess';
  create?: Maybe<TicketTypesCreateDocAccess>;
  delete?: Maybe<TicketTypesDeleteDocAccess>;
  fields?: Maybe<TicketTypesDocAccessFields>;
  read?: Maybe<TicketTypesReadDocAccess>;
  update?: Maybe<TicketTypesUpdateDocAccess>;
};

export type UsersAccess = {
  __typename?: 'usersAccess';
  create?: Maybe<UsersCreateAccess>;
  delete?: Maybe<UsersDeleteAccess>;
  fields?: Maybe<UsersFields>;
  read?: Maybe<UsersReadAccess>;
  unlock?: Maybe<UsersUnlockAccess>;
  update?: Maybe<UsersUpdateAccess>;
};

export type UsersDocAccess = {
  __typename?: 'usersDocAccess';
  create?: Maybe<UsersCreateDocAccess>;
  delete?: Maybe<UsersDeleteDocAccess>;
  fields?: Maybe<UsersDocAccessFields>;
  read?: Maybe<UsersReadDocAccess>;
  unlock?: Maybe<UsersUnlockDocAccess>;
  update?: Maybe<UsersUpdateDocAccess>;
};

export type UsersJwt = {
  __typename?: 'usersJWT';
  collection: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
};

export type UsersLoginResult = {
  __typename?: 'usersLoginResult';
  exp?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type UsersMe = {
  __typename?: 'usersMe';
  collection?: Maybe<Scalars['String']['output']>;
  exp?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type UsersRefreshedUser = {
  __typename?: 'usersRefreshedUser';
  exp?: Maybe<Scalars['Int']['output']>;
  refreshedToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UsersJwt>;
};

export type UsersResetPassword = {
  __typename?: 'usersResetPassword';
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type VouchersAccess = {
  __typename?: 'vouchersAccess';
  create?: Maybe<VouchersCreateAccess>;
  delete?: Maybe<VouchersDeleteAccess>;
  fields?: Maybe<VouchersFields>;
  read?: Maybe<VouchersReadAccess>;
  update?: Maybe<VouchersUpdateAccess>;
};

export type VouchersDocAccess = {
  __typename?: 'vouchersDocAccess';
  create?: Maybe<VouchersCreateDocAccess>;
  delete?: Maybe<VouchersDeleteDocAccess>;
  fields?: Maybe<VouchersDocAccessFields>;
  read?: Maybe<VouchersReadDocAccess>;
  update?: Maybe<VouchersUpdateDocAccess>;
};

export type LoginUserMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser?: { __typename: 'usersLoginResult', exp?: number | null, token?: string | null } | null };

export type CreateBookingMutationVariables = Exact<{
  input?: InputMaybe<CreateBookingInput>;
}>;


export type CreateBookingMutation = { __typename?: 'Mutation', createBooking?: { __typename: 'Error', reason?: ErrorReason | null, message?: string | null } | { __typename: 'StringResponse', response?: string | null } | null };

export type CreatePaymentAccountLinkMutationVariables = Exact<{ [key: string]: never; }>;


export type CreatePaymentAccountLinkMutation = { __typename?: 'Mutation', createPaymentAccountLink?: string | null };

export type CreateVoucherCheckoutMutationVariables = Exact<{
  input?: InputMaybe<CreateVoucherCheckoutInput>;
}>;


export type CreateVoucherCheckoutMutation = { __typename?: 'Mutation', createVoucherCheckout?: { __typename: 'Error', reason?: ErrorReason | null, message?: string | null } | { __typename: 'StringResponse', response?: string | null } | null };

export type RegisterServiceProviderMutationVariables = Exact<{
  input: ServiceProviderInput;
}>;


export type RegisterServiceProviderMutation = { __typename?: 'Mutation', registerServiceProvider?: { __typename: 'Error', reason?: ErrorReason | null, message?: string | null, field?: string | null } | { __typename: 'StringResponse', response?: string | null } | null };

export type SetAdminUserTenantMutationVariables = Exact<{
  input: Scalars['ID']['input'];
}>;


export type SetAdminUserTenantMutation = { __typename?: 'Mutation', setAdminUserTenant?: { __typename?: 'Error', reason?: ErrorReason | null, message?: string | null } | { __typename?: 'StringResponse', response?: string | null } | null };

export type CurrencyDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrencyDetailsQuery = { __typename?: 'Query', PaymentDetail?: { __typename?: 'PaymentDetail', currency?: PaymentDetail_Currency | null } | null };

export type AdminTenantsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminTenantsQuery = { __typename?: 'Query', adminTenants?: Array<{ __typename?: 'AdminTenantsResponse', id?: string | null, slug?: string | null } | null> | null };

export type AvailableTimeslotsQueryVariables = Exact<{
  tenant: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  serviceId?: InputMaybe<Scalars['String']['input']>;
}>;


export type AvailableTimeslotsQuery = { __typename?: 'Query', availableTimeslots?: Array<{ __typename?: 'AvailableTimeslotResponse', service?: { __typename?: 'Service', id?: string | null, maxTicketCount?: number | null, ticketTypes: Array<{ __typename?: 'Service_TicketTypes', id?: string | null, primary?: boolean | null, ticketType?: { __typename?: 'TicketType', id?: string | null } | null }> } | null, bookedTickets?: Array<{ __typename?: 'BookedTicketsResponse', timeslot?: { __typename?: 'TimeslotResponse', start?: string | null, end?: string | null } | null, tickets?: Array<{ __typename?: 'TimeslotTicketsResponse', bookedCount?: number | null, ticket?: { __typename?: 'TicketType', id?: string | null, displayName?: string | null, price: number } | null } | null> | null, extras?: Array<{ __typename?: 'TimeslotExtrasResponse', extra?: { __typename?: 'Extra', id?: string | null, displayName?: string | null, price: number } | null } | null> | null } | null> | null } | null> | null };

export type BookingsForEventQueryVariables = Exact<{
  input?: InputMaybe<Scalars['ID']['input']>;
}>;


export type BookingsForEventQuery = { __typename?: 'Query', bookingsForEvent?: Array<{ __typename?: 'Booking', id?: string | null, bookingNumber?: string | null, contact: { __typename?: 'Contact', id?: string | null, fullName: string, email: any }, tickets?: Array<{ __typename?: 'bookingTickets', count?: number | null, price?: number | null }> | null } | null> | null };

export type CheckoutStatusQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  tenant?: InputMaybe<Scalars['ID']['input']>;
}>;


export type CheckoutStatusQuery = { __typename?: 'Query', checkoutStatus?: { __typename?: 'CheckoutStatusResponse', contactId?: string | null, bookingId?: string | null, voucherId?: string | null, email?: string | null, bookingNumber?: string | null, bookingFlowType?: string | null, tenantId?: string | null } | null };

export type EventsByDateQueryVariables = Exact<{
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
}>;


export type EventsByDateQuery = { __typename?: 'Query', eventsByDate?: Array<{ __typename?: 'Event', id?: string | null, title?: string | null, start: any, end: any, totalTickets?: number | null, location: string, service?: { __typename?: 'Service', id?: string | null, title?: string | null } | null } | null> | null };

export type OnboardingStepsQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingStepsQuery = { __typename?: 'Query', onboardingSteps?: { __typename: 'Error', reason?: ErrorReason | null, message?: string | null } | { __typename: 'OnboardingStepsResponse', canAcceptPayments?: boolean | null, serviceAdded?: boolean | null, addressFilledOut?: boolean | null, companyDetailsFilledOut?: boolean | null } | null };

export type ServicesByTenantQueryVariables = Exact<{
  input: Scalars['String']['input'];
  locale?: InputMaybe<LocaleInputType>;
}>;


export type ServicesByTenantQuery = { __typename?: 'Query', servicesByTenant: Array<{ __typename?: 'Service', id?: string | null, title?: string | null, location: string, shortDescription?: string | null, longDescription?: any | null, maxTicketCount?: number | null, vouchersEnabled?: boolean | null, image?: { __typename?: 'Media', url?: string | null } | null, ticketTypes: Array<{ __typename?: 'Service_TicketTypes', id?: string | null, primary?: boolean | null, ticketType?: { __typename?: 'TicketType', id?: string | null, name: string, displayName?: string | null, price: number } | null }>, extras?: Array<{ __typename?: 'Service_Extras', id?: string | null, extra?: { __typename?: 'Extra', id?: string | null, name: string, displayName?: string | null, price: number } | null }> | null }> };

export type TenantDetailsQueryVariables = Exact<{
  input: Scalars['String']['input'];
}>;


export type TenantDetailsQuery = { __typename?: 'Query', tenantDetails?: { __typename: 'Error', reason?: ErrorReason | null, message?: string | null } | { __typename: 'TenantDetailsResponse', displayName?: string | null, logoURL?: string | null, shortDescription?: string | null, websiteURL?: string | null, contactEmail?: string | null, currency?: Currency | null, country?: string | null, originalLanguage?: string | null, timezone?: string | null, googleAnalyticsMeasurementID?: string | null } | null };

export type VoucherDetailsQueryVariables = Exact<{
  input: Scalars['ID']['input'];
}>;


export type VoucherDetailsQuery = { __typename?: 'Query', voucherDetails?: { __typename: 'Error', reason?: ErrorReason | null, message?: string | null } | { __typename: 'Voucher', bookingStatus?: Voucher_BookingStatus | null, tickets?: Array<{ __typename?: 'bookingTickets', price?: number | null, count?: number | null, ticket?: { __typename?: 'TicketType', id?: string | null, displayName?: string | null, name: string } | null }> | null, extras?: Array<{ __typename?: 'bookingExtras', price?: number | null, count?: number | null, extra?: { __typename?: 'Extra', id?: string | null, name: string } | null }> | null, service?: { __typename?: 'Service', id?: string | null, title?: string | null } | null } | null };


export const LoginUserDocument = gql`
    mutation loginUser($email: String, $password: String) {
  loginUser(email: $email, password: $password) {
    __typename
    exp
    token
  }
}
    `;
export const CreateBookingDocument = gql`
    mutation createBooking($input: CreateBookingInput) {
  createBooking(input: $input) {
    __typename
    ... on StringResponse {
      response
    }
    ... on Error {
      reason
      message
    }
  }
}
    `;
export const CreatePaymentAccountLinkDocument = gql`
    mutation createPaymentAccountLink {
  createPaymentAccountLink
}
    `;
export const CreateVoucherCheckoutDocument = gql`
    mutation createVoucherCheckout($input: CreateVoucherCheckoutInput) {
  createVoucherCheckout(input: $input) {
    __typename
    ... on StringResponse {
      response
    }
    ... on Error {
      reason
      message
    }
  }
}
    `;
export const RegisterServiceProviderDocument = gql`
    mutation registerServiceProvider($input: ServiceProviderInput!) {
  registerServiceProvider(input: $input) {
    __typename
    ... on StringResponse {
      response
    }
    ... on Error {
      reason
      message
      field
    }
  }
}
    `;
export const SetAdminUserTenantDocument = gql`
    mutation setAdminUserTenant($input: ID!) {
  setAdminUserTenant(tenantId: $input) {
    ... on StringResponse {
      response
    }
    ... on Error {
      reason
      message
    }
  }
}
    `;
export const CurrencyDetailsDocument = gql`
    query currencyDetails {
  PaymentDetail {
    currency
  }
}
    `;
export const AdminTenantsDocument = gql`
    query adminTenants {
  adminTenants {
    id
    slug
  }
}
    `;
export const AvailableTimeslotsDocument = gql`
    query availableTimeslots($tenant: String!, $startDate: String!, $endDate: String!, $serviceId: String) {
  availableTimeslots(
    tenant: $tenant
    startDate: $startDate
    endDate: $endDate
    serviceId: $serviceId
  ) {
    service {
      id
      maxTicketCount
      ticketTypes {
        id
        primary
        ticketType {
          id
        }
      }
    }
    bookedTickets {
      timeslot {
        start
        end
      }
      tickets {
        bookedCount
        ticket {
          id
          displayName
          price
        }
      }
      extras {
        extra {
          id
          displayName
          price
        }
      }
    }
  }
}
    `;
export const BookingsForEventDocument = gql`
    query bookingsForEvent($input: ID) {
  bookingsForEvent(id: $input) {
    id
    contact {
      id
      fullName
      email
    }
    bookingNumber
    tickets {
      count
      price
    }
  }
}
    `;
export const CheckoutStatusDocument = gql`
    query checkoutStatus($id: ID, $tenant: ID) {
  checkoutStatus(id: $id, tenant: $tenant) {
    contactId
    bookingId
    voucherId
    email
    bookingNumber
    bookingFlowType
    tenantId
  }
}
    `;
export const EventsByDateDocument = gql`
    query eventsByDate($start: String!, $end: String!) {
  eventsByDate(start: $start, end: $end) {
    id
    title
    start
    end
    totalTickets
    location
    service {
      id
      title
    }
  }
}
    `;
export const OnboardingStepsDocument = gql`
    query onboardingSteps {
  onboardingSteps {
    __typename
    ... on OnboardingStepsResponse {
      canAcceptPayments
      serviceAdded
      addressFilledOut
      companyDetailsFilledOut
    }
    ... on Error {
      reason
      message
    }
  }
}
    `;
export const ServicesByTenantDocument = gql`
    query servicesByTenant($input: String!, $locale: LocaleInputType) {
  servicesByTenant(tenant: $input, locale: $locale) {
    id
    title
    location
    shortDescription
    image {
      url
    }
    longDescription
    maxTicketCount
    vouchersEnabled
    ticketTypes {
      id
      primary
      ticketType(locale: $locale) {
        id
        name
        displayName
        price
      }
    }
    extras {
      id
      extra(locale: $locale) {
        id
        name
        displayName
        price
      }
    }
  }
}
    `;
export const TenantDetailsDocument = gql`
    query tenantDetails($input: String!) {
  tenantDetails(tenant: $input) {
    __typename
    ... on TenantDetailsResponse {
      displayName
      logoURL
      shortDescription
      websiteURL
      contactEmail
      currency
      country
      originalLanguage
      timezone
      googleAnalyticsMeasurementID
    }
    ... on Error {
      reason
      message
    }
  }
}
    `;
export const VoucherDetailsDocument = gql`
    query voucherDetails($input: ID!) {
  voucherDetails(voucherId: $input) {
    __typename
    ... on Voucher {
      bookingStatus
      tickets {
        ticket {
          id
          displayName
          name
        }
        price
        count
      }
      extras {
        extra {
          id
          name
        }
        price
        count
      }
      service {
        id
        title
      }
    }
    ... on Error {
      reason
      message
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    loginUser(variables?: LoginUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LoginUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoginUserMutation>(LoginUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'loginUser', 'mutation');
    },
    createBooking(variables?: CreateBookingMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateBookingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateBookingMutation>(CreateBookingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createBooking', 'mutation');
    },
    createPaymentAccountLink(variables?: CreatePaymentAccountLinkMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreatePaymentAccountLinkMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreatePaymentAccountLinkMutation>(CreatePaymentAccountLinkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createPaymentAccountLink', 'mutation');
    },
    createVoucherCheckout(variables?: CreateVoucherCheckoutMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateVoucherCheckoutMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateVoucherCheckoutMutation>(CreateVoucherCheckoutDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createVoucherCheckout', 'mutation');
    },
    registerServiceProvider(variables: RegisterServiceProviderMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RegisterServiceProviderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RegisterServiceProviderMutation>(RegisterServiceProviderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'registerServiceProvider', 'mutation');
    },
    setAdminUserTenant(variables: SetAdminUserTenantMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SetAdminUserTenantMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SetAdminUserTenantMutation>(SetAdminUserTenantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'setAdminUserTenant', 'mutation');
    },
    currencyDetails(variables?: CurrencyDetailsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CurrencyDetailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CurrencyDetailsQuery>(CurrencyDetailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'currencyDetails', 'query');
    },
    adminTenants(variables?: AdminTenantsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AdminTenantsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AdminTenantsQuery>(AdminTenantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'adminTenants', 'query');
    },
    availableTimeslots(variables: AvailableTimeslotsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AvailableTimeslotsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AvailableTimeslotsQuery>(AvailableTimeslotsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'availableTimeslots', 'query');
    },
    bookingsForEvent(variables?: BookingsForEventQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<BookingsForEventQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BookingsForEventQuery>(BookingsForEventDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'bookingsForEvent', 'query');
    },
    checkoutStatus(variables?: CheckoutStatusQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CheckoutStatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CheckoutStatusQuery>(CheckoutStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'checkoutStatus', 'query');
    },
    eventsByDate(variables: EventsByDateQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<EventsByDateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<EventsByDateQuery>(EventsByDateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'eventsByDate', 'query');
    },
    onboardingSteps(variables?: OnboardingStepsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OnboardingStepsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OnboardingStepsQuery>(OnboardingStepsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'onboardingSteps', 'query');
    },
    servicesByTenant(variables: ServicesByTenantQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ServicesByTenantQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ServicesByTenantQuery>(ServicesByTenantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'servicesByTenant', 'query');
    },
    tenantDetails(variables: TenantDetailsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<TenantDetailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TenantDetailsQuery>(TenantDetailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'tenantDetails', 'query');
    },
    voucherDetails(variables: VoucherDetailsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<VoucherDetailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VoucherDetailsQuery>(VoucherDetailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'voucherDetails', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;
export function getSdkWithHooks(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  const sdk = getSdk(client, withWrapper);
  const genKey = <V extends Record<string, unknown> = Record<string, unknown>>(name: string, object: V = {} as V): SWRKeyInterface => [name, ...Object.keys(object).sort().map(key => object[key])];
  return {
    ...sdk,
    useCurrencyDetails(variables?: CurrencyDetailsQueryVariables, config?: SWRConfigInterface<CurrencyDetailsQuery, ClientError>) {
      return useSWR<CurrencyDetailsQuery, ClientError>(genKey<CurrencyDetailsQueryVariables>('CurrencyDetails', variables), () => sdk.currencyDetails(variables), config);
    },
    useAdminTenants(variables?: AdminTenantsQueryVariables, config?: SWRConfigInterface<AdminTenantsQuery, ClientError>) {
      return useSWR<AdminTenantsQuery, ClientError>(genKey<AdminTenantsQueryVariables>('AdminTenants', variables), () => sdk.adminTenants(variables), config);
    },
    useAvailableTimeslots(variables: AvailableTimeslotsQueryVariables, config?: SWRConfigInterface<AvailableTimeslotsQuery, ClientError>) {
      return useSWR<AvailableTimeslotsQuery, ClientError>(genKey<AvailableTimeslotsQueryVariables>('AvailableTimeslots', variables), () => sdk.availableTimeslots(variables), config);
    },
    useBookingsForEvent(variables?: BookingsForEventQueryVariables, config?: SWRConfigInterface<BookingsForEventQuery, ClientError>) {
      return useSWR<BookingsForEventQuery, ClientError>(genKey<BookingsForEventQueryVariables>('BookingsForEvent', variables), () => sdk.bookingsForEvent(variables), config);
    },
    useCheckoutStatus(variables?: CheckoutStatusQueryVariables, config?: SWRConfigInterface<CheckoutStatusQuery, ClientError>) {
      return useSWR<CheckoutStatusQuery, ClientError>(genKey<CheckoutStatusQueryVariables>('CheckoutStatus', variables), () => sdk.checkoutStatus(variables), config);
    },
    useEventsByDate(variables: EventsByDateQueryVariables, config?: SWRConfigInterface<EventsByDateQuery, ClientError>) {
      return useSWR<EventsByDateQuery, ClientError>(genKey<EventsByDateQueryVariables>('EventsByDate', variables), () => sdk.eventsByDate(variables), config);
    },
    useOnboardingSteps(variables?: OnboardingStepsQueryVariables, config?: SWRConfigInterface<OnboardingStepsQuery, ClientError>) {
      return useSWR<OnboardingStepsQuery, ClientError>(genKey<OnboardingStepsQueryVariables>('OnboardingSteps', variables), () => sdk.onboardingSteps(variables), config);
    },
    useServicesByTenant(variables: ServicesByTenantQueryVariables, config?: SWRConfigInterface<ServicesByTenantQuery, ClientError>) {
      return useSWR<ServicesByTenantQuery, ClientError>(genKey<ServicesByTenantQueryVariables>('ServicesByTenant', variables), () => sdk.servicesByTenant(variables), config);
    },
    useTenantDetails(variables: TenantDetailsQueryVariables, config?: SWRConfigInterface<TenantDetailsQuery, ClientError>) {
      return useSWR<TenantDetailsQuery, ClientError>(genKey<TenantDetailsQueryVariables>('TenantDetails', variables), () => sdk.tenantDetails(variables), config);
    },
    useVoucherDetails(variables: VoucherDetailsQueryVariables, config?: SWRConfigInterface<VoucherDetailsQuery, ClientError>) {
      return useSWR<VoucherDetailsQuery, ClientError>(genKey<VoucherDetailsQueryVariables>('VoucherDetails', variables), () => sdk.voucherDetails(variables), config);
    }
  };
}
export type SdkWithHooks = ReturnType<typeof getSdkWithHooks>;