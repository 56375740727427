import { createStore, useStore } from "zustand";
import { persist, PersistStorage } from "zustand/middleware";
import { Timeslot } from "../components/ServiceSelector/ServiceSelector.types";
import superjson from "superjson";
import {
  BookingFlowEntryType,
  BookingFlowType,
} from "../../models/constants/BookingFlowType";
import { sdk } from "../hooks/useSDK";

const storage: PersistStorage<UserStore> = {
  getItem: (name) => {
    if (Boolean(localStorage)) {
      const item = localStorage.getItem(name);
      if (!item) return null;
      return superjson.parse(item);
    }
    return null;
  },
  setItem: (name, value) => {
    if (Boolean(localStorage)) {
      localStorage.setItem(name, superjson.stringify(value));
    }
  },
  removeItem: (name) => {
    if (Boolean(localStorage)) {
      localStorage.removeItem(name);
    }
  },
};

export type UserStore = {
  bookingFlowType: BookingFlowType;
  bookingFlowEntryType: BookingFlowEntryType;
  setBookingFlowType: (bookingFlowType: BookingFlowType) => void;
  setBookingFlowEntryType: (bookingFlowEntryType: BookingFlowEntryType) => void;
  locale: string | null;
  setLocale: (locale: string) => void;
  month?: Date;
  setMonth: (month: Date) => void;
  selectedDate?: Date | null;
  setSelectedDate: (date: Date | null) => void;
  selectedEvent?: { id: string; name: string } | null;
  setSelectedEvent: (selectedEvent: { id: string; name: string } | null) => void;
  selectedTimeslot: Timeslot | null;
  setSelectedTimeslot: (timeslot: Timeslot) => void;
  selectedTickets?: { tickets: Record<string, number>; extras: Record<string, number> };
  updateSelectedTickets: (ticketId: string, quantity: number, isExtra: boolean) => void;
  clearSelectedTickets: () => void;
  voucherCode?: string | null;
  setVoucherCode: (voucherCode: string) => void;
  fetchVoucherDetails: () => Promise<void>;
  reset: () => void;
};

export const userStore = createStore<UserStore>()(
  persist(
    (set, get) => ({
      bookingFlowType: BookingFlowType.FullBooking,
      bookingFlowEntryType: BookingFlowEntryType.Default,
      setBookingFlowType: (bookingFlowType) => set({ bookingFlowType }),
      setBookingFlowEntryType: (bookingFlowEntryType) => set({ bookingFlowEntryType }),
      locale: null,
      setLocale: (locale) => set({ locale }),
      selectedDate: null,
      setSelectedDate: (date) => set({ selectedDate: date }),
      selectedEvent: null,
      month: new Date(),
      setMonth: (month) => set({ month }),
      setSelectedEvent: (selectedEvent) => set({ selectedEvent }),
      selectedTimeslot: null,
      setSelectedTimeslot: (timeslot) => set({ selectedTimeslot: timeslot }),
      selectedTickets: { tickets: {}, extras: {} },
      updateSelectedTickets: (ticketId, quantity, isExtra) => {
        const selectedTickets = { ...get().selectedTickets };
        if (quantity === 0) {
          delete selectedTickets[isExtra ? "extras" : "tickets"][ticketId];
        } else {
          selectedTickets[isExtra ? "extras" : "tickets"][ticketId] = quantity;
        }
        set({ selectedTickets });
      },
      clearSelectedTickets: () => set({ selectedTickets: { tickets: {}, extras: {} } }),
      voucherCode: null,
      setVoucherCode: (voucherCode) => set({ voucherCode }),
      fetchVoucherDetails: async () => {
        const voucherCode = get().voucherCode;
        if (!voucherCode) {
          return;
        }

        const result = await sdk.voucherDetails({ input: voucherCode });
        if (result.voucherDetails?.__typename === "Voucher") {
          userStore.setState({
            selectedEvent: {
              id: result.voucherDetails.service.id,
              name: result.voucherDetails.service.title,
            },
            selectedTickets: {
              tickets: result.voucherDetails.tickets.reduce((acc, ticket) => {
                acc[ticket.ticket.id] = ticket.count;
                return acc;
              }, {} as Record<string, number>),
              extras: result.voucherDetails.extras.reduce((acc, extra) => {
                acc[extra.extra.id] = extra.count;
                return acc;
              }, {} as Record<string, number>),
            },
          });
        } else if (result.voucherDetails?.__typename === "Error") {
          set({ voucherCode: null, bookingFlowEntryType: BookingFlowEntryType.Default });
        }
      },
      reset: () =>
        set({
          bookingFlowType: BookingFlowType.FullBooking,
          bookingFlowEntryType: BookingFlowEntryType.Default,
          selectedDate: null,
          selectedEvent: null,
          selectedTimeslot: null,
          selectedTickets: { tickets: {}, extras: {} },
        }),
    }),
    {
      name: "userStore",
      version: 1,
      storage,
      partialize: (state) =>
        ({
          month: state.month,
          bookingFlowType: state.bookingFlowType,
          bookingFlowEntryType: state.bookingFlowEntryType,
          selectedDate: state.selectedDate,
          selectedEvent: state.selectedEvent,
          selectedTimeslot: state.selectedTimeslot,
          selectedTickets: state.selectedTickets,
          voucherCode: state.voucherCode,
        } as UserStore),
    }
  )
);

export const useUserStore = () => useStore(userStore);
