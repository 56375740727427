import React from "react";
import { useCallback, useEffect } from "react";
import GlobeIcon from "../../icons/GlobeIcon.svg";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useUserStore } from "../../store/user.store";

const LanguageSelector = ({ originalLanguage }: { originalLanguage?: string }) => {
  const { i18n } = useTranslation();
  const language = i18n.resolvedLanguage;
  const { setLocale } = useUserStore();

  // TODO: use dropdown (or some other solution) when more languages are added
  const changeLanguage = useCallback(() => {
    if (language === "en" && originalLanguage) {
      i18n.changeLanguage(originalLanguage);
    } else {
      i18n.changeLanguage("en");
    }
  }, [language, originalLanguage]);

  useEffect(() => {
    if (language && originalLanguage) {
      setLocale(language === "en" ? "en" : "original");
    }
  }, [language, originalLanguage]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.5}
      sx={{
        cursor: "pointer",
        userSelect: "none",
      }}
      onClick={changeLanguage}
    >
      <img src={GlobeIcon} />
      <Typography fontSize={10} textTransform="uppercase">
        {language}
      </Typography>
    </Stack>
  );
};

export default LanguageSelector;
