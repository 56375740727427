export enum BookingFlowType {
  FullBooking = "FULL_BOOKING",
  VoucherPurchase = "VOUCHER_PURCHASE",
}

export enum BookingFlowEntryType {
  Default = "DEFAULT",
  ServicePreselect = "SERVICE_PRESELECT",
  VoucherRedeem = "VOUCHER_REDEEM",
}
