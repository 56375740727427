import React from "react";
import { useLocation } from "wouter";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { MainLayout } from "../../layouts/MainLayout/MainLayout";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { useFeTranslation } from "../../hooks/useFeTranslation";
import { CalendarLabels } from "../../components/CalendarLabels/CalendarLabels";
import { useUserStore } from "../../store/user.store";
import { useTenantStore } from "../../store/tenant.store";
import { DatePickerCalendar } from "../../components/DatePickerCalendar/DatePickerCalendar";
import { Step, useStep } from "../../hooks/useStep";
import { BookingFlowSelector } from "../../components/BookingFlowSelector/BookingFlowSelector";
import {
  BookingFlowEntryType,
  BookingFlowType,
} from "../../../models/constants/BookingFlowType";
import { useEffect } from "react";
import EyeIcon from "../../icons/EyeIcon.svg";
import KeyboardArrowRightIcon from "../../icons/KeyboardArrowRightIcon.svg";

export default function DatePage() {
  const [, setLocation] = useLocation();
  const { t } = useFeTranslation();
  const { goForward, goTo, goToStep, search } = useStep();

  const {
    selectedEvent,
    selectedDate,
    setSelectedEvent,
    setSelectedDate,
    bookingFlowType,
    bookingFlowEntryType,
    month,
    setMonth,
    setBookingFlowType,
    setBookingFlowEntryType,
    voucherCode,
    setVoucherCode,
    reset,
  } = useUserStore();
  const { availableTimeslots, services } = useTenantStore();
  const hasVoucherPurchaseService = services.filter((s) => s.vouchersEnabled).length > 0;

  useEffect(() => {
    setSelectedDate(null);
  }, []);

  useEffect(() => {
    const voucher = search.get("voucher");
    if (voucher && voucher?.length > 0) {
      setVoucherCode(voucher);

      if (bookingFlowEntryType !== BookingFlowEntryType.VoucherRedeem) {
        setBookingFlowEntryType(BookingFlowEntryType.VoucherRedeem);
        setBookingFlowType(BookingFlowType.FullBooking);
      }
    }
  }, [search, bookingFlowEntryType]);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    goForward();
  };

  const handleServiceDetailsClick = () => {
    if (!selectedEvent?.id) return;
    goTo(`/previewservice/${selectedEvent.id}`);
  };

  return (
    <MainLayout
      tenantInfoPosition="left"
      onBackButtonClick={
        bookingFlowEntryType === BookingFlowEntryType.ServicePreselect
          ? () => {
              setLocation(`/bookservice/${selectedEvent?.id}`);
            }
          : undefined
      }
    >
      {hasVoucherPurchaseService &&
      bookingFlowEntryType !== BookingFlowEntryType.VoucherRedeem ? (
        <BookingFlowSelector />
      ) : null}
      {bookingFlowType !== BookingFlowType.VoucherPurchase ? (
        <PageTitle title={t("pages.date.title")} helpText={t("pages.date.helpText")} />
      ) : null}
      {selectedEvent ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginTop={1}
          gap={1}
        >
          <Stack direction="row" gap="5px">
            <Typography fontSize={20} fontWeight={700}>
              {selectedEvent.name}
            </Typography>
            <Box
              component="img"
              src={EyeIcon}
              sx={{ cursor: "pointer" }}
              onClick={handleServiceDetailsClick}
            />
          </Stack>
          <Link
            color="inherit"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedEvent(null);
              setBookingFlowEntryType(BookingFlowEntryType.Default);
              goToStep(Step.Date);
            }}
            sx={{
              textAlign: "right",
            }}
          >
            {bookingFlowEntryType !== BookingFlowEntryType.VoucherRedeem
              ? t("pages.date.viewAllEvents")
              : t("pages.date.discardVoucher")}
          </Link>
        </Stack>
      ) : null}
      {selectedEvent && bookingFlowEntryType === BookingFlowEntryType.VoucherRedeem ? (
        <Typography>{t("pages.date.usingVoucher")}</Typography>
      ) : null}
      {bookingFlowType === BookingFlowType.VoucherPurchase ? (
        <Button
          sx={{
            width: "100%",
            maxWidth: "100%",
            margin: {
              md: "20px auto",
              xs: "15px auto",
            },
          }}
          variant="contained"
          color="secondary"
          endIcon={<img src={KeyboardArrowRightIcon} />}
          onClick={() => {
            goForward();
          }}
        >
          {t("common.next")}
        </Button>
      ) : (
        <Stack direction="column" alignContent="center">
          <DatePickerCalendar
            selectedDate={selectedDate}
            onChange={handleDateChange}
            month={month}
            setMonth={setMonth}
            availableTimeslots={availableTimeslots}
          />
          <CalendarLabels />
        </Stack>
      )}
    </MainLayout>
  );
}
